import { CardContent, CardHeader, CircularProgress } from "@material-ui/core";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import AzureAD, { AuthenticationState } from "react-aad-msal";
import { authProvider } from "../../common/authProvider";
import { SubscribeContext } from "../../common/subcriptionProvider";
import { useLazyGetNewestPublicationDate } from "../../graphql/GetNewestPublicationDate";
import {
  CardStyle,
  Link,
  SignUpText,
  UpdateMessage,
  WelcomeMessage,
  WelcomeSubText,
  WelcomeText,
} from "./WelcomeCard.css";

const WelcomeCard: React.FC = () => {
  const [PublicationDate, setPublicationDate] = useState<Date>();

  const { setErrorMessage } = useContext(SubscribeContext);

  const [newestPublicationDate, { data, loading, error }] = useLazyGetNewestPublicationDate();

  useEffect(() => {
    newestPublicationDate();
  }, [newestPublicationDate]);

  useEffect(() => {
    if (data) {
      setPublicationDate(data.getNewestPublicationDate.newestPublicationDate);
    }
    if (error) {
      setErrorMessage(error.message);
    }
  }, [data, error, setErrorMessage]);

  return (
    <CardStyle>
      <CardHeader
        title={
          <>
            <WelcomeText>
              Welcome to eFIRDS
              <WelcomeSubText>- a European database on financial instruments reference data.</WelcomeSubText>
            </WelcomeText>
          </>
        }
      />
      <CardContent>
        <WelcomeMessage>
          Search Issuers, LEI codes and ISINs listed on any recognized trading venue in the EU and UK. Verify regulatory
          compliance and find all instruments from an issuer with related company hierarchy structure.
        </WelcomeMessage>
        <UpdateMessage>
          <span>
            All data is updated daily at ~06.00 a.m. (CET) with reference data updates from European trading venues.
            Last updated:
          </span>
          {!loading ? <span>{moment(PublicationDate).format("DD/MM/YYYY")}</span> : <CircularProgress size={25} />}
        </UpdateMessage>
        <AzureAD provider={authProvider}>
          {(props: any) => {
            const { login, authenticationState } = props;
            switch (authenticationState) {
              case AuthenticationState.Authenticated:
                return null;
              case AuthenticationState.Unauthenticated:
                return (
                  <SignUpText>
                    <Link onClick={login}>Sign up</Link> to get full access.
                  </SignUpText>
                );
              default:
                return null;
            }
          }}
        </AzureAD>
      </CardContent>
    </CardStyle>
  );
};

export default WelcomeCard;
