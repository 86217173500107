import { gql } from "apollo-boost";
import { Subscription } from "../common/types";
import { useMutation } from "@apollo/react-hooks";

const REACTIVATE_SUBSCRIPTION = gql`
  mutation reactivateSubscription {
    reactivateSubscription {
      activeUntil
      cancelAtPeriodEnd
      canceledAt
      createdAt
      currentPeriodStart
      endedAt
      status
      stripeCustomerID
      subscriptionID
      userID
      paymentMethodID
      paymentIntent {
        clientSecret
        error
        status
      }
    }
  }
`;

interface ReactivateSubscription {
  reactivateSubscription: Subscription;
}

export const useMutationReactivateSubscription = () => useMutation<ReactivateSubscription>(REACTIVATE_SUBSCRIPTION);
