import React from "react";
import Grid from "@material-ui/core/Grid";
import { StyledGrid, StyledIcon, StyledTypography } from "./ErrorMessage.css";
import { Constants } from "../../common/constants";

function ErrorMessage() {
  return (
    <Grid item xs={12} justify={"center"} container>
      <StyledGrid xs={12} sm={8} item>
        <StyledIcon />
        <StyledTypography variant="h3">{Constants.errorMessage}</StyledTypography>
      </StyledGrid>
    </Grid>
  );
}
export default ErrorMessage;
