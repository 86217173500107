import { Box, Card, CardContent, CardHeader, makeStyles, Tab, Tabs, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { OtherInstrumentTable } from "..";
import { Instrument } from "../../common/types";
import { CFITypes } from "./OtherInstrumentTable";

const useStyles = makeStyles(() => ({
  tabs: {
    float: "right",
  },
}));
interface InstrumentListProps {
  instruments?: Instrument[];
}

export function NonDebtCard({ instruments = [] }: InstrumentListProps) {
  const classes = useStyles();
  const availableCfiTabs = Array.from(new Set(instruments.map((i) => i.finInstrmGnlAttrbts.clssfctnTp[0])));

  const [selectedCFI, setSelectedCFI] = useState<string>(availableCfiTabs[0] || "");

  const filteredNonDebtInstruments = filterInstruments(instruments, selectedCFI);

  return (
    <Box mt={2}>
      <Card>
        <CardHeader
          title={
            <>
              <Tabs
                value={selectedCFI}
                onChange={(e, value: string) => setSelectedCFI(value)}
                indicatorColor="primary"
                className={classes.tabs}
              >
                {availableCfiTabs.map((key) => (
                  <Tab value={key} label={CFITypes[key]} key={key} />
                ))}
              </Tabs>
              <Typography variant="h6">
                {instruments.length > 0 ? "Other Instruments" : "No Other Instruments"}
              </Typography>
            </>
          }
        />
        <CardContent>
          <OtherInstrumentTable instruments={filteredNonDebtInstruments} />
        </CardContent>
      </Card>
    </Box>
  );
}

function filterInstruments(instruments: Instrument[], filtersState: string) {
  let filteredInstruments = [...instruments];

  if (filtersState) {
    filteredInstruments = filteredInstruments.filter(
      (instrument) => instrument.finInstrmGnlAttrbts.clssfctnTp[0] === filtersState
    );
  }
  return filteredInstruments;
}
