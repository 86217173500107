import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { ITransparencyCalculation } from "../common/types";

const queryAuth = (isPremium: any) => {
  const GET_TRANSPARENCY_CALCULATION_FOR_INSTRUMENT = gql`
          query getTransparencyCalculationForInstrument(
            $isin: String,
            $fileSource: String,
          ) {
            getTransparencyCalculationForInstrument(
                isin: $isin,
                fileSource: $fileSource,
              ) {       
                  mifidIdentifier @include(if: ${isPremium})
                  liquidity @include(if: ${isPremium})
                  calculationMethodology @include(if: ${isPremium})
                  averageDailyTurnoverAmount @include(if: ${isPremium})
                  averageDailyTurnoverCurrency @include(if: ${isPremium})
                  averageValueOfTransactionsAmount @include(if: ${isPremium})
                  standardMarketSize @include(if: ${isPremium})
                  averageDailyNumberOfTransactions @include(if: ${isPremium})
                  averageValueOfTransactionsCurrency @include(if: ${isPremium})
                  totalNumberOfTransactions @include(if: ${isPremium})
                  totalVolumeOfTransactions @include(if: ${isPremium})
                  mostRelevantMarketInTermsOfLiquidity @include(if: ${isPremium})
                  averageDailyNumberOfTransactionsOnMRMTL @include(if: ${isPremium})
                  tradingActivityPeriodStart @include(if: ${isPremium})
                  tradingActivityPeriodEnd @include(if: ${isPremium})
                  calculationTime @include(if: ${isPremium})
                  preTradeLargeInScaleThresholdCurrency @include(if: ${isPremium})
                  preTradeLargeInScaleThresholdAmount @include(if: ${isPremium})
                  postTradeLargeInScaleThresholdAmount @include(if: ${isPremium})
                  postTradeLargeInScaleThresholdCurrency @include(if: ${isPremium})
                  preTradeSizeSpecificToTheInstrumentThresholdAmount @include(if: ${isPremium})
                  preTradeSizeSpecificToTheInstrumentThresholdCurrency @include(if: ${isPremium})
                  postTradeSizeSpecificToTheInstrumentThresholdAmount @include(if: ${isPremium})
                  postTradeSizeSpecificToTheInstrumentThresholdCurrency @include(if: ${isPremium})
                  
              }
          }
      `;
  return GET_TRANSPARENCY_CALCULATION_FOR_INSTRUMENT;
};

interface Params {
  isin: string;
  fileSource: string;
}

interface GetTransparencyCalculationForInstrument {
  getTransparencyCalculationForInstrument: ITransparencyCalculation[];
}

export const useLazyGetTransparencyCalculationForInstrument = (isPremium: any) =>
  useLazyQuery<GetTransparencyCalculationForInstrument, Params>(queryAuth(isPremium));
