import { Card } from "@material-ui/core";
import styled, { css } from "styled-components";
import mediaQuery from "../../../common/mediaQuery";

interface TreeDirectChildLineInterface {
  isLineRender: boolean;
}

interface TreeChildNestedLineInterface {
  isNestedChild: boolean;
}

export const CardHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;

  ${mediaQuery.SM_LANDSCAPE} {
    a {
      font-size: 0.8rem;
    }
  }

  ${mediaQuery.SM} {
    a {
      font-size: 0.8rem;
    }
  }
`;

export const CardLink = styled.div`
  padding: 20px;

  .MuiLink-underlineHover {
    font-weight: 700;

    &:hover {
      cursor: pointer;
      color: #40a9ff;
      text-decoration: none;
    }
  }
`;

export const CardTitle = styled.div`
  .MuiTypography-body1 {
    font-size: 25px;
    font-weight: 700;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    .MuiTypography-body1 {
      font-size: 1.1rem;
    }
  }

  ${mediaQuery.SM} {
    .MuiTypography-body1 {
      font-size: 1.1rem;
    }
  }
`;

export const StructureTree = styled.div`
  margin: 0 0 20px 70px;

  span {
    display: inherit;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    margin-left: 46px;
  }

  ${mediaQuery.SM} {
    margin-left: 46px;
  }
`;

export const Title = styled.div`
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  color: #3f18aa;

  ${mediaQuery.SM_LANDSCAPE} {
    font-size: 11px;
  }

  ${mediaQuery.SM} {
    font-size: 11px;
  }
`;

export const RootTitle = styled.div`
  position: relative;
  font-size: 16px;
  font-weight: 700;
  color: #000;

  ${mediaQuery.SM_LANDSCAPE} {
    font-size: 15px;
  }

  ${mediaQuery.SM} {
    font-size: 15px;
  }
`;

export const UltimateParentNode = styled.div`
  position: relative;
  padding: 10px 0;
`;

export const DirectParentNode = styled.div`
  position: relative;
  margin-left: 45px;

  ${mediaQuery.SM_LANDSCAPE} {
    margin-left: 30px;
  }

  ${mediaQuery.SM} {
    margin-left: 30px;
  }
`;

export const DirectParentDescription = styled.div`
  position: relative;
  padding: 10px 0;
`;

export const RootNode = styled.div`
  position: relative;
  margin-left: 45px;
  padding: 10px 0;

  ${mediaQuery.SM_LANDSCAPE} {
    margin-left: 30px;
    padding: 7px 0 18px 0;
  }

  ${mediaQuery.SM} {
    margin-left: 30px;
    padding: 7px 0 18px 0;
  }
`;

export const RootContainerNode = styled.div`
  position: relative;
`;

export const DirectChildrenNode = styled.div`
  position: relative;
  margin-left: 90px;
  padding: 10px 0;

  ${mediaQuery.SM_LANDSCAPE} {
    margin-left: 60px;
  }

  ${mediaQuery.SM} {
    margin-left: 60px;
  }
`;

export const DirectParentChildrenNode = styled.div`
  position: relative;
  margin-left: 45px;
  padding: 10px 0;

  ${mediaQuery.SM_LANDSCAPE} {
    margin-left: 30px;
  }

  ${mediaQuery.SM} {
    margin-left: 30px;
  }
`;

export const Circle = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  border: 2px solid #d2d2d2;
  border-radius: 20px;
  background-color: #f7f8f6;
  top: 4px;
  left: -45px;
  z-index: 1;

  ${mediaQuery.SM_LANDSCAPE} {
    width: 25px;
    height: 25px;
    left: -35px;
  }

  ${mediaQuery.SM} {
    width: 25px;
    height: 25px;
    left: -35px;
  }
`;

export const RootCircle = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  border: 2px solid #3f18aa;
  border-radius: 20px;
  background-color: #f7f8f6;
  top: 4px;
  left: -45px;
  z-index: 1;

  &:after {
    content: "";
    position: absolute;
    width: 18px;
    height: 18px;
    border: 2px solid #3f18aa;
    border-radius: 15px;
    background-color: #3f18aa;
    top: 4px;
    left: 4px;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    width: 25px;
    height: 25px;
    top: 6px;
    left: -35px;

    &:after {
      width: 15px;
      height: 15px;
      top: 3px;
      left: 3px;
    }
  }

  ${mediaQuery.SM} {
    width: 25px;
    height: 25px;
    top: 6px;
    left: -35px;

    &:after {
      width: 15px;
      height: 15px;
      top: 3px;
      left: 3px;
    }
  }
`;

export const ConnectCircle = styled.div`
  position: absolute;
  width: 25px;
  border-bottom: 2px solid #d2d2d2;
  left: -76px;
  top: 17px;

  ${mediaQuery.SM_LANDSCAPE} {
    width: 18px;
    left: -53px;
  }

  ${mediaQuery.SM} {
    width: 18px;
    left: -53px;
  }
`;

export const ConnectCircleRoot = styled.div`
  position: absolute;
  width: 70px;
  border-bottom: 2px solid #d2d2d2;
  left: -121px;
  top: 17px;
`;

export const TreeLine = styled.div`
  position: absolute;
  border-left: 2px solid #d2d2d2;
  height: 100%;
  top: 17px;
  left: -31px;

  ${mediaQuery.SM_LANDSCAPE} {
    left: -23px;
  }

  ${mediaQuery.SM} {
    left: -23px;
  }
`;

export const TreeParentLine = styled.div<TreeDirectChildLineInterface>`
  ${(props) =>
    !props.isLineRender
      ? `
          position: absolute;
          border-left: 2px solid #d2d2d2;
          height: 100%;
          top: 17px;
          left: -76px;
          
          ${mediaQuery.SM_LANDSCAPE} {
            left: -53px;
          }

          ${mediaQuery.SM} {
            left: -53px;
          }
          
        `
      : null}
`;

export const TreeChildNested = styled.div<TreeChildNestedLineInterface>`
  ${(props) =>
    props.isNestedChild
      ? css`
          position: absolute;
          border-left: 2px solid #d2d2d2;
          height: 72px;
          top: 17px;
          left: -31px;

          ${mediaQuery.SM_LANDSCAPE} {
            height: 67px;
            left: -23px;
          }

          ${mediaQuery.SM} {
            height: 59px;
            left: -23px;
          }
        `
      : null}
`;

export const CardStyle = styled(Card)`
  position: relative;
  height: 100%;
`;

export const TooltipTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
`;
