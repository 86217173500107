import { countries } from "country-data";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { InstrumentInterface } from "../../common/types";
import { LinkStyle } from "./InstrumentPage.css";
import moment from "moment";
import { IStateRows } from "./HistoricReportingTab/InstrumentHistoricReportingsInterface";
import { expiryDate, isDatePast } from "../../utils/calcDates";
import { nameIndxData } from "../../components/InstrumentDetails/mapNames";

const clearSessionFilters = () => {
  sessionStorage.removeItem("DEBT_filters");
  sessionStorage.removeItem("OTHER_filters");
};

const mapNameFunc = (data: string | undefined, mapObj: any) => {
  if (data) {
    let mappedValue: any = data;
    for (const [key, value] of Object.entries(mapObj)) {
      if (key === data) {
        mappedValue = value;
        return mappedValue;
      }
    }
    return mappedValue;
  } else {
    return null;
  }
};

export const mapInstrumentFunc = (gleifData: any, instrumentData: InstrumentInterface | undefined) => {
  const jurisdictionCode = () => {
    const legalJurisdiction = gleifData && gleifData.getIssuerByLEI.entityNew?.legalJurisdiction;
    const legalJurisdictionAuthority = legalJurisdiction?.includes("-")
      ? legalJurisdiction.split("-")[0]
      : legalJurisdiction;
    return legalJurisdictionAuthority;
  };

  const authority = jurisdictionCode();

  const setCountryByAuthority = (authority: string) => {
    const legalJurisdictionCountry = authority ? countries[authority].name : "N/A";
    return legalJurisdictionCountry;
  };

  const issuerRows = {
    "Issuer or trading venue name":
      gleifData && gleifData.getIssuerByLEI && gleifData.getIssuerByLEI.entityNew ? (
        <LinkStyle
          id="instrument_page__issuer_or_trading_venue_name"
          component={RouterLink}
          to={`/issuer/${instrumentData?.issuerOrOperatorIdentifier}`}
          onClick={clearSessionFilters}
        >
          {gleifData.getIssuerByLEI.entityNew.legalName.length > 25
            ? gleifData.getIssuerByLEI.entityNew.legalName.slice(0, 25) + "..."
            : gleifData.getIssuerByLEI.entityNew.legalName}
        </LinkStyle>
      ) : (
        "N/A"
      ),
    "LEI":
      gleifData && gleifData.getIssuerByLEI ? (
        <LinkStyle
          id="instrument_page__LEI"
          component={RouterLink}
          to={`/issuer/${instrumentData?.issuerOrOperatorIdentifier}`}
          onClick={clearSessionFilters}
        >
          {gleifData.getIssuerByLEI.lei}
        </LinkStyle>
      ) : (
        "N/A"
      ),
    "Jurisdiction": setCountryByAuthority(authority),
    "LEI code status in GLEIF": (gleifData && gleifData.getIssuerByLEI.entityNew?.entityStatus) || "N/A",
  };
  return { issuerRows };
};

const fieldNames: any = {
  totalIssuedNominalAmount: "Issued Amount",
  nominalValuePerUnit: "Value per Unit",
  interestType: "Interest Type",
  identifierOfTheIndex: "Identifier of the index/benchmark of a floating rate bond",
  nameOfTheIndex: "Name of the index/benchmark of a floating rate bond",
  spread: "Spread (basic pts)",
  firstTradeDate: "First Trade Date",
  instrumentFullName: "Instrument name",
  maturityDate: "Maturity Date",
  tradingVenue: "Trading Venue",
  instrumentClassification: "CFI Code",
  issuer: "Issuer (LEI Code)",
  fixedRate: "Interest rate",
  requestForAdmissionToTrading: "Request for Admission to Trading",
  commoditiesOrEmissionDerivativeIndicator: "Commodities Derivative Indicator",
  instrumentType: "Instrument Type",
  currency: "Notional Currency",
  upcomingRCA: "Authority",
  terminationDate: "Termination Date",
  referenceRate: "Term of the index/benchmark of a floating rate bond",
  basePointSpread: "Spread (basic pts)",
  notionalCurrency: "Notional currency",
};

export const compareHistoricalUpdates = (sortedData: any) => {
  const instrumentUpdatesRows = [];
  let updateDate = "";
  let updateField = "";

  for (let i = 0; i < sortedData.length - 1; i++) {
    for (const j in sortedData[i]) {
      if (sortedData[i].hasOwnProperty(j)) {
        if (
          sortedData[i][j] !== sortedData[i + 1][j] &&
          j !== "publicationDate" &&
          j !== "termOfTheIndexUnit" &&
          j !== "termOfTheIndexValue" &&
          j !== "referenceRate"
        ) {
          const oldValue =
            j === "maturityDate" ||
            j === "firstTradeDate" ||
            j === "requestForAdmissionToTrading" ||
            j === "terminationDate"
              ? sortedData[i + 1][j]
                ? moment(sortedData[i + 1][j]).format("DD/MM/YYYY ")
                : "N/A"
              : sortedData[i + 1][j];

          const newValue =
            j === "maturityDate" ||
            j === "firstTradeDate" ||
            j === "requestForAdmissionToTrading" ||
            j === "terminationDate"
              ? sortedData[i][j]
                ? moment(sortedData[i][j]).format("DD/MM/YYYY")
                : "N/A"
              : sortedData[i][j];

          instrumentUpdatesRows.push([
            updateDate === moment(sortedData[i].publicationDate).format("DD/MM/YYYY") && updateField === j
              ? ""
              : moment(sortedData[i].publicationDate).format("DD/MM/YYYY"),
            fieldNames[j],
            mapNameFunc(oldValue, nameIndxData) || "N/A",
            mapNameFunc(newValue, nameIndxData) || "N/A",
          ]);
          updateDate = moment(sortedData[i].publicationDate).format("DD/MM/YYYY");
          updateField = j;
        } else if (j === "referenceRate") {
          const oldValue = sortedData[i + 1].referenceRate;
          const newValue = sortedData[i].referenceRate;
          if (oldValue !== newValue) {
            instrumentUpdatesRows.push([
              updateDate === moment(sortedData[i].publicationDate).format("DD/MM/YYYY") && updateField === j
                ? ""
                : moment(sortedData[i].publicationDate).format("DD/MM/YYYY"),
              "Reference Rate",
              oldValue.trim() || "N/A",
              newValue.trim() || "N/A",
            ]);
          }
        }
      }
    }
  }
  return instrumentUpdatesRows;
};

export const sortInstrumentHistoricData = (instrumentHistory: any) => {
  return instrumentHistory.sort((a: any, b: any) =>
    moment(a.publicationDate).unix() > moment(b.publicationDate).unix() ? -1 : 1
  );
};

const formatAmount = (amount: string) => {
  return amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};

const formatReferenceRate = (referenceRate: any) => {
  if (!referenceRate) return;

  if (referenceRate && !referenceRate.trim()) {
    return "N/A";
  } else if (referenceRate.includes("DAYS")) {
    return referenceRate.replace("DAYS", "day(s)");
  } else if (referenceRate.includes("MNTH")) {
    return referenceRate.replace("DAYS", "month(s)");
  } else if (referenceRate.includes("YEAR")) {
    return referenceRate.replace("DAYS", "year(s)");
  } else {
    return referenceRate;
  }
};
export const generateStateRows = (data: IStateRows | null, lei: any) => {
  const nameOfTheIndex = mapNameFunc(data?.nameOfTheIndex, nameIndxData);

  return [
    {
      "Instrument name": data?.instrumentFullName || "N/A",
      "Issuer (LEI Code)": lei || "N/A",
      "Issued Amount": data?.totalIssuedNominalAmount
        ? `${formatAmount(data?.totalIssuedNominalAmount)} ${data?.notionalCurrency}`
        : "N/A",
      [isDatePast(data?.maturityDate, data?.terminationDate)
        ? "Min. Tradable Value"
        : "Value per Unit"]: data?.nominalValuePerUnit
        ? `${formatAmount(data?.nominalValuePerUnit)} ${data?.notionalCurrency}`
        : "N/A",
      "Interest Type": data?.interestType || "N/A",

      ...(data?.interestType === "Fixed"
        ? { "Interest Rate": `${data?.fixedRate} %` || "N/A" }
        : {
            "Identifier of the index/benchmark of a floating rate bond": data?.identifierOfTheIndex || "N/A",
            "Name of the index/benchmark of a floating rate bond": nameOfTheIndex || "N/A",
            "Term of the index/benchmark of a floating rate bond": formatReferenceRate(data?.referenceRate),
            "Spread (basic pts)": data?.basePointSpread ? data?.basePointSpread : "N/A",
          }),
    },
    {
      ...(data?.instrumentType === "Debt"
        ? {
            "First Trade Date": data?.firstTradeDate ? moment(data?.firstTradeDate).format("DD/MM/YYYY") : "N/A",
            "Maturity Date": moment(data?.maturityDate).format("DD/MM/YYYY"),
            "Trading Venues": data?.tradingVenue || "N/A",
            "CFI Code": data?.instrumentClassification || "N/A",
            "Request for Admission to Trading": data?.requestForAdmissionToTrading ? "Yes" : "No",
            "Commodities Derivative Indicator": data?.commoditiesOrEmissionDerivativeIndicator.toString(),
            "Notional Currency": data?.notionalCurrency ? data?.notionalCurrency : "N/A",
            "Instrument Type": data?.instrumentType || "N/A",
            "Termination date": data?.terminationDate ? moment(data?.terminationDate).format("DD/MM/YYYY") : "N/A",
          }
        : {
            "First Trade Date": data?.firstTradeDate ? moment(data?.firstTradeDate).format("DD/MM/YYYY") : "N/A",
            "Expiry Date": expiryDate(data?.terminationDate),
            "Trading Venues": data?.tradingVenue || "N/A",
            "CFI Code": data?.instrumentClassification || "N/A",
            "Authority": data?.upcomingRCA || "N/A",
            "Notional Currency": data?.notionalCurrency ? data?.notionalCurrency : "N/A",
            "Instrument Type": data?.instrumentType || "N/A",
            "Termination date": data?.terminationDate ? moment(data?.terminationDate).format("DD/MM/YYYY") : "N/A",
          }),
    },
  ];
};
