export const definitionsData: any = {
  "Access Data":
    "Username, password, payment details, subscription and contact information and other relevant data in order for a User to access the Service",
  "Database":
    "Systematized data on European financial instruments and issuer information gathered from ESMA FIRDS, FCA FIRDS and GLEI or other relevant sources",
  "Data Provider": "Stamdata AS",
  "Service": "The personalized premium subscription service giving a User access to the Database though the Website",
  "User": "An individual person with access to the Service",
  "User Account": "The Users’ individual account for accessing the Service",
  "Use Terms": "This user terms together with any annexes",
  "Website": "www.efirds.eu",
};
