import { CardContent } from "@material-ui/core";
import styled from "styled-components";
import mediaQuery from "../../common/mediaQuery";

export const Title = styled.div`
  display: flex;
  flex-direction: column;

  span {
    &:nth-child(1) {
      font-size: 25px;
      font-weight: 700;

      ${mediaQuery.SM} {
        font-size: 1.1rem;
      }
    }

    &:nth-child(2) {
      padding-top: 5px;
      font-size: 14px;
      font-weight: 700;

      ${mediaQuery.SM} {
        font-size: 0.6rem;
      }
    }

    a {
      color: #3f18aa;
    }
  }
`;

export const Image = styled.img`
  width: 100%;
  cursor: pointer;
`;

export const TooltipText = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
`;

export const CardContentStyle = styled(CardContent)`
  display: flex;
  padding: 0 16px;
  justify-content: center;
  max-height: 240px;

  ${mediaQuery.SM} {
    padding: 0 10px;
  }
`;

export const StyledCardFooter = styled.span`
  display: flex;
  justify-content: space-around;
  display: flex;
  font-weight: 500;
  padding: 12px;
  font-size: 13px;

  ${mediaQuery.SM} {
    font-size: 0.6rem;
    white-space: nowrap;
    flex-wrap: wrap;
    justify-content: flex-start;

    span {
      width: 33%;
    }
  }
`;
