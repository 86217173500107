import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { CardStyle, TransparencyCalculationWrapper } from "./TransparencyCalculations.css";
import { ITransparencyCalculations } from "./TransparencyCalculationsInterface";
import TransparencyCalculationsFileList from "./TransparencyCalculationsFileList/TransparencyCalculationsFileList";
import TransparencyCalculationsDetails from "./TransparencyCalculationDetails/TransparencyCalculationsDetails";

const TransparencyCalculations: React.FC<ITransparencyCalculations> = ({ isin, tab }) => {
  const [selectedFileESMA, setSelectedFileESMA] = useState<any>();
  const [selectedFileFCA, setSelectedFileFCA] = useState<any>();

  return (
    <TransparencyCalculationWrapper>
      <Grid container item spacing={2}>
        <Grid item xs={12} lg={6}>
          <CardStyle>
            <TransparencyCalculationsFileList
              isin={isin}
              fileSource="ESMA"
              tab={tab}
              setSelectedFile={setSelectedFileESMA}
            />
          </CardStyle>
        </Grid>
        <Grid item xs={12} lg={6}>
          <CardStyle>
            <TransparencyCalculationsDetails selectedFile={selectedFileESMA} />
          </CardStyle>
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12} lg={6}>
          <CardStyle>
            <TransparencyCalculationsFileList
              isin={isin}
              fileSource="FCA"
              tab={tab}
              setSelectedFile={setSelectedFileFCA}
            />
          </CardStyle>
        </Grid>
        <Grid item xs={12} lg={6}>
          <CardStyle>
            <TransparencyCalculationsDetails selectedFile={selectedFileFCA} />
          </CardStyle>
        </Grid>
      </Grid>
    </TransparencyCalculationWrapper>
  );
};

export default TransparencyCalculations;
