import styled from "styled-components";

export const PeriodsProgressContainer = styled.div`
  display: flex;
  align-items: "center";
  min-width: 180px;
`;

export const ProgressContainer = styled.div`
  flex: 1;
  margin: 0;
  position: relative;
`;

export const NotAvailable = styled.span`
  display: block;
  text-align: left;
`;
