import styled from "styled-components";
import { Typography } from "@material-ui/core";
import mediaQuery from "../../common/mediaQuery";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import Grid from "@material-ui/core/Grid";

export const StyledTypography = styled(Typography)`
  display: inline-block;
  text-align: center;
  font-weight: 300;

  ${mediaQuery.SM} {
    font-size: 20px;
  }
`;

export const StyledIcon = styled(SentimentVeryDissatisfiedIcon)`
  font-size: 250px;
  margin-bottom: 40px;
  color: #bbbbbb;

  ${mediaQuery.SM} {
    font-size: 150px;
  }
`;

export const StyledGrid = styled(Grid)`
  margin-top: 25vh;
  display: flex;
  flex-wrap: wrap;
  height: max-content;
  align-items: center;
  justify-content: center;

  ${mediaQuery.SM} {
    margin-top: 20vh;
  }
`;
