import { Card, Link, Select, TableBody, TableCell, Typography, MenuItem } from "@material-ui/core";
import styled, { css } from "styled-components";
import mediaQuery from "../../common/mediaQuery";

interface SelectStyleInterface {
  disabled: boolean;
}

interface TitleDropdownWrapperInterface {
  media: number;
}
interface ILinkStyled {
  visible?: number;
}

export const CardStyle = styled(Card)`
  position: relative;
  min-height: 170px;
`;

export const TypographyStyle = styled(Typography)`
  font-weight: 700;

  ${mediaQuery.SM} {
    font-size: 0.7rem;
    margin-bottom: 10px;
  }
`;

export const TableCellStyle = styled(TableCell)`
  padding: 6px 24px 6px 0;
  width: 50%;
`;

export const TableCellHeader = styled(TableCell)`
  padding: 6px 24px 6px 0;
  font-weight: 700;
`;

export const Title = styled.div`
  font-weight: 700;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LinkStyle = styled(Link)<ILinkStyled>`
  display: ${({ visible }) => (visible ? "block" : "none")};

  &:hover {
    cursor: pointer;
    color: #40a9ff;
    text-decoration: none;
  }

  ${mediaQuery.SM} {
    position: absolute;
    right: 10px;
    text-align: center;
    width: 90px;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  p {
    text-align: right;
  }

  ${mediaQuery.SM} {
    flex-wrap: wrap;
  }
`;

export const TitleDropdownWrapper = styled.div<TitleDropdownWrapperInterface>`
  display: flex;

  ${(props) =>
    props.media
      ? css`
          flex-direction: column;
          h5 {
            margin-bottom: 10px;
          }
        `
      : css`
          h5 {
            margin-right: 10px;
          }
        `}
`;

export const SelectStyle = styled(Select)<SelectStyleInterface>`
  background-color: ${(props) => (props.disabled ? "#e3e3e3" : "")};

  display: inline-flex;

  &.MuiOutlinedInput-root {
    height: ${(props) => (props.disabled ? "35px" : "45px")};
    max-width: 260px;
    min-width: 120px;
  }

  .MuiSelect-select:focus {
    background: none;
  }

  .MuiSelect-root {
    div span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  &[data-active="false"] {
    color: #d8d8d8;
    position: relative;
  }
  &[data-active="false"]::before {
    color: #d8d8d8;
    top: 2px;
    right: 5px;
    font-size: 13px;
    content: "terminated";
    position: absolute;
  }
`;

export const SelectItem = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;

  span {
    &:nth-child(1) {
      font-weight: 700;
    }
  }
`;

export const StyledTableBody = styled(TableBody)`
  tr:last-child td {
    border-bottom: none;
  }
`;

export const EmptyCard = styled.div`
  text-align: center;
  color: #cbcbcb;
`;
