import { Box, CardContent, CardHeader, Link, Typography } from "@material-ui/core";
import SubjectIcon from "@material-ui/icons/Subject";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SubscribeContext } from "../../common/subcriptionProvider";
import { useLazyGetIssuerRelationsBasicQuery } from "../../graphql/GetIssuerRelationsBasic";
import Loader from "../loader/Loader";
import {
  CardHeaderContainer,
  CardLink,
  CardStyle,
  CardTitle,
  Circle,
  ConnectCircle,
  ConnectCircleRoot,
  DirectChildrenNode,
  DirectParentChildrenNode,
  DirectParentDescription,
  DirectParentNode,
  EmptyCard,
  Message,
  RootCircle,
  RootContainerNode,
  RootNode,
  RootTitle,
  StructureTree,
  Title,
  TreeBasicLine,
  TreeLine,
  TreeParentLine,
  UltimateParentNode,
} from "./StructureBasic.css";
import { IssuerRelationsBasicInterface } from "./StructureBasicInterface";

interface Props {
  lei: string;
  isIssuerDataLoaded: any;
  handleTabChange: Function;
  isStructureBasic: boolean;
  setStructureBasic: Function;
  setIsStructureBasicLoaded: Function;
  setIsStructureBasicEmpty: Function;
}

const StructureCard: React.FC<Props> = ({
  lei,
  isIssuerDataLoaded,
  handleTabChange,
  isStructureBasic,
  setStructureBasic,
  setIsStructureBasicLoaded,
  setIsStructureBasicEmpty,
}) => {
  const history = useHistory();

  const [issuersData, setIssuersData] = useState<IssuerRelationsBasicInterface>();
  const { isUserPremium, setErrorMessage } = useContext(SubscribeContext);

  const cardTitle = "Corporate Group Issues";

  const hideDuplicateParent =
    issuersData && issuersData.ultimateParent && issuersData.directParent
      ? issuersData.ultimateParent.lei !== issuersData.directParent.lei
      : true;

  const [
    getIssuerRelationsBasic,
    { data: issuers, loading: loadingIssuersData, error: issuersRelationError },
  ] = useLazyGetIssuerRelationsBasicQuery(true);

  useEffect(() => {
    if (isIssuerDataLoaded && lei) {
      getIssuerRelationsBasic({ variables: { lei } });
    }
  }, [getIssuerRelationsBasic, lei, isIssuerDataLoaded]);

  useEffect(() => {
    if (issuersRelationError) {
      setErrorMessage(issuersRelationError.message);
    }

    if (issuers && issuers?.getIssuerRelationsBasic) {
      setIssuersData(issuers.getIssuerRelationsBasic);
    }

    if (
      issuers &&
      issuers?.getIssuerRelationsBasic &&
      issuers?.getIssuerRelationsBasic.ultimateParent === null &&
      issuers?.getIssuerRelationsBasic.directParent === null &&
      issuers.getIssuerRelationsBasic.directChildren.length === 0
    ) {
      setStructureBasic(false);
      setIsStructureBasicEmpty(true);
      setIsStructureBasicLoaded(true);
    } else if (
      (issuers && issuers?.getIssuerRelationsBasic && issuers?.getIssuerRelationsBasic.ultimateParent) ||
      issuers?.getIssuerRelationsBasic.directParent ||
      issuers?.getIssuerRelationsBasic.directChildren
    ) {
      setStructureBasic(true);
      setIsStructureBasicEmpty(false);
      setIsStructureBasicLoaded(true);
    }
  }, [
    issuers,
    setStructureBasic,
    setIsStructureBasicLoaded,
    lei,
    issuersRelationError,
    setErrorMessage,
    setIsStructureBasicEmpty,
  ]);

  useEffect(() => {
    if (!isUserPremium) {
      setIsStructureBasicLoaded(false);
    }
  }, [isUserPremium, setIsStructureBasicLoaded]);

  return (
    <>
      {!loadingIssuersData && issuersData ? (
        <CardStyle>
          <CardHeader
            title={
              <Box>
                <CardHeaderContainer>
                  <CardTitle>
                    <Typography variant="h5">{cardTitle}</Typography>
                  </CardTitle>
                  {issuersData && isStructureBasic && (
                    <CardLink>
                      <Typography>
                        <Link id="issuer_page__see_all_corporate_group_issues" onClick={() => handleTabChange(null, 1)}>
                          See all
                        </Link>
                      </Typography>
                    </CardLink>
                  )}
                </CardHeaderContainer>
              </Box>
            }
          />
          <CardContent>
            {isStructureBasic ? (
              <StructureTree>
                {issuersData.ultimateParent && hideDuplicateParent && (
                  <UltimateParentNode>
                    <Title
                      id="issuer_page__overview_issuer"
                      onClick={() => history.push(`/Issuer/${issuersData.ultimateParent.lei}`)}
                    >
                      {issuersData.ultimateParent.name}
                    </Title>
                    <span>{`Debt: ${issuersData.ultimateParent.instrumentsAmount.debt} instruments`}</span>
                    <span>{`Equity: ${issuersData.ultimateParent.instrumentsAmount.equities} instruments`}</span>
                    <Circle />
                    <TreeLine />
                  </UltimateParentNode>
                )}
                <DirectParentNode>
                  {issuersData.directParent && (
                    <DirectParentDescription>
                      <Title
                        id="issuer_page__overview_issuer"
                        onClick={() => history.push(`/Issuer/${issuersData.directParent.lei}`)}
                      >
                        {issuersData.directParent.name}
                      </Title>
                      <span>{`Debt: ${issuersData.directParent.instrumentsAmount.debt} instruments`}</span>
                      <span>{`Equity: ${issuersData.directParent.instrumentsAmount.equities} instruments`}</span>
                      <Circle />
                      {issuersData.ultimateParent && hideDuplicateParent && <ConnectCircle />}
                      <TreeLine />
                    </DirectParentDescription>
                  )}
                  <RootContainerNode>
                    <RootNode>
                      <RootTitle>{issuersData.name}</RootTitle>
                      <RootCircle />
                      {issuersData.directParent && <ConnectCircle />}
                      {!issuersData.directParent && issuersData.ultimateParent && <ConnectCircleRoot />}
                      {issuersData.directChildren.length > 0 && <TreeLine />}
                    </RootNode>
                    {issuersData.directParent &&
                      issuersData.directParent.directChildren &&
                      issuersData.directParent.directChildren.length > 0 && <TreeLine />}

                    {issuersData.directChildren &&
                      issuersData.directChildren.map((child, index) => {
                        const isLineRender = issuersData.directChildren.length === index + 1;
                        return (
                          <DirectChildrenNode key={child.lei}>
                            <Title
                              id="issuer_page__overview_issuer"
                              onClick={() => history.push(`/Issuer/${child.lei}`)}
                            >
                              {child.name}
                            </Title>
                            <span>{`Debt: ${child.instrumentsAmount.debt} instruments`}</span>
                            <span>{`Equity: ${child.instrumentsAmount.equities} instruments`}</span>
                            <Circle />
                            <ConnectCircle />
                            <TreeParentLine isLineRender={isLineRender} />
                            {isLineRender &&
                              issuersData.directParent &&
                              issuersData.directParent.hasMoreDirectChildren && <TreeBasicLine />}
                          </DirectChildrenNode>
                        );
                      })}
                  </RootContainerNode>
                  {issuersData.directParent &&
                    issuersData.directParent.directChildren &&
                    issuersData.directParent.directChildren.map((child, index) => {
                      const isLineRender =
                        issuersData.directParent.directChildren &&
                        issuersData.directParent.directChildren.length === index + 1;
                      return (
                        <DirectParentChildrenNode key={child.lei}>
                          <Title id="issuer_page__overview_issuer" onClick={() => history.push(`/Issuer/${child.lei}`)}>
                            {child.name}
                          </Title>
                          <span>{`Debt: ${child.instrumentsAmount.debt} instruments`}</span>
                          <span>{`Equity: ${child.instrumentsAmount.equities} instruments`}</span>
                          <Circle />
                          <ConnectCircle />
                          <TreeParentLine isLineRender={isLineRender} />
                          {isLineRender && issuersData.directParent.hasMoreDirectChildren && <TreeBasicLine />}
                        </DirectParentChildrenNode>
                      );
                    })}
                </DirectParentNode>
              </StructureTree>
            ) : (
              <EmptyCard>
                <SubjectIcon fontSize="large" color="inherit" />
                <Message>No parents or children.</Message>
              </EmptyCard>
            )}
          </CardContent>
        </CardStyle>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default StructureCard;
