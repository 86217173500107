import { Card, CardHeader, Tooltip } from "@material-ui/core";
import React from "react";
import stamdata_promo from "../../assets/images/StamdataPromoImage.svg";
import { CardContentStyle, Image, StyledCardFooter, Title, TooltipText } from "./Promo.css";

const Promo: React.FC = () => {
  const tooltipTitle = "Visit stamdata.com";
  return (
    <>
      <Card>
        <CardHeader
          title={
            <Title>
              <span>
                Visit{" "}
                <a rel="preload" id="dashboard__visit_stamdata_link" href="https://new.stamdata.com/app/">
                  Stamdata.com
                </a>
              </span>
              <span>- The leading source of information for Nordic bond markets</span>
            </Title>
          }
        />
        <CardContentStyle>
          <Tooltip
            title={<TooltipText>{tooltipTitle}</TooltipText>}
            PopperProps={{
              popperOptions: {
                modifiers: {
                  offset: {
                    enabled: true,
                    offset: "0, -50%",
                  },
                },
              },
            }}
          >
            <Image
              id="dashboard__visit_stamdata_image"
              src={stamdata_promo}
              onClick={() => window.open("https://new.stamdata.com/app/", "_blank")}
            />
          </Tooltip>
        </CardContentStyle>
        <StyledCardFooter>
          <span>Announcements</span>
          <span>Reference data</span>
          <span>Corporate actions</span>
          <span>Loan docs</span>
          <span>Ratings</span>
        </StyledCardFooter>
      </Card>
    </>
  );
};

export default Promo;
