import { Card } from "@material-ui/core";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import UpdatePaymentCard from "./UpdatePaymentCard";
import { UpdateCardWrapper } from "./UpdatePaymentCard.css";

declare const window: any;

const stripePromise = loadStripe(window._env_.STRIPE_ID);

const UpdatePaymentCardElement = (props?: any) => {
  const { refreshCustomer } = props;
  return (
    <UpdateCardWrapper>
      <Card>
        <Elements stripe={stripePromise} options={{ locale: "en" }}>
          <UpdatePaymentCard refreshCustomer={refreshCustomer} />
        </Elements>
      </Card>
    </UpdateCardWrapper>
  );
};

export default UpdatePaymentCardElement;
