import { gql } from "apollo-boost";
import { Customer } from "../common/types";
import { useLazyQuery } from "@apollo/react-hooks";

const GET_CUSTOMER = gql`
 query getCustomer{
  getCustomer {
    created
    email
    id
    name
    paymentMethodDetails {
      brand
      country
      cvcCheck
      expMonth
      expYear
      id
      last4Digits
    }
  }
  }
`;



interface GetCustomer {
  getCustomer: Customer;
}

export const useLazyGetCustomerQuery = (params? : any ) =>
  useLazyQuery<GetCustomer>(GET_CUSTOMER,{fetchPolicy: params ? params.fetchPolicy : "cache-first"});
