import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import Loader from "../loader/Loader";
import { DataCardStyles, themeTypography } from "./DataCard.css";
import { DataCardProps } from "./DataCardInterface";

function DataCard({ title, rows, loading, style }: DataCardProps) {
  const classes = DataCardStyles();

  return (
    <Card className={classes.root} style={style}>
      <CardHeader
        title={
          <ThemeProvider theme={themeTypography}>
            <Typography variant="h5">{title}</Typography>
          </ThemeProvider>
        }
      />
      <CardContent>
        {loading ? (
          <Loader />
        ) : (
          <Table size="small">
            <TableBody>
              {Object.keys(rows).map((key: string) => (
                <TableRow key={key} className={classes.row}>
                  <TableCell scope="row" className={classes.keyCell}>
                    {key}
                  </TableCell>
                  <TableCell scope="row" className={classes.valueCell}>
                    {rows[key]}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </CardContent>
    </Card>
  );
}

export { DataCard };
