import styled from "styled-components";
import mediaQuery from "../../common/mediaQuery";
import StatusGreen from "../../assets/images/status_icon_green.png";
import StatusRed from "../../assets/images/status_icon_red.png";

export const PaymentsDetailsPageWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 100px;
  overflow: hidden;
  & > div:last-child {
    margin-top: 8px;
  }

  span[data-status="active"]:after {
    background-image: url(${StatusGreen});
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    content: "";
  }

  span[data-status="incomplete"]:after,
  span[data-status="incomplete_expired"]:after,
  span[data-status="trialing"]:after,
  span[data-status="past_due"]:after,
  span[data-status="unpaid"]:after,
  span[data-status="canceled"]:after {
    background-image: url(${StatusRed});
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    content: "";
  }

  ${mediaQuery.MD} {
    margin-top: 0;
  }

  ${mediaQuery.SM} {
    h5 {
      font-size: 1.1rem;
    }

    td {
      font-size: 10px;
    }

    .MuiCardHeader-root {
      padding: 16px 0 0 16px;
    }
  }
`;

export const StyledNoAuthorisedContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  svg {
    width: 100%;
    display: block;
    font-size: 6rem;
  }

  h1 {
    font-size: 40px;
    display: block;
    width: 100%;
    text-align: center;
  }

  h3 {
    font-size: 20px;
    display: block;
    width: 100%;
    text-align: center;
  }

  strong {
    font-size: 30px;
  }

  ${mediaQuery.SM} {
    margin-top: 10vh !important;

    h1 {
      font-size: 25px;
    }

    h3 {
      font-size: 15px;
    }

    strong {
      font-size: 20px;
    }
  }
`;
