import React, { useContext, useEffect, useState } from "react";
import qs from "qs";
import { useHistory } from "react-router-dom";
import { SearchField } from "../SearchField/SearchField";

import { useLazyGetIssuerInfoByLeiQuery } from "../../graphql/GetIssuerInfoByLei";
import { useLazyGetInstrumentSuggestionsQuery } from "../../graphql/GetInstrumentSuggestions";
import { useLazyGetIssuerSuggestionsQuery } from "../../graphql/GetIssuerSuggestions";
import { useLazyGetInstrumentAndIssuerSuggestionsQuery } from "../../graphql/GetInstrumentAndIssuerSuggestions";
import { AppContext } from "../../common/store";
import { SubscribeContext } from "../../common/subcriptionProvider";
import useDebounce from "../../hooks/useDebounce";
import { Constants } from "../../common/constants";

const SearchBar: React.FC = () => {
  const history = useHistory();
  const { setErrorMessage } = useContext(SubscribeContext);

  const searchQueryParam = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  }).search;
  const activeFilterQueryParam = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  }).filter;

  const searchInput = document.getElementById("searchField");
  const [searchText, setSearchText] = useState(searchQueryParam ? (searchQueryParam as string) : "");
  const filters = ["All", "Instrument (ISIN)", "Issuer (Name, LEI)"];
  const [activeFilter, setActiveFilter] = useState<string>(
    activeFilterQueryParam ? (activeFilterQueryParam as string) : filters[0]
  );
  const [openSugestionList, setOpenSugestionList] = useState(false);
  const debouncedSearchText = useDebounce(searchText.trim(), Constants.debounceSearchRate);

  const [
    getIssuerInfoByLei,
    { data: IssuerInfoByLei, loading: loadingIssuerInfoByLei, error: getIssuerInfoByLeiError },
  ] = useLazyGetIssuerInfoByLeiQuery();

  const [
    getInstrumentSugestions,
    { data: instrumentSugestions, loading: instrumentSuggestionLoading, error: getInstrumentSugestionsError },
  ] = useLazyGetInstrumentSuggestionsQuery();

  const { isTopSearchBarOpen, toggleTopSearchBar } = React.useContext(AppContext);

  const [
    getIssuerSugestions,
    { data: issuerSugestions, loading: issuerSuggestionLoading, error: getIssuerSugestionsError },
  ] = useLazyGetIssuerSuggestionsQuery();

  const [
    getInstrumentAndIssuerSuggestions,
    {
      data: instrumentsAndIssuerSuggestions,
      loading: instrumentAndIssuerSuggestionLoading,
      error: getInstrumentAndIssuerSuggestionsError,
    },
  ] = useLazyGetInstrumentAndIssuerSuggestionsQuery();

  useEffect(() => {
    if (getIssuerInfoByLeiError) {
      setErrorMessage(getIssuerInfoByLeiError.message);
    }
    if (getInstrumentSugestionsError) {
      setErrorMessage(getInstrumentSugestionsError.message);
    }
    if (getIssuerSugestionsError) {
      setErrorMessage(getIssuerSugestionsError.message);
    }
    if (getInstrumentAndIssuerSuggestionsError) {
      setErrorMessage(getInstrumentAndIssuerSuggestionsError.message);
    }
    if (
      searchInput &&
      (instrumentsAndIssuerSuggestions || instrumentSugestions || issuerSugestions || IssuerInfoByLei)
    ) {
      searchInput.focus();
    }
  }, [
    instrumentsAndIssuerSuggestions,
    issuerSugestions,
    instrumentSugestions,
    IssuerInfoByLei,
    searchInput,
    getIssuerInfoByLeiError,
    getInstrumentSugestionsError,
    getIssuerSugestionsError,
    getInstrumentAndIssuerSuggestionsError,
  ]);

  useEffect(() => {
    const isSearchByLei = debouncedSearchText.length === 20 && !debouncedSearchText.includes(" ");
    if (debouncedSearchText.length >= Constants.searchMinLength && !isSearchByLei) {
      if (activeFilter === filters[0]) {
        getInstrumentAndIssuerSuggestions({
          variables: { startingCharacters: debouncedSearchText },
        });
      }
      if (activeFilter === filters[1]) {
        getInstrumentSugestions({
          variables: { startingCharacters: debouncedSearchText },
        });
      }
      if (activeFilter === filters[2]) {
        getIssuerSugestions({
          variables: { startingCharacters: debouncedSearchText },
        });
      }
    }
    if (isSearchByLei) {
      getIssuerInfoByLei({ variables: { lei: debouncedSearchText } });
    }
  }, [debouncedSearchText]);

  const sugestionList = () => {
    if (
      instrumentsAndIssuerSuggestions &&
      !instrumentsAndIssuerSuggestions?.getInstrumentAndIssuerSuggestions.instruments &&
      instrumentsAndIssuerSuggestions &&
      !instrumentsAndIssuerSuggestions?.getInstrumentAndIssuerSuggestions.issuers
    )
      return [];
    if (activeFilter === filters[0]) {
      const issuerIsinSuggestion =
        instrumentsAndIssuerSuggestions && instrumentsAndIssuerSuggestions?.getInstrumentAndIssuerSuggestions
          ? [
              ...instrumentsAndIssuerSuggestions.getInstrumentAndIssuerSuggestions.instruments,
              ...instrumentsAndIssuerSuggestions.getInstrumentAndIssuerSuggestions.issuers,
            ]
          : [];
      const leiSuggestion =
        IssuerInfoByLei && IssuerInfoByLei.getIssuerByLEI
          ? [
              {
                lei: IssuerInfoByLei?.getIssuerByLEI.lei,
                name: IssuerInfoByLei?.getIssuerByLEI.entityNew.legalName,
                __typename: "IssuerSuggestion",
              },
            ]
          : [];
      return issuerIsinSuggestion.length ? issuerIsinSuggestion : leiSuggestion;
    }
    if (activeFilter === filters[1]) {
      return instrumentSugestions && instrumentSugestions?.getInstrumentSuggestions
        ? instrumentSugestions?.getInstrumentSuggestions
        : [];
    }
    if (activeFilter === filters[2]) {
      const issuerSuggestion =
        issuerSugestions && issuerSugestions.getIssuerSuggestions && issuerSugestions!.getIssuerSuggestions;
      const leiSuggestion =
        IssuerInfoByLei && IssuerInfoByLei.getIssuerByLEI && IssuerInfoByLei.getIssuerByLEI.entityNew
          ? [
              {
                lei: IssuerInfoByLei?.getIssuerByLEI.lei,
                name: IssuerInfoByLei?.getIssuerByLEI.entityNew.legalName,
                __typename: "IssuerSuggestion",
              },
            ]
          : [];
      return issuerSuggestion && issuerSuggestion.length ? issuerSuggestion : leiSuggestion;
    }
    return [];
  };

  const handleChange = (value: string) => {
    setOpenSugestionList(true);
    setSearchText(value);
  };

  const handleSubmit = (selectedItem: { lei?: string;  isin: string }) => {
    const id = selectedItem.lei || selectedItem.lei || selectedItem.isin || selectedItem.isin;
    if (id!.match(/^[A-Z]{2}[0-9A-Za-z]{9}[0-9]{1}$/)) {
      history.push(`/instrument/${id}`);
    } else {
      history.push(`/issuer/${id}`);
    }
    setOpenSugestionList(false);
  };

  const handleSearch = () => {
    const trimmedValue = searchText.trim();
    if (trimmedValue.length < 3) {
      return;
    }

    if (!(instrumentAndIssuerSuggestionLoading || instrumentSuggestionLoading || issuerSuggestionLoading)) {
      setOpenSugestionList(false);
      history.push(`/search?search=${trimmedValue}&filter=${activeFilter}`);
    }
  };

  return (
    <>
      <SearchField
        value={searchText}
        placeholder={"Search ISIN/Issuer Name/LEI"}
        onChange={handleChange}
        onSubmit={handleSubmit}
        sugestions={sugestionList()}
        loading={
          instrumentAndIssuerSuggestionLoading ||
          instrumentSuggestionLoading ||
          issuerSuggestionLoading ||
          loadingIssuerInfoByLei
        }
        disabled={false}
        onSearch={handleSearch}
        openSugestionList={openSugestionList}
        isTopSearchBarOpen={isTopSearchBarOpen}
        toggleTopSearchBar={toggleTopSearchBar}
        closeSugestions={() => setOpenSugestionList(false)}
        filters={filters}
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
      />
    </>
  );
};

export default SearchBar;
