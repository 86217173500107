import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { AuthenticationState, IAzureADFunctionProps } from "react-aad-msal";
import { authProvider } from "../common/authProvider";
import ErrorMessage from "../components/ErrorMessage/ErrorMessage";
import Loader from "../components/loader/Loader";
import { useLazyGetCurrentUserQuery } from "../graphql/GetCurrentUser";
import { appInsights } from "../utils/appInsights";
interface StoreProps {
  children: React.ReactNode;
  azureProps: IAzureADFunctionProps;
  errorMessage: string;
  setErrorMessage: Dispatch<SetStateAction<string>>;
}

const currentUserInitData = {
  role: "",
  country: "",
  displayName: "",
  email: "",
  givenName: "",
  groups: [],
  mobilePhone: "",
  profession: "",
  surname: "",
};

const subscriptionCtxDefaultValue = {
  userSubscription: null,
  isUserPremium: null,
  isStamdataUser: false,
  isFirstLogin: null,
  setUserSubscription: (userSubscription: null | boolean) => {},
  setIsFirstLogin: (isFirstLogin: null | boolean) => {},
  currentUserData: currentUserInitData,
  refreshContext: (setWithInterval: boolean) => {},
  azureProps: {} as IAzureADFunctionProps,
  errorMessage: "",
  setErrorMessage: (errorMessage: string) => {},
};

export const SubscribeContext = React.createContext(subscriptionCtxDefaultValue);

export const SubscriptionProvider = ({ children, azureProps, errorMessage, setErrorMessage }: StoreProps) => {
  const { authenticationState } = azureProps;

  const [isAuthCompleted, setIsAuthCompleted] = useState<boolean>(false);

  const [userSubscription, setUserSubscription] = useState<any>(subscriptionCtxDefaultValue.userSubscription);

  const [isUserPremium, setUserPremium] = useState<any>(subscriptionCtxDefaultValue.isUserPremium);

  const [isStamdataUser, setIsStamdataUser] = useState<boolean>(false);

  const [currentUserData, setCurrentUserData] = useState<any>(subscriptionCtxDefaultValue.currentUserData);

  const [isFirstLogin, setIsFirstLogin] = useState<any>(subscriptionCtxDefaultValue.isFirstLogin);

  const [getCurrentUser, { data: currentUser, error: currentUserError }] = useLazyGetCurrentUserQuery({
    fetchPolicy: "no-cache",
  });

  const refetchInterval: any = useRef();
  const refetchTimeOut: any = useRef();

  const refreshContext = (setWithInterval: boolean) => {
    if (setWithInterval) {
      refetchInterval.current = setInterval(() => {
        getCurrentUser();
      }, 1000);

      //600000ms = 10min
      refetchTimeOut.current = setTimeout(() => {
        clearInterval(refetchInterval.current);
      }, 600000);
    } else {
      getCurrentUser();
    }
  };

  useEffect(() => {
    if (isUserPremium && refetchInterval.current && refetchTimeOut.current) {
      clearInterval(refetchInterval.current);
      clearTimeout(refetchTimeOut.current);
    }
  }, [isUserPremium]);

  useEffect(() => {
    if (isUserPremium !== null || authenticationState === "Unauthenticated") {
      setIsAuthCompleted(true);
    }
  }, [isUserPremium, authenticationState]);

  useEffect(() => {
    if (authProvider.getAccountInfo()?.jwtIdToken && authenticationState === "Authenticated") {
      getCurrentUser();
      setInterval(() => {
        getCurrentUser();
      }, 3600000);
    }
  }, [getCurrentUser, authenticationState]);

  useEffect(() => {
    if (currentUserError) {
      setErrorMessage(currentUserError.message);
    }

    if (currentUser && currentUser?.getCurrentUser) {
      if (appInsights.hasOwnProperty("stopTrackPage")) {
        appInsights.stopTrackPage("initialLoad");
      }
      const isSubscriber = currentUser.getCurrentUser.hasSubscription;
      setUserSubscription(isSubscriber);
      setCurrentUserData(currentUser.getCurrentUser);
      setIsFirstLogin(currentUser.getCurrentUser.isFirstLogin);

      if (authProvider.authenticationState === AuthenticationState.Authenticated) {
        setUserPremium(
          currentUser.getCurrentUser.groups.includes("Premium") ||
            currentUser.getCurrentUser.groups.includes("StamdataUsers")
        );
        setIsStamdataUser(currentUser.getCurrentUser.groups.includes("StamdataUsers"));
      }
    }
  }, [currentUser, currentUserError, setErrorMessage]);

  useEffect(() => {
    sessionStorage.removeItem("EQUITY_filters");
    sessionStorage.removeItem("DEBT_filters");
    sessionStorage.removeItem("OTHER_filters");
    sessionStorage.removeItem("NIM_filters");
  }, []);

  return (
    <SubscribeContext.Provider
      value={{
        userSubscription,
        setUserSubscription,
        isUserPremium,
        isStamdataUser,
        isFirstLogin,
        setIsFirstLogin,
        currentUserData,
        refreshContext,
        azureProps,
        errorMessage,
        setErrorMessage,
      }}
    >
      {isAuthCompleted ? children : currentUserError ? <ErrorMessage /> : <Loader />}
    </SubscribeContext.Provider>
  );
};

export default SubscriptionProvider;
