import { FormControl, NativeSelect } from "@material-ui/core";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { SubscribeContext } from "../../../common/subcriptionProvider";
import DoubleDataCard from "../../../components/DoubleDataCard/DoubleDataCard";
import { InstrumentUpdatesTable } from "../../../components/InstrumentUpdateTable/InstrumentUpdatesTable";
import Loader from "../../../components/loader/Loader";
import { useLazyGetInstrumentHistoryQuery } from "../../../graphql/GetIntrumentHistory";
import { lockDataCell } from "../../../utils/lockDataCell";
import { compareHistoricalUpdates, generateStateRows, sortInstrumentHistoricData } from "../InstrumentPageFunctions";
import { SelectTradingVenuesWrapper, TablesWrapper } from "./InstrumentHistoricReporting.css";
import { IHistoricalReporting } from "./InstrumentHistoricReportingsInterface";

const InstrumentHistoricalReporting: React.FC<IHistoricalReporting> = ({ isin, lei, tradingVenues, tab }) => {
  const [tradingVenuesValue, changeTradingVenuesValue] = useState("");
  const [instrumentHistoricalData, setInstrumentHistoricalData] = useState<any>();
  const { azureProps, setErrorMessage, isUserPremium } = useContext(SubscribeContext);
  const [tableRows, setTableRows] = useState<any>();
  const [updateDate, setUpdateDate] = useState<any>();

  const [
    getInstrumentHistory,
    { data: instrumentHistory, loading: loadingInstrumentHistory, error: instrumentHistoryError },
  ] = useLazyGetInstrumentHistoryQuery();

  useEffect(() => {
    if (instrumentHistoryError) {
      setErrorMessage(instrumentHistoryError.message);
    }

    if (instrumentHistory && instrumentHistory?.getInstrumentHistory) {
      const sortedData = sortInstrumentHistoricData(instrumentHistory?.getInstrumentHistory);
      const initialData = { ...sortedData.slice().reverse()[0] };
      const currentData = { ...sortedData[0] };
      setTableRows({
        initialRows: generateStateRows(initialData, lei),
        currentRows: generateStateRows(currentData, lei),
      });
      setUpdateDate({
        initialDate: moment(sortedData.slice().reverse()[0].publicationDate).format("DD/MM/YYYY"),
        currentDate: moment(sortedData[0].publicationDate).format("DD/MM/YYYY"),
      });
      setInstrumentHistoricalData(compareHistoricalUpdates(sortedData));
    }
  }, [instrumentHistory, instrumentHistoryError, setErrorMessage, lei]);

  const lockData = () => {
    if (!isUserPremium) {
      const dataObj = generateStateRows(null, lei);
      dataObj.map((item: any) => {
        lockDataCell(item);
      });
      return dataObj;
    }
    return [];
  };

  useEffect(() => {
    if (isUserPremium && azureProps.authenticationState === "Authenticated" && tab === 1 && tradingVenues) {
      getInstrumentHistory({
        variables: { isin: isin, tradingVenue: tradingVenues.split(",")[0] },
      });
    }
  }, [getInstrumentHistory, isin, tradingVenues, azureProps.authenticationState, tab]);

  const handleChange = (e: any) => {
    changeTradingVenuesValue(e.target.value);
    getInstrumentHistory({
      variables: { isin: isin, tradingVenue: e.target.value },
    });
  };

  return (
    <>
      <SelectTradingVenuesWrapper disabled={!isUserPremium}>
        <p>Trading Venue:</p>
        <FormControl variant="outlined">
          <NativeSelect value={tradingVenuesValue} onChange={handleChange} disabled={!isUserPremium}>
            {tradingVenues
              ?.replace(/\s/g, "")
              .split(",")
              .map((el) => {
                return (
                  <option key={el} value={el}>
                    {el}
                  </option>
                );
              })}
          </NativeSelect>
        </FormControl>
        {!isUserPremium && <span>Upgrade to premium to unlock</span>}
      </SelectTradingVenuesWrapper>
      {!instrumentHistoryError ? (
        <TablesWrapper>
          <DoubleDataCard
            title="Current State"
            updateDate={updateDate?.currentDate}
            loading={loadingInstrumentHistory}
            firstTableRows={tableRows?.currentRows[0] || lockData()[0]}
            secondTableRows={tableRows?.currentRows[1] || lockData()[1]}
          />
          <InstrumentUpdatesTable
            title="Updates"
            instrumentHistoricalData={instrumentHistoricalData}
            loading={loadingInstrumentHistory}
          />
          <DoubleDataCard
            title="Initial State"
            updateDate={updateDate?.initialDate}
            loading={loadingInstrumentHistory}
            firstTableRows={tableRows?.initialRows[0] || lockData()[0]}
            secondTableRows={tableRows?.initialRows[1] || lockData()[1]}
          />
        </TablesWrapper>
      ) : !instrumentHistoryError ? (
        <Loader />
      ) : null}
    </>
  );
};

export default InstrumentHistoricalReporting;
