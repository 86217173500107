import { CardHeader, Link, Tooltip, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SubscribeContext } from "../../../common/subcriptionProvider";
import Loader from "../../../components/loader/Loader";
import { useLazyGetIssuerRelationsFullQuery } from "../../../graphql/GetIssuerRelationsFull";
import {
  CardHeaderContainer,
  CardLink,
  CardStyle,
  CardTitle,
  Circle,
  ConnectCircle,
  ConnectCircleRoot,
  DirectChildrenNode,
  DirectParentChildrenNode,
  DirectParentDescription,
  DirectParentNode,
  RootCircle,
  RootContainerNode,
  RootNode,
  RootTitle,
  StructureTree,
  Title,
  TooltipTitle,
  TreeChildNested,
  TreeLine,
  TreeParentLine,
  UltimateParentNode,
} from "./IssuerStructure.css";
import { IssuerRelationsFullInterface, Props } from "./IssuerStructureInterface";

const IssuerStructure: React.FC<Props> = ({ lei, setTab, tab, handleTabChange, setIsScroll }) => {
  const history = useHistory();
  const { setErrorMessage } = useContext(SubscribeContext);

  const [issuersData, setIssuersData] = useState<IssuerRelationsFullInterface>();

  const cardTitle = "Overview";
  const tooltipTitle = "All issues by legal entities within a corporate group structure as recorded in GLEIF";

  const [
    getIssuerRelationsFull,
    { data: issuers, loading: loadingIssuersData, error: getIssuerRelationsFullError },
  ] = useLazyGetIssuerRelationsFullQuery(true);

  const hideDuplicateParent =
    issuersData && issuersData.ultimateParent && issuersData.directParent
      ? issuersData.ultimateParent.lei !== issuersData.directParent.lei
      : true;

  useEffect(() => {
    if (lei && tab === 1) {
      getIssuerRelationsFull({ variables: { lei } });
    }
  }, [getIssuerRelationsFull, lei, tab]);

  useEffect(() => {
    if (getIssuerRelationsFullError) {
      setErrorMessage(getIssuerRelationsFullError.message);
    }
    if (issuers && issuers?.getIssuerRelationsFull) {
      setIssuersData(issuers.getIssuerRelationsFull);
    }
  }, [issuers, lei, getIssuerRelationsFullError]);

  const handleInstrumentRedirect = (issuerLei: string) => {
    history.push(`/Issuer/${issuerLei}`);
    setTab(0);
  };

  const handleTabChangeWithScroll = () => {
    setIsScroll(true);
    handleTabChange(null, 2);
  };

  return (
    <>
      {!loadingIssuersData && issuersData ? (
        <CardStyle>
          <CardHeaderContainer>
            <CardTitle>
              <CardHeader
                title={
                  <Tooltip placement="right" title={<TooltipTitle>{tooltipTitle}</TooltipTitle>}>
                    <Typography>{cardTitle}</Typography>
                  </Tooltip>
                }
              />
            </CardTitle>
            <CardLink>
              <Typography>
                <Link id="issuer_page__see_all_legal_entities" onClick={handleTabChangeWithScroll}>
                  See all Legal Entities
                </Link>
              </Typography>
            </CardLink>
          </CardHeaderContainer>

          <StructureTree>
            {issuersData.ultimateParent && hideDuplicateParent && (
              <UltimateParentNode>
                <Title
                  id="issuer_page__overview_issuer"
                  onClick={() => handleInstrumentRedirect(issuersData.ultimateParent.lei)}
                >
                  {issuersData.ultimateParent.name}
                </Title>
                <span>{`Debt: ${issuersData.ultimateParent.instrumentsAmount.debt} instruments`}</span>
                <span>{`Equity: ${issuersData.ultimateParent.instrumentsAmount.equities} instruments`}</span>
                <Circle />
                <TreeLine />
              </UltimateParentNode>
            )}

            <DirectParentNode>
              {issuersData.directParent && (
                <DirectParentDescription>
                  <Title
                    id="issuer_page__overview_issuer"
                    onClick={() => handleInstrumentRedirect(issuersData.directParent.lei)}
                  >
                    {issuersData.directParent.name}
                  </Title>
                  <span>{`Debt: ${issuersData.directParent.instrumentsAmount.debt} instruments`}</span>
                  <span>{`Equity: ${issuersData.directParent.instrumentsAmount.equities} instruments`}</span>
                  <Circle />
                  {issuersData.ultimateParent && hideDuplicateParent && <ConnectCircle />}
                  <TreeLine />
                </DirectParentDescription>
              )}

              <RootContainerNode>
                <RootNode>
                  <RootTitle>{issuersData.name}</RootTitle>
                  <RootCircle />
                  {issuersData.directParent && <ConnectCircle />}
                  {!issuersData.directParent && issuersData.ultimateParent && <ConnectCircleRoot />}
                  {issuersData.directChildren.length > 0 && <TreeLine />}
                </RootNode>
                {issuersData.directParent &&
                  issuersData.directParent.directChildren &&
                  issuersData.directParent.directChildren.length > 0 && <TreeLine />}

                {issuersData.directChildren &&
                  issuersData.directChildren.map((child, index) => {
                    const isLineRender = issuersData.directChildren.length === index + 1;
                    const isNestedChild = Array.isArray(child.directChildren);
                    return (
                      <DirectChildrenNode key={child.lei}>
                        <Title id="issuer_page__overview_issuer" onClick={() => handleInstrumentRedirect(child.lei)}>
                          {child.name}
                        </Title>
                        <span>{`Debt: ${child.instrumentsAmount.debt} instruments`}</span>
                        <span>{`Equity: ${child.instrumentsAmount.equities} instruments`}</span>
                        <Circle />
                        <ConnectCircle />
                        <TreeParentLine isLineRender={isLineRender} />

                        {isNestedChild &&
                          child.directChildren.map((nested, index) => {
                            const isLineRender = issuersData.directChildren.length === index + 1;
                            return (
                              <DirectChildrenNode key={nested.lei}>
                                <Title
                                  id="issuer_page__overview_issuer"
                                  onClick={() => handleInstrumentRedirect(nested.lei)}
                                >
                                  {nested.name}
                                </Title>
                                <span>{`Debt: ${nested.instrumentsAmount.debt} instruments`}</span>
                                <span>{`Equity: ${nested.instrumentsAmount.equities} instruments`}</span>
                                <Circle />
                                <ConnectCircle />
                                <TreeParentLine isLineRender={isLineRender} />
                              </DirectChildrenNode>
                            );
                          })}
                        <TreeChildNested isNestedChild={isNestedChild} />
                      </DirectChildrenNode>
                    );
                  })}
              </RootContainerNode>

              {issuersData.directParent &&
                issuersData.directParent.directChildren.map((child, index) => {
                  const isLineRender = issuersData.directParent.directChildren.length === index + 1;
                  const isNestedChild = Array.isArray(child.directChildren);
                  return (
                    <DirectParentChildrenNode key={child.lei}>
                      <Title id="issuer_page__overview_issuer" onClick={() => history.push(`/Issuer/${child.lei}`)}>
                        {child.name}
                      </Title>
                      <span>{`Debt: ${child.instrumentsAmount.debt} instruments`}</span>
                      <span>{`Equity: ${child.instrumentsAmount.equities} instruments`}</span>
                      <Circle />
                      <ConnectCircle />
                      <TreeParentLine isLineRender={isLineRender} />
                      {child.directChildren.length > 0 && <TreeChildNested isNestedChild={isNestedChild} />}
                      {isNestedChild &&
                        child.directChildren.map((nested, index) => {
                          const isLineRender = child.directChildren.length === index + 1;
                          return (
                            <DirectParentChildrenNode key={nested.lei}>
                              <Title
                                id="issuer_page__overview_issuer"
                                onClick={() => handleInstrumentRedirect(nested.lei)}
                              >
                                {nested.name}
                              </Title>
                              <span>{`Debt: ${nested.instrumentsAmount.debt} instruments`}</span>
                              <span>{`Equity: ${nested.instrumentsAmount.equities} instruments`}</span>
                              <Circle />
                              <ConnectCircle />
                              <TreeParentLine isLineRender={isLineRender} />
                            </DirectParentChildrenNode>
                          );
                        })}
                    </DirectParentChildrenNode>
                  );
                })}
            </DirectParentNode>
          </StructureTree>
        </CardStyle>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default IssuerStructure;
