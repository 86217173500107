import { Grid, makeStyles } from "@material-ui/core";
import styled from "styled-components";
import mediaQuery from "../../common/mediaQuery";
import theme from "../../common/theme";

export const DashboardPageStyles = makeStyles({
  rightColumn: {
    alignContent: "flex-start",
    order: 2,
    width: "100%",
    [theme.breakpoints.down("lg")]: {
      order: 1,
    },
  },
  leftColumn: {
    order: 1,
    width: "100%",
    [theme.breakpoints.down("lg")]: {
      order: 2,
    },
  },
});

export const DashboardWrapper = styled(Grid)`
  ${mediaQuery.SM} {
    .MuiGrid-spacing-xs-2 {
      width: 100%;
      margin: 0;
      padding: 8px 0;
    }
  }
`;
