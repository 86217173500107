import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { InstrumentResourceParametersInput } from "../common/types";

const queryAuth = (isPremium: any) => {
  const GET_ADDITIONAL_DATA_FOR_INSTRUMENTS = gql`
    query getAdditionalDataForInstruments(
      $lei: String
      $resourceParameters: InstrumentResourceParametersInput!
    ) {
      getAdditionalDataForInstruments(
        lei: $lei
        resourceParameters: $resourceParameters
      ) {
        countries @include(if: ${isPremium})
        currencies @include(if: ${isPremium})
        totalIssuedAmount
        __typename @include(if: ${isPremium})
      }
    }
  `;
  return GET_ADDITIONAL_DATA_FOR_INSTRUMENTS;
};

interface Params {
  lei: string;
  resourceParameters: InstrumentResourceParametersInput;
}

interface GetAdditionalDataForInstruments {
  getAdditionalDataForInstruments: {
    countries: Array<string>;
    currencies: Array<string>;
    totalIssuedAmount: number;
    __typename: string;
  };
}

export const useLazyGetAdditionalDataForInstrumentsQuery = (isPremium: any) =>
  useLazyQuery<GetAdditionalDataForInstruments, Params>(queryAuth(isPremium));
