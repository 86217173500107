import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { IssuerNew } from "../common/types";

const GET_ISSUER_SUGESTIONS_WITH_DETAILS = gql`
  query getIssuerSuggestionsWithDetails($startingCharacters: String!) {
    getIssuerSuggestionsWithDetails(startingCharacters: $startingCharacters) {
      lei
      entityNew {
        entityStatus
        legalJurisdiction
        legalName
      }
    }
  }
`;

interface Params {
  startingCharacters: string;
}

interface GetIssuerSuggestionsWithDetails {
  getIssuerSuggestionsWithDetails: IssuerNew[];
}

export const useLazyGetIssuerSuggestionsWithDetailsQuery = () =>
  useLazyQuery<GetIssuerSuggestionsWithDetails, Params>(
    GET_ISSUER_SUGESTIONS_WITH_DETAILS
  );
