import { CardContent, Tooltip, Typography } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import React, { FC, useEffect, useRef } from "react";
import { generateChildrenItems, generateParentItems } from "../../pages/IssuerPage/IssuerPageFunctions";
import { themeTypography } from "../../pages/IssuerPage/LeiSearchTab/Issuer.css";
import { CardTitle, StyledCard, TooltipTitle } from "./IssuerRelations.css";
import { IIssuerRelations } from "./IssuerRelationsInterface";

const IssuerRelations: FC<IIssuerRelations> = ({ issuerRelationsData, isScroll, setIsScroll, cardTitle }) => {
  const ref = useRef(null);

  const tooltipTitle = "All legal entities within a corporate group structure as recorded in GLEIF";

  const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);

  useEffect(() => {
    if (isScroll) {
      scrollToRef(ref);
      setIsScroll(false);
    }
  }, [isScroll, setIsScroll]);

  return (
    <StyledCard ref={ref}>
      <Tooltip placement="right" title={<TooltipTitle>{tooltipTitle}</TooltipTitle>}>
        <CardTitle>{cardTitle}</CardTitle>
      </Tooltip>
      <CardContent>
        <ThemeProvider theme={themeTypography}>
          <Typography variant="h5">Parents</Typography>
          {generateParentItems(issuerRelationsData)}
          <Typography variant="h5">Children</Typography>
          {generateChildrenItems(issuerRelationsData)}
        </ThemeProvider>
      </CardContent>
    </StyledCard>
  );
};

export default IssuerRelations;
