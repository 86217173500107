import React from "react";
import { Breadcrumbs, Typography, Link } from "@material-ui/core";

import { BreadcrumbsLink } from "../common/types";

interface BreadcrumbsLinks {
  links: BreadcrumbsLink[];
}

function BreadcrumbsLinks({ links }: BreadcrumbsLinks) {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {links.map(
        (link: BreadcrumbsLink, index: number, arr: BreadcrumbsLink[]) => {
          if (arr.length === index + 1) {
            return (
              <Typography key={link.label} color="primary">
                {link.label}
              </Typography>
            );
          } else {
            return (
              <Link color="inherit" key={link.label} href={link.to}>
                {link.label}
              </Link>
            );
          }
        }
      )}
    </Breadcrumbs>
  );
}

export { BreadcrumbsLinks };
