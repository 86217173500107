import { Table, TableBody } from "@material-ui/core";
import React from "react";
import { definitionsData } from "./UserTermsData";
import { UserTermsInterface } from "./UserTermsInterface";
import {
  DialogContent,
  DialogHeader,
  DialogStyle,
  MainTitle,
  SubTitle,
  TableCellKey,
  TableCellVal,
  TableRowStyle,
  Text,
} from "./UseTerms.css";

const UserTerms: React.FC<UserTermsInterface> = ({ openModal, handleCloseModal }) => {
  return (
    <DialogStyle open={openModal} onClose={handleCloseModal}>
      <DialogContent>
        <DialogHeader>
          <div>eFIRDS - User Terms</div>
          <div>Please read these User Terms carefully.</div>
        </DialogHeader>
        <MainTitle>1. General terms</MainTitle>
        <Text>
          eFIRDS provides a Service that allows you to access the Database via the Website. These User Terms governs
          your access and use of the Service provided by the Data Provider.
        </Text>
        <Text>
          The User Terms also applies for any user provided access to the Service through a corporate agreement with the
          Data Provider, entered into in any other way.
        </Text>
        <Text>By accessing the Service, you agree to be bound by these User Terms.</Text>
        <MainTitle>2. Definitions</MainTitle>

        <Table size="small">
          <TableBody>
            {Object.keys(definitionsData).map((key: string) => (
              <TableRowStyle key={key}>
                <TableCellKey>{key}</TableCellKey>
                <TableCellVal>{definitionsData[key]}</TableCellVal>
              </TableRowStyle>
            ))}
          </TableBody>
        </Table>

        <MainTitle>3. Your rights to use the Service</MainTitle>
        <SubTitle>3.1 Term</SubTitle>
        <Text>
          The term of these User Terms will commence upon your accessing to the Service and as long as you have a valid
          payment to access to the Service. The minimum subscription period is one month. Details of your subscription
          and Access Data is available and may be updated under your User Account.
        </Text>
        <SubTitle>3.2 License grant</SubTitle>
        <Text>
          The Data Provider grants you a limited, non-exclusive, non-transferable, non-sublicensable right to utilize
          the Service to extract and/or re-utilise parts of its content.
        </Text>
        <Text>
          The Service uses inter alia material downloaded from ESMA’s website. Such material may also be obtained free
          of charge through the ESMA website.
        </Text>
        <SubTitle>3.3 License restrictions</SubTitle>
        <Text>
          The Database is protected by law in accordance with the EU Directive 96/9/EC and you have no right to
          reproduce, translate, adapt, or make any other arrangement or alteration of the Database, without prior
          written consent from the Data Provider.
        </Text>
        <Text>
          You have no right to extract and/or re-utilise a substantial part of the content of the Database. And you
          acquire absolutely no rights in or to this Website or any text, graphics, user interfaces, visual interfaces
          or computer code.
        </Text>
        <Text>
          Any scripting, copying, storing of a substantial part of the content in third party systems and/ or a
          systematic download /transfer (hereunder crawling) of any content by you, are not allowed without prior
          written consent from the Data Provider.
        </Text>
        <Text>
          If you extract and/or re-utilise any content of the Database in any external presentations, report or similar,
          Stamdata and eFIRDS shall be referred to as source of information.
        </Text>
        <Text>
          In case of any dispute, the Data Provider will in its sole discretion, acting reasonable, decide what is
          considered as breach of the paragraphs above.
        </Text>
        <SubTitle>3.4 User access and account safety</SubTitle>
        <Text>
          You are required to create a User Account and provide the Data Provider with the relevant Access Data. You may
          view and update your Access Data under you User Account.
        </Text>
        <Text>
          You are solely responsible for the information associated with the User Account. Your User Account is strictly
          personal, and you shall not give any other person or third-party access to your User Account.
        </Text>
        <SubTitle>3.5 Free Trial</SubTitle>
        <Text>
          If you access the Service on a free-trial basis provided by the Data Provider, you are bound by these User
          Terms, except for the payment obligations as set out in clause 4. The trial subscription may only be used to
          review and evaluate the Service. If the User does not pay the subscription fee at the end of the free-trial
          subscription period, the access to the Service will be terminated with immediate effect.
        </Text>
        <MainTitle>4. Fees and payment terms</MainTitle>
        <SubTitle>4.1 Fees</SubTitle>
        <Text>
          You shall pay a monthly subscription fee for access to the chosen products of the Service according to the
          fees available at the Website. The Data Provider may execute annual CPI-adjustments of the monthly
          subscription fee without further notifications. Further, the Data Provider may change prices for the Service
          at any time, such changes to be notified in advance.
        </Text>
        <Text>
          Fees are billed monthly in advance and are payable in EUR, your next billing date is available under your User
          Account. Fees are based on subscription and not actual usage.
        </Text>
        <SubTitle>4.2 Payments</SubTitle>
        <Text>
          You shall provide the Data Provider with valid and updated payment information reasonable acceptable for the
          Data Provider. You authorize the Data Provider to charge any credit card (or similar payment arrangements) for
          the Service made available for you on the Website. Any further payment details are available under your User
          Account.
        </Text>
        <Text>
          Payment obligations are non-cancellable, and fees paid are non-refundable. If any amount owing by you are 30
          or more days overdue, the Data Provider may without limiting its other rights under these User Terms, suspend
          or terminate the User Account with immediate effect.
        </Text>
        <MainTitle>5. Intellectual property rights</MainTitle>
        <Text>
          The ownership and intellectual property rights of the Database and the Website is protected by law in
          accordance with the EU Directive 96/9/EC, see also clause 3.3, and you hereby agree not to exploit such rights
          and to use commercially reasonable efforts to protect the proprietary rights of the Data Provider.
        </Text>
        <Text>
          ESMA does not endorse the Data Provider and in no way is liable for copyright or other intellectual property
          rights infringements for any material downloaded from ESMA’s website nor for any damages caused to third
          parties through the Service.
        </Text>
        <Text>
          Information from FCA FIRDS is provided and may only be used in accordance with the{" "}
          <a href="https://data.fca.org.uk/#/viewdata" target="_blank" rel="noopener noreferrer">
            Notice and Disclaimer
          </a>{" "}
          and{" "}
          <a href="https://www.fca.org.uk/legal" target="_blank" rel="noopener noreferrer">
            Copyright Notice and Disclaimer
          </a>{" "}
          published on the FCA website that applies to use of the FCA FIRDS.
        </Text>
        <MainTitle>6. Privacy and security of personal data</MainTitle>
        <Text>
          The Data Provider only collects information that is required to fulfil the purpose of the actual Service. Any
          personal data provided for the Access Data are collected only for the purposes of internal use in order for
          the Data Provider to handle User access, system administration and payments.
        </Text>
        <Text>
          All personal data provided by a User will be handled in accordance with relevant data protection regulations
          (GDPR), please see current privacy statement at our group website (
          <a href="https://nordictrustee.com/about/governance/" target="_blank" rel="noopener noreferrer">
            https://nordictrustee.com/about/governance/
          </a>
          ).
        </Text>
        <MainTitle>7. Modification of the Service</MainTitle>
        <Text>
          The Data Provider may make changes to the Service, hereunder to the Website and the Database, including
          products offered and modifications to reflect statistical, technical, administrative, market based or similar
          changes that the Data Provider determines in good faith are required or desirable. The Data Provider will
          notify you of any material changes in advance.
        </Text>
        <MainTitle>8. Termination</MainTitle>
        <SubTitle>8.1 Termination</SubTitle>
        <Text>These User Terms may be terminated by either party with one month written notice.</Text>
        <Text>
          Any use in material breach with these User Terms gives the Data Provider right in its sole discretion to
          terminate these User Terms and your access to the Service with immediate effect with no right of any
          compensation for you.
        </Text>
        <Text>
          If any content provided by the Service gathered from or delivered by third parties is cancelled by such
          parties or is otherwise no longer accessible for the Data Provider, your right to use such content will
          automatically terminate. Such termination shall not constitute a breach of the Data Providers obligations.
        </Text>
        <MainTitle>9. Limitation of liability and disclaimer </MainTitle>
        <Text>
          Access to and use of the Service is at your own risk. The Data Provider shall never be liable towards a User
          for any damage or loss of any kind arising from or relating to the Service, the Website or the Database,
          unless the Data Provider has acted with gross negligence or wilful misconduct, and in such case, only for any
          direct damage or direct losses for a User.
        </Text>
        <Text>
          The Data Provider does not warrant that any content provided via the Service will be error-free or that the
          use of the Service will be uninterrupted or error-free. The Data Provider will use its best efforts to
          maintain adequate and reasonable security to prevent unlawful access by third parties to the Data Provider’s
          infrastructures.
        </Text>
        <Text>
          You shall be held liable for any damage or liability incurred by the Data Provider for your breach of these
          User Terms.
        </Text>
        <MainTitle>10. Notifications</MainTitle>
        <Text>
          Any information concerning these User Terms or any other agreement shall be notified by you to the Data
          Provider by email to <a href="mailto:mail@stamdata.com">mail@stamdata.com</a>.
        </Text>
        <Text>
          The Data Provider may notify you at the Website or by email to the email address provided for the Access Data.
        </Text>
        <MainTitle>11. Changes to these User Terms</MainTitle>
        <Text>
          The Data Provider may change these User Terms at any time and will notify you of any material changes. By
          continuing to use the Service after the date of revision, you agree to be bound by the changes.
        </Text>
        <MainTitle>12. Governing law and jurisdiction</MainTitle>
        <Text>
          These User Terms shall be exclusively governed by Norwegian laws. All disputes arising out of, or in
          connection with these User Terms shall be exclusively resolved by the courts of Norway, with Oslo City Court
          as first legal venue.
        </Text>
      </DialogContent>
    </DialogStyle>
  );
};

export default UserTerms;
