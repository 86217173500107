import { FormControl } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import styled from "styled-components";
import mediaQuery from "../../common/mediaQuery";

interface ArrowInterface {
  disabled: boolean;
}

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${mediaQuery.SM_LANDSCAPE} {
    .MuiSelect-select {
      font-size: 0.6rem;
    }
  }

  ${mediaQuery.SM} {
    .MuiSelect-select {
      font-size: 0.6rem;
    }
  }
`;

export const Title = styled.div`
  font-size: 14px;

  ${mediaQuery.SM_LANDSCAPE} {
    font-size: 0.55rem;
  }

  ${mediaQuery.SM} {
    font-size: 0.55rem;
  }
`;

export const NavigationArrows = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 40px;
  margin-left: 20px;

  svg {
    font-size: 12px;
  }
`;

export const Range = styled.div`
  font-size: 16px;
  margin: 0 15px;

  ${mediaQuery.SM_LANDSCAPE} {
    font-size: 0.55rem;
  }

  ${mediaQuery.SM} {
    font-size: 0.55rem;
  }
`;

export const ArrowBackIosIconStyle = styled(ArrowBackIosIcon)<ArrowInterface>`
  color: ${(props) => (props.disabled ? "" : "#a6a6a6")};
  cursor: ${(props) => (props.disabled ? "pointer" : "auto")};
`;

export const ArrowForwardIosIconStyle = styled(ArrowForwardIosIcon)<ArrowInterface>`
  color: ${(props) => (props.disabled ? "" : "#a6a6a6")};
  cursor: ${(props) => (props.disabled ? "pointer" : "auto")};
`;

export const FormControlStyle = styled(FormControl)`
  min-width: 30px;
  max-height: 60px;
  margin: 8px;
`;

export const PaginationLoader = styled.div`
  padding: 0 20px 10px 0;
`;
