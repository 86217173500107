import { Card } from "@material-ui/core";
import {createMuiTheme} from "@material-ui/core/styles";
import styled from "styled-components";
import mediaQuery from "../../common/mediaQuery";

export const themeTypography = createMuiTheme({
  typography: {
    h5: {
      fontWeight: 700,
    },
    caption: {
      alignSelf: "center",
      marginLeft: "15px",
    },
  },
});

export const DoubleCardWrapper = styled(Card)`
  .MuiCardContent-root {
    display: flex;
    justify-content: space-between;
  }

  table {
    width: 49%;
  }

  tr:last-child td {
    border-bottom: none;
  }

  td:first-child {
    width: 250px;
    font-weight: 700;
    white-space: nowrap;
  }

  td:nth-child(2n) {
    text-align: left;
  }

  ${mediaQuery.SM} {
    td:first-child {
      width: 40%;
      white-space: nowrap;
      padding-left: 0;
    }

    td:last-child {
      text-align: left;
    }

    tr:last-child td {
      border-bottom: 1px solid #e0e0e0;
    }
  }
`;

export const TittleWrapper = styled.div`
  display: flex;
`;
