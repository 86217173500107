import { CardContent, CardHeader } from "@material-ui/core";
import SubjectIcon from "@material-ui/icons/Subject";
import React, { useContext, useEffect, useState } from "react";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { SubscribeContext } from "../../common/subcriptionProvider";
import { useLazyGetMaturingInstrumentsQuery } from "../../graphql/GetMaturingInstruments";
import Loader from "../loader/Loader";
import { CardStyle, EmptyCard, Message, TypographyStyle } from "./MaturingInstruments.css";

interface Props {
  lei: string;
  setMaturingInstrumentsLoaded: Function;
}

const MaturingInstruments: React.FC<Props> = ({ lei, setMaturingInstrumentsLoaded }) => {
  const [chartData, setChartData] = useState<any>();
  const {  setErrorMessage } = useContext(SubscribeContext);

  const cardTitle = "Maturing instruments - next five years";

  const [
    getMaturingInstruments,
    { data: maturingInstruments, loading: loadingMaturingInstruments, error: getMaturingInstrumentsError },
  ] = useLazyGetMaturingInstrumentsQuery(true);

  useEffect(() => {
    if (lei) {
      getMaturingInstruments({ variables: { lei, years: 5 } });
    }
  }, [getMaturingInstruments, lei]);

  useEffect(() => {
    if (getMaturingInstrumentsError) {
      setErrorMessage(getMaturingInstrumentsError.message);
    }

    if (maturingInstruments && maturingInstruments.getMaturingInstruments) {
      setMaturingInstrumentsLoaded(true);
      setChartData(maturingInstruments.getMaturingInstruments);
    }
  }, [maturingInstruments, getMaturingInstrumentsError, setErrorMessage, setMaturingInstrumentsLoaded]);

  const chartAmount = chartData && chartData.map((item: any) => item.amount);
  const chartMaxAmount = Math.max.apply(null, chartAmount) + 1;
  const chartDomainMax = chartMaxAmount <= 4 ? 4 : chartMaxAmount;

  const isChartRendered = chartAmount && chartAmount.filter((item: number) => item > 0).length > 0;

  return (
    <>
      {!loadingMaturingInstruments && chartData ? (
        <CardStyle>
          <CardHeader title={<TypographyStyle>{cardTitle}</TypographyStyle>}></CardHeader>
          <CardContent>
            {chartData && isChartRendered ? (
              <ResponsiveContainer width="100%" height={250}>
                <BarChart width={550} height={250} data={chartData} margin={{ top: 5, right: 25, left: 5, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="year" />
                  <YAxis domain={[0, chartDomainMax]} />
                  <Tooltip separator="" formatter={(value: number) => [`${value} instrument(s)`, ""]} />
                  <Legend
                    payload={[
                      {
                        value: "Settlement Date - year / instruments",
                        type: "line",
                      },
                    ]}
                  />
                  <Bar dataKey="amount" fill="#003748" />
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <EmptyCard>
                <SubjectIcon fontSize="large" color="inherit" />
                <Message>No instruments.</Message>
              </EmptyCard>
            )}
          </CardContent>
        </CardStyle>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default MaturingInstruments;
