import React, { useEffect, useState } from "react";
import { DataCard, Page } from "../../components";
import { PaymentsDetailsPageWrapper } from "./PaymentsDetailsPage.css";
import { Grid, Paper } from "@material-ui/core";
import { useLazyGetSubscriptionDetailsQuery } from "../../graphql/GetSubscriptionDetails";
import { useLazyGetCustomerQuery } from "../../graphql/GetCustomer";
import { Customer, SubscriptionDetails } from "../../common/types";
import moment from "moment";
import { SubscribeContext } from "../../common/subcriptionProvider";
import UpdatePaymentCardElement from "../../components/UpdatePaymentCard/UpdatePaymentCardElement";

function PaymentsDetailsPage() {
  const [customer, setCustomer] = useState<Customer>();
  const [subscriptionDetails, setSubscriptionDetails] = useState<SubscriptionDetails>();
  const { isUserPremium, azureProps, userSubscription } = React.useContext(SubscribeContext);

  // eslint-disable-next-line no-console
  console.log(isUserPremium,userSubscription);
  
  const [
    getCustomer,
    { data: customerData, loading: loadingCustomer, error: getCustomerError },
  ] = useLazyGetCustomerQuery({ fetchPolicy: "no-cache" });

  const [
    getSubscriptionDetails,
    { data: subscriptionDetailsData, loading: loadingSubscriptionDetails, error: getSubscriptionDetailsError },
  ] = useLazyGetSubscriptionDetailsQuery();

  useEffect(() => {
    if (isUserPremium || userSubscription) {
      // eslint-disable-next-line no-debugger
      debugger;
      getCustomer();
      getSubscriptionDetails();
    }
  }, [getCustomer, getSubscriptionDetails, isUserPremium]);

  useEffect(() => {
    if (getCustomerError || getSubscriptionDetailsError) {
      localStorage.setItem("isLogoutAfterTokenExpiryOrError", "true");
      azureProps.logout();
    }
    if (subscriptionDetailsData && subscriptionDetailsData?.getSubscriptionDetails) {
      setSubscriptionDetails(subscriptionDetailsData?.getSubscriptionDetails);
    }
    if (customerData && customerData?.getCustomer) {
      setCustomer(customerData?.getCustomer);
    }
  }, [subscriptionDetailsData, customerData, getSubscriptionDetailsError, getCustomerError, azureProps]);

  const customerInfoRows = {
    "Creation date": customer?.created ? moment(customer?.created).format("DD/MM/YYYY HH:mm:ss") : "N/A",
    "Email": customer?.email || "N/A",
    "Name": customer?.name || "N/A",
  };

  const cardDetailsRows = {
    "Card brand": customer?.paymentMethodDetails.brand || "N/A",
    "Country": customer?.paymentMethodDetails.country || "N/A",
    "CVV code": customer?.paymentMethodDetails.cvcCheck || "N/A",
    "Expire Data":
      customer?.paymentMethodDetails.expMonth && customer?.paymentMethodDetails.expYear
        ? `${customer?.paymentMethodDetails.expMonth} / ${customer?.paymentMethodDetails.expYear}`
        : "N/A",
    "Card Number": customer?.paymentMethodDetails.last4Digits
      ? `**** **** **** ${customer?.paymentMethodDetails.last4Digits}`
      : "N/A",
  };

  const cardSubscriptionDetails = {
    "Creation date": subscriptionDetails?.createdAt
      ? moment(subscriptionDetails?.createdAt).format("DD/MM/YYYY HH:mm:ss")
      : "N/A",
    "Active until": subscriptionDetails?.activeUntil
      ? moment(subscriptionDetails?.activeUntil).format("DD/MM/YYYY HH:mm:ss")
      : "N/A",
    "Cancel date": subscriptionDetails?.canceledAt
      ? moment(subscriptionDetails?.canceledAt).format("DD/MM/YYYY HH:mm:ss")
      : "N/A",
    "End date": subscriptionDetails?.endedAt
      ? moment(subscriptionDetails?.endedAt).format("DD/MM/YYYY HH:mm:ss")
      : "N/A",
    "Current period start date": subscriptionDetails?.currentPeriodStart
      ? moment(subscriptionDetails?.currentPeriodStart).format("DD/MM/YYYY HH:mm:ss")
      : "N/A",
    "Status": <span data-status={subscriptionDetails?.status}>{subscriptionDetails?.status}</span>,
  };

  return (
    <Page hideSearch={!!userSubscription}>
      <PaymentsDetailsPageWrapper>
        {isUserPremium !== null ? (
          <>
            <Grid container spacing={2} justify="center">
              <Grid item xs={12} sm={9} md={5}>
                <DataCard title="Customer information" rows={customerInfoRows} loading={loadingCustomer} />
              </Grid>
              <Grid item xs={12} sm={9} md={5}>
                <DataCard title="Card details" rows={cardDetailsRows} loading={loadingCustomer} />
              </Grid>
            </Grid>
            <Grid container spacing={2} justify="center">
              <Grid item xs={12} sm={9} md={5}>
                <Paper>
                  <DataCard
                    title="Subscription details"
                    rows={cardSubscriptionDetails}
                    loading={loadingSubscriptionDetails}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={9} md={5}>
                <UpdatePaymentCardElement refreshCustomer={getCustomer} />
              </Grid>
            </Grid>
          </>
        ) : (
          <h2>...Loading</h2>
        )}
      </PaymentsDetailsPageWrapper>
    </Page>
  );
}

export { PaymentsDetailsPage };
