import React, { useEffect, useState } from "react";
import { authProvider, authProviderForgotPassword } from "../../common/authProvider";
import ApolloClient, { Operation } from "apollo-boost";
import AzureAD from "react-aad-msal";
import { ApolloProvider } from "@apollo/react-hooks";
import SubscriptionProvider from "../../common/subcriptionProvider";
import { CssBaseline, MuiThemeProvider } from "@material-ui/core";
import theme from "../../common/theme";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  DashboardPage,
  DisclaimerPage,
  HomePage,
  InstrumentPage,
  IssuerPage,
  NotFound,
  SearchPage,
  UserSettingsPage,
  PaymentsDetailsPage,
  NewInstrumentMonitorPage,
  ProductsPage,
  FavouriteInstrumentsPage,
  SubscriptionPaymentsCardPage,
} from "../../pages";
import { IProps } from "./AppRouterWithProviderInterface";
import { AuthError } from "msal";
import { reactPlugin } from "../../utils/appInsights";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import moment from "moment";

declare const window: any;

const client = new ApolloClient({
  uri: window._env_.REACT_APP_API_URL,
  request: async (operation: any) => {
    let token = await refreshToken();
    if (token) {
      operation.setContext({
        headers: {
          authorization: "Bearer " + token,
        },
      });
    }
  },
});

const refreshToken = async () => {
  const account = authProvider.getAccountInfo();
  if (account) {
    const token = await authProvider.getIdToken();
    const shouldRefresh = moment(token.idToken.expiration).unix() - moment().unix() / 1000 <= 0;
    if (shouldRefresh) {
      const tokenResponse = await authProvider.acquireTokenSilent({
        scopes: [window._env_.AD_B2C_SCOPE_URL],
      });
      return tokenResponse.accessToken;
    }
    return token.idToken.rawIdToken;
  }
  return null;
};

const handleAzureError = (error: AuthError | null, setErrorMessage: (msg: string) => void) => {
  if (error && error.errorMessage) {
    if (error.errorCode === "access_denied" || error.errorCode === "token_renewal_error") {
      return;
    } else if (error.errorCode === "acquiretoken_progress_error") {
      setErrorMessage("Please allow third-party cookies in your browser");
    } else {
      setErrorMessage(error.errorMessage);
    }
  }
};

const InvitationSetPassword = () => {
  return <AzureAD forceLogin provider={authProviderForgotPassword} />;
};

const APICreateAccount = () => {
  return <AzureAD forceLogin provider={authProvider} />;
};

const AppRouterWithProviders = ({ azureProps }: IProps) => {
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => handleAzureError(azureProps.error, setErrorMessage), [azureProps.error]);

  return (
    <ApolloProvider client={client}>
      <Router>
        <AppInsightsContext.Provider value={reactPlugin}>
          <SubscriptionProvider azureProps={azureProps} errorMessage={errorMessage} setErrorMessage={setErrorMessage}>
            <MuiThemeProvider theme={theme}>
              <CssBaseline />
              <Switch>
                <Route exact path="/" component={HomePage} />
                <Route exact path="/dashboard" component={DashboardPage} />
                <Route exact path="/instrument/:isin" component={InstrumentPage} />
                <Route exact path="/issuer/:lei" component={IssuerPage} />
                <Route path="/disclaimer" component={DisclaimerPage} />
                <Route path="/search" component={SearchPage} />
                <PrivateRoute path="/user-settings" component={UserSettingsPage} />
                <PrivateRoute path="/payments-details" component={PaymentsDetailsPage} />
                <PrivateRoute path="/subscription-payment" component={SubscriptionPaymentsCardPage} />
                <Route path="/products" component={ProductsPage} />
                <Route path="/new-instrument-monitor" component={NewInstrumentMonitorPage} />
                <Route path="/favourite-instruments" component={FavouriteInstrumentsPage} />
                <Route path="/invitation-set-password" component={InvitationSetPassword} />
                <Route path="/api-create-account" component={APICreateAccount} />
                <Route component={NotFound} />
              </Switch>
            </MuiThemeProvider>
          </SubscriptionProvider>
        </AppInsightsContext.Provider>
      </Router>
    </ApolloProvider>
  );
};

export default AppRouterWithProviders;

