import React, { useContext, useEffect, useState } from "react";
import {
  CardHeader,
  CardContent,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { themeTypography } from "../../../components/DataCard/DataCard.css";
import { SelectLabel } from "../../../components/InstrumentList/InstrumentFilter.css";
import {
  TradingVenuesData,
  useLazyGetTradingVenuesForInstrumentQuery,
} from "../../../graphql/GetTradingVenuesForInstrument";
import { SubscribeContext } from "../../../common/subcriptionProvider";
import moment from "moment";
import Loader from "../../../components/loader/Loader";
import TablePagination from "../../../components/TablePagination/TablePagination";
import {
  LoaderOverlay,
  StyledCardWrapper,
  StyledFilterRow,
  StyledFormControl,
  StyledSelect,
  StyledSort,
  StyledSortCell,
  StyledTable,
  StyledTradingVenueFilter,
  ActiveFiltersContainer,
  ClearFilters,
  Filter,
  StyledChip,
  EmptyCard,
  Message,
  TablePaginationWrapper,
  StyledTableRow,
} from "./TradingVenuesTable.css";
import SubjectIcon from "@material-ui/icons/Subject";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import { useLazyGetSelectableTradingVenues } from "../../../graphql/getSelectableTradingVenues";

interface ITradingVenuesTable {
  isin: string;
  tab: number;
}

const tableHeaderCellNames = {
  micCode: "Trading venue ID  (MIC code)",
  name: "Name",
  tradingVenueType: "Trading venue type",
  leiCode: "LEI code",
  jurisdiction: "Jurisdiction",
  authorisationDate: "Authorisation date",
  competentAuthority: "Competent authority",
  authorisationWithdrawalEndDate: "Authorisation withdrawal end date",
};

const TradingVenuesTable: React.FC<ITradingVenuesTable> = ({ isin, tab }) => {
  const { setErrorMessage, isUserPremium } = useContext(SubscribeContext);
  const [tradingVenueArray, setTradingVenueArray] = useState<TradingVenuesData[]>();
  const [filteredTradingVenueArray, setFilteredTradingVenueArray] = useState<TradingVenuesData[]>();
  const [tradingVenueType, setTradingVenueType] = useState("");
  const [sortType, setSortType] = useState("");
  const [sortIconRotation, setSortIconRotation] = useState(0);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);

  const [
    getTradingVenuesForInstrument,
    {
      data: tradingVenuesForInstrumentData,
      loading: loadingTradingVenuesForInstrument,
      error: tradingVenuesForInstrumentError,
    },
  ] = useLazyGetTradingVenuesForInstrumentQuery();

  const [
    getSelectableTradingVenues,
    { data: tradingVenueSelectData, error: getSelectableTradingVenuesError },
  ] = useLazyGetSelectableTradingVenues();

  useEffect(() => {
    if (tab === 2 && isin) {
      const sessionFilters = JSON.parse(sessionStorage.getItem(`TradingVenuesTable_filters_${isin}`) as string);
      if (sessionFilters) {
        sessionFilters.sortType && setSortType(sessionFilters.sortType);
        sessionFilters.tradingVenueType && setTradingVenueType(sessionFilters.tradingVenueType);
      }
      getTradingVenuesForInstrument({ variables: { isin: isin } });
      getSelectableTradingVenues({ variables: { isin: isin } });
    }
  }, [getTradingVenuesForInstrument, tab, isin]);

  useEffect(() => {
    if (tradingVenuesForInstrumentError) {
      setErrorMessage(tradingVenuesForInstrumentError.message);
    }
    if (getSelectableTradingVenuesError) {
      setErrorMessage(getSelectableTradingVenuesError.message);
    }

    if (
      tradingVenuesForInstrumentData &&
      tradingVenuesForInstrumentData.getTradingVenuesForInstrument &&
      tradingVenueSelectData &&
      tradingVenueSelectData.getSelectableTradingVenues
    ) {
      const tradingVenueArray = tradingVenuesForInstrumentData.getTradingVenuesForInstrument.map((el) => {
        return {
          ...el,
          isActive: tradingVenueSelectData.getSelectableTradingVenues.filter((i) => i.micCode === el.micCode)[0]
            .isActive,
        };
      });
      setTradingVenueArray(customDefaultSort(tradingVenueArray));
    }
  }, [
    tradingVenuesForInstrumentData,
    tradingVenuesForInstrumentError,
    setErrorMessage,
    tradingVenueSelectData,
    getSelectableTradingVenuesError,
  ]);

  useEffect(() => {
    if (tradingVenueArray) {
      updateTradingVenuesList();
    }

    const filters = {
      ...(sortType && { sortType: sortType }),
      ...(tradingVenueType && { tradingVenueType: tradingVenueType }),
    };
    if (Object.keys(filters).length !== 0) {
      sessionStorage.setItem(`TradingVenuesTable_filters_${isin}`, JSON.stringify(filters));
    }
  }, [sortType, tradingVenueType, tradingVenueArray]);

  const customDefaultSort = (tradingVenuesArray: any) => {
    const nameSortOrder: any = [
      "Regulated market",
      "Multilateral trading facility",
      "Organised trading facility",
      "Systematic internaliser",
      "Approved reporting mechanism",
      "Approved publication arrangement",
    ];

    const customSort = ({ data, sortBy, sortField }: any) => {
      const sortByObject = sortBy.reduce(
        (obj: any, item: any, index: any) => ({
          ...obj,
          [item]: index,
        }),
        {}
      );
      return data.sort((a: any, b: any) => sortByObject[a[sortField]] - sortByObject[b[sortField]]);
    };

    return customSort({
      data: tradingVenuesArray,
      sortBy: nameSortOrder,
      sortField: "tradingVenueType",
    });
  };

  const updateTradingVenuesList = () => {
    let updatedTradingVenueArray = tradingVenueArray ? [...tradingVenueArray] : [];
    if (tradingVenueType) {
      updatedTradingVenueArray = updatedTradingVenueArray.filter((el: any) =>
        tradingVenueType === el.tradingVenueType ? el.tradingVenueType : null
      );
    }

    if (sortType !== "") {
      const sortedTable = updatedTradingVenueArray.sort((a: any, b: any) => {
        return new Date(a.authorisationDate).getTime() > new Date(b.authorisationDate).getTime()
          ? -1
          : new Date(a.authorisationDate).getTime() < new Date(b.authorisationDate).getTime()
          ? 1
          : 0;
      });

      updatedTradingVenueArray = sortType === "desc" ? sortedTable : sortedTable.reverse();
    }
    setFilteredTradingVenueArray(updatedTradingVenueArray);
  };

  const handleVenueType = (e: any) => {
    setPage(0);
    setTradingVenueType(e.target.value);
  };

  const handleDeleteFilterTradingVenue = () => {
    setPage(0);
    setTradingVenueType("");
  };

  const handleDeleteFilterSort = () => {
    setPage(0);
    setSortType("");
  };

  const handleSort = () => {
    setPage(0);
    setSortType(sortType === "desc" ? "asc " : "desc");
    setSortIconRotation(sortIconRotation === 0 ? 180 : 0);
  };

  const handleRowsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPage(0);
    setRowsPerPage(event.target.value as number);
  };

  const handleClearFilters = () => {
    setSortType("");
    setTradingVenueType("");
    sessionStorage.removeItem(`TradingVenuesTable_filters_${isin}`);
    setPage(0);
  };

  const filters = [
    {
      name: "Trading venue type",
      value: tradingVenueType,
      handler: handleDeleteFilterTradingVenue,
    },
    {
      name: "Sort type",
      value: sortType,
      handler: handleDeleteFilterSort,
    },
  ];

  const generateTradingVenueSelectItems = () => {
    return (
      <StyledSelect
        id="SelectCurrency"
        onChange={handleVenueType}
        label="Trading venue type"
        value={filteredTradingVenueArray ? tradingVenueType : ""}
        disabled={loadingTradingVenuesForInstrument}
      >
        {tradingVenueArray &&
          tradingVenueArray
            .map((tradingVenueObj) => tradingVenueObj.tradingVenueType)
            .filter(
              (tradingVenueType, index, tradingVenuesTypesArray) =>
                tradingVenuesTypesArray.indexOf(tradingVenueType) === index
            )
            .map((uniqueTradingValue) => (
              <MenuItem key={uniqueTradingValue} value={uniqueTradingValue}>
                {uniqueTradingValue}
              </MenuItem>
            ))}
      </StyledSelect>
    );
  };

  const generateFiltersRow = () => {
    return (
      <StyledFilterRow>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <StyledTradingVenueFilter>
          <StyledFormControl variant="outlined">
            <SelectLabel>
              <InputLabel>Trading venue type</InputLabel>
            </SelectLabel>
            {generateTradingVenueSelectItems()}
          </StyledFormControl>
        </StyledTradingVenueFilter>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
      </StyledFilterRow>
    );
  };

  const isFilter = !!filters.filter((item) => item.value).length;
  return (
    <StyledCardWrapper>
      <CardHeader
        title={
          <ThemeProvider theme={themeTypography}>
            <Typography variant="h5">Trading venue(s)</Typography>
          </ThemeProvider>
        }
      />
      <CardContent>
        <ActiveFiltersContainer>
          {(tradingVenueType || sortType) &&
            filters.map((filter) => {
              return filter.value ? (
                <Filter key={filter.name}>
                  <StyledChip label={`${filter.name}: ${filter.value}`} onDelete={filter.handler} />
                </Filter>
              ) : null;
            })}
          {tradingVenueType && sortType && <ClearFilters onClick={handleClearFilters}>Clear filters</ClearFilters>}
        </ActiveFiltersContainer>
        <StyledTable isfilter={isFilter ? 1 : 0}>
          <TableHead>
            <TableRow>
              {Object.values(tableHeaderCellNames).map((headerCellName) =>
                headerCellName === "Authorisation date" ? (
                  <StyledSortCell key={headerCellName}>
                    <StyledSort onClick={handleSort} sortIconRotation={sortIconRotation} isUserPremium={isUserPremium}>
                      <span>{headerCellName}</span>
                      <SwapVertIcon />
                    </StyledSort>
                  </StyledSortCell>
                ) : (
                  <TableCell key={headerCellName}>{headerCellName}</TableCell>
                )
              )}
            </TableRow>
            {generateFiltersRow()}
          </TableHead>
          <TableBody>
            {filteredTradingVenueArray &&
              !loadingTradingVenuesForInstrument &&
              filteredTradingVenueArray.map((el: any, index: number) => {
                if (page * rowsPerPage <= index && index < (page + 1) * rowsPerPage) {
                  return (
                    <StyledTableRow key={el.id} data-active={el.isActive}>
                      <TableCell align={"center"}>{el.micCode}</TableCell>
                      <TableCell>{el.name}</TableCell>
                      <TableCell>{el.tradingVenueType}</TableCell>
                      <TableCell>{el.lei}</TableCell>
                      <TableCell>{el.jurisdiction}</TableCell>
                      <TableCell>{moment(el.authorisationDate).format("DD/MM/yyyy")}</TableCell>
                      <TableCell>{el.competentAuthority}</TableCell>
                      <TableCell>{el.authorisationWithdrawalEndDate}</TableCell>
                    </StyledTableRow>
                  );
                }
              })}
          </TableBody>
        </StyledTable>
        {filteredTradingVenueArray && filteredTradingVenueArray.length === 0 && (
          <EmptyCard>
            <SubjectIcon fontSize="large" color="inherit" />
            <Message>No results</Message>
          </EmptyCard>
        )}
        {loadingTradingVenuesForInstrument && (
          <LoaderOverlay>
            <Loader />
          </LoaderOverlay>
        )}
      </CardContent>
      {filteredTradingVenueArray && filteredTradingVenueArray.length > 20 && (
        <TablePaginationWrapper>
          <TablePagination
            rowsPerPage={rowsPerPage}
            handleRowsChange={handleRowsChange}
            page={page}
            setPage={setPage}
            totalInstrumentElements={filteredTradingVenueArray ? filteredTradingVenueArray.length : 0}
          />
        </TablePaginationWrapper>
      )}
    </StyledCardWrapper>
  );
};

export default TradingVenuesTable;
