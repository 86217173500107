import moment from "moment";

export const isDatePast = (maturityDate: any, terminationDate: any) => {
  const terminationValidation = (moment(maturityDate).unix() - moment().unix()) / (3600 * 24 * 365);
  const maturityValidation = (moment(terminationDate).unix() - moment().unix()) / (3600 * 24 * 365);
  return terminationValidation < 0 || maturityValidation < 0;
};

export const isDateValid = (date: any) => {
  const validation = (moment(date).unix() - moment().unix()) / (3600 * 24 * 365);
  return validation > 0 && validation < 50;
};

export const calcDaysProgress = (firstTradeDate: any, maturityDate: any, terminationDate: any) => {
  const currentDate = moment();
  const startDate = moment(firstTradeDate);
  const isStartDateFuture = isDateValid(firstTradeDate);
  const endDate = isDateValid(maturityDate)
    ? moment(maturityDate)
    : isDateValid(terminationDate)
    ? moment(terminationDate)
    : null;

  if (endDate) {
    const durationDays = (endDate.unix() - startDate.unix()) / (3600 * 24);
    const leftDays = (endDate.unix() - currentDate.unix()) / (3600 * 24);
    const progressResult = isStartDateFuture && endDate ? -1 : 100 - (leftDays / durationDays) * 100;

    return progressResult;
  }
  return 0;
};

export const expiryDate = (terminationDate: any) => {
  return terminationDate && isDateValid(terminationDate) ? moment(terminationDate).format("DD/MM/YYYY") : "N/A";
};

export const calcEndDate = (maturityDate: any, terminationDate: any) => {
  return isDateValid(maturityDate)
    ? moment(maturityDate).format("DD/MM/YYYY")
    : isDateValid(terminationDate)
    ? moment(terminationDate).format("DD/MM/YYYY")
    : null;
};
