import { TableBody, TableCell, Typography } from "@material-ui/core";
// import SubjectIcon from "@material-ui/icons/Subject";
import styled from "styled-components";
import mediaQuery from "../../../../common/mediaQuery";

export const TypographyStyle = styled(Typography)`
  font-weight: 500;
`;

export const TableCellStyle = styled(TableCell)`
  padding: 6px 24px 6px 0;

  &:first-child {
    font-weight: 700;
    width: 40%;
  }

  &:nth-child(2),
  &:nth-child(3) {
    text-align: left;
    width: 30%;
    padding: 6px 24px 6px 30px;
    white-space: nowrap;
  }

  ${mediaQuery.SM} {
    &:first-child {
      white-space: nowrap !important;
    }
  }
`;

export const TableContainer = styled.div`
  display: flex;

  ${mediaQuery.SM_LANDSCAPE} {
    overflow-x: scroll;
  }

  ${mediaQuery.SM} {
    overflow-x: scroll;
  }
`;

export const LockedFileDetails = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 75%;

  & > svg {
    height: 100px;
    width: 100px;
  }
`;

export const IconTitle = styled.div`
  font-size: 18px;

  ${mediaQuery.SM_LANDSCAPE} {
    font-size: 12px;
  }

  ${mediaQuery.SM} {
    font-size: 12px;
  }
`;

export const StyledTableBody = styled(TableBody)`
  tr:last-child td {
    border-bottom: none;
  }
`;

export const TooltipTitle = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 200px;
  font-size: 12px;
  justify-content: flex-start;

  span {
    padding: 2px 0;
    display: block;
  }
`;
