import moment from "moment";

interface IPaymentInformation {
  brand: string;
  last4Digits: string;
  expMonth: number;
  expYear: number;
}

export const createPaymentInformation = ({ brand, last4Digits, expMonth, expYear }: IPaymentInformation) => {
  return `${brand.charAt(0).toUpperCase() + brand.slice(1)} ending in ${last4Digits} - Expires ${expMonth}/${expYear}`;
};

interface IUserSettingInformation {
  price: number;
  currency: string;
  activeUntil: string;
}

export const createNextBillingInformation = ({ price, currency, activeUntil }: IUserSettingInformation) => {
  return `${price} ${currency} on ${moment(activeUntil).format("DD/MM/YYYY")}`;
};
