import React, { useEffect, useState } from "react";
import { Breadcrumbs, Button, Link } from "@material-ui/core";
import { useStripe } from "@stripe/react-stripe-js";
import { useMutationCancelSubscription } from "../../../graphql/CancelSubscription";
import { useMutationReactivateSubscription } from "../../../graphql/ReactivateSubscription";
import { CircularProgress } from "@material-ui/core";
import { SubscribeContext } from "../../../common/subcriptionProvider";
import { ButtonsContainer } from "../UserSettingsPage.css";

interface IMessage {
  success?: string;
  error?: string;
}

const SubscriptionToggleButtons = ({ isSubscriptionCanceled, setIsSubscriptionCanceled }: any) => {
  const stripe = useStripe();
  const { setErrorMessage, refreshContext, isUserPremium } = React.useContext(SubscribeContext);
  const [infoMessage, setInfoMessage] = useState<IMessage>({ error: "", success: "" });
  const [isPaymentProcessFinished, setIsPaymentProcessFinished] = useState<boolean>(true);

  const [
    cancelSubscription,
    { data: cancelSubscriptionData, loading: loadingCancelSubscription, error: cancelSubscriptionError },
  ] = useMutationCancelSubscription();

  const [
    reactivateSubscription,
    { data: reactivateSubscriptionData, loading: loadingReactivateSubscription, error: reactivateSubscriptionError },
  ] = useMutationReactivateSubscription();

  useEffect(() => {
    if (isUserPremium) {
      setInfoMessage({ error: "", success: "" });
    }
  }, [isUserPremium]);

  useEffect(() => {
    if (cancelSubscriptionError) {
      setErrorMessage(cancelSubscriptionError.message);
    }
    if (cancelSubscriptionData) {
      const { cancelAtPeriodEnd } = cancelSubscriptionData?.cancelSubscription;
      setIsSubscriptionCanceled(cancelAtPeriodEnd);
    }
  }, [cancelSubscriptionData, cancelSubscriptionError]);

  useEffect(() => {
    if (reactivateSubscriptionError) {
      setErrorMessage(reactivateSubscriptionError.message);
    }
    if (reactivateSubscriptionData) {
      const { cancelAtPeriodEnd, paymentIntent, paymentMethodID } = reactivateSubscriptionData?.reactivateSubscription;

      setIsSubscriptionCanceled(cancelAtPeriodEnd);
      setIsPaymentProcessFinished(false);

      if (!isUserPremium && paymentIntent) {
        const { clientSecret, status } = paymentIntent;
        if (status === "requires_action" && paymentIntent) {
          if (!stripe) {
            setInfoMessage({ error: "Stripe connection error." });
            return;
          }

          stripe
            .confirmCardPayment(clientSecret, {
              payment_method: paymentMethodID,
            })
            .then((result: any) => {
              if (result.error) {
                setInfoMessage({ error: result.error.message });
              } else {
                setInfoMessage({ success: "Payment has successfully passed." });
                refreshContext(true);
              }
              setIsPaymentProcessFinished(true);
            });
        } else if (status === "succeeded") {
          setInfoMessage({ success: "Payment has successfully passed." });
          setIsPaymentProcessFinished(true);
          refreshContext(true);
        }
      } else {
        setIsPaymentProcessFinished(true);
      }
    }
  }, [reactivateSubscriptionData, reactivateSubscriptionError]);

  const toggleSubscriptionActivation = () => {
    if (isUserPremium === false) {
      return reactivateSubscription();
    }
    if (isSubscriptionCanceled) {
      reactivateSubscription();
    } else {
      cancelSubscription();
    }
  };

  const generateButtonText = () => {
    if (isUserPremium === false) {
      return { id: "user_settings__pay_50_e", buttonText: "Pay 50E" };
    }
    if (isSubscriptionCanceled) {
      return { id: "user_settings__reactivate_subscription", buttonText: "Reactivate subscription" };
    } else {
      return {
        id: "user_settings__cancel_subscription",
        buttonText: "Cancel subscription",
      };
    }
  };
  return (
    <ButtonsContainer>
      {!isPaymentProcessFinished || loadingReactivateSubscription || loadingCancelSubscription ? (
        <CircularProgress className="progress" color="primary" size={30} />
      ) : (
        <>
          <Breadcrumbs aria-label="breadcrumb">
            <Link id="user_settings__update_payment_details" href="/payments-details">
              Update payment details
            </Link>
          </Breadcrumbs>
          {infoMessage.error && <p className="error">{infoMessage.error}</p>}
          {infoMessage.success && <p className="success">{infoMessage.success}</p>}
          <Button
            id={generateButtonText().id}
            variant="outlined"
            color="primary"
            onClick={() => toggleSubscriptionActivation()}
          >
            {generateButtonText().buttonText}
          </Button>
        </>
      )}
    </ButtonsContainer>
  );
};

export default SubscriptionToggleButtons;
