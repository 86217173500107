import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { IssuerSugestion } from "../common/types";

const GET_ISSUER_SUGGESTIONS = gql`
  query getIssuerSuggestions($startingCharacters: String!) {
    getIssuerSuggestions(startingCharacters: $startingCharacters) {
      name
      lei
    }
  }
`;

interface QueryText {
  startingCharacters: string;
}

interface GetIssuerSuggestions {
  getIssuerSuggestions: IssuerSugestion[];
}

export const useLazyGetIssuerSuggestionsQuery = () =>
  useLazyQuery<GetIssuerSuggestions, QueryText>(GET_ISSUER_SUGGESTIONS);
