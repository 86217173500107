import {
  Box,
  Card,
  CardContent,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import {createMuiTheme} from "@material-ui/core/styles";
import styled from "styled-components";
import mediaQuery from "../../common/mediaQuery";
interface IStyledTable {
  isfilter: number;
}

export const ActiveFiltersContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Filter = styled.div`
  margin-right: 10px;
`;

export const StyledChip = styled(Chip)`
  background: #b498ff29;
  margin-right: 5px;
  height: 25px;

  & svg {
    color: #b8aae0;
  }
`;
export const StyledTableHead = styled(TableHead)`
  th:first-child,
  th:nth-child(2) {
    max-width: 110px;
  }

  th:nth-child(3) {
    max-width: 200px;
  }

  th:nth-child(4) {
    max-width: 175px;
  }

  th:nth-child(5) {
    max-width: 190px;
  }

  th:nth-child(6) {
    width: 100%;
  }
`;

export const TablePeriodCell = styled(TableCell)`
  width: 250px;
  min-width: 250px;

  & > div {
    margin-top: 0;
    font-size: 13px;
  }

  & div:first-child {
    height: 13px;
  }

  ${mediaQuery.SM} {
    min-width: 170px;

    & div:first-child {
      height: 11px;
    }
  }

  ${mediaQuery.XXL} {
    min-width: 220px;
  }
`;

export const ClearFilters = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: #3f18aa;
  cursor: pointer;
`;

export const RecentInstrumentsCardWrapper = styled(Card)`
  ${mediaQuery.LG} {
    & > div:last-child {
      overflow-x: scroll;
    }
  }

  ${mediaQuery.MD} {
    & > div:last-child {
      overflow-x: scroll;
    }
  }

  ${mediaQuery.SM} {
    .MuiCardHeader-root {
      padding: 16px 0 0 16px;
    }
  }
`;

export const LoaderOverlay = styled.div`
  position: absolute;
  top: 200px;
  width: 100%;
  transform: translate(0%, -50%);
  z-index: 2;
`;

export const TableRowStyle = styled(TableRow)`
  cursor: pointer;

  & > th > *,
  & > td > * {
    pointer-events: none;
  }

  & > th,
  & > td {
    pointer-events: all;
  }
`;

export const TooltipTitle = styled.div`
  font-size: 14px;
  font-weight: 300;
`;

export const CardContentStyle = styled(CardContent)`
  min-height: 280px;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const TypographyStyle = styled(Typography)`
  font-size: 24px;
  font-weight: 700;
`;

export const themeTypography = createMuiTheme({
  typography: {
    h5: {
      fontWeight: 700,
    },
  },
});

export const StyledBox = styled(Box)`
  display: inline-block;

  ${mediaQuery.SM} {
    h5 {
      font-size: 1.2rem;
    }
  }
`;

export const StyledTable = styled(Table)<IStyledTable>`
  margin-top: ${({ isfilter }) => (isfilter ? "0" : "25px")};
  th,
  td:first-child {
    width: 100px;
  }

  th {
    font-size: 15px;
    font-weight: bold;
    white-space: nowrap;
  }

  tbody tr:last-child td {
    border-bottom: none;
  }

  tr > td:nth-child(3) {
    max-width: 280px;
  }

  tr > td:nth-child(6) p {
    margin: 5px 0;
    overflow: hidden;
    width: 100%;
  }

  tr > td:nth-child(6) p:first-child {
    font-weight: bold;
  }

  tr > td:nth-child(6) {
    padding: 10px 0 10px 18px;
  }

  tr > td:nth-child(6) > div {
    margin-top: 0;
    font-size: 13px;
  }

  tr > td:nth-child(6) div:first-child {
    height: 13px;
  }

  ${mediaQuery.SM} {
    th,
    td {
      font-size: 0.8rem;
    }
  }

  ${mediaQuery.XXL} {
    tr > td:nth-child(3) {
      max-width: 170px;
    }
  }
`;

interface ILoadingInstruments {
  loading: any;
}

export const StyledTableBody = styled(TableBody)<ILoadingInstruments>`
  filter: ${({ loading }) => (loading ? "blur(1px)" : "blur(0)")};
  transition: ${({ loading }) => (loading ? "none" : "all .6s")};
`;

export const EmptyCard = styled.div`
  text-align: center;
  color: #cbcbcb;
  margin: 11px 0;
`;

export const Message = styled.div`
  font-size: 16px;
  font-weight: 700;
`;

export const StyledTradingVenueName = styled.span`
  font-weight: bold;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledTradingVenueCodeWithIcon = styled.span`
  display: flex;
  svg {
    margin-left: 5px;
    height: 0.8em;
    width: 0.8em;
  }
`;

export const HeaderTooltipTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
`;
