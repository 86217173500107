import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import {
  IFavoriteInstruments,
  IFavoritesMetadata,
} from "../pages/FavouriteInstrumentsPage/FavouriteInstrumentsInterface";

const GET_FAVORITES = gql`
  query getFavorites($numberOfRecords: Int, $startingRecord: Int) {
    getFavorites(numberOfRecords: $numberOfRecords, startingRecord: $startingRecord) {
      instruments {
        upcomingRCA
        instrumentFullName
        notionalCurrency
        instrumentIdentificationCode
        tradingVenues
        nominalValuePerUnit
        totalIssuedNominalAmount
        firstTradeDate
        maturityDate
        terminationDate
      }
      metadata {
        totalNumberOfRecords
      }
    }
  }
`;

interface Params {
  numberOfRecords: number;
  startingRecord: number;
}

interface GetFavorites {
  getFavorites: {
    instruments: IFavoriteInstruments;
    metadata: IFavoritesMetadata;
  };
}

export const useLazyGetFavoritesQuery = (params: any) =>
  useLazyQuery<GetFavorites, Params>(GET_FAVORITES, {
    fetchPolicy: params ? params.fetchPolicy : "cache-first",
  });
