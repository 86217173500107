import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/images/logo2.svg";
import PageWidthWrapper from "../../components/PageWidthWrapper";
import { redirectPath } from "../../utils/redirect";
import UserTerms from "../UserTerms/UserTerms";
import {
  Footer,
  FooterContent,
  LinksContainer,
  LinksSubContainer,
  StyledDialog,
  StyledDialogTitle,
  StyledCookieBar,
  Text,
  StyledDialogContent,
} from "./PageFooter.css";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { Typography } from "@material-ui/core";
import useCookies from "react-cookie/cjs/useCookies";

const apiDocUrl = window._env_.API_DOC_URL;

const PageFooter: React.FC = () => {
  const history = useHistory();
  const [cookies, setCookie] = useCookies(["NTEFIRDSCookieConsent"]);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openCookieBar, setOpenCookieBar] = useState(false);
  const [openCookieInfoDialog, setOpenCookieInfoDialog] = useState<boolean>(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCookie = () => {
    setCookie("NTEFIRDSCookieConsent", "true");
    setOpenCookieBar(false);
  };

  const handleClose = () => {
    setCookie("NTEFIRDSCookieConsent", "false");
    setOpenCookieBar(false);
  };

  const handleCloseDialog = () => {
    setOpenCookieInfoDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenCookieInfoDialog(true);
  };

  useEffect(() => {
    if (
      !cookies["NTEFIRDSCookieConsent"] ||
      (cookies["NTEFIRDSCookieConsent"] && cookies["NTEFIRDSCookieConsent"] === false)
    ) {
      setOpenCookieBar(true);
    }
  }, [cookies]);

  return (
    <>
      <Footer>
        <StyledDialog onClose={handleCloseDialog} aria-labelledby="customized-dialog-title" open={openCookieInfoDialog}>
          <StyledDialogTitle id="customized-dialog-title">
            PERSONAL DATA
            <IconButton aria-label="close" onClick={handleCloseDialog}>
              <CloseIcon />
            </IconButton>
          </StyledDialogTitle>
          <StyledDialogContent dividers>
            <Typography gutterBottom>
              All personal data provided by a User on the eFirds will be handled in accordance with relevant data
              protection regulations from time to time, and in accordance with Nordic Trustee’s privacy statement
              available at{" "}
              <a href="https://nordictrustee.com/about/governance">https://nordictrustee.com/about/governance</a>
            </Typography>
            <Typography gutterBottom>
              The personal data collected through eFirds is only for internal use and will not be handed over to any
              external parties. Nordic Trustee only collects information that is required to fulfill the purpose of the
              actual service, however Nordic Trustee may use external technology providers (such as Hotjar and Google
              Analytics) in order to use data for better understanding of Nordic Trustee’s customers’ needs and to
              optimize the solution. Such providers may use cookies and other technologies to collect data on Nordic
              Trustee’s users’ behavior and their devices. This data does not contain any personally identifiable
              information and will never be used to identify individual users. You may opt-out of Hotjar’s tracking
              service by visiting{" "}
              <a href="https://www.hotjar.com/legal/compliance/opt-out">
                https://www.hotjar.com/legal/compliance/opt-out
              </a>{" "}
              and opt-out of Google Analytics by downloading Google’s official browser add-on here:
              <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.
            </Typography>
            <Typography gutterBottom>
              More information about Nordic Trustee’s use of cookies can also be found at{" "}
              <a href="https://www.nordictrustee.com/privacy">https://www.nordictrustee.com/privacy</a>.
            </Typography>
          </StyledDialogContent>
        </StyledDialog>
        <StyledCookieBar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={openCookieBar}
          autoHideDuration={6000}
          message={
            <p>
              efirds.eu uses cookies to ensure that we give you the best experience on our website. More information{" "}
              <span id="footer__cookies_bar_here_link" onClick={handleOpenDialog}>
                here
              </span>
              .
            </p>
          }
          action={
            <>
              <Button color="primary" onClick={handleClose}>
                DECLINE
              </Button>
              <Button color="secondary" onClick={handleCookie}>
                ACCEPT
              </Button>
            </>
          }
        />
        <PageWidthWrapper>
          <FooterContent>
            <div>
              <Logo />
              <Text>
                eFIRDS™ provides capital market participants daily updated European reference data sourced directly from
                ESMA FIRDS, FCA FIRDS, GLEIF and other relevant databases.
              </Text>
              <Text>
                All eFIRDS™ products are developed by Stamdata AS - the leading provider of fixed income market
                information in the Nordic capital markets, and a fully owned subsidiary of Nordic Trustee.
              </Text>
              <Text>
                This database uses inter alia material downloaded from ESMA's website. Such material may also be
                obtained free of charge through ESMA's website. ESMA does not endorse eFIRDS or Stamdata AS and in no
                way is liable for copyright or other intellectual copyrights infringements nor for any damages caused to
                third parties through eFIRDS.
              </Text>
              <Text>
                Information from FCA FIRDS is provided and may only be used in accordance with the{" "}
                <a
                  id="footer__notice_and_disclaimer"
                  href="https://data.fca.org.uk/#/viewdata"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notice and Disclaimer
                </a>{" "}
                and{" "}
                <a
                  id="footer__copyright_notice_and_disclaimer"
                  href="https://www.fca.org.uk/legal"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Copyright Notice and Disclaimer
                </a>{" "}
                published on the FCA website that applies to use of the FCA FIRDS.
              </Text>
            </div>
            <LinksContainer>
              <LinksSubContainer>
                <a
                  id="footer__about_us"
                  href="https://nordictrustee.com/service/efirds/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  About us
                </a>
                <a
                  id="footer__contact_us"
                  href=" https://nordictrustee.com/service/efirds/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contact us
                </a>
              </LinksSubContainer>
              <LinksSubContainer>
                <div id="footer__user_terms" onClick={handleOpenModal}>
                  User Terms
                </div>
                <div id="footer__disclaimer" onClick={() => redirectPath("/disclaimer", history)}>
                  Disclaimer
                </div>
              </LinksSubContainer>
              <a id="footer__API_docs" href={apiDocUrl} target="_blank" rel="noopener noreferrer">
                API docs
              </a>
            </LinksContainer>
          </FooterContent>
        </PageWidthWrapper>
      </Footer>
      <UserTerms openModal={openModal} handleCloseModal={handleCloseModal} />
    </>
  );
};

export { PageFooter };
