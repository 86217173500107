import { List, ListItem, ListItemText, Typography } from "@material-ui/core";
import { countries } from "country-data";
import moment from "moment";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { GleifEntryResult, ValidationAuthority } from "../../common/types";
import { StyledRecordContainer, StyledSpan } from "./LeiSearchTab/Issuer.css";

export const mapGleifData = ({
  lei,
  bic,
  entityNew: {
    headquartersAddress: {
      additionalAddressLine: headquarterAdditionalAddress,
      country: headquarterCountry,
      city: headquarterCity,
      firstAddressLine: headquarterFirstAddressLine,
      postalCode: headquarterPostalCode,
      region: headquarterRegion,
    },
    legalAddress: {
      additionalAddressLine: legalAdditionalAddress,
      country: legalCountry,
      city: legalCity,
      firstAddressLine: legalFirstAddressLine,
      postalCode: legalPostalCode,
      region: legalRegion,
    },
    legalName,
    legalForm,
    registrationAuthority,
    entityStatus,
    entityCategory,
    legalJurisdiction,
  },
  registrationNew: {
    leiIssuerName,
    leiIssuerMarketingName,
    registrationStatus,
    managingLOU,
    initialRegistrationDate,
    lastUpdateDate,
    nextRenewalDate,
    validationSources,
    validationAuthority: { validationAuthorityID, validationAuthorityEntityID },
    otherValidationAuthorities,
  },
}: GleifEntryResult) => {
  return {
    title: {
      country: legalCountry,
      legalName: legalName,
      lei: lei,
      entityStatus: entityStatus,
    },
    mainInformation: {
      ...(legalName && { "(Primary) Legal Name": legalName }),
      ...(lei && { LEI: lei }),
      ...(legalJurisdiction && { "Jurisdiction of Formation": legalJurisdiction }),
      ...(entityCategory && { "Entity Category": entityCategory }),
    },
    basicInformation: {
      ...(legalName && { "(Primary) Legal Name": legalName }),
      ...(lei && { LEI: lei }),
      "Registered At": generateRegistrationAt(registrationAuthority),
      ...(registrationAuthority.registrationAuthorityEntityID && {
        "Registered As": registrationAuthority.registrationAuthorityEntityID,
      }),
      ...(legalJurisdiction && { "Jurisdiction of Formation": legalJurisdiction }),
      "Entity Legal Form": generateLegalEntityForm(legalForm),
      "Entity Status": <span data-status={entityStatus}>{entityStatus}</span>,
      ...(bic && { "BIC Code": generateBicList(bic) }),
    },
    legalAddress: {
      ...(legalFirstAddressLine && { Street: legalFirstAddressLine }),
      ...(legalAdditionalAddress && {
        ...(legalAdditionalAddress.length && {
          "Additional Address Line": generateAdditionalAddressData(legalAdditionalAddress),
        }),
      }),
      ...(legalPostalCode && { "ZIP Code": legalPostalCode }),
      Area: filterEmptyElements(`${legalCity} ${legalRegion}`),
      ...(legalCountry && { Country: countries[legalCountry].name }),
    },
    headQuarterAddress: {
      ...(headquarterFirstAddressLine && { Street: headquarterFirstAddressLine }),
      ...(headquarterAdditionalAddress && {
        ...(headquarterAdditionalAddress.length && {
          "Additional Address Line": generateAdditionalAddressData(headquarterAdditionalAddress),
        }),
      }),
      ...(headquarterPostalCode && { "ZIP Code": headquarterPostalCode }),
      Area: filterEmptyElements(`${headquarterCity} ${headquarterRegion}`),
      ...(headquarterCountry && { Country: countries[headquarterCountry].name }),
    },
    registrationDetails: {
      "Registration Date": moment(initialRegistrationDate).format().replace("T", " "),
      "Last Update": moment(lastUpdateDate).format().replace("T", " "),
      "Status": <span data-status={registrationStatus}>{registrationStatus}</span>,
      "Next Renewal": moment(nextRenewalDate).format().replace("T", " "),
      ...((leiIssuerName || leiIssuerMarketingName) && {
        "LEI Issuer": `${leiIssuerName} (${leiIssuerMarketingName})`,
      }),
      ...(managingLOU && { "LEI Issuer LEI": managingLOU }),
      ...(validationSources && { "Corroboration Level": validationSources.replaceAll("_", " ") }),
      ...(validationAuthorityID && { "Data Validated At": validationAuthorityID }),
      ...(validationAuthorityEntityID && { "Data Validated As": validationAuthorityEntityID }),
      ...(otherValidationAuthorities.length && {
        "Other Validation Authorities": generateOtherValidationAuthorities(otherValidationAuthorities),
      }),
    },
  };
};

const filterEmptyElements = (value: string) => {
  return value.replaceAll("null", "").replaceAll("undefined", "");
};

const generateOtherValidationAuthorities = (data: ValidationAuthority[]) => {
  return data.map(({ validationAuthorityEntityID, validationAuthorityID }: ValidationAuthority) => (
    <StyledRecordContainer key={validationAuthorityEntityID}>
      {validationAuthorityID && (
        <>
          <StyledSpan>Data Validated At (ID): </StyledSpan>
          <span>{validationAuthorityID}</span>
        </>
      )}
      {validationAuthorityEntityID && (
        <>
          <StyledSpan>Data Validated As: </StyledSpan>
          <span>{validationAuthorityEntityID}</span>
          <br />
        </>
      )}
    </StyledRecordContainer>
  ));
};

const generateAdditionalAddressData = (data: string[]) => {
  let additionalAddressData = "";
  data.map((el: string) => {
    additionalAddressData += ` ${el}`;
  });
  return additionalAddressData;
};

const generateBicList = (data: string[]) => {
  return data.map((bicCode: string) => <p key={bicCode}>{bicCode}</p>);
};

const generateRegistrationAt = ({
  registrationAuthorityInternationalName,
  registrationAuthorityInternationalOrganizationName,
  registrationAuthorityLocalOrganizationName,
  registrationAuthorityLocalName,
  registrationAuthorityJurisdictions,
  registrationAuthorityID,
  otherRegistrationAuthorityID,
}: any) => {
  return (
    <>
      <p>
        {registrationAuthorityInternationalName}{" "}
        {registrationAuthorityInternationalOrganizationName
          ? `(${registrationAuthorityInternationalOrganizationName})`
          : null}
      </p>
      <p>
        {registrationAuthorityLocalName}{" "}
        {registrationAuthorityLocalOrganizationName ? `(${registrationAuthorityLocalOrganizationName})` : null}
      </p>
      {registrationAuthorityJurisdictions.map((el: any, index: number) => {
        return el.jurisdiction === el.country ? (
          <p key={index}>{el.jurisdiction}</p>
        ) : (
          <p key={index}>
            {el.jurisdiction}, {el.country}
          </p>
        );
      })}
      <p>{registrationAuthorityID}</p>
      {!otherRegistrationAuthorityID &&
        registrationAuthorityJurisdictions.length === 1 &&
        !registrationAuthorityJurisdictions[0].country &&
        !registrationAuthorityJurisdictions[0].jurisdiction && <p>No alternative Registration Authority ID provided</p>}
    </>
  );
};

const generateLegalEntityForm = ({ entityLegalFormCode, otherLegalForm, names }: any) => {
  return (
    <>
      {names &&
        names.map((el: any) => {
          if (names.length === 1 && !el.localName && !el.languageCode) {
            return <p key={1}>No Entity Legal Form available ({entityLegalFormCode})</p>;
          } else {
            return (
              <p key={el.languageCode}>
                {el.localName} {el.languageCode ? `(${el.languageCode})` : null}
              </p>
            );
          }
        })}
      {!otherLegalForm && names.length === 1 && !names[0].localName && !names[0].languageCode && entityLegalFormCode ? (
        <p>No alternative legal form description provided</p>
      ) : (
        <p>{entityLegalFormCode}</p>
      )}
    </>
  );
};

const relationTitle = {
  directParent: "Direct:",
  ultimateParent: "Ultimate:",
  directChildren: "Direct:",
  ultimateChildren: "Ultimate:",
};
const StyledLink = styled(Link)`
  & > * {
    pointer-events: none;
  }
`;

export const generateParentItems = (issuerRelationsData: any) => {
  return Object.keys(issuerRelationsData).map((relation: string) => {
    if (relation === "directParent" || relation === "ultimateParent") {
      if (issuerRelationsData[relation]) {
        const { lei, name } = issuerRelationsData[relation];
        return (
          <Fragment key={relation}>
            <Typography variant="subtitle1">{relationTitle[relation]}</Typography>
            <List>
              <ListItem>
                <StyledLink id="issuer_page__corporate_group_structure" to={"/issuer/" + lei}>
                  <ListItemText primary={name + " - " + lei} />
                </StyledLink>
              </ListItem>
            </List>
          </Fragment>
        );
      } else {
        return (
          <Fragment key={relation}>
            <Typography variant="subtitle1">{relationTitle[relation]}</Typography>
            <Typography variant="caption">No parent</Typography>
          </Fragment>
        );
      }
    } else {
      return null;
    }
  });
};

export const generateChildrenItems = (issuerRelationsData: any) => {
  return Object.keys(issuerRelationsData).map((relation: string) => {
    if (relation === "directChildren" || relation === "ultimateChildren") {
      if (issuerRelationsData[relation].length) {
        return (
          <Fragment key={relation}>
            <Typography variant="subtitle1">{relationTitle[relation]}</Typography>
            <List>
              {issuerRelationsData[relation].map((relationChild: any) => {
                const { lei, name } = relationChild;
                return (
                  <ListItem key={lei}>
                    <StyledLink id="issuer_page__corporate_group_structure" to={"/issuer/" + lei}>
                      <ListItemText key={lei} primary={name + " - " + lei} />
                    </StyledLink>
                  </ListItem>
                );
              })}
            </List>
          </Fragment>
        );
      } else {
        return (
          <Fragment key={relation}>
            <Typography variant="subtitle1">{relationTitle[relation]}</Typography>
            <Typography variant="caption">No children</Typography>
          </Fragment>
        );
      }
    } else {
      return null;
    }
  });
};
