import { Card, CardContent } from "@material-ui/core";
import {createMuiTheme} from "@material-ui/core/styles";
import styled from "styled-components";
import rightArrow from "../../assets/images/right-arrow.png";
import mediaQuery from "../../common/mediaQuery";

export const TableScrollWrapper = styled.div`
  overflow-y: scroll;
  height: auto;
  max-height: 400px;
  width: 100%;
`;

export const CardContentStyle = styled(CardContent)`
  overflow: auto;
  min-height: 100px;
`;

export const EmptyCard = styled.div`
  text-align: center;
  color: #cbcbcb;
  margin-top: 10px;
`;

export const Message = styled.div`
  font-size: 16px;
  font-weight: 700;
`;

export const UpdateTableWrapper = styled(Card)`
  margin: 10px 0;
  position: relative;

  table {
    max-width: 800px;
  }

  tr[data-emptycell="false"]:nth-child(n + 2) {
    border-top: 30px solid #f7f8f6;
  }

  tr {
    height: 32px;
  }

  th {
    font-weight: 700;
    border-bottom: 0;
    padding-left: 0;
    position: sticky;
    top: 0;
    background: #f7f8f6;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }

  td {
    font-weight: 700;
    border-bottom: 0;
    padding-left: 0;
  }

  td:nth-child(3) {
    font-weight: normal;
    border: none;
    text-decoration: line-through;
    border-bottom: 0;
    padding-right: 85px;
    padding-left: 0;
    text-align: left;
    background: url(${rightArrow});
    background-repeat: no-repeat;
    background-position: 75%;
    background-size: 30px 20px;
    min-width: 150px;
  }

  th:after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
  }

  ${mediaQuery.SM} {
    td,
    th {
      font-size: 0.7rem;
      white-space: nowrap;
    }
  }
`;

export const themeTypography = createMuiTheme({
  typography: {
    h5: {
      fontWeight: 700,
    },
    h6: {
      paddingTop: 50,
    },
  },
});
