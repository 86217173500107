import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { InstrumentInterface } from "../common/types";

const queryAuth = (isPremium: any) => {
  const GET_INSTRUMENT = gql`
  query getInstrument($isin: String!) {
    getInstrument(isin: $isin) {
      firstTradeDate
      instrumentFullName
      instrumentType
      interestType
      maturityDate
      publicationDate
      requestForAdmissionToTrading
      terminationDate
      tradingVenues @include(if: ${isPremium})
      underlyingInstruments @include(if: ${isPremium})
      isFavorite @include(if: ${isPremium})
      totalIssuedNominalAmount @include(if: ${isPremium}) 
      seniorityOfTheBond
      instrumentClassification 
      upcomingRCA
      termOfTheIndexUnit
      termOfTheIndexValue
      identifierOfTheIndex
      nameOfTheIndex
      notionalCurrency
      issuerOrOperatorIdentifier
      instrumentIdentificationCode
      nominalValuePerUnit @include(if: ${isPremium})
      basePointSpread @include(if: ${isPremium})
      fixedRate
      commoditiesOrEmissionDerivativeIndicator
      additionalData {
        baseProduct
        deliveryType
        derivativeInstrumentReferenceRate
        expiryDate
        finalPriceType
        fixedRateOfLeg1
        fixedRateOfLeg2
        floatingRateOfLeg2
        furtherSubProduct
        fxNotionalCurrency2
        fxType
        irTermOfContract
        irTermOfContractOfLeg2
        notionalCurrency2
        optionExerciseStyle
        optionType
        priceMultiplier
        strikePrice
        strikePriceCurrency
        subProduct
        transactionType
        underlyingIndexName @include(if: ${isPremium})
        underlyingIndexTerm @include(if: ${isPremium})
        underlyingIssuer @include(if: ${isPremium})
      }
    }
  }
`;
  return GET_INSTRUMENT;
};

interface QueryText {
  isin: string;
}

interface GetInstrument {
  getInstrument: InstrumentInterface;
}

export const useLazyGetInstrumentQuery = (isPremium: any, params: any) =>
  useLazyQuery<GetInstrument, QueryText>(queryAuth(isPremium), {
    fetchPolicy: params ? params : "cache-first",
  });
