import { SubFieldType } from "./InstrumentDetailsInterface";

export const mapSubFields = () => {
  const fxType: SubFieldType = {
    FXCR: "FX Cross Rates",
    FXEM: "FX Emerging Markets",
    FXMJ: "FX Majors",
  };

  const finalPriceType: SubFieldType = {
    ARGM: "Argus/McCloskey",
    BLTC: "Baltic",
    EXOF: "Exchange",
    GBCL: "GlobalCOAL",
    IHSM: "IHS McCloskey",
    PLAT: "Platts",
    OTHR: "Other",
  };

  const transactionType: SubFieldType = {
    FUTR: "Futures",
    OPTN: "Options",
    TAPO: "TAPOS",
    SWAP: "SWAPS",
    MINI: "Minis",
    OTCT: "OTC",
    ORIT: "Outright",
    CRCK: "Crack",
    DIFF: "Differential",
    OTHR: "Other",
  };

  const deliveryType: SubFieldType = {
    PHYS: "Physically Settled",
    CASH: "Cash settled",
    OPTL: "Optional for counterparty or when determined by a third party",
  };

  const optionExerciseStyle: SubFieldType = {
    EURO: "European",
    AMER: "American",
    ASIA: "Asian",
    BERM: "Bermudan",
    OTHR: "Any other type",
  };

  const optionType: SubFieldType = {
    PUTO: "Put",
    CALL: "Call",
    OTHR: "Other",
  };

  const strikePrice: SubFieldType = {
    PNDG: "PNDG (pending price)",
    NOAP: "NOAP (price not applicable)",
  };

  return {
    fxType,
    finalPriceType,
    transactionType,
    deliveryType,
    optionExerciseStyle,
    optionType,
    strikePrice,
  };
};
