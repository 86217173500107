import {
  CardContent,
  CardHeader,
  Link,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Flag from "react-world-flags";
import { useLazyGetRecentInstrumentsQuery } from "../../graphql/GetRecentInstruments";
import { calcDaysProgress } from "../../utils/calcDates";
import { CATEGORY_COL, instrColumnMap, TYPE_COL } from "../InstrumentsTypeAndCategory/InstrumentSelectFunctions";
import LinearProgressBar from "../LinearProgressBar/LinearProgressBar";
import Loader from "../loader/Loader";
import {
  RecentInstrumentsCardWrapper,
  StyledBox,
  StyledTable,
  TableNameCell,
  TablePeriodCell,
  TableRowStyle,
  themeTypography,
} from "./RecentInstrumentsTable.css";
import { IRecentInstrumentsRows } from "./RecentInstrumentsTableInterface";
import { SubscribeContext } from "../../common/subcriptionProvider";

const RecentInstrumentsTable = () => {
  const [recentInstrumentsRows, setRecentInstrumentsRows] = useState<any>();
  const history = useHistory();
  const { setErrorMessage } = useContext(SubscribeContext);

  const [
    getRecentInstruments,
    { data: recentInstruments, loading: loadingRecentInstruments, error: getRecentInstrumentsError },
  ] = useLazyGetRecentInstrumentsQuery(false);

  useEffect(() => {
    getRecentInstruments({
      variables: {
        randomize: true,
        numberOfRecords: 15,
        resourceParameters: {
          instrumentType: "ANY",
          instrumentStatus: "LISTED",
        },
        startingRecord: 1,
      },
    });
  }, [getRecentInstruments]);

  useEffect(() => {
    if (getRecentInstrumentsError) {
      setErrorMessage(getRecentInstrumentsError.message);
    }
    if (recentInstruments && recentInstruments?.getRecentInstruments) {
      setRecentInstrumentsRows(recentInstruments.getRecentInstruments.instruments);
    }
  }, [recentInstruments, getRecentInstrumentsError]);

  const handleRedirectInstrument = (isin: string) => {
    history.push(`/instrument/${isin}`);
  };

  return (
    <RecentInstrumentsCardWrapper>
      <CardHeader
        title={
          <StyledBox>
            <ThemeProvider theme={themeTypography}>
              <Typography variant="h5">New Instrument Monitor</Typography>
            </ThemeProvider>
            <Typography>
              <Link
                id="dashboard__see_all_new_instrument_monitor"
                onClick={() => history.push("/new-instrument-monitor")}
              >
                See all
              </Link>
            </Typography>
          </StyledBox>
        }
      />
      <CardContent>
        {loadingRecentInstruments ? (
          <Loader />
        ) : (
          <StyledTable size="small">
            <TableHead>
              <TableRow>
                <TableCell>Country</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Instrument Type</TableCell>
                <TableCell>Instrument Category</TableCell>
                <TableCell>Period</TableCell>
                <TableCell>Currency</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {recentInstrumentsRows &&
                recentInstrumentsRows.map((row: IRecentInstrumentsRows) => {
                  const daysProgress = calcDaysProgress(row.firstTradeDate, row.maturityDate, row.terminationDate);
                  const instrType = instrColumnMap(row.instrumentClassification, TYPE_COL);
                  const instrCategory = instrColumnMap(row.instrumentClassification, CATEGORY_COL);
                  return (
                    <TableRowStyle
                      key={row.instrumentIdentificationCode}
                      hover
                      onClick={() => handleRedirectInstrument(row.instrumentIdentificationCode)}
                    >
                      <TableCell id="dashboard__instrument" align="center">
                        {row.upcomingRCA ? (
                          <Flag code={row.upcomingRCA} alt={row.upcomingRCA} style={{ width: "38px" }} />
                        ) : (
                          <Flag code={"EU"} alt={"EU"} style={{ width: "38px" }} />
                        )}
                      </TableCell>
                      <TableNameCell id="dashboard__instrument">
                        <p>{row.instrumentIdentificationCode}</p>
                        <p>{row.instrumentFullName}</p>
                      </TableNameCell>
                      <TableCell id="dashboard__instrument">{instrType}</TableCell>
                      <TableCell id="dashboard__instrument">{instrCategory}</TableCell>
                      {daysProgress !== 0 ? (
                        <TablePeriodCell>
                          <LinearProgressBar
                            progress={daysProgress}
                            firstTradeDate={row.firstTradeDate}
                            maturityDate={row.maturityDate}
                            terminationDate={row.terminationDate}
                          />
                        </TablePeriodCell>
                      ) : (
                        <TableCell id="dashboard__instrument"></TableCell>
                      )}
                      <TableCell id="dashboard__instrument">{row.notionalCurrency}</TableCell>
                    </TableRowStyle>
                  );
                })}
            </TableBody>
          </StyledTable>
        )}
      </CardContent>
    </RecentInstrumentsCardWrapper>
  );
};

export default RecentInstrumentsTable;
