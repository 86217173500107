import React, { useContext } from "react";
import { Grid, Paper, Typography, Button, Container } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import TimelineIcon from "@material-ui/icons/Timeline";
import homePagePicture_1 from "../../assets/images/homePagePicture_1.png";
import homePagePicture_2 from "../../assets/images/homePagePicture_2.png";
import homePagePicture_3 from "../../assets/images/homePagePicture_3.png";
import homePagePicture_4 from "../../assets/images/homePagePicture_4.png";
import premium from "../../assets/images/premium_user.svg";
import basic from "../../assets/images/basic_user.svg";
import { Page } from "../../components";
import { useHistory } from "react-router-dom";
import {
  HomePageStyles,
  LandingFooter,
  PricesContainer,
  FavImage,
  FeaturesContainer,
  LandingMain,
  StatsContainer,
  CircleIcon,
  CircleColorFree,
  CircleColorPremium,
  PaperHeader,
  PaperContent,
  ImageContainer,
  InfoSection,
  FeatureSection,
  Feature,
  SubscribeButtonFree,
  SubscribeButtonPremium,
  Image,
  GridContainerStyles,
  GridStyles,
  ItemTitle,
  ItemTitleSecondary,
  StyledList,
  NewInstrumentMonitorWrapper,
  KeyFeaturesWrapper,
  StyledFeaturesList,
  NewInstrumentMonitorImage,
  StatsTitle,
  StatsNumbers,
  StatsNumberTextWrapper,
  ImgWrapper,
  StyledSectionTitle,
  ButtonWithTextWrapper,
  ButtonsWrapper,
  SearchBarWrapper,
  StyledButton,
} from "./HomePage.css";
import ProductTiles from "../ProductsPage/ProductsTiles";
import { SubscribeContext } from "../../common/subcriptionProvider";
import SearchBar from "../../components/SearchBar/SearchBar";

function HomePage() {
  const { isUserPremium, azureProps } = useContext(SubscribeContext);
  const classes = HomePageStyles();
  const history = useHistory();

  const generateGetStartedPremiumButton = () => {
    if (isUserPremium) {
      return <SubscribeButtonPremium onClick={() => history.push("/dashboard")}>GET STARTED</SubscribeButtonPremium>;
    } else if (isUserPremium !== null && azureProps.authenticationState === "Authenticated") {
      return (
        <SubscribeButtonPremium
          id="landing_page__upgrade_to_premium_access"
          onClick={() => history.push("/payments-details")}
        >
          UPGRADE TO PREMIUM ACCESS
        </SubscribeButtonPremium>
      );
    } else if (azureProps.authenticationState === "Unauthenticated") {
      return (
        <SubscribeButtonPremium id="landing_page__sign_up" onClick={azureProps.login}>
          SIGN UP
        </SubscribeButtonPremium>
      );
    } else {
      return null;
    }
  };

  const generateGetStartedFreeButton = () => {
    if (isUserPremium) {
      return (
        <SubscribeButtonFree id="landing_page__get_started" disabled>
          GET STARTED
        </SubscribeButtonFree>
      );
    } else {
      return (
        <SubscribeButtonFree id="landing_page__try_now" onClick={() => history.push("/dashboard")}>
          TRY NOW
        </SubscribeButtonFree>
      );
    }
  };

  const generateSubscribeTryForFreeButtons = () => {
    if (isUserPremium) {
      return (
        <StyledButton
          id="landing_page__go_to_dashboard_top"
          className={classes.button}
          onClick={() => history.push("/dashboard")}
          variant="contained"
        >
          Go to dashboard
        </StyledButton>
      );
    } else if (
      !isUserPremium &&
      isUserPremium !== null &&
      (azureProps.authenticationState === "Authenticated" || azureProps.authenticationState === "InProgress")
    ) {
      return (
        <ButtonsWrapper>
          <ButtonWithTextWrapper>
            <Button
              id="landing_page__premium_access"
              className={classes.button}
              onClick={() => history.push("/payments-details")}
              variant="contained"
            >
              Premium access
            </Button>
            <span>Just €50/ month / cancel anytime</span>
          </ButtonWithTextWrapper>
          <Button className={classes.outlined} onClick={() => history.push("/dashboard")} variant="outlined">
            Try for free
          </Button>
        </ButtonsWrapper>
      );
    } else if (
      azureProps.authenticationState === "Unauthenticated" ||
      azureProps.authenticationState === "InProgress"
    ) {
      return (
        <ButtonsWrapper>
          <ButtonWithTextWrapper>
            <Button
              id="landing_page__premium_access"
              className={classes.button}
              onClick={azureProps.login}
              variant="contained"
            >
              Premium access
            </Button>
            <span>Just €50/ month / cancel anytime</span>
          </ButtonWithTextWrapper>
          <Button className={classes.outlined} onClick={() => history.push("/dashboard")} variant="outlined">
            Try for free
          </Button>
        </ButtonsWrapper>
      );
    } else {
      return null;
    }
  };

  const generateCreateAccountButton = () => {
    if (azureProps.authenticationState === "Unauthenticated") {
      return (
        <Button
          id="landing_page__create_account"
          className={classes.button}
          variant="contained"
          onClick={azureProps.login}
        >
          Create account
        </Button>
      );
    } else if (azureProps.authenticationState === "Authenticated") {
      return (
        <Button
          id="landing_page__go_to_dashboard_bottom"
          className={classes.button}
          variant="contained"
          onClick={() => history.push("/dashboard")}
        >
          Go to dashboard
        </Button>
      );
    } else {
      return null;
    }
  };

  const premiumAccess = [
    "Access to all European listed instruments",
    "Access to all available information per instrument/issuer",
    "Advanced filtering",
    "Track your favourites",
  ];

  const freeAccess = [
    "Access to all European listed instruments",
    "Limited functionality and information per instrument/issuer",
    "Overview of company structure",
  ];

  return (
    <Page isHomepage hideSearch>
      <LandingMain>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography className={classes.h1} component="h1" variant="h1">
                European <span>Financial Instruments</span> <br /> Reference Data System
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.p} component="p">
                Connecting European capital markets
                <br /> with the global LEI system
              </Typography>
              <StyledList>
                <li>
                  <Typography>
                    <strong>SEARCH</strong> – company, LEI and ISIN codes listed on any recognized trading venue in EU
                    and UK
                  </Typography>
                </li>
                <li>
                  <Typography>
                    <strong>ACCESS</strong> – reference, liquidity, issuer and trading venue data on financial
                    instruments traded in Europe
                  </Typography>
                </li>
              </StyledList>
              <SearchBarWrapper>
                <SearchBar />
              </SearchBarWrapper>
              <Grid item xs={12}>
                {generateSubscribeTryForFreeButtons()}
              </Grid>
            </Grid>
          </Grid>
          <StatsContainer container>
            <StatsTitle item xs={12}>
              <Typography variant="h2">
                <span>The entire European capital market</span> – in one single access point
              </Typography>
            </StatsTitle>
            <StatsNumbers>
              <StatsNumberTextWrapper>
                <Typography variant="h3">34 000 000+</Typography>
                <Typography variant="h5">Instruments</Typography>
              </StatsNumberTextWrapper>
              <StatsNumberTextWrapper>
                <Typography variant="h3">48 000+</Typography>
                <Typography variant="h5">Unique issuers</Typography>
              </StatsNumberTextWrapper>
              <StatsNumberTextWrapper>
                <Typography variant="h3">460+</Typography>
                <Typography variant="h5">Trading venues</Typography>
              </StatsNumberTextWrapper>
              <StatsNumberTextWrapper>
                <Typography variant="h3">50+</Typography>
                <Typography variant="h5">Countries</Typography>
              </StatsNumberTextWrapper>
            </StatsNumbers>
          </StatsContainer>
        </Container>
      </LandingMain>
      <FeaturesContainer>
        <Container>
          <StyledSectionTitle variant="h2">
            Monitor <span>new instruments</span> by market and currency
          </StyledSectionTitle>
          <NewInstrumentMonitorWrapper>
            <ImgWrapper>
              <NewInstrumentMonitorImage src={homePagePicture_1} />
            </ImgWrapper>
            <KeyFeaturesWrapper>
              <Typography variant="h4">Key features:</Typography>
              <StyledFeaturesList>
                <li>
                  <Typography>
                    <strong>SEARCH</strong> - issuers, LEI codes and ISINs listed on any recognized trading venue in EU
                    and UK
                  </Typography>
                </li>
                <li>
                  <Typography>
                    <strong>REFERENCE DATA</strong> - find key data on any financial instrument
                  </Typography>
                </li>
                <li>
                  <Typography>
                    <strong>LIQUIDITY DATA</strong> – access liquidity flags, most liquid trading venues and other
                    regulatory transparency calculations data
                  </Typography>
                </li>
                <li>
                  <Typography>
                    <strong>ISSUER DATA</strong> – find legal entity data mapped to ISINs with overview of all traded
                    equity, debt and other financial instruments per issuer
                  </Typography>
                </li>
                <li>
                  <Typography>
                    <strong>TRADING VENUE DATA</strong> – view all trading venues and systematic internalizes of a
                    financial instrument
                  </Typography>
                </li>
              </StyledFeaturesList>
            </KeyFeaturesWrapper>
          </NewInstrumentMonitorWrapper>
          <Typography component="p" className={classes.featuresText}>
            New Issue Monitor lets you track new instruments by their market and currency, so you can stay up-to-date on
            the newest developments.
          </Typography>
        </Container>
      </FeaturesContainer>
      <PricesContainer>
        <Container>
          <Typography variant="h2" className={classes.featuresh2} component="h2">
            Pricing
          </Typography>
          <Grid container spacing={4} justify="center">
            <Grid item lg={3} xs={12}>
              <Paper className={classes.pricingPaper}>
                <PaperHeader>Premium Access</PaperHeader>
                <PaperContent>
                  <ImageContainer>
                    <Image src={premium} alt="Premium" />
                  </ImageContainer>
                  <InfoSection>€ 50.00 / month (cancel anytime)</InfoSection>
                  <FeatureSection>
                    {premiumAccess.map((feature: string) => (
                      <Feature key={feature}>
                        <CircleColorPremium>
                          <CircleIcon />
                        </CircleColorPremium>
                        <Typography>{feature}</Typography>
                      </Feature>
                    ))}
                  </FeatureSection>
                  {generateGetStartedPremiumButton()}
                </PaperContent>
              </Paper>
            </Grid>
            <Grid item lg={3} xs={12}>
              <Paper className={classes.pricingPaper}>
                <PaperHeader>Free</PaperHeader>
                <PaperContent>
                  <ImageContainer>
                    <Image src={basic} alt="Basic" />
                  </ImageContainer>
                  <InfoSection>Free</InfoSection>
                  <FeatureSection>
                    {freeAccess.map((feature: string) => (
                      <Feature key={feature}>
                        <CircleColorFree>
                          <CircleIcon />
                        </CircleColorFree>
                        <Typography>{feature}</Typography>
                      </Feature>
                    ))}
                  </FeatureSection>
                  {generateGetStartedFreeButton()}
                </PaperContent>
              </Paper>
            </Grid>
          </Grid>
          <GridContainerStyles container spacing={4}>
            <GridStyles item xs={6}>
              <ItemTitle>
                <SearchIcon />
                Access <span>favorite</span> instruments
              </ItemTitle>
              <Typography>
                Tag and track your favorite instruments, and stay up-to-date on changes on instrument reference data.
              </Typography>
            </GridStyles>
            <Grid item xs={6}>
              <FavImage src={homePagePicture_2} />
            </Grid>
          </GridContainerStyles>
          <GridContainerStyles container spacing={4}>
            <Grid item xs={6}>
              <FavImage src={homePagePicture_3} />
            </Grid>
            <GridStyles item xs={6}>
              <ItemTitleSecondary>
                <TimelineIcon />
                Updated <span>daily</span>
              </ItemTitleSecondary>
              <Typography>
                We source data directly from <strong>ESMA FIRDS, FCA FIRDS, GLEIF</strong> and other relevant "golden
                source" databases on a daily basis, providing you with up-to-date and reliable reference data on
                instruments listed on recognized trading venues in Europe.
              </Typography>
            </GridStyles>
          </GridContainerStyles>
          <GridContainerStyles container spacing={4}>
            <GridStyles item xs={6}>
              <ItemTitle>
                <SearchIcon />
                View company structure
              </ItemTitle>
              <Typography>
                Navigate Legal Entity Information (<strong>LEI</strong>) including Parent relationship linked to
                instruments, and analyze issuer exposure across the Corporate Structure
              </Typography>
            </GridStyles>
            <Grid item xs={6}>
              <FavImage src={homePagePicture_4} />
            </Grid>
          </GridContainerStyles>
        </Container>
      </PricesContainer>
      <Container>
        <Grid container justify="center">
          <Typography variant="h2" className={classes.featuresh2} component="h2">
            Products
          </Typography>
          <Grid container spacing={2}>
            <ProductTiles />
          </Grid>
        </Grid>
      </Container>
      <LandingFooter>
        <Typography component="h3">
          Access reference data for <br />
          the
          <span> entire European</span> capital market
        </Typography>
        {generateCreateAccountButton()}
      </LandingFooter>
    </Page>
  );
}

export { HomePage };
