import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import styled from "styled-components";
import mediaQuery from "../../common/mediaQuery";

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    top: 50px;
    right: 30px;
    position: absolute;

    box-shadow: 0 0 10px rgba(232, 127, 64, 0.6);
    animation: pulse 2s infinite;

    &:hover {
      animation: none;
    }
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(232, 127, 64, 0.6);
    }
    70% {
      box-shadow: 0 0 0 30px rgba(232, 127, 64, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(232, 127, 64, 0);
    }
  }

  ${mediaQuery.SM} {
    .MuiDialog-paper {
      right: unset;
      margin: 0 8px;
      max-width: 340px;
    }
    .MuiIconButton-root {
      padding: 5px;
    }
  }
`;

export const StyledDialogTitle = styled(DialogTitle)`
  padding: 8px 24px;
  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ${mediaQuery.SM} {
    padding: 8px 15px;
    h2 {
      font-size: 14px;
    }
  }
`;

export const StyledDialogContent = styled(DialogContent)`
  padding: 8px 15px;
  ${mediaQuery.SM} {
    p {
      font-size: 13px;
    }
  }
`;

export const StyledDialogActions = styled(DialogActions)`
  ${mediaQuery.SM} {
    button {
      font-size: 12px;
    }
  }
`;
