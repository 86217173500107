import { Box, Grid } from "@material-ui/core";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { DebtInstruments, EquitiesTable, IssuersTable, OtherInstruments, Page } from "../../components";
import { SearchPageWrapper, StyledTypography } from "./SearchPage.css";

export function SearchPage() {
  const location = useLocation();

  const [searchParam, setSearchParam] = useState<string>(
    (qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).search as string) && ""
  );
  const [filterParam, setFilterParam] = useState<string>(
    (qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).filter as string) && ""
  );
  useEffect(() => {
    const params = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    setSearchParam(params.search as string);
    setFilterParam(params.filter as string);
    sessionStorage.removeItem("OTHER_filters");
    sessionStorage.removeItem("DEBT_filters");
    sessionStorage.removeItem("EQUITY_filters");
  }, [location]);

  const renderTables = () => {
    const params = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    const isSearchByLei = searchParam?.length === 20 && !searchParam.includes(" ");

    if (params.filter === "All" && searchParam) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <IssuersTable search={searchParam} isSearchByLei={isSearchByLei} />
          </Grid>
          <Grid item xs={12}>
            <EquitiesTable search={searchParam} isSearchByLei={isSearchByLei} />
          </Grid>
          <Grid item xs={12}>
            <DebtInstruments search={searchParam} isSearchByLei={isSearchByLei} />
          </Grid>
          <Grid item xs={12}>
            <OtherInstruments search={searchParam} isSearchByLei={isSearchByLei} />
          </Grid>
        </Grid>
      );
    }
    if (params.filter === "Instrument (ISIN)" && searchParam) {
      return (
        <>
          <EquitiesTable searchByIsin={true} search={searchParam} isSearchByLei={isSearchByLei} />
          <DebtInstruments searchByIsin={true} search={searchParam} isSearchByLei={isSearchByLei} />
          <OtherInstruments searchByIsin={true} search={searchParam} isSearchByLei={isSearchByLei} />
        </>
      );
    }
    if (params.filter === "Issuer (Name, LEI)" && searchParam) {
      return <IssuersTable search={searchParam} isSearchByLei={isSearchByLei} />;
    }
  };

  return (
    <Page>
      <SearchPageWrapper>
        Search results for{" "}
        {searchParam ? (
          <>
            <StyledTypography color={"primary"}>{searchParam}</StyledTypography> in{" "}
          </>
        ) : null}
        <StyledTypography color={"primary"}>{filterParam}</StyledTypography>
        <Box mb={3}>{renderTables()}</Box>
      </SearchPageWrapper>
    </Page>
  );
}
