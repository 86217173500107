import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { IssuerRelationsWithoutInstrumentsInterface } from "../pages/IssuerPage/CompanyStructureTab/IssuerStructureInterface";

const GET_ISSUER_RELATIONS_WITHOUT_INSTRUMENTS = gql`
  query getIssuerRelationsWithoutInstruments($lei: String!) {
    getIssuerRelationsWithoutInstruments(lei: $lei) {
      instrumentsAmount {
        debt
        equities
      }
      directParent {
        lei
        name
      }
      directChildren {
        lei
        name
      }
      ultimateParent {
        lei
        name
      }
      ultimateChildren {
        lei
        name
      }
    }
  }
`;

interface Param {
  lei: string;
}

interface GetIssuerRelationsWithoutInstruments {
  getIssuerRelationsWithoutInstruments: IssuerRelationsWithoutInstrumentsInterface;
}

export const useLazyGetIssuerRelationsWithoutInstrumentsQuery = () =>
  useLazyQuery<GetIssuerRelationsWithoutInstruments, Param>(GET_ISSUER_RELATIONS_WITHOUT_INSTRUMENTS);
