import { GleifEntryResult } from "../common/types";
import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

const GET_ISSUER_INFO_BY_LEI = gql`
  query getIssuerByLEI($lei: String!) {
    getIssuerByLEI(lei: $lei) {
      bic
      entityNew {
        associatedEntity {
          associatedEntityName
          associatedLEI
        }
        entityExpirationDate
        entityExpirationReason
        entityCategory
        entityStatus
        legalJurisdiction
        legalName
        legalForm {
          entityLegalFormCode
          otherLegalForm
          names {
            languageCode
            localName
          }
        }
        headquartersAddress {
          additionalAddressLine
          city
          country
          firstAddressLine
          postalCode
          region
        }
        legalAddress {
          additionalAddressLine
          city
          country
          firstAddressLine
          postalCode
          region
        }
        registrationAuthority {
          otherRegistrationAuthorityID
          registrationAuthorityID
          registrationAuthorityEntityID
          registrationAuthorityInternationalOrganizationName
          registrationAuthorityLocalName
          registrationAuthorityLocalOrganizationName
          registrationAuthorityInternationalName
          registrationAuthorityJurisdictions {
            country
            jurisdiction
          }
        }
        successorEntity {
          successorEntityName
          successorLEI
        }
      }
      lei
      registrationNew {
        leiIssuerName
        leiIssuerMarketingName
        initialRegistrationDate
        lastUpdateDate
        managingLOU
        nextRenewalDate
        registrationStatus
        validationAuthority {
          otherValidationAuthorityID
          validationAuthorityID
          validationAuthorityEntityID
        }
        otherValidationAuthorities {
          otherValidationAuthorityID
          validationAuthorityEntityID
          validationAuthorityID
        }
        validationSources
      }
    }
  }
`;

interface GetIssuerInfoByLei {
  getIssuerByLEI: GleifEntryResult;
}

interface Params {
  lei: string;
}
export const useLazyGetIssuerInfoByLeiQuery = () => useLazyQuery<GetIssuerInfoByLei, Params>(GET_ISSUER_INFO_BY_LEI);
