import { CardHeader, CircularProgress } from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import SubjectIcon from "@material-ui/icons/Subject";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SubscribeContext } from "../../../common/subcriptionProvider";
import { IssuerNew } from "../../../common/types";
import { useLazyGetIssuerInfoByLeiQuery } from "../../../graphql/GetIssuerInfoByLei";
import { useLazyGetIssuerSuggestionsWithDetailsQuery } from "../../../graphql/GetIssuerSuggestionsWithDetails";
import CustomFlag from "../../CustomFlag";
import {
  CardContentStyle,
  CardStyle,
  EmptyCard,
  Message,
  ProgressOverlay,
  StyledBox,
  StyledTable,
  StyledTableBody,
  TableHeaderCell,
  TableMainCell,
  TableRowStyle,
  TableScrollContainer,
  TypographyStyle,
} from "./IssuerTable.css";

interface IssuersTableProps {
  search: string;
  isSearchByLei?: boolean;
}

const IssuersTable: React.FC<IssuersTableProps> = ({ search, isSearchByLei }) => {
  const history = useHistory();

  const [issuers, setIssuers] = useState<any[]>([]);

  const { setErrorMessage } = useContext(SubscribeContext);

  const humanize = (char: string) => {
    return char.charAt(0).toUpperCase() + char.slice(1).toLowerCase();
  };

  const [
    fetchIssuerSuggestionsWithDetails,
    { data: issuerResults, loading: issuerLoading, error: fetchIssuerSuggestionsWithDetailsError },
  ] = useLazyGetIssuerSuggestionsWithDetailsQuery();

  const [
    getIssuerInfoByLei,
    { data: issuerInfoByLei, loading: loadingIssuerInfoByLei, error: issuerInfoByLeiError },
  ] = useLazyGetIssuerInfoByLeiQuery();

  useEffect(() => {
    if (!isSearchByLei) {
      fetchIssuerSuggestionsWithDetails({
        variables: {
          startingCharacters: search,
        },
      });
    }
  }, [search, isSearchByLei, fetchIssuerSuggestionsWithDetails]);

  useEffect(() => {
    if (isSearchByLei && search) {
      getIssuerInfoByLei({ variables: { lei: search } });
    }
  }, [search, isSearchByLei, isSearchByLei]);

  useEffect(() => {
    if (fetchIssuerSuggestionsWithDetailsError) {
      setErrorMessage(fetchIssuerSuggestionsWithDetailsError.message);
    }
    if (issuerInfoByLeiError) {
      setErrorMessage(issuerInfoByLeiError.message);
    }
    if (!isSearchByLei && issuerResults && issuerResults.getIssuerSuggestionsWithDetails) {
      setIssuers(issuerResults.getIssuerSuggestionsWithDetails);
    }
    if (isSearchByLei && issuerInfoByLei && issuerInfoByLei.getIssuerByLEI) {
      setIssuers([issuerInfoByLei.getIssuerByLEI]);
    }
  }, [issuerResults, issuerInfoByLei, isSearchByLei, fetchIssuerSuggestionsWithDetailsError, issuerInfoByLeiError]);

  return (
    <CardStyle>
      <CardHeader
        title={
          <StyledBox>
            <TypographyStyle variant="h5">Companies</TypographyStyle>
          </StyledBox>
        }
      />
      <CardContentStyle>
        {issuerLoading || loadingIssuerInfoByLei ? (
          <ProgressOverlay className={!issuers.length ? "empty" : ""}>
            <CircularProgress />
          </ProgressOverlay>
        ) : null}
        {!issuers.length && !issuerLoading && !loadingIssuerInfoByLei ? (
          <EmptyCard>
            <SubjectIcon fontSize="large" color="inherit" />
            <Message>No results</Message>
          </EmptyCard>
        ) : (
          <TableScrollContainer>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <TableHeaderCell>Country</TableHeaderCell>
                  <TableHeaderCell>Name</TableHeaderCell>
                  <TableHeaderCell>Status</TableHeaderCell>
                  <TableHeaderCell>LEI</TableHeaderCell>
                  <TableHeaderCell></TableHeaderCell>
                  <TableHeaderCell></TableHeaderCell>
                </TableRow>
              </TableHead>
              <StyledTableBody loading={issuerLoading ? 1 : 0}>
                {issuers.map((instrument: IssuerNew, i: number) => (
                  <TableRowStyle key={i} onClick={() => history.push(`/issuer/${instrument.lei}`)} hover>
                    <TableMainCell id="search_page__issuer" align="center" component="th" scope="row">
                      <CustomFlag
                        code={instrument.entityNew.legalJurisdiction}
                        alt={instrument.entityNew.legalJurisdiction}
                      />
                    </TableMainCell>
                    <TableMainCell id="search_page__issuer" component="th" scope="row">
                      <Typography variant="body1" style={{ fontWeight: "bold" }}>
                        {instrument?.entityNew.legalName}
                      </Typography>
                    </TableMainCell>
                    <TableMainCell id="search_page__issuer">
                      <Typography variant="body1">{humanize(instrument.entityNew.entityStatus)}</Typography>
                    </TableMainCell>
                    <TableMainCell id="search_page__issuer">
                      <Typography variant="body1">{instrument.lei}</Typography>
                    </TableMainCell>
                    <TableMainCell id="search_page__issuer"></TableMainCell>
                    <TableMainCell id="search_page__issuer"></TableMainCell>
                  </TableRowStyle>
                ))}
              </StyledTableBody>
            </StyledTable>
          </TableScrollContainer>
        )}
      </CardContentStyle>
    </CardStyle>
  );
};

export { IssuersTable };
