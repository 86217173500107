import { gql } from "apollo-boost";
import { Subscription } from "../common/types";
import { useMutation } from "@apollo/react-hooks";

const CANCEL_SUBSCRIPTION = gql`
 mutation cancelSubscription {
   cancelSubscription {
    activeUntil
    cancelAtPeriodEnd
    canceledAt
    createdAt
    currentPeriodStart
    endedAt
    status
    stripeCustomerID
    subscriptionID
    userID
  }
  }
`;

interface CancelSubscription {
  cancelSubscription: Subscription;
}

export const useMutationCancelSubscription = () =>
  useMutation<CancelSubscription>(CANCEL_SUBSCRIPTION);
