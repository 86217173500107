import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { InstrumentInterface, InstrumentResourceParametersInput } from "../common/types";

const queryAuth = (isPremium: any) => {
  const GET_INSTRUMENTS = gql`
    query getInstruments(
      $lei: String!
      $numberOfRecords: Int
      $resourceParameters: InstrumentResourceParametersInput
      $startingRecord: Int!
    ) {
      getInstruments(
        lei: $lei
        numberOfRecords: $numberOfRecords
        resourceParameters: $resourceParameters
        startingRecord: $startingRecord
      ) {
        instruments {
          upcomingRCA
          instrumentClassification
          notionalCurrency
          firstTradeDate
          instrumentFullName
          instrumentIdentificationCode
          totalIssuedNominalAmount @include(if: ${isPremium})
          maturityDate
          terminationDate
          nominalValuePerUnit @include(if: ${isPremium})
        }
      }
    }
  `;
  return GET_INSTRUMENTS;
};

interface QueryText {
  lei: string;
  numberOfRecords: number;
  resourceParameters: InstrumentResourceParametersInput;
  startingRecord: number;
}

interface GetInstruments {
  getInstruments: {
    instruments: InstrumentInterface;
  };
}

export const useLazyGetInstrumentsQuery = (isPremium: any) =>
  useLazyQuery<GetInstruments, QueryText>(queryAuth(isPremium));
