import { Card, Chip, TableBody, Typography } from "@material-ui/core";
import styled from "styled-components";

interface LoaderOverlayInterface {
  loaderPosition: number;
}

interface ChipStyleInterface {
  label: string;
}

export const EmptyCard = styled.div`
  text-align: center;
  color: #cbcbcb;
  margin: 10px 0;
`;

export const TypographyStyle = styled(Typography)`
  font-weight: 700;
`;

export const CardStyle = styled(Card)`
  position: relative;
  min-height: 170px;
`;

export const LoaderOverlay = styled.div<LoaderOverlayInterface>`
  position: absolute;
  top: ${(props) => (props.loaderPosition ? "50%" : "60%")};
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

export const ChipStyle = styled(Chip)<ChipStyleInterface>`
  display: ${({ label }) => !label && "none"};
  background-color: #efe6ff;
  &:hover {
    background-color: ${({ label }) => !label && "#e5d6ff"};
  }
`;

export const StyTableBody = styled(TableBody)`
  tr:last-child > td {
    border-bottom: none;
  }
`;
