import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { InstrumentSugestion } from "../common/types";

const GET_INSTRUMENT_SUGGESTIONS = gql`
  query getInstrumentSuggestions($startingCharacters: String!) {
    getInstrumentSuggestions(startingCharacters: $startingCharacters) {
      name
      isin
    }
  }
`;

interface QueryText {
  startingCharacters: string;
}

interface GetInstrumentSuggestions {
  getInstrumentSuggestions: InstrumentSugestion[];
}

export const useLazyGetInstrumentSuggestionsQuery = () =>
  useLazyQuery<GetInstrumentSuggestions, QueryText>(GET_INSTRUMENT_SUGGESTIONS);
