import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { CurrentUser } from "../common/types";

const GET_CURRENT_USER = gql`
  query getCurrentUser {
    getCurrentUser {
      country
      displayName
      email
      givenName
      groups
      isFirstLogin
      hasSubscription
      id
      mobilePhone
      surname
      role
      profession
    }
  }
`;


interface GetCurrentUser {
  getCurrentUser: CurrentUser;
}

export const useLazyGetCurrentUserQuery = (params: any) =>
  useLazyQuery<GetCurrentUser>(GET_CURRENT_USER, {fetchPolicy: params ? params.fetchPolicy : "cache-first"});
