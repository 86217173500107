import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import PaymentCardElement from "../../components/PaymentCard/PaymentCardElement";
import { SubscriptionPaymentsCardPageWrapper } from "./SubscriptionPaymentsCardPage.css";
import { SubscribeContext } from "../../common/subcriptionProvider";
import { useHistory } from "react-router-dom";

function SubscriptionPaymentsCardPage() {
  const { isUserPremium } = React.useContext(SubscribeContext);
  const history = useHistory();

  useEffect(() => {
    if (isUserPremium) {
      setTimeout(() => {
        history.push("/dashboard");
      }, 5000);
    }
  }, [isUserPremium, history]);

  return (
    <SubscriptionPaymentsCardPageWrapper>
      <Grid container spacing={2} justify="center">
        <Grid item xs={12} sm={5}>
          <PaymentCardElement />
        </Grid>
      </Grid>
    </SubscriptionPaymentsCardPageWrapper>
  );
}

export { SubscriptionPaymentsCardPage };
