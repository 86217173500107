export const tableHeader = (table: string) => {
  const equityTable = ["Country", "Instrument Category", "Name", "Period", "Currency", "CFI Code"];
  const debtTable = ["Country", "Instrument Category", "Name", "Period", "Currency", "Value Per Unit", "Issued Amt"];
  const otherTable = ["Country", "Instrument Type", "Instrument Category", "Name", "Period", "Currency", "CFI Code"];
  const tableHeaders = table === "EQUITY" ? equityTable : table === "OTHER" ? otherTable : debtTable;
  return tableHeaders;
};

export const enabledFilters = (table: string) => {
  const equitiesFilters = ["", "Instrument Category", "", "", "Currency"];
  const debtFilters = ["", "Instrument Category", "Name", "Period", "Currency"];
  const otherFilters = ["Instrument Type", "Instrument Category", "Name", "Period", "Currency"];
  const filters = table === "EQUITY" ? equitiesFilters : table === "OTHER" ? otherFilters : debtFilters;
  return filters;
};
