export const onSubscriptionComplete = (
  result: any,
  setInfoMessage: any,
  changeIsPaymentLoading: any,
  setIsSubscriptionTrialPeriod?: any
) => {
  if (result.subscription.status === "active") {
    setInfoMessage({ success: "Payment was successful." });
  }

  if (result.subscription.status === "trialing") {
    setInfoMessage({ success: " Trial period activation in progress please wait for confirmation." });
    setIsSubscriptionTrialPeriod(true);
  }

  if (result.subscription.status !== "trialing") {
    setInfoMessage({ success: "Payment in progress please wait for payment confirmation." });
  }
  changeIsPaymentLoading(false);
};

export const handleRequiresPaymentMethod = ({ subscription, paymentMethodId, refreshContext }: any) => {
  if (subscription.status === "active") {
    return { subscription, paymentMethodId };
  } else if (subscription.status === "trialing") {
    return { subscription, paymentMethodId };
  } else if (subscription.paymentIntent.status === "requires_payment_method") {
    localStorage.setItem("latestInvoiceId", subscription.invoiceID);
    localStorage.setItem("latestInvoicePaymentIntentStatus", subscription.paymentIntent.status);

    refreshContext(false);

    throw new Error("Your card was declined.");
  } else {
    return { subscription, paymentMethodId };
  }
};

export const handlePaymentThatRequiresCustomerAction = ({
  subscription,
  invoice,
  paymentMethodId,
  isRetry,
  refreshContext,
  setInfoMessage,
  changeIsPaymentLoading,
  stripe,
}: any) => {
  if (subscription && subscription.status === "active") {
    return { subscription, paymentMethodId };
  }

  if (subscription && subscription.status === "trialing") {
    return { subscription, paymentMethodId };
  }

  let paymentIntent = invoice ? invoice.paymentIntent : subscription.paymentIntent;
  let invoiceID = invoice ? invoice.id : subscription.invoiceID;

  if (
    paymentIntent.status === "requires_action" ||
    (isRetry === true && paymentIntent.status === "requires_payment_method")
  ) {
    if (stripe) {
      return stripe
        .confirmCardPayment(paymentIntent.clientSecret, {
          payment_method: paymentMethodId,
        })
        .then((result: any) => {
          if (result) {
            if (result.error) {
              localStorage.setItem("latestInvoicePaymentIntentStatus", "requires_payment_method");
              localStorage.setItem("latestInvoiceId", invoiceID);
              let error = { message: result.error.message };
              throw error;
            } else {
              if (result.paymentIntent && result.paymentIntent.status === "succeeded") {
                setInfoMessage({ success: "Payment was successful." });
                changeIsPaymentLoading(false);
                return {
                  subscription: subscription,
                  invoice: invoice,
                  paymentMethodId: paymentMethodId,
                };
              }
            }
          } else {
            throw new Error("Payment confirm error");
          }
        })
        .catch((error: any) => {
          refreshContext(false);
          throw error;
        });
    } else {
      throw new Error();
    }
  } else {
    return { subscription, paymentMethodId };
  }
};

export const retryInvoiceWithNewPaymentMethod = ({
  paymentMethodId,
  invoiceId,
  stripe,
  refreshContext,
  setInfoMessage,
  changeIsPaymentLoading,
  retrieveInvoice,
}: any) => {
  return retrieveInvoice({
    variables: {
      retryInvoice: {
        paymentMethodToken: paymentMethodId,
        invoiceID: invoiceId,
      },
    },
  })
    .then((response: any) => {
      if (response && response.data) {
        if (response.data.retryInvoice.error) {
          const error = {
            message: response.data.retryInvoice.error,
          };
          throw error;
        }
        return response.data.retryInvoice;
      } else {
        throw new Error();
      }
    })
    .then((result: any) => {
      if (result.paymentIntent.error) {
        throw new Error(result.paymentIntent.error);
      }
      return result;
    })
    .then((result: any) => {
      return {
        subscription: result.subscriptionDetails.status,
        invoice: result,
        paymentMethodId: paymentMethodId,
        isRetry: true,
      };
    })
    .then((result: any) =>
      handlePaymentThatRequiresCustomerAction({
        ...result,
        refreshContext,
        setInfoMessage,
        changeIsPaymentLoading,
        stripe,
      })
    )
    .then((result: any) => {
      onSubscriptionComplete(result, setInfoMessage, changeIsPaymentLoading);
      refreshContext(true);
    })
    .catch((error: any) => {
      // An error has happened. Display the failure to the user here.
      // We utilize the HTML element we created.
      setInfoMessage({ error: error.message });
      changeIsPaymentLoading(false);
    });
};

export const createSubscription = ({
  paymentMethodId,
  refreshContext,
  setInfoMessage,
  changeIsPaymentLoading,
  stripe,
  createSubscriptionRequest,
  setIsSubscriptionTrialPeriod,
}: any) => {
  return createSubscriptionRequest({
    variables: { paymentMethodToken: paymentMethodId },
  })
    .then((response: any) => {
      if (response && response.data) {
        if (response.data.createSubscription.error) {
          const error = {
            message: response.data.createSubscription.error,
          };
          throw error;
        }
        return response.data.createSubscription;
      } else {
        throw new Error();
      }
    })
    .then((result: any) => {
      return {
        paymentMethodId: paymentMethodId,
        subscription: result,
      };
    })
    .then((result: any) =>
      handlePaymentThatRequiresCustomerAction({
        ...result,
        refreshContext,
        setInfoMessage,
        changeIsPaymentLoading,
        stripe,
      })
    )
    .then((result: any) => handleRequiresPaymentMethod({ ...result, refreshContext }))
    .then((result: any) => {
      onSubscriptionComplete(result, setInfoMessage, changeIsPaymentLoading, setIsSubscriptionTrialPeriod);
      refreshContext(true);
    })
    .catch((error: any) => {
      setInfoMessage({ error: error.message });
      changeIsPaymentLoading(false);
    });
};
