import { Card, CardHeader, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import AzureAD, { AuthenticationState } from "react-aad-msal";
import { useHistory } from "react-router-dom";
import api from "../../assets/images/api.svg";
import feed from "../../assets/images/feed.svg";
import corporate_subscription from "../../assets/images/multi_user.svg";
import web from "../../assets/images/web_analysis.svg";
import { authProvider } from "../../common/authProvider";
import { SubscribeContext } from "../../common/subcriptionProvider";
import {
  CardContentStyle,
  CircleColor,
  CircleIcon,
  ContactInfo,
  Feature,
  FeatureSection,
  GridStyle,
  Image,
  ImageContainer,
  InfoSection,
  SubscribeButtonStyles,
} from "./ProductsTiles.css";

const apiDocUrl = window._env_.API_DOC_URL;

const ProductsTiles: React.FC = () => {
  const { isUserPremium } = useContext(SubscribeContext);
  const history = useHistory();

  const dataTiles = [
    {
      id: "products__contact_us_web",
      title: "Web",
      icon: web,
      info:
        "Full access to daily updated reference data for all listed instruments through our user-friendly web portal",
      features: [
        "Flexible monthly subscription",
        "Immediate access - first month free of charge",
        "€ 50.00 / month (cancel anytime)",
      ],
      circleColor: "#3f18aa",
      link: false,
      information: "information",
    },
    {
      id: "products__contact_us_feed",
      title: "Feed",
      icon: feed,
      info:
        "Receive up-to-date reference data from out complete dataset, directly to your back office systems - on a daily basis",
      features: ["Formatted to your needs", "Plug & Play", "Multiple datasets - One feed"],
      circleColor: "#e87f38",
      link: true,
      information: "information",
    },
    {
      id: "products__contact_us_API",
      title: "API",
      icon: api,
      info: "Integrate your systems with updated data from our database directly through our intuitive REST APIs",
      features: ["Flexible", "On-demand", "Multiple datasets - One API"],
      circleColor: "#3f18aa",
      link: true,
      information: "information",
    },
    {
      id: "products__contact_us_corporate_subsciption",
      title: "Corporate Subscription",
      icon: corporate_subscription,
      info:
        "Full access for multiple employees to our daily updated reference data through our user-friendly web portal",
      features: [
        "Flexible and discounted monthly subscription",
        "One subscription, multiple users",
        "Corporate invoice",
      ],
      circleColor: "#e87f38",
      link: true,
      information: "quote",
    },
  ];

  return (
    <>
      {dataTiles.map((tile: any) => (
        <GridStyle key={tile.title} item md={6} lg={3}>
          <Card>
            <CardHeader title={<Typography variant="h6">{tile.title}</Typography>} />
            <CardContentStyle>
              <ImageContainer>
                <Image src={tile.icon} alt="" />
              </ImageContainer>
              <InfoSection>
                {tile.info}
                {tile.id === "products__contact_us_API" && (
                  <p>
                    <a href={apiDocUrl} target="_blank" rel="noopener noreferrer">
                      API docs
                    </a>
                  </p>
                )}
              </InfoSection>

              <FeatureSection>
                {tile.features.map((feature: string, index: number) => (
                  <Feature key={`${feature}_${index}`}>
                    <CircleColor circleColor={tile.circleColor}>
                      <CircleIcon />
                    </CircleColor>
                    <div>{feature}</div>
                  </Feature>
                ))}
              </FeatureSection>
              {tile.link ? (
                <ContactInfo>
                  <a id={tile.id} href="mailto:sales@efirds.eu">
                    Contact us
                  </a>{" "}
                  for {tile.information}
                </ContactInfo>
              ) : (
                <>
                  {isUserPremium ? (
                    <ContactInfo>
                      <a id={tile.id} href="mailto:sales@efirds.eu">
                        Contact us
                      </a>{" "}
                      for {tile.information}
                    </ContactInfo>
                  ) : (
                    <AzureAD provider={authProvider}>
                      {(props: any) => {
                        const { login, authenticationState } = props;
                        switch (authenticationState) {
                          case AuthenticationState.Authenticated:
                            return (
                              <SubscribeButtonStyles
                                id="landing_page__subscribe"
                                onClick={() => history.push("/user-settings")}
                              >
                                Subscribe
                              </SubscribeButtonStyles>
                            );
                          case AuthenticationState.Unauthenticated:
                            return <SubscribeButtonStyles onClick={login}>Subscribe</SubscribeButtonStyles>;
                          default:
                            return null;
                        }
                      }}
                    </AzureAD>
                  )}
                </>
              )}
            </CardContentStyle>
          </Card>
        </GridStyle>
      ))}
    </>
  );
};

export default ProductsTiles;
