import { Link, TableBody, TableCell, Typography } from "@material-ui/core";
import styled from "styled-components";
import mediaQuery from "../../common/mediaQuery";

export const TypographyStyle = styled(Typography)`
  font-weight: 700;
`;

export const TableCellStyle = styled(TableCell)`
  padding: 6px 24px 6px 0;
  width: 50%;
`;

export const TableCellHeader = styled(TableCell)`
  padding: 6px 24px 6px 0;
  font-weight: 700;
`;

export const Title = styled.div`
  font-weight: 700;
`;

export const TableTitle = styled.div`
  font-weight: 700;
  margin: 10px 0;
  color: #3f18aa;
`;

export const TableContainer = styled.div`
  margin-bottom: 30px;
`;

export const StyledTableBody = styled(TableBody)`
  tr:last-child td {
    border-bottom: none;
  }
`;

interface LinkComponent {
  component: any;
  to: any;
}

export const LinkStyle = styled(Link)<LinkComponent>`
  background-color: #efe6ff;
  border-radius: 12px;
  padding: 3px 15px 3px 15px;
  color: #000;
  margin: 0 0 0 -15px;
  height: auto;

  &:hover {
    background-color: #e5d6ff;
    text-decoration: none;
  }

  ${mediaQuery.SM} {
    display: block;
  }
`;
