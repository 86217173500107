import styled from "styled-components";
import mediaQuery from "../../common/mediaQuery";

export const SubscriptionPaymentsCardPageWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 100px;
  overflow: hidden;
  & > div:last-child {
    margin-top: 8px;
  }

  button {
    border-radius: 10px;
  }

  ${mediaQuery.SM} {
    h5 {
      font-size: 1.1rem;
    }

    p {
      font-size: 10px;
    }
  }
`;

export const StyledNoAuthorisedContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  svg {
    width: 100%;
    display: block;
    font-size: 6rem;
  }

  h1 {
    font-size: 40px;
    display: block;
    width: 100%;
    text-align: center;
  }

  h3 {
    font-size: 20px;
    display: block;
    width: 100%;
    text-align: center;
  }

  strong {
    font-size: 30px;
  }

  ${mediaQuery.SM} {
    margin-top: 10vh !important;

    h1 {
      font-size: 25px;
    }

    h3 {
      font-size: 15px;
    }

    strong {
      font-size: 20px;
    }
  }
`;
