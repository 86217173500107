import { InputLabel, MuiThemeProvider } from "@material-ui/core";
import React from "react";
import FilterLoader from "../loader/FilterLoader";
import { SelectLabel, SelectStyle, StyledFormControl, StyledMenuItem } from "./DropdownTypeCategory.css";
import { formControlTheme } from "./InstrumentFilter.css";
import { TypeCategoryInterface } from "./InstrumentFilterInterface";

const DropdownTypeCategory: React.FC<TypeCategoryInterface> = ({
  title,
  label,
  cell,
  table,
  instrumentValue,
  handleOnChange,
  isDisable,
  instrumentFields,
}) => {
  return (
    <MuiThemeProvider theme={formControlTheme}>
      <StyledFormControl variant="outlined" cell={cell} table={table}>
        <SelectLabel>
          <InputLabel>{label || title}</InputLabel>
        </SelectLabel>
        <SelectStyle
          id={`${cell}_table`}
          value={instrumentValue}
          onChange={handleOnChange}
          label={title}
          disabled={isDisable}
        >
          {instrumentFields ? (
            instrumentFields.map((item: any) => (
              <StyledMenuItem key={item[cell]} value={item[cell]}>
                {item[cell]}
              </StyledMenuItem>
            ))
          ) : (
            <FilterLoader />
          )}
        </SelectStyle>
      </StyledFormControl>
    </MuiThemeProvider>
  );
};

export default DropdownTypeCategory;
