import React, { useContext } from "react";
import {
  CardHeader,
  CardContent,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Box,
  Link,
} from "@material-ui/core";
import Loader from "../loader/Loader";
import { InformationCardWrapper } from "./InformationCard.css";
import { DataCardProps } from "./InformationCardInterface";
import { SubscribeContext } from "../../common/subcriptionProvider";

const InformationCard = ({ title, rows, loading, style, handleClick }: DataCardProps) => {
  const { isUserPremium } = useContext(SubscribeContext);

  const handleLinkClick = () => {
    handleClick(null, 2);
  };

  return (
    <InformationCardWrapper style={style}>
      <CardHeader
        title={
          <Box>
            <Typography variant="h5">{title}</Typography>
            {isUserPremium && (
              <Typography>
                <Link id="issuer_page__see_all_company_information" onClick={handleLinkClick}>
                  See all
                </Link>
              </Typography>
            )}
          </Box>
        }
      />
      <CardContent>
        {loading ? (
          <Loader />
        ) : (
          <Table size="small">
            <TableBody>
              {Object.keys(rows).map((key: string) => (
                <TableRow key={key}>
                  <TableCell scope="row">{key}</TableCell>
                  <TableCell scope="row">{rows[key]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </CardContent>
    </InformationCardWrapper>
  );
};

export default InformationCard;
