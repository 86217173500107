import { InputLabel, MenuItem, MuiThemeProvider, Select } from "@material-ui/core";
import React, { useContext } from "react";
import { SubscribeContext } from "../../common/subcriptionProvider";
import {
  getInstrCode,
  instrSubcategoryFields,
  instrTypeFields,
  instrTypeOtherFields,
  subgroupD,
  subgroupE,
  TYPE_CODE,
  TYPE_COL,
} from "../../components/InstrumentsTypeAndCategory/InstrumentSelectFunctions";
import FilterLoader from "../loader/FilterLoader";
import DropdownTypeCategory from "./DropdownTypeCategory";
import {
  DateCell,
  DatePickerTheme,
  DropdownStyle,
  formControlTheme,
  SearchStyles,
  SelectLabel,
  StyledDatePicker,
  StyledInput,
  StyledSearchIcon,
  StyledSelect,
  TableMainCell,
} from "./InstrumentFilter.css";
import { InstrumentFilterProps } from "./InstrumentFilterInterface";
import { enabledFilters } from "./InstrumentsTablesUtils";

const InstrumentFilter: React.FC<InstrumentFilterProps> = ({
  totalAmountData,
  currency,
  name,
  setCurrency,
  setName,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setPage,
  instrumentType,
  setInstrumentType,
  instrumentCategory,
  setInstrumentCategory,
  table,
}) => {
  const { isUserPremium } = useContext(SubscribeContext);

  const filters = enabledFilters(table);

  const code = getInstrCode(instrTypeFields, instrumentType, TYPE_COL, TYPE_CODE);
  const subGroupOther = instrSubcategoryFields(code);

  const category = table === "EQUITY" ? subgroupE : table === "DEBT" ? subgroupD : subGroupOther;

  const updateSessionStorageFilter = (filterName: string, value: string) => {
    const sessionStorageFilters = JSON.parse(sessionStorage.getItem(`${table}_filters`) as string);
    sessionStorage.setItem(`${table}_filters`, JSON.stringify({ ...sessionStorageFilters, [filterName]: value }));
  };

  const handleCurrencyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const currency = event.target.value as string;
    updateSessionStorageFilter("currency", currency);
    setCurrency(currency);
    setPage(0);
  };

  const handleNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const name = event.target.value as string;
    updateSessionStorageFilter("name", name);
    setName(name);
    setPage(0);
  };

  const handleInstrumentTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const instrumentType = event.target.value as string;
    updateSessionStorageFilter("instrumentType", instrumentType);
    setInstrumentType(instrumentType);
    setPage(0);
  };

  const handleInstrumentCategoryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const instrumentCategory = event.target.value as string;
    updateSessionStorageFilter("instrumentCategory", instrumentCategory);
    setInstrumentCategory(instrumentCategory);
    setPage(0);
  };

  const handleStartDateChange = (date: any) => {
    const startDate = date ? date.toDate() : null;
    updateSessionStorageFilter("startDate", startDate);
    setStartDate(startDate);
    setPage(0);
  };

  const handleEndDateChange = (date: any) => {
    const endDate = date ? date.toDate() : null;
    updateSessionStorageFilter("endDate", endDate);
    setEndDate(endDate);
    setPage(0);
  };

  const isDisabled = () => {
    if (instrumentType.length !== 0 || table !== "OTHER") {
      return false;
    }
    return true;
  };

  return (
    <>
      <TableMainCell></TableMainCell>
      {table === "OTHER" && filters[0] && (
        <TableMainCell>
          <DropdownTypeCategory
            title="Instr. Type"
            cell="type"
            table={table}
            instrumentValue={instrumentType}
            handleOnChange={handleInstrumentTypeChange}
            isDisable={false}
            instrumentFields={instrTypeOtherFields}
          />
        </TableMainCell>
      )}
      <TableMainCell>
        <DropdownTypeCategory
          title="Instr. Category"
          label={table === "OTHER" ? (instrumentType.length !== 0 ? "Instr. Category" : "Choose Instr. type") : ""}
          cell="category"
          table={table}
          instrumentValue={instrumentCategory}
          handleOnChange={handleInstrumentCategoryChange}
          isDisable={isDisabled()}
          instrumentFields={category}
        />
      </TableMainCell>
      <TableMainCell>
        <SearchStyles isUserPremium={isUserPremium} filter={filters[2]}>
          <StyledSearchIcon />
          <StyledInput
            placeholder="Filter by name or ISIN"
            value={name}
            inputProps={{ "aria-label": "search" }}
            onChange={handleNameChange}
            disabled={false}
          />
        </SearchStyles>
      </TableMainCell>
      <TableMainCell>
        <DateCell filter={filters[3]}>
          <MuiThemeProvider theme={DatePickerTheme}>
            <StyledDatePicker
              disabled={false}
              autoOk
              clearable
              placeholder="Start date"
              value={startDate}
              format="DD/MM/yyyy"
              onChange={handleStartDateChange}
            />
            <StyledDatePicker
              disabled={false}
              autoOk
              clearable
              placeholder="End date"
              value={endDate}
              format="DD/MM/yyyy"
              onChange={handleEndDateChange}
            />
          </MuiThemeProvider>
        </DateCell>
      </TableMainCell>
      <TableMainCell>
        <DropdownStyle isUserPremium={isUserPremium} filter={filters[4]}>
          <MuiThemeProvider theme={formControlTheme}>
            <StyledSelect variant="outlined">
              <SelectLabel>
                <InputLabel>Currency</InputLabel>
              </SelectLabel>
              <Select
                id="SelectCurrency"
                disabled={false}
                value={totalAmountData ? currency : ""}
                onChange={handleCurrencyChange}
                label="Currency"
              >
                {totalAmountData ? (
                  totalAmountData.map((currency: string) => (
                    <MenuItem key={currency} value={currency}>
                      {currency}
                    </MenuItem>
                  ))
                ) : (
                  <FilterLoader />
                )}
              </Select>
            </StyledSelect>
          </MuiThemeProvider>
        </DropdownStyle>
      </TableMainCell>
      <TableMainCell></TableMainCell>
      {table === "DEBT" && <TableMainCell></TableMainCell>}
    </>
  );
};

export default InstrumentFilter;
