import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Grid, Button, Typography } from "@material-ui/core";
import theme, { MuiMainBackgroundColor, MuiPaperBackgroundColor } from "../../common/theme";
import styled from "styled-components";
import bg from "../../assets/images/bg.png";
import mediaQuery from "../../common/mediaQuery";

export const HomePageStyles = makeStyles({
  h1: {
    "fontSize": 80,
    "textAlign": "left",
    "& span": {
      fontWeight: "bold",
      color: theme.palette.secondary.main,
    },
  },
  p: {
    fontSize: 32,
    letterSpacing: "0px",
    fontWeight: 600,
    marginTop: 15,
  },
  h2: {
    "fontSize": 40,
    "letterSpacing": "0px",
    "fontWeight": "bold",
    "textAlign": "center",
    "& span": {
      color: theme.palette.secondary.main,
    },
  },
  button: {
    "background": theme.palette.secondary.main,
    "color": MuiPaperBackgroundColor,
    "margin": "30px 15px 50px 0",
    "padding": "5px 60px",
    "borderRadius": "10px",
    "&:hover": {
      background: MuiPaperBackgroundColor,
      color: theme.palette.secondary.main,
    },
  },
  outlined: {
    "color": theme.palette.secondary.main,
    "border": `1px solid ${theme.palette.secondary.main}`,
    "margin": "30px 15px 50px 0",
    "padding": "5px 60px",
    "borderRadius": "10px",
    "&:hover": {
      background: MuiPaperBackgroundColor,
      color: theme.palette.secondary.main,
    },
  },
  featuresh2: {
    "fontSize": 40,
    "textAlign": "center",
    "fontWeight": "bold",
    "margin": "10px 0 30px",
    "& span": {
      color: MuiMainBackgroundColor,
    },
  },
  featuresText: {
    textAlign: "center",
    margin: "10px auto",
    width: "80%",
  },
  pricingh5: {
    fontSize: 20,
    letterSpacing: 0,
  },
  pricingPaper: {
    padding: "15px",
    minHeight: "300px",
    height: "100%",
  },
});

export const LandingMain = styled.section`
  overflow: hidden;
  padding-top: 200px;
  background: url(${bg}) no-repeat center center;
  background-size: cover;
  overflow-x: hidden;

  ${mediaQuery.SM_LANDSCAPE} {
    height: auto;
    margin-bottom: 50px;
    padding-top: 65px;

    & > div > div > .MuiGrid-item {
      padding: 0 0 20px 0;
    }

    h1 {
      font-size: 45px;
    }

    h1 > span {
      display: inline-block;
      width: 100%;
    }

    h2 > span {
      display: inline-block;
      width: 100%;
      font-size: 27px;
    }

    p {
      font-size: 17px;
      white-space: nowrap;
      margin: 10px 0 30px 0;
    }

    .MuiGrid-item > button {
      padding: 5px 0;
      margin: 10px 7px;
      width: 150px;
    }

    & .MuiGrid-root {
      width: 100%;
      margin: 0;
    }
  }

  ${mediaQuery.SM} {
    height: auto;
    margin-bottom: 50px;
    padding-top: 65px;

    & > div > div > .MuiGrid-item {
      padding: 0 0 20px 0;
    }

    h1 {
      font-size: 45px;
    }

    h1 > span {
      display: inline-block;
      width: 100%;
    }

    h2 > span {
      display: inline-block;
      width: 100%;
      font-size: 27px;
    }

    p {
      font-size: 17px;
      white-space: nowrap;
      margin: 10px 0 30px 0;
    }

    .MuiGrid-item > button {
      padding: 5px 0;
      margin: 10px 7px;
      width: 150px;
    }

    & .MuiGrid-root {
      width: 100%;
      margin: 0;
    }
  }
`;

export const FeaturesContainer = styled.section`
  margin: 50px 0;
  ${mediaQuery.SM} {
    p {
      margin-top: 10px;
      width: 100%;
    }
  }
`;

export const StatsContainer = styled(Grid)`
  margin-top: 150px;
  margin-bottom: 50px;

  h3 {
    white-space: nowrap;
    font-weight: bold;
    text-align: center;
  }

  h5 {
    white-space: nowrap;
    text-align: center;
  }

  ${mediaQuery.MD} {
    h2 {
      font-size: 24px;
      margin: 25px 0;
      text-align: center;
    }
  }

  ${mediaQuery.SM} {
    .MuiGrid-spacing-xs-8 > .MuiGrid-item {
      padding: 16px 0px;
    }

    .MuiGrid-grid-xs-12 > div {
      transform: none;
      display: flex;
      justify-content: center;
    }

    h2 {
      font-size: 24px;
      margin: 25px 0;
    }
  }
`;
export const StatsTitle = styled(Grid)`
  margin-bottom: 40px;
  white-space: nowrap;

  h2 {
    font-size: 40px;
    font-weight: bold;
    text-align: center;
  }

  span {
    color: #e87f40;
  }

  ${mediaQuery.XL} {
    white-space: unset;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    h2,
    span {
      font-size: 27px;
    }
  }

  ${mediaQuery.SM} {
    h2,
    span {
      font-size: 27px;
    }
  }
`;

export const StatsNumbers = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
`;

export const StatsNumberTextWrapper = styled.div`
  margin: 10px 0;

  ${mediaQuery.XL} {
    width: 50%;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    width: 50%;

    h3 {
      font-size: 23px;
    }

    h5 {
      font-size: 12px;
      white-space: nowrap;
      font-weight: 500;
      margin-top: 0;
    }
  }

  ${mediaQuery.SM} {
    width: 50%;
    h3 {
      font-size: 23px;
    }

    h5 {
      font-size: 12px;
      white-space: nowrap;
      font-weight: 500;
      margin-top: 0;
    }
  }
`;

export const ImgWrapper = styled.div`
  width: 50%;
  margin: 20px 70px 20px 0;

  ${mediaQuery.LG} {
    width: 100%;
    margin: 20px 0px 60px 0;
  }

  ${mediaQuery.SM} {
    margin: 20px 0px 40px 0;
    padding: 0;
  }
`;

export const NewInstrumentMonitorImage = styled.img`
  width: 100%;
  border-radius: 13px;

  filter: drop-shadow(1px 5px 5px #00000029);
`;

export const FavImage = styled.img`
  height: auto;
  width: 100%;
  margin: 20px 0px 20px 0;
  -webkit-filter: drop-shadow(1px 5px 5px #00000029);
  filter: drop-shadow(1px 5px 5px #00000029);
  border-radius: 13px;
`;

export const PaperHeader = styled.div`
  text-align: left;
  margin: 0 0 20px 0;
  font-size: 20px;
  font-weight: bold;
`;

export const PaperContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ImageContainer = styled.div`
  min-height: 105px;
`;

export const Image = styled.img`
  display: block;
  margin: 0 auto 20px;
`;

export const InfoSection = styled.div`
  font-weight: 800;
  margin: 0 0 15px 0;
`;

export const FeatureSection = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 240px;
  justify-content: start;
`;

export const Feature = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;
`;

export const SubscribeButtonPremium = styled(Button)`
  display: block;
  margin: 25px auto;
  width: 180px;
  height: max-content;
  min-height: 48px;
  color: #fff;
  background-color: #e87f38;
  font-size: 16px;
  font-weight: 400;
  z-index: 2;
  border-radius: 10px;

  button > * {
    pointer-events: none;
  }

  &:hover {
    background-color: #e87f38 !important;
  }

  &:disabled {
    color: red;
    cursor: not-allowed;
    pointer-events: visible;
  }

  ${mediaQuery.LG} {
    width: 155px;
  }
`;

export const SubscribeButtonFree = styled(Button)`
  display: block;
  margin: 25px auto;
  width: 180px;
  height: 48px;
  color: #fff;
  background-color: #3f18aa;
  font-size: 16px;
  font-weight: 300;
  z-index: 2;
  border-radius: 10px;

  &:hover {
    background-color: #3f18aa !important;
  }

  button > * {
    pointer-events: none;
  }

  &:disabled {
    color: #e6e6e6;
    cursor: not-allowed;
    pointer-events: visible;
  }

  ${mediaQuery.LG} {
    width: 155px;
  }
`;

export const CircleIcon = styled(CheckCircleIcon)`
  font-size: 16px;
  margin: 0 5px 0 0;
  display: flex;
  align-content: center;
`;

export const CircleColorFree = styled.div`
  color: #3f18aa;
`;

export const CircleColorPremium = styled.div`
  color: #e87f38;
`;

export const GridContainerStyles = styled(Grid)`
  margin-bottom: 50px;
  padding: 40px 0;
  align-items: center;
`;

export const GridStyles = styled(Grid)`
  padding: 100px 0;
`;

export const ItemTitle = styled.div`
  margin: 15px 0;
  font-weight: bold;
  font-size: 40px;
  span {
    color: ${MuiMainBackgroundColor};
  }
  svg {
    font-size: 64px;
    margin-bottom: -20px;
    fill: ${MuiMainBackgroundColor};
  }

  ${mediaQuery.SM_LANDSCAPE} {
    font-size: 32px;

    svg {
      font-size: 50px;
    }
  }

  ${mediaQuery.SM} {
    font-size: 32px;

    svg {
      font-size: 50px;
    }
  }
`;

export const ItemTitleSecondary = styled.div`
  margin: 15px 0;
  font-weight: bold;
  font-size: 40px;
  span {
    color: #e88e3c;
  }
  svg {
    font-size: 64px;
    margin-bottom: -20px;
    fill: #e88e3c;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    font-size: 32px;

    svg {
      font-size: 50px;
    }
  }

  ${mediaQuery.SM} {
    font-size: 32px;

    svg {
      font-size: 50px;
    }
  }
`;

export const PricesContainer = styled.section`
  margin: 100px 10px 0;
  overflow: hidden;

  ${mediaQuery.LG} {
    h5 {
      font-size: 33px !important;
    }

    img {
      margin: 10px 0;
    }

    & > div > div:nth-last-child(-n + 2) {
      padding: 0 16px !important;
    }

    & > div > div:nth-last-child(-n + 3) > div {
      max-width: 100%;
      flex-basis: 100%;
      padding: 0 !important;
    }

    & > div > div:last-child > div:first-child {
      order: 2;
    }

    & > div > div:nth-of-type(2) > div:first-child {
      order: 2;
    }
  }
`;

export const LandingFooter = styled.footer`
  background: black;
  padding: 200px 0;
  margin-top: 100px;
  h3 {
    font-weight: bold;
    line-height: 48px;
    color: white;
    font-size: 40px;
    text-align: center;
    span {
      color: ${theme.palette.secondary.main};
    }
  }
  button {
    margin: 20px auto;
    text-transform: uppercase;
    display: flex;
    padding: 15px 20px;
  }

  button > * {
    pointer-events: none;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    padding: 0 0 20px 0;
    margin-top: 75px;

    h3 {
      font-size: 25px;
    }

    button {
      margin: 20px auto 0;
      padding: 10px 20px;
    }
  }

  ${mediaQuery.SM} {
    padding: 0 0 20px 0;
    margin-top: 75px;

    h3 {
      font-size: 25px;
    }

    button {
      margin: 20px auto 0;
      padding: 10px 20px;
    }
  }
`;

export const StyledFeaturesList = styled.ul`
  padding-left: 25px;
  max-width: 570px;

  li {
    padding-left: 25px;
    font-weight: 400;
  }

  li > strong {
    font-weight: 900;
  }

  li::marker {
    color: #3922a3;
    font-size: 1.5em;
  }

  ${mediaQuery.MD} {
    padding-left: 15px;
    max-width: 80%;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    max-width: 100%;
  }

  ${mediaQuery.SM} {
    padding-left: 15px;
    max-width: 100%;

    li {
      padding-left: 0;
      font-weight: 500;
    }

    li .MuiTypography-body1 {
      font-size: 0.8rem;
      white-space: unset;
      margin: 10px 0 20px 0;
    }

    li::marker {
      color: #3922a3;
      font-size: 1rem;
    }
  }
`;

export const NewInstrumentMonitorWrapper = styled.div`
  display: flex;

  ${mediaQuery.LG} {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const KeyFeaturesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;

  h4 {
    font-weight: 700;
  }

  ${mediaQuery.SM} {
    margin-bottom: 20px;
    flex-wrap: wrap;
    justify-content: center;
  }
`;
export const StyledList = styled.ul`
  list-style: none;
  padding-left: 2px;
  max-width: 455px;
  margin-top: 60px;
  li {
    margin: 30px 0 30px 0;
  }

  ul > li:nth-child(2) {
    margin-bottom: 50px;
  }

  li strong {
    font-weight: 700;
    color: #3922a3;
    font-size: 1.2rem;
  }

  li .MuiTypography-body1 {
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1.2;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    max-width: 300px;
    margin-top: 35px;

    li strong {
      font-size: 1rem;
    }

    li .MuiTypography-body1 {
      white-space: unset;
      font-size: 0.9rem;
      font-weight: 400;
    }
  }

  ${mediaQuery.SM} {
    max-width: 300px;
    margin-top: 35px;

    li strong {
      font-size: 1rem;
    }

    li .MuiTypography-body1 {
      white-space: unset;
      font-size: 0.9rem;
      font-weight: 400;
    }
  }
`;

export const StyledSectionTitle = styled(Typography)`
  font-size: 40px;
  font-weight: bold;
  margin: 10px 0 30px;
  text-align: center;

  span {
    color: #3f18aa;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    font-size: 32px;
  }

  ${mediaQuery.SM} {
    font-size: 32px;
  }
`;

export const StyledButton = styled(Button)`
  & > * {
    pointer-events: none;
  }
`;

export const ButtonWithTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 260px;
  margin-right: 15px;

  button {
    margin: 30px 0px 0px 0;
    max-height: 37px;
  }

  button > * {
    pointer-events: none;
  }

  & > span {
    font-weight: 500;
  }

  ${mediaQuery.SM} {
    justify-content: end;

    & > span {
      font-size: 12px;
    }

    button {
      margin: 10px 0px;
    }
  }
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  button:nth-child(2) {
    margin: 30px 15px 40px 0;
    visibility: hidden;
  }

  ${mediaQuery.SM} {
    max-width: 300px;
    button {
      padding: 5px 0;
      width: 190px;
    }
  }
`;
export const SearchBarWrapper = styled.div`
  & > div:first-child > div:first-child {
    height: 60px !important;
    box-shadow: 0px 0px 10px 0px rgb(98 97 100);
    margin: 60px 0;
  }

  .suggestionList {
    top: 60px !important;
  }

  input {
    font-size: 22px;
  }

  ${mediaQuery.SM} {
    & > div:first-child > div:first-child {
      height: 45px !important;
      box-shadow: 0px 0px 10px 0px rgb(98 97 100);
      margin: 20px 0;
    }

    .suggestionList {
      top: 45px !important;
    }

    .suggestionList li {
      border-bottom: 1px solid #dbdbdb;
      padding: 0 16px !important;
      min-height: 35px;
    }

    .suggestionList p {
      margin: 0;
      font-size: 0.8rem;
    }
    .suggestionList p:first-child {
      font-weight: 500;
    }

    input {
      font-size: 12px;
      padding: 11px 0 11px 5px;
      min-width: 168px;
    }

    button:first-child {
      svg {
        font-size: 15px;
      }

      span {
        font-size: 10px;
      }
    }

    button:last-child {
      width: 60px;
    }
  }

  ${mediaQuery.SM_LANDSCAPE} {
    & > div:first-child > div:first-child {
      height: 45px !important;
      box-shadow: 0px 0px 10px 0px rgb(98 97 100);
      margin: 20px 0;
    }

    .suggestionList {
      top: 45px !important;
    }

    .suggestionList li {
      border-bottom: 1px solid #dbdbdb;
      padding: 0 16px !important;
      min-height: 35px;
    }

    .suggestionList p {
      margin: 0;
      font-size: 0.8rem;
    }
    .suggestionList p:first-child {
      font-weight: 500;
    }

    input {
      font-size: 12px;
      padding: 11px 0 11px 5px;
      min-width: 168px;
    }

    button:first-child {
      svg {
        font-size: 15px;
      }

      span {
        font-size: 10px;
      }
    }

    button:last-child {
      width: 60px;
    }
  }
`;
