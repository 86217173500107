import { Button, Grid } from "@material-ui/core";
import React, { FC, useContext, useEffect, useState } from "react";
import { SubscribeContext } from "../../../common/subcriptionProvider";
import { DataCard } from "../../../components";
import IssuerRelations from "../../../components/IssuerRelations/IssuerRelations";
import Loader from "../../../components/loader/Loader";
import { useLazyGetIssuerRelationsWithoutInstrumentsQuery } from "../../../graphql/GetIssuerRelationsWithoutInstruments";
import { IssuerRelationsWithoutInstrumentsInterface } from "../CompanyStructureTab/IssuerStructureInterface";
import { IIssuerPageLeiSearch } from "../IssuerPageInterface";
import { ButtonWrapper, CardContainer, GridItemWithButton, InformationCardWrapper } from "./Issuer.css";

const IssuerPageLeiSearch: FC<IIssuerPageLeiSearch> = ({
  informationData: { basicInformation, legalAddress, registrationDetails, headQuarterAddress },
  lei,
  tab,
  isScroll,
  setIsScroll,
}) => {
  const { setErrorMessage } = useContext(SubscribeContext);
  const [issuerRelationsData, setIssuerRelationsData] = useState<IssuerRelationsWithoutInstrumentsInterface>();

  const cardTitle = "Corporate Group Structure";

  const [
    getIssuerRelationsWithoutInstruments,
    { data: relationsData, error: RelationsDataError },
  ] = useLazyGetIssuerRelationsWithoutInstrumentsQuery();

  useEffect(() => {
    if (tab === 2) {
      getIssuerRelationsWithoutInstruments({ variables: { lei: lei } });
    }
  }, [tab, getIssuerRelationsWithoutInstruments, lei]);

  useEffect(() => {
    if (RelationsDataError) {
      setErrorMessage(RelationsDataError.message);
    }

    if (relationsData && relationsData.getIssuerRelationsWithoutInstruments) {
      setIssuerRelationsData(relationsData.getIssuerRelationsWithoutInstruments);
    }
  }, [relationsData, RelationsDataError, setErrorMessage]);

  const handleRedirect = () => {
    window.open("https://www-en.kdd.si/", "_blank");
  };

  return (
    <InformationCardWrapper container item xs={12} spacing={2}>
      <CardContainer>
        <Grid item xs={12} md={6}>
          <DataCard title="Basic" rows={basicInformation} style={{ marginBottom: "16px" }} />
        </Grid>
        <GridItemWithButton item xs={12} md={6}>
          <ButtonWrapper>
            <Button id="issuer_page__get_renew_LEI" onClick={handleRedirect}>
              GET / RENEW LEI
            </Button>
          </ButtonWrapper>
          <DataCard
            title="LEI code - Registration details"
            rows={registrationDetails}
            style={{ marginBottom: "16px" }}
          />
        </GridItemWithButton>
        <Grid item xs={12} md={6}>
          <DataCard title="Legal Address" rows={legalAddress} style={{ marginBottom: "16px" }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <DataCard title="Headquarter Address" rows={headQuarterAddress} />
        </Grid>
      </CardContainer>
      <Grid item xs={12}>
        {issuerRelationsData ? (
          <IssuerRelations
            issuerRelationsData={issuerRelationsData}
            isScroll={isScroll}
            setIsScroll={setIsScroll}
            cardTitle={cardTitle}
          />
        ) : (
          <Loader />
        )}
      </Grid>
    </InformationCardWrapper>
  );
};

export default IssuerPageLeiSearch;
