import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { InstrumentSugestion, IssuerSugestion } from "../common/types";

const GET_INSTRUMENT_AND_ISSUER_SUGGESTIONS = gql`
  query getInstrumentAndIssuerSuggestions($startingCharacters: String!) {
    getInstrumentAndIssuerSuggestions(startingCharacters: $startingCharacters) {
      instruments {
        isin
        name
      }
      issuers {
        lei
        name
      }
    }
  }
`;

interface QueryText {
  startingCharacters: string;
}

interface GetInstrumentAndIssuerSuggestions {
  getInstrumentAndIssuerSuggestions: {
    instruments: InstrumentSugestion[];
    issuers: IssuerSugestion[];
  };
}

export const useLazyGetInstrumentAndIssuerSuggestionsQuery = () =>
  useLazyQuery<GetInstrumentAndIssuerSuggestions, QueryText>(
    GET_INSTRUMENT_AND_ISSUER_SUGGESTIONS
  );
