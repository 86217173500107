import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { InstrumentNew, InstrumentResourceParametersInput } from "../common/types";

const queryAuth = (isPremium: any) => {
  const GET_INSTRUMENTS_BY_MULTIPLE_ISINS = gql`
    query getInstrumentsByMultipleISINs(
      $numberOfRecords: Int
      $isins: [String]
      $resourceParameters: InstrumentResourceParametersInput
      $startingRecord: Int!
    ) {
      getInstrumentsByMultipleISINs(
        numberOfRecords: $numberOfRecords
        isins: $isins
        resourceParameters: $resourceParameters
        startingRecord: $startingRecord
      ) {
        instruments {
          upcomingRCA
          instrumentFullName
          firstTradeDate
          terminationDate
          maturityDate
          notionalCurrency
          instrumentIdentificationCode
          totalIssuedNominalAmount @include(if: ${isPremium})
          nominalValuePerUnit @include(if: ${isPremium})
        }
      }
    }
  `;
  return GET_INSTRUMENTS_BY_MULTIPLE_ISINS;
};

interface Params {
  numberOfRecords: number;
  isins: string[];
  resourceParameters: InstrumentResourceParametersInput;
  startingRecord: number;
}

interface GetInstrumentsByMultipleISINs {
  getInstrumentsByMultipleISINs: {
    instruments: InstrumentNew[];
  };
}

export const useLazyGetInstrumentsByMultipleISINsQuery = (isPremium: any) =>
  useLazyQuery<GetInstrumentsByMultipleISINs, Params>(queryAuth(isPremium));
