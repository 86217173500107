const mediaQuery = {
  SM: "@media (max-width: 576px)",
  SM_LANDSCAPE: "@media  (max-width: 824px) and (orientation: landscape) ",
  MD: "@media (max-width: 768px)",
  LG_MIN: "@media (min-width: 992px)",
  LG: "@media (max-width: 992px)",
  EXTRA_LG: "@media (max-width: 1200px)",
  MUI_LG: "@media (max-width: 1280px)",
  XL: "@media (max-width: 1440px)",
  XXL: "@media (max-width: 1919px),",
};

export default mediaQuery;
