import { Box, Button, Tooltip, Typography } from "@material-ui/core";
import { AddAlert } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { SubscribeContext } from "../../common/subcriptionProvider";
import { InstrumentInterface } from "../../common/types";
import { Page } from "../../components";
import CustomFlag from "../../components/CustomFlag";
import Loader from "../../components/loader/Loader";
import { useMutationAddFavorite } from "../../graphql/AddFavorite";
import { useLazyGetInstrumentQuery } from "../../graphql/GetInstrument";
import { useLazyGetIssuerInfoByLeiQuery } from "../../graphql/GetIssuerInfoByLei";
import { useMutationRemoveFavorite } from "../../graphql/RemoveFavorite";
import { calcDaysProgress } from "../../utils/calcDates";
import InstrumentHistoricalReporting from "./HistoricReportingTab/InstrumentHistoricReportings";
import {
  AntTab,
  AntTabs,
  InstrumentPageContent,
  InstrumentPageHeader,
  InstrumentPageTabs,
  LoadingMessage,
} from "./InstrumentPage.css";
import { mapInstrumentFunc } from "./InstrumentPageFunctions";
import InstrumentMainDetails from "./MainDetailsTab/InstrumentMainDetails";
import TradingVenuesTable from "./TradingVenueTableTab/TradingVenuesTable";
import TransparencyCalculations from "./TransparencyCalculationsTab/TransparencyCalculations";

function InstrumentPage(props: RouteComponentProps<{ isin: string }>) {
  const history = useHistory();

  const [tab, setTab] = useState(0);
  const [instrumentData, setInstrumentData] = useState<InstrumentInterface>();
  const [isFav, setFav] = useState<boolean>(false);
  const [isTradingVenueDisabled, setIsTradingVenueDisabled] = useState<boolean>(false);
  const [isQueriesDataLoaded, setIsQueriesDataLoaded] = useState<any>({
    getInstrument: false,
    getTradingVenue: false,
    getSelectableTradingVenues: false,
  });
  const { match } = props;
  const isin = match.params.isin;

  const { isUserPremium, setErrorMessage } = useContext(SubscribeContext);

  const checkIfAllQueriesAreLoaded = (obj: any) => {
    for (const o in obj) {
      if (!obj[o]) return false;
    }
    return true;
  };

  const [
    getInstrument,
    { data: instrument, loading: loadingInstrumentData, error: instrumentError },
  ] = useLazyGetInstrumentQuery(true, "no-cache");

  const [
    getIssuerInfoByLei,
    { data: IssuerInfoByLei, loading: loadingIssuerInfoByLei, error: getIssuerInfoByLeiError },
  ] = useLazyGetIssuerInfoByLeiQuery();

  const [addFavorite] = useMutationAddFavorite();
  const [removeFavorite] = useMutationRemoveFavorite();

  useEffect(() => {
    if (isin) {
      setIsQueriesDataLoaded({
        getInstrument: false,
        getTradingVenue: false,
        getSelectableTradingVenues: false,
      });
      getInstrument({ variables: { isin } });
    }
  }, [getInstrument, isin, setIsQueriesDataLoaded]);

  useEffect(() => {
    if (instrumentError) {
      setErrorMessage(instrumentError.message);
    }
    if (instrument && instrument?.getInstrument) {
      setIsQueriesDataLoaded((prevState: any) => ({
        ...prevState,
        getInstrument: true,
      }));

      getIssuerInfoByLei({ variables: { lei: instrument.getInstrument.issuerOrOperatorIdentifier } });
      setInstrumentData(instrument.getInstrument);
      setFav(instrument.getInstrument.isFavorite);
      setTab(0);
    }
  }, [instrument, instrumentError, setErrorMessage]);

  useEffect(() => {
    if (getIssuerInfoByLeiError) {
      setErrorMessage(getIssuerInfoByLeiError.message);
    }
  }, [setErrorMessage, getIssuerInfoByLeiError]);

  useEffect(() => {
    if (history.action === "POP") {
      if (history.location.state === 1 && history.location.state !== undefined) {
        setTab(1);
      } else if (history.location.state === 2 && history.location.state !== undefined) {
        setTab(2);
      } else if (history.location.state === undefined || history.location.state === 0) {
        setTab(0);
      }
    }
  }, [history.location.state, history.action]);

  const issuerRows = mapInstrumentFunc(IssuerInfoByLei, instrumentData).issuerRows;

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
    window.scrollTo(0, 0);
    if (newValue !== tab && newValue === 0) {
      history.push(`/instrument/${isin}`, 0);
    } else if (newValue !== tab && newValue === 1) {
      history.push(`/instrument/${isin}`, 1);
    } else if (newValue !== tab && newValue === 2) {
      history.push(`/instrument/${isin}`, 2);
    }
  };

  const handleFavorite = () => {
    if (isFav) {
      removeFavorite({ variables: { isin } });
      setFav(false);
    } else {
      addFavorite({ variables: { isin } });
      setFav(true);
    }
  };

  return (
    <Page>
      {instrumentData && IssuerInfoByLei && (
        <>
          <InstrumentPageHeader>
            <Box mb={3} style={{ position: "relative" }}>
              <Button
                style={{ float: "right" }}
                variant="outlined"
                color="primary"
                startIcon={<AddAlert />}
                onClick={handleFavorite}
                disabled={isUserPremium === null}
              >
                {isFav ? "Remove from favourites" : "Add to favourites"}
              </Button>
              <Box display="flex" alignItems="center">
                {instrumentData.upcomingRCA ? (
                  <CustomFlag code={instrumentData.upcomingRCA} alt={instrumentData?.upcomingRCA} />
                ) : (
                  <CustomFlag code={"EU"} alt={"EU"} />
                )}

                <Box>
                  <Typography variant="h4">{instrumentData?.instrumentIdentificationCode}</Typography>
                  <Typography variant="h5">{instrumentData?.instrumentFullName}</Typography>
                  <Typography variant="subtitle2">{instrumentData?.instrumentType}</Typography>
                </Box>
              </Box>
            </Box>
          </InstrumentPageHeader>
          <InstrumentPageTabs>
            <AntTabs value={tab} onChange={handleTabChange}>
              <AntTab disableRipple label="Main Details" />
              <AntTab disableRipple label="Historic Data" disabled={!checkIfAllQueriesAreLoaded(isQueriesDataLoaded)} />
              <AntTab
                disableRipple
                disabled={!checkIfAllQueriesAreLoaded(isQueriesDataLoaded) || isTradingVenueDisabled}
                label={
                  isTradingVenueDisabled ? (
                    <Tooltip title="No trading venue attached yet">
                      <span>Trading Venues</span>
                    </Tooltip>
                  ) : (
                    "Trading Venues"
                  )
                }
              />
              <AntTab
                disableRipple
                label="MiFID II / MiFIR Calculations"
                disabled={!checkIfAllQueriesAreLoaded(isQueriesDataLoaded)}
              />
            </AntTabs>
          </InstrumentPageTabs>
          <InstrumentPageContent>
            <Box hidden={tab !== 0}>
              <InstrumentMainDetails
                instrumentData={instrumentData && instrumentData}
                loadingGleifData={loadingIssuerInfoByLei}
                loadingInstrumentData={loadingInstrumentData}
                issuerRows={issuerRows}
                calcDaysProgress={calcDaysProgress}
                underlyingInstruments={instrumentData.underlyingInstruments}
                setTab={setTab}
                tab={tab}
                isin={isin}
                setIsTradingVenueDisabled={setIsTradingVenueDisabled}
                setIsQueriesDataLoaded={setIsQueriesDataLoaded}
              />
            </Box>
            <Box hidden={tab !== 1}>
              <InstrumentHistoricalReporting
                tab={tab}
                isin={isin}
                lei={IssuerInfoByLei.getIssuerByLEI.lei}
                tradingVenues={instrumentData?.tradingVenues || ""}
              />
            </Box>
            <Box hidden={tab !== 2}>
              <TradingVenuesTable isin={isin} tab={tab} />
            </Box>
            <Box hidden={tab !== 3}>
              <TransparencyCalculations isin={isin} tab={tab} />
            </Box>
          </InstrumentPageContent>
        </>
      )}
      {(loadingInstrumentData || loadingIssuerInfoByLei) && (
        <>
          <LoadingMessage>Loading data...</LoadingMessage>
          <Loader />
        </>
      )}
      {!loadingInstrumentData && !instrumentData && <Typography variant="h1">Unknown Instrument</Typography>}
    </Page>
  );
}

export { InstrumentPage };
