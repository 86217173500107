import {
  Box,
  Card,
  CardContent,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import {createMuiTheme} from "@material-ui/core/styles";
import { DatePicker } from "@material-ui/pickers";
import styled from "styled-components";
import mediaQuery from "../../../common/mediaQuery";
interface ILoadingInstruments {
  loading: any;
}

interface DisableInterface {
  isUserPremium?: boolean | null;
  disabled?: boolean;
}

export const ProgressOverlay = styled.div`
  width: 100%;
  top: 50%;
  left: 0;
  position: absolute;
  &.empty {
    bottom: 50px;
  }
  .MuiCircularProgress-root {
    margin: 0 auto;
    display: block;
    position: relative;
    top: 50%;
  }
`;

export const StyledTable = styled(Table)`
  th,
  td {
    &:first-child {
      width: 10%;
    }

    &:nth-child(2) {
      width: 30%;
    }
    &:nth-child(3) {
      padding: 5px 0 3px 0;
      width: 30%;
    }
    &:nth-child(4) {
      width: 10%;
    }
  }
`;

export const TableHeaderCell = styled(TableCell)`
  font-weight: 700;
  font-size: 15px;
  ${mediaQuery.SM} {
    p {
      font-size: 0.8rem;
    }
  }
`;

export const TableRowStyle = styled(TableRow)`
  cursor: pointer;

  & > th > *,
  & > td > * {
    pointer-events: none;
  }

  & > th,
  & > td {
    pointer-events: all;
  }

  ${mediaQuery.SM} {
    td,
    th {
      padding: 5px 8px !important;
    }
  }
`;

export const TableMainCell = styled(TableCell)`
  img {
    width: 38px;
  }

  ${mediaQuery.SM} {
    &,
    p {
      font-size: 0.7rem;
    }
  }
`;

export const TableNameCell = styled(TableCell)`
  width: 30%;

  p {
    font-weight: bold;
  }

  ${mediaQuery.SM} {
    p {
      font-size: 0.7rem;
    }

    span {
      font-size: 0.6rem;
    }
  }
`;

export const StyledFormControl = styled(FormControl)`
  display: flex;
  min-width: 110px;

  & > div:nth-child(2) {
    max-height: 33px;
    font-size: 14px;
    & div {
      padding: 22.5px 14px 18.5px 14px;
    }
  }

  & label {
    font-size: 14px;
  }
`;

export const SelectLabel = styled.div`
  label {
    top: -10px;
    color: #b7b7b6;
  }
`;

export const StyledTableBody = styled(TableBody)<ILoadingInstruments>`
  filter: ${({ loading }) => (loading ? "blur(1px)" : "blur(0)")};
  transition: ${({ loading }) => (loading ? "none" : "all .6s")};
`;

export const StyledTextField = styled(TextField)<DisableInterface>`
  & ::after,
  & ::before {
    display: none;
  }
  min-width: 170px;
  border-radius: 4px;
  border: 1px solid #70707080;
  margin: 2px 5px 2px 0;
  padding: 0 5px;
  height: 33px;
  background-color: ${(props) => (props.disabled ? "#e3e3e3" : "")};
  & input {
    font-size: 14px;
    padding: 7px 11px 7px;
  }
`;

export const StyledDatePicker = styled(DatePicker)<DisableInterface>`
  width: 100%;
  min-width: 100px;
  & ::after,
  & ::before {
    display: none;
  }
  border-radius: 4px;
  border: 1px solid #70707080;
  margin: 2px 5px 2px 0;
  padding: 0 5px;
  height: 33px;
  background-color: ${(props) => (props.disabled ? "#e3e3e3" : "")};
  & input {
    font-size: 14px;
    padding: 7px 11px 7px;
  }
`;

export const StyledTablePagination = styled.div`
  margin: 16px 0;
`;

export const UpgradeMessage = styled.p`
  color: #959595;
  white-space: nowrap;
  font-size: 13px;
  letter-spacing: 2px;
  line-height: normal;
  margin: 0;
`;

export const EmptyCard = styled.div`
  text-align: center;
  color: #cbcbcb;
  margin: 10px 0;
`;

export const Message = styled.div`
  font-size: 16px;
  font-weight: 700;
`;

export const DropdownStyle = styled.div<DisableInterface>`
  background-color: ${(props) => (props.isUserPremium ? "" : "#e3e3e3")};
`;

export const TableScrollContainer = styled.div`
  ${mediaQuery.LG} {
    overflow-x: scroll;
  }
`;

export const StyledBox = styled(Box)`
  display: flex;
  justify-content: space-between;

  ${mediaQuery.SM} {
    h5 {
      font-size: 1.2rem;
    }
  }
`;

export const TypographyStyle = styled(Typography)`
  font-size: 24px;
  font-weight: 700;
`;

export const CardContentStyle = styled(CardContent)`
  min-height: 165px;
`;

export const CardStyle = styled(Card)`
  position: relative;

  ${mediaQuery.SM} {
    padding: 0;
  }
`;

export const formControlTheme = createMuiTheme({
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "#F7F8F6",
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        "&$shrink": {
          transform: "translate(15px, 6px) scale(0.75)",
        },
      },
    },
  },
});

export const StyledUpgradeToUnlockRow = styled(TableRow)`
  th {
    border-bottom: none;
    padding: 3px 0;
  }
`;

export const StyledFiltersRow = styled(TableRow)`
  th {
    padding: 5px 5px 3px 0;
  }
`;
