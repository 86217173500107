import { Paper, Table, TableRow, Grid } from "@material-ui/core";
import {createMuiTheme} from "@material-ui/core/styles";
import styled from "styled-components";
import mediaQuery from "../../common/mediaQuery";
import crossIcon from "../../assets/images/cross-icon.png";
import tickIcon from "../../assets/images/tick-icon.png";
import defaultProfilePicture from "../../assets/images/default-profile-picture.jpg";

export const UserSettingsPageWrapper = styled.div`
  button {
    float: right;
    padding: 10px 30px;
  }

  .MuiPaper-root {
    padding: 8px;
  }

  ${mediaQuery.SM} {
    button {
      font-size: 11px;
      padding: 5px 20px;
    }

    h3 {
      font-size: 1.8rem;
    }

    h5 {
      margin-top: 15px;
    }

    h4 {
      padding: 10px 0 0px 13px;
    }

    .MuiPaper-root {
      height: auto;
    }

    .MuiGrid-spacing-xs-2 {
      width: 100%;
      margin: 0;
    }

    .MuiGrid-spacing-xs-2 > .MuiGrid-item {
      padding: 8px 0;
    }
  }
`;

export const UserPersonalInformationTable = styled(Table)`
  margin-left: 20px;
  td {
    padding: 14px 24px 14px 16px;
    font-weight: bold;
  }
  tr:last-child > td {
    border-bottom: none;
  }

  ${mediaQuery.MD} {
    margin-left: 0;
  }
  ${mediaQuery.SM} {
    td {
      padding: 9px 0px 9px 0px;
    }
  }
`;

export const UserPersonalInformationDetails = styled.div`
  display: flex;
  clear: both;
  padding: 20px;
  overflow: hidden;

  ${mediaQuery.EXTRA_LG} {
    padding: 10px;
  }

  ${mediaQuery.MD} {
    flex-wrap: wrap;
    justify-content: center;
  }

  ${mediaQuery.SM} {
    padding: 10px;

    td {
      width: 75%;
      font-size: 10px;
    }

    td:first-child {
      width: 25%;
    }
  }
`;

export const Avatar = styled.div`
  background: url(${defaultProfilePicture});
  background-size: contain;
  background-repeat: no-repeat;
  width: 250px;

  ${mediaQuery.LG} {
    height: 200px;
  }
  ${mediaQuery.MD} {
    height: 200px;
    background-position: center;
  }
  ${mediaQuery.SM} {
    height: 150px;
  }
`;

export const TableWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: flex-start;
  align-items: start;
  flex-wrap: wrap;
  width: 100%;
`;

export const UserDetailStyle = styled(Paper)`
  height: 100%;
  & table {
    width: 100%;
  }
`;

export const ButtonsContainer = styled.div`
  min-height: 120px;
  display: flex;
  flex-wrap: wrap;

  button > * {
    pointer-events: none;
  }

  nav {
    display: block;
    width: 100%;
  }

  p {
    margin: 0 15px;
  }

  p.error {
    color: #9e2146;
    letter-spacing: 1.4px;
    line-height: 20px;
  }

  p.success {
    display: inline-block;
    color: green;
    letter-spacing: 1.4px;
    line-height: 20px;
  }

  p.success:before {
    content: "";
    background: url(${tickIcon}) no-repeat;
    background-size: contain;
    width: 20px;
    height: 100%;
    float: left;
    margin: 0 10px 10px 0px;
  }

  p.error:before {
    content: "";
    background: url(${crossIcon}) no-repeat;
    background-size: contain;
    width: 20px;
    height: 100%;
    float: left;
    margin: 0 10px 10px 0px;
  }

  & > button {
    border-radius: 10px;
    min-width: 220px;
  }
`;

export const CellContent = styled.div`
  font-weight: 400;
`;

export const CellTitle = styled.div`
  width: max-content;
`;

export const TableRowStyle = styled(TableRow)`
  td:first-child {
    width: 100px;
  }
  ${mediaQuery.SM} {
    flex-direction: row;
  }
`;

export const GridStyle = styled(Grid)`
  position: relative;
`;

export const themeTypography = createMuiTheme({
  typography: {
    h3: {
      fontWeight: "bold",
    },
    h4: {
      fontWeight: "bold",
      color: "#3922a3",
      fontSize: "2.8rem",
      padding: "30px 0 5px 13px",
    },
    h5: {
      fontWeight: "bold",
      paddingLeft: "14px",
    },
  },
});

export const EditButtonRow = styled(TableRow)`
  td {
    padding: 0px 24px 14px 16px;
    border-bottom: none;
  }
`;
