const DAYS = "DAYS";
const WEEK = "WEEK";
const MNTH = "MNTH";

export const termIndexUnit = (unit: string) => {
  const subUnit = unit === MNTH ? "month(s)" : "year(s)";
  const resultUnit = unit === DAYS ? "day(s)" : subUnit;
  return resultUnit;
};

export const termIndexUnitDecode = (value: string) => {
  const decodeVal = value.slice(0, -4);

  if (value.includes(DAYS)) {
    return `${decodeVal} day(s)`;
  } else if (value.includes(WEEK)) {
    return `${decodeVal} week(s)`;
  } else if (value.includes(MNTH)) {
    return `${decodeVal} month(s)`;
  }

  return `${decodeVal} year(s)`;
};
