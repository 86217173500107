import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { InstrumentHistory } from "../common/types";

const GET_INSTRUMENT_HISTORY = gql`
  query getInstrumentHistory($isin: String!, $tradingVenue: String!) {
    getInstrumentHistory(isin: $isin, tradingVenue: $tradingVenue) {
      upcomingRCA
      totalIssuedNominalAmount
      notionalCurrency
      nominalValuePerUnit
      interestType
      fixedRate
      referenceRate
      nameOfTheIndex
      basePointSpread
      firstTradeDate
      publicationDate
      maturityDate
      terminationDate
      tradingVenue
      instrumentClassification
      identifierOfTheIndex
      requestForAdmissionToTrading
      commoditiesOrEmissionDerivativeIndicator
      instrumentType
      instrumentFullName
    }
  }
`;

interface Params {
  isin: string;
  tradingVenue: string;
}

interface GetInstrumentHistory {
  getInstrumentHistory: InstrumentHistory[];
}

export const useLazyGetInstrumentHistoryQuery = () =>
  useLazyQuery<GetInstrumentHistory, Params>(GET_INSTRUMENT_HISTORY);
