import React from "react";
import Flag from "react-world-flags";

interface FlagProps {
  code: string;
  alt: string;
  style?: React.CSSProperties;
}

const CustomFlag = ({ code, alt, style }: FlagProps) => {
  const formattedCode = code.substr(0, 2);

  return <Flag code={formattedCode} alt={alt} style={style} />;
};

export default CustomFlag;
