import { Lock } from "@material-ui/icons";
import React from "react";

export const lockDataCell = (dataObj: any, omittedFields?: Array<string> | undefined) => {
  Object.keys(dataObj).map((key: string) => {
    if (omittedFields && omittedFields.includes(key)) return;
    const result: any = dataObj;
    result[key] = <Lock />;
  });
};
