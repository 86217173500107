import { countries } from "country-data";
import moment from "moment";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { InstrumentInterface } from "../../common/types";
import { formatAmount } from "../../utils/formatAmount";
import { termIndexUnit, termIndexUnitDecode } from "../../utils/formatIndexUnit";
import {
  CATEGORY_CODE,
  CATEGORY_COL,
  getInstrCode,
  instrCategoryFields,
  instrTypeFields,
  TYPE_CODE,
  TYPE_COL,
} from "../InstrumentsTypeAndCategory/InstrumentSelectFunctions";
import { TradingVenuesInterface } from "../TradingVenues/TradingVenuesInterface";
import { LinkStyle } from "./InstrumentDetails.css";
import { mapSubFields } from "./InstrumentSubFields";
import { nameIndxData, SeniorityBondData } from "./mapNames";

const mapNameFunc = (data: string, mapObj: any) => {
  for (const [key, value] of Object.entries(mapObj)) {
    if (key === data) {
      return value;
    } else {
      return data;
    }
  }
};

const clearSessionFilters = () => {
  sessionStorage.removeItem("DEBT_filters");
  sessionStorage.removeItem("OTHER_filters");
};

export const mapInstrumentDetailsFunc = (
  instrumentData: InstrumentInterface | undefined,
) => {
  if (instrumentData) {
    const nameOfTheIndex = mapNameFunc(instrumentData.nameOfTheIndex, nameIndxData);
    const seniorityOfTheBond = mapNameFunc(instrumentData.seniorityOfTheBond, SeniorityBondData);

    const derivatives = instrumentData.additionalData;
    const subField = mapSubFields();

    const typeCodeChar = instrumentData.instrumentClassification?.charAt(0);
    const categoryCodeChar = instrumentData.instrumentClassification?.substring(0, 2);
    const typeCode = getInstrCode(instrTypeFields, typeCodeChar, TYPE_CODE, TYPE_COL);
    const categoryCode = getInstrCode(instrCategoryFields, categoryCodeChar, CATEGORY_CODE, CATEGORY_COL);

    const issueData = {
      ...(instrumentData.totalIssuedNominalAmount && {
        "Total Issued Nominal Amount": `${formatAmount(instrumentData.totalIssuedNominalAmount)} ${
          instrumentData.notionalCurrency
        }`,
      }),
      ...(instrumentData.nominalValuePerUnit && {
        "Nominal Value Per Unit/Minimum Traded Value": `${formatAmount(instrumentData.nominalValuePerUnit)} ${
          instrumentData.notionalCurrency
        }`,
      }),
      ...(instrumentData.interestType && { "Interest Type": instrumentData.interestType }),
      ...(instrumentData.interestType === "Fixed" && { "Interest Rate": `${instrumentData.fixedRate} %` }),
      ...(instrumentData.interestType === "FRN" &&
        instrumentData.identifierOfTheIndex && {
          "Identifier of the index/benchmark of a floating rate bond": instrumentData.identifierOfTheIndex,
        }),
      ...(instrumentData.interestType === "FRN" &&
        instrumentData.nameOfTheIndex && {
          "Name of the index/benchmark of a floating rate bond": nameOfTheIndex,
        }),
      ...(instrumentData.interestType === "FRN" && {
        "Term of the index/benchmark of a floating rate bond": `${instrumentData.termOfTheIndexValue} ${termIndexUnit(
          instrumentData.termOfTheIndexUnit
        )}`,
      }),
      ...(instrumentData.interestType === "FRN" &&
        instrumentData.basePointSpread && { "Spread (bps)": instrumentData.basePointSpread }),
      ...(instrumentData.seniorityOfTheBond && { "Seniority of a Bond": seniorityOfTheBond }),
      ...(instrumentData.maturityDate && { "Maturity Date": moment(instrumentData.maturityDate).format("DD/MM/YYYY") }),
      ...(instrumentData.notionalCurrency && { "Notional Currency": instrumentData.notionalCurrency }),
      ...(derivatives?.expiryDate && { "Expiry Date": moment(derivatives.expiryDate).format("DD/MM/YYYY") }),
      ...(derivatives?.priceMultiplier && { "Price Multiplier ": derivatives.priceMultiplier }),
      ...(derivatives?.underlyingIssuer?.length && {
        "Underlying Issuer LEI Code": (
          <LinkStyle
            component={RouterLink}
            to={`/issuer/${instrumentData?.issuerOrOperatorIdentifier}`}
            onClick={clearSessionFilters}
          >
            {derivatives.underlyingIssuer.join(", ")}
          </LinkStyle>
        ),
      }),
      ...(derivatives?.underlyingIndexName && { "Underlying index name": derivatives.underlyingIndexName }),
      ...(derivatives?.underlyingIndexTerm && {
        "Term of the underlying index": termIndexUnitDecode(derivatives.underlyingIndexTerm),
      }),
      ...(derivatives?.optionType && { "Option Type": subField.optionType[derivatives.optionType] }),
      ...(derivatives?.strikePrice && {
        "Strike Price":
          derivatives.strikePrice === "PNDG" || derivatives.strikePrice === "NOAP"
            ? subField.strikePrice[derivatives.strikePrice]
            : derivatives.strikePrice,
      }),
      ...(derivatives?.strikePriceCurrency && { "Strike Price Currency": derivatives.strikePriceCurrency }),
      ...(derivatives?.optionExerciseStyle && {
        "Option Exercise Style": subField.optionExerciseStyle[derivatives.optionExerciseStyle],
      }),
      ...(derivatives?.deliveryType && { "Delivery Type": subField.deliveryType[derivatives.deliveryType] }),
      ...(derivatives?.baseProduct && { "Base Product": derivatives.baseProduct }),
      ...(derivatives?.subProduct && { "Sub Product": derivatives.subProduct }),
      ...(derivatives?.furtherSubProduct && { "Further Sub Product": derivatives.furtherSubProduct }),
      ...(derivatives?.transactionType && {
        "Transaction Type": subField.transactionType[derivatives.transactionType],
      }),
      ...(derivatives?.finalPriceType && {
        "Final Price Type": subField.finalPriceType[derivatives.finalPriceType],
      }),
      ...(derivatives?.derivativeInstrumentReferenceRate && {
        "Reference Rate": derivatives.derivativeInstrumentReferenceRate,
      }),
      ...(derivatives?.irTermOfContract && {
        "IR Term of Contract": termIndexUnitDecode(derivatives.irTermOfContract),
      }),
      ...(derivatives?.notionalCurrency2 && { "Notional Currency 2": derivatives.notionalCurrency2 }),
      ...(derivatives?.fixedRateOfLeg1 && { "Fixed rate of leg 1": derivatives.fixedRateOfLeg1 }),
      ...(derivatives?.fixedRateOfLeg2 && { "Fixed rate of leg 2": derivatives.fixedRateOfLeg2 }),
      ...(derivatives?.floatingRateOfLeg2 && { "Floating rate of leg 2": derivatives.floatingRateOfLeg2 }),
      ...(derivatives?.irTermOfContractOfLeg2 && {
        "IR Term of Contract of leg 2": termIndexUnitDecode(derivatives.irTermOfContractOfLeg2),
      }),
      ...(derivatives?.fxNotionalCurrency2 && {
        "FX Notional Currency 2": derivatives.fxNotionalCurrency2,
      }),
      ...(derivatives?.fxType && { "FX Type": subField.fxType[derivatives.fxType] }),
    };

    const regulatoryData = {
      ...(instrumentData.instrumentFullName && { "Instrument Full Name": instrumentData.instrumentFullName }),
      ...(instrumentData.instrumentClassification && {
        "Instrument Classification (CFI Code)": instrumentData.instrumentClassification,
      }),
      ...(instrumentData.instrumentClassification && { "Instrument type": typeCode }),
      ...(instrumentData.instrumentClassification && { "Instrument category": categoryCode }),
      "Commodities Derivative Indicator": instrumentData.commoditiesOrEmissionDerivativeIndicator ? "Yes" : "No",
      ...(instrumentData.upcomingRCA && { "Relevant Competent Authority": countries[instrumentData.upcomingRCA].name }),
    };

    return { issueData, regulatoryData };
  }
};

export const mapTradingVenuesFunc = (tradingData: TradingVenuesInterface | undefined, cellLock?: string) => {
  const tradingVenues = {
    ...((tradingData?.tradingVenueName || cellLock) && { "Trading Venue Full Name": tradingData?.tradingVenueName }),
    ...((tradingData?.micCode || cellLock) && { "Trading Venue ID (MIC Code)": tradingData?.micCode }),
    ...((tradingData?.type || cellLock) && { "Trading Venue Type": tradingData?.type }),
    ...((tradingData?.instrumentShortName || cellLock) && {
      "Instrument Short Name (FISN)": tradingData?.instrumentShortName,
    }),
    "Request for Admission to Trading by Issuer": tradingData?.requestForAdmissionToTradingByIssuer ? "Yes" : "No",
    ...((tradingData?.approvalOfTheAdmissionToTrading || cellLock) && {
      "Date of Approval of the Admission to Trading": moment(tradingData?.approvalOfTheAdmissionToTrading).format(
        "DD/MM/YYYY"
      ),
    }),
    ...((tradingData?.requestForAdmissionToTrading || cellLock) && {
      "Date Request for Admission to Trading": moment(tradingData?.requestForAdmissionToTrading).format("DD/MM/YYYY"),
    }),
    ...((tradingData?.firstTradeDate || cellLock) && {
      "Date of Admission to Trading or Date of First Trade": moment(tradingData?.firstTradeDate).format("DD/MM/YYYY"),
    }),
    ...((tradingData?.terminationDate || cellLock) && {
      "Termination Date": moment(tradingData?.terminationDate).format("DD/MM/YYYY"),
    }),
  };

  return { tradingVenues };
};
