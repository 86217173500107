import React, { useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from "./UserSettingReminderDialog.css";
import { useHistory } from "react-router-dom";
import { SubscribeContext } from "../../common/subcriptionProvider";

const UserSettingReminderDialog = () => {
  const history = useHistory();
  const [open, setOpen] = useState<boolean>(false);
  const { currentUserData, azureProps } = useContext(SubscribeContext);

  useEffect(() => {
    if (
      azureProps.authenticationState === "Authenticated" &&
      currentUserData &&
      sessionStorage.getItem("isReminderDialogClosed") !== "true"
    ) {
      const { role, profession } = currentUserData;
      if (!role || !profession) {
        setOpen(true);
      }
    }
  }, [currentUserData, azureProps.authenticationState]);

  const handleClose = () => {
    setOpen(false);
    sessionStorage.setItem("isReminderDialogClosed", "true");
  };

  const handleRedirect = () => {
    setOpen(false);
    sessionStorage.setItem("isReminderDialogClosed", "true");
    history.push("/user-settings");
  };

  return (
    <StyledDialog className="test" onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <StyledDialogTitle id="customized-dialog-title">
        Fill your account data
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <StyledDialogContent dividers>
        <Typography gutterBottom>Go to user settings and set your role and industry.</Typography>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button disableRipple autoFocus onClick={handleRedirect} color="primary">
          Go to setting
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default UserSettingReminderDialog;
