import { TableCell, TableRow } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import {
  EmptyCard,
  IconTitle,
  LockedFileList,
  StyledCardHeader,
  StyledTable,
  StyledTableBody,
  StyledTableHead,
  StyledTableRow,
  SubjectIconStyle,
  TableWrapper,
} from "../TransparencyCalculations.css";
import { ITransparencyCalculationsFileList } from "../TransparencyCalculationsInterface";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { SubscribeContext } from "../../../../common/subcriptionProvider";
import Loader from "../../../../components/loader/Loader";
import { useLazyGetTransparencyCalculationForInstrument } from "../../../../graphql/GetTransparencyCalculationForInstrument";
import { Lock } from "@material-ui/icons";
import { TypographyStyle } from "../TransparencyCalculationDetails/TransparencyCalculationsDetails.css";
import { countMethodology } from "../TransparencyCalculationDetails/TransparencyCalculationsFunctions";

const TransparencyCalculationsFileList: React.FC<ITransparencyCalculationsFileList> = ({
  isin,
  fileSource,
  tab,
  setSelectedFile,
}) => {
  const { isUserPremium, setErrorMessage } = useContext(SubscribeContext);
  const [transparencyCalculations, setTransparencyCalculations] = useState<any>();

  const [
    getTransparencyCalculationForInstrument,
    {
      data: transparencyCalculationData,
      loading: loadingTransparencyCalculationData,
      error: transparencyCalculationDataError,
    },
  ] = useLazyGetTransparencyCalculationForInstrument(isUserPremium === true);

  useEffect(() => {
    if (tab === 3 && isin && isUserPremium && fileSource) {
      getTransparencyCalculationForInstrument({ variables: { isin: isin, fileSource: fileSource } });
    }
  }, [isin, isUserPremium, fileSource, getTransparencyCalculationForInstrument, tab]);

  useEffect(() => {
    if (transparencyCalculationDataError) {
      setErrorMessage(transparencyCalculationDataError.message);
    }
    if (transparencyCalculationData && transparencyCalculationData?.getTransparencyCalculationForInstrument) {
      setTransparencyCalculations(
        transparencyCalculationData.getTransparencyCalculationForInstrument.sort(function (a: any, b: any) {
          return new Date(b.tradingActivityPeriodEnd).getTime() - new Date(a.tradingActivityPeriodEnd).getTime();
        })
      );
    }
  }, [transparencyCalculationData, transparencyCalculationDataError, setErrorMessage, setTransparencyCalculations]);

  const handleRowClick = (e: any, calcData: any) => {
    setSelectedFile(calcData);
    const tableBody = e.target.closest("tr").closest("tbody").children;
    Object.values(tableBody).map((el: any) => el.classList.remove("selected"));
    e.target.closest("tr").classList.add("selected");
  };

  const mapLiquidityFlag = (liquidity: boolean | null) => {
    if (liquidity !== null) {
      return liquidity ? "Liquid" : "Not Liquid";
    } else {
      return "N/A";
    }
  };

  const generateTransparencyCalculationFileList = () => {
    if (!loadingTransparencyCalculationData && transparencyCalculations) {
      const isFileListEmpty = transparencyCalculations.length === 0;
      if (isFileListEmpty) {
        return (
          <EmptyCard>
            <SubjectIconStyle fontSize="large" color="inherit" />
            <IconTitle>No transparency calculations.</IconTitle>
          </EmptyCard>
        );
      } else {
        return (
          <TableWrapper>
            <StyledTable>
              <StyledTableHead>
                <TableRow>
                  <TableCell scope="col" align="center">
                    Methodology
                  </TableCell>
                  <TableCell align="center">Calculation From Date</TableCell>
                  <TableCell align="center">Calculation To Date</TableCell>
                  <TableCell align="center">Publication Date</TableCell>
                  <TableCell align="center">Liquidity Flag</TableCell>
                  <TableCell align="center">More info</TableCell>
                </TableRow>
              </StyledTableHead>
              <StyledTableBody className="fileList">
                {transparencyCalculations.map((el: any, index: number) => {
                  return (
                    <StyledTableRow key={index} onClick={(e: any) => handleRowClick(e, el)}>
                      <TableCell align="center">
                        {countMethodology(
                          el.calculationMethodology,
                          el.tradingActivityPeriodStart,
                          el.tradingActivityPeriodEnd
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {el.tradingActivityPeriodStart ? el.tradingActivityPeriodStart.slice(0, 10) : "N/A"}
                      </TableCell>
                      <TableCell align="center">
                        {el.tradingActivityPeriodEnd ? el.tradingActivityPeriodEnd.slice(0, 10) : "N/A"}
                      </TableCell>
                      <TableCell align="center">
                        {el.calculationTime ? el.calculationTime.slice(0, 10) : "N/A"}
                      </TableCell>
                      <TableCell align="center">{mapLiquidityFlag(el.liquidity)}</TableCell>
                      <TableCell align="center">
                        <ArrowRightIcon />
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              </StyledTableBody>
            </StyledTable>
          </TableWrapper>
        );
      }
    } else {
      return <Loader />;
    }
  };

  return (
    <>
      <StyledCardHeader
        title={
          <TypographyStyle variant="h5">
            {fileSource === "ESMA" ? "EU" : "UK"} - Transparency Calculations - files ({fileSource})
          </TypographyStyle>
        }
      />
      {isUserPremium ? (
        generateTransparencyCalculationFileList()
      ) : (
        <LockedFileList>
          <Lock />
        </LockedFileList>
      )}
    </>
  );
};

export default TransparencyCalculationsFileList;
