import { Card } from "@material-ui/core";
import styled from "styled-components";
import mediaQuery from "../../common/mediaQuery";

export const StyledCard = styled(Card)`
  h6 {
    font-size: 1.1rem;
    margin-left: 40px;
    font-weight: 600;
    line-height: 2.5;
  }

  span {
    font-size: 0.95rem;
    font-weight: 400;
    margin-left: 80px;
    line-height: 2.5;
  }

  ul {
    max-height: 300px;
    overflow-y: auto;
  }

  ul::-webkit-scrollbar {
    width: 0.7em;
  }

  ul::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    border-radius: 30px;
  }

  ul::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: none;
    border-radius: 30px;
  }

  li {
    padding: 0;
  }

  li:hover {
    background: #d3d3d35e;
  }

  h5:first-child {
    padding-bottom: 5px;
  }

  h5:nth-child(2n) {
    padding: 5px 0;
  }

  ul {
    padding: 0;
  }

  ul div {
    margin: 0;
  }

  a:link {
    color: #3f18aa;
  }

  a:visited {
    color: #3f18aa;
  }

  a:hover {
    color: black;
  }

  a:active {
    color: #3f18aa;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    h6 {
      font-size: 0.8rem;
      margin-left: 15px;
    }

    ul {
      max-height: 100px;
    }

    li {
      padding-bottom: 10px;
    }

    span {
      margin-left: 25px;
      font-size: 0.7rem;
      line-height: 1.3;
    }
  }

  ${mediaQuery.SM} {
    h6 {
      font-size: 0.8rem;
      margin-left: 15px;
    }

    ul {
      max-height: 100px;
    }

    li {
      padding-bottom: 10px;
    }

    span {
      margin-left: 25px;
      font-size: 0.7rem;
      line-height: 1.3;
    }
  }
`;

export const CardTitle = styled.div`
  display: inline-block;
  font-weight: 700;
  font-size: 24px;
  padding: 16px;

  ${mediaQuery.SM_LANDSCAPE} {
    font-size: 1.1rem;
  }

  ${mediaQuery.SM} {
    font-size: 1.1rem;
  }
`;

export const TooltipTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
`;
