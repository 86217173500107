import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { InstrumentInterface, InstrumentResourceParametersInput } from "../common/types";

const checkIfIsExtendedQuery = (extended: boolean) => {
  const GET_RECENT_INSTRUMENTS = gql`
        query getRecentInstruments(
            $numberOfRecords: Int
            $randomize: Boolean
            $resourceParameters: InstrumentResourceParametersInput!
            $startingRecord: Int!
        ) {
            getRecentInstruments(
                numberOfRecords: $numberOfRecords
                randomize: $randomize
                resourceParameters: $resourceParameters
                startingRecord: $startingRecord
            ) {
                instruments {
                    upcomingRCA
                    instrumentIdentificationCode
                    notionalCurrency
                    instrumentFullName
                    firstTradeDate
                    terminationDate
                    maturityDate
                    nominalValuePerUnit @include(if: ${extended})
                    totalIssuedNominalAmount @include(if: ${extended})
                    tradingVenues @include(if: ${extended})
                    instrumentClassification
                }
            }
        }
    `;
  return GET_RECENT_INSTRUMENTS;
};

interface Params {
  numberOfRecords: number;
  randomize: boolean;
  resourceParameters: InstrumentResourceParametersInput;
  startingRecord: number;
}

interface GetRecentInstruments {
  getRecentInstruments: {
    instruments: InstrumentInterface;
  };
}

export const useLazyGetRecentInstrumentsQuery = (extended: boolean) =>
  useLazyQuery<GetRecentInstruments, Params>(checkIfIsExtendedQuery(extended));
