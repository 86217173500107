import React from "react";
import {
  BorderLinearContainer,
  BorderLinearProgress,
  Legend,
  SubTitle,
  Title,
} from "./TransparencyLinearProgressBar.css";
import { ProgressBarPropsInterface } from "./TransparencyLinearProgressInterface";

const TransparencyLinearProgressBar: React.FC<ProgressBarPropsInterface> = ({
  progressData: { progress, startDate, endDate, publicationDate },
}) => {
  return (
    <BorderLinearContainer>
      <Legend>
        <Title>
          <SubTitle>Publication Date: </SubTitle>
          {publicationDate ? publicationDate : "N/A"}
        </Title>
      </Legend>
      <BorderLinearProgress variant="determinate" value={progress} />
      <Legend>
        <Title>
          <SubTitle>Calculation From Date: </SubTitle>
          {startDate ? startDate : "N/A"}
        </Title>
        <Title>
          <SubTitle>Calculation To Date: </SubTitle>
          {endDate ? endDate : "N/A"}
        </Title>
      </Legend>
    </BorderLinearContainer>
  );
};

export default TransparencyLinearProgressBar;
