import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { GetTradingVenuesInterface } from "../components/TradingVenues/TradingVenuesInterface";

const GET_SELECTABLE_TRADING_VENUES = gql`
  query getSelectableTradingVenues($isin: String) {
    getSelectableTradingVenues(isin: $isin) {
      micCode
      name
      isActive
    }
  }
`;

interface Param {
  isin: string;
}

interface GetSelectableTradingVenuesInterface {
  getSelectableTradingVenues: GetTradingVenuesInterface[];
}

export const useLazyGetSelectableTradingVenues = () =>
  useLazyQuery<GetSelectableTradingVenuesInterface, Param>(GET_SELECTABLE_TRADING_VENUES);
