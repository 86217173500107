import { makeStyles } from "@material-ui/core/styles";
import {createMuiTheme} from "@material-ui/core/styles";
import theme from "../../common/theme";

export const DataCardStyles = makeStyles({
  root: {
    height: "100%",
  },
  row: {
    "borderBottomColor": theme.palette.divider,
    "borderBottom": "1px solid",
    "height": theme.spacing(4),
    "&:last-child": {
      borderBottom: "none",
    },
  },
  keyCell: {
    width: "50%",
    borderBottom: "0",
    paddingLeft: "0",
    color: theme.palette.text.primary,
    fontSize: "14px",
    fontWeight: 700,
    whiteSpace: "nowrap",
  },
  valueCell: {
    width: "50%",
    borderBottom: "0",
    paddingLeft: "0",
    textAlign: "left",
    fontSize: "14px",
  },
});

export const themeTypography = createMuiTheme({
  typography: {
    h5: {
      fontWeight: 700,
    },
  },
});
