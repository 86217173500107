import { Grid } from "@material-ui/core";
import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { SubscribeContext } from "../../common/subcriptionProvider";
import { Page } from "../../components";
import Loader from "../../components/loader/Loader";
import Promo from "../../components/Promo/Promo";
import RecentInstrumentsTable from "../../components/RecentInstrumentsTable/RecentInstrumentsTable";
import WelcomeCard from "../../components/WelcomeCard/WelcomeCard";
import FavouriteInstrumentsTable from "../FavouriteInstrumentsPage/FavouriteInstrumentsTable";
import { DashboardPageStyles, DashboardWrapper } from "./DashboardPage.css";

function DashboardPage() {
  const {
    userSubscription,
    isFirstLogin,
    azureProps: { authenticationState, error },
  } = useContext(SubscribeContext);

  const classes = DashboardPageStyles();
  const directLink = sessionStorage.getItem("directLinkPathName");

  if (authenticationState === "Authenticated" && directLink && directLink !== "/") {
    sessionStorage.removeItem("directLinkPathName");
    return <Redirect to={directLink} />;
  }

  if (authenticationState === "Authenticated" && userSubscription === false && isFirstLogin && !error) {
    return <Redirect to="/subscription-payment" />;
  } else if (
    (userSubscription === null || isFirstLogin === null) &&
    authenticationState === "Authenticated" &&
    !error
  ) {
    return (
      <Page>
        <Loader />
      </Page>
    );
  } else {
    return (
      <Page>
        <DashboardWrapper container spacing={2}>
          <Grid container item spacing={2} xl={8} className={classes.leftColumn}>
            <Grid item xs={12} xl={12}>
              <RecentInstrumentsTable />
            </Grid>
          </Grid>
          <Grid item container spacing={2} lg={12} xl={4} className={classes.rightColumn}>
            <Grid item xs={12} xl={12}>
              <WelcomeCard />
            </Grid>
            <Grid item xs={12} xl={12}>
              <FavouriteInstrumentsTable />
            </Grid>
            <Grid item xs={12} xl={12}>
              <Promo />
            </Grid>
          </Grid>
        </DashboardWrapper>
      </Page>
    );
  }
}

export { DashboardPage };
