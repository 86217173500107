import styled from "styled-components";
import { Card, Chip, FormControl, Select, Table, TableCell, TableRow } from "@material-ui/core";
import mediaQuery from "../../../common/mediaQuery";
import { DatePicker } from "@material-ui/pickers";

interface IStyledTable {
  isfilter: number;
}

interface SelectStyleInterface {
  disabled: boolean;
}

export const StyledCardWrapper = styled(Card)`
  .MuiCardHeader-root {
    padding: 16px 0 10px 16px;
  }

  .MuiCardContent-root {
    position: relative;
    padding: 0 10px 35px 10px;
    min-height: 300px;
    & > button {
      top: unset;
      bottom: 18px;
    }
  }

  th {
    vertical-align: bottom;
  }

  th:not(:first-child):not(:last-child) {
    white-space: nowrap;
    font-weight: 600;
  }

  th:first-child,
  td:first-child {
    text-align: center;
    width: 125px;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 300px;
  }

  th:nth-child(3),
  td:nth-child(3) {
    width: 260px;
  }

  th:nth-child(4),
  td:nth-child(4) {
    width: 240px;
  }

  th:last-child,
  td:last-child {
    text-align: center;
    width: 100px;
  }

  td {
    padding: 10px 16px;
    vertical-align: middle !important;
  }

  tr:last-child > td {
    border-bottom: none;
  }

  th:nth-child(5),
  td:nth-child(5) {
    width: 170px;
  }

  th:nth-child(6),
  td:nth-child(6) {
    width: 240px;
  }

  ${mediaQuery.XL} {
    .MuiCardContent-root {
      overflow-x: scroll;
    }
  }

  ${mediaQuery.SM} {
    th,
    td {
      white-space: nowrap;
      min-width: auto !important;
      max-width: none !important;
    }

    th {
      font-size: 0.8rem;
    }

    td {
      font-size: 0.7rem;
    }
  }
`;

export const StyledTable = styled(Table)<IStyledTable>`
  margin-top: ${({ isfilter }) => (isfilter ? "0" : "25px")};
  margin-bottom: 40px;
`;

export const StyledTableRow = styled(TableRow)`
  &[data-active="false"] > td {
    color: #d8d8d8;
  }
`;

export const StyledFilterRow = styled(TableRow)`
  th {
    padding: 10px 0;
  }
`;

export const StyledSelect = styled(Select)<SelectStyleInterface>`
  background-color: ${(props) => (props.disabled ? "#e3e3e3" : "")};

  .MuiSelect-select:focus {
    background: none;
  }
`;

export const StyledFormControl = styled(FormControl)`
  display: flex;
  min-width: 200px;
  max-width: 200px;

  div:nth-child(2n) {
    max-height: 30px;
  }

  label {
    top: -13px;
    font-size: 1rem;
    color: #b7b7b6;
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, 3px) scale(0.75);
  }

  span {
    color: #959595;
    font-size: 12px;
    font-weight: 500;
    margin-left: 5px;
    line-height: 1.7;
  }

  ${mediaQuery.SM} {
    & label {
      font-size: 0.65rem;
      top: -11px;
    }

    div:nth-child(2n) {
      max-height: 28px;
    }
  }
`;

interface DisableInterface {
  isUserPremium?: boolean | null;
  disabled?: boolean;
}

export const StyledDatePicker = styled(DatePicker)<DisableInterface>`
  & ::after,
  & ::before {
    display: none;
  }

  border-radius: 4px;
  border: 1px solid #70707080;
  margin: 2px 5px 2px 0;
  padding: 0 5px;
  height: 31px;
  width: 100%;

  & input {
    font-size: 14px;
  }

  background-color: ${(props) => (props.disabled ? "#e3e3e3" : "")};

  ${mediaQuery.SM} {
    height: 28px;
    width: 80px;

    & input {
      font-size: 0.65rem;
    }
  }
`;

export const LoaderOverlay = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

export const EmptyCard = styled.div`
  text-align: center;
  color: #cbcbcb;
  margin: 11px 0;
`;

export const Message = styled.div`
  font-size: 16px;
  font-weight: 700;
`;

interface IStyledSort {
  sortIconRotation: number;
  isUserPremium: boolean | null;
}

export const StyledSort = styled.div<IStyledSort>`
  display: flex;
  justify-content: flex-start;
  cursor: pointer;

  span {
    margin-right: 8px;
  }

  svg {
    transition: 0.7s;
    transform: ${({ sortIconRotation }) => (sortIconRotation === 0 ? "rotate(180deg)" : "rotate(0)")};
  }
  ${mediaQuery.SM} {
    padding: 0 10px 0 10px;
  }
`;

export const StyledSortCell = styled(TableCell)``;

export const StyledTradingVenueFilter = styled(TableCell)``;

export const ActiveFiltersContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Filter = styled.div`
  margin-right: 10px;
`;

export const StyledChip = styled(Chip)`
  background: #b498ff29;
  margin-right: 5px;
  height: 25px;

  & svg {
    color: #b8aae0;
  }
`;

export const ClearFilters = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: #3f18aa;
  white-space: nowrap;
  cursor: pointer;
`;

export const TablePaginationWrapper = styled.div`
  margin: 0 20px 20px 0;
`;
