import { Box, Grid, Typography } from "@material-ui/core";
import SubjectIcon from "@material-ui/icons/Subject";
import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import { SubscribeContext } from "../../common/subcriptionProvider";
import { Page } from "../../components";
import CustomFlag from "../../components/CustomFlag";
import InformationCard from "../../components/InformationCard/InformationCard";
import InstrumentsTables from "../../components/InstrumentList/InstrumentsTables";
import MaturingInstruments from "../../components/MaturingInstruments/MaturingInstruments";
import StructureBasic from "../../components/StructureBasic/StructureBasic";
import { useLazyGetIssuerInfoByLeiQuery } from "../../graphql/GetIssuerInfoByLei";
import { AntTab, AntTabs, DisabledTab } from "../InstrumentPage/InstrumentPage.css";
import IssuerStructure from "./CompanyStructureTab/IssuerStructure";
import { mapGleifData } from "./IssuerPageFunctions";
import IssuerPageLeiSearch from "./LeiSearchTab/IsserLeiSearch";
import {
  CardStyle,
  EmptyCard,
  GridContentWrapper,
  IssuerPageHeaderWrapper,
  LoaderWrapper,
  Message,
  SingleNoInstrumentTableWrapper,
  SubTitle,
  TablesWrapper,
  TopTablesWrapper,
  useStyles,
} from "./LeiSearchTab/Issuer.css";

function IssuerPage(props: RouteComponentProps<{ lei: string }>) {
  const history = useHistory();
  const location = useLocation();
  const { setErrorMessage } = useContext(SubscribeContext);
  const [tab, setTab] = useState(0);
  const [issuer, setIssuer] = useState<any>();
  const [isStructureBasic, setStructureBasic] = useState<boolean>(true);
  const [isStructureBasicLoaded, setIsStructureBasicLoaded] = useState<boolean>(false);
  const [isStructureBasicEmpty, setIsStructureBasicEmpty] = useState<boolean>(true);
  const [isMaturingInstrumentsLoaded, setMaturingInstrumentsLoaded] = useState<boolean>(false);
  const [isEquityEmpty, setIsEquityEmpty] = useState<boolean | null>(null);
  const [isDebtEmpty, setIsDebtEmpty] = useState<boolean | null>(null);
  const [isOtherEmpty, setIsOtherEmpty] = useState<boolean | null>(null);
  const [isSingleNoInstrumentVisible, setIsSingleNoInstrumentVisible] = useState<string | null>(null);
  const [isScroll, setIsScroll] = useState<boolean>(false);
  const [isAnyFilterSelected, setIsAnyFilterSelected] = useState(false);
  const [isIssuerDataLoaded, setIsIssuerDataLoaded] = useState(false);

  const { match } = props;
  const lei = match.params.lei;

  const [
    getIssuerInfoByLei,
    { data: IssuerInfoByLei, loading: loadingIssuerInfoByLei, error: getIssuerInfoByLeiError },
  ] = useLazyGetIssuerInfoByLeiQuery();

  useEffect(() => {
    sessionStorage.setItem("Issuer_LEI_for_filters", JSON.stringify({ lei }));
    getIssuerInfoByLei({ variables: { lei: lei } });
  }, [getIssuerInfoByLei, lei]);

  useEffect(() => {
    setIsIssuerDataLoaded(false);
  });

  useEffect(() => {
    setIsStructureBasicLoaded(false);
    setMaturingInstrumentsLoaded(false);
    setIsStructureBasicEmpty(true);
  }, [lei, setIsStructureBasicLoaded, setMaturingInstrumentsLoaded, setIsStructureBasicEmpty]);

  useEffect(() => {
    if (loadingIssuerInfoByLei) {
      setIsEquityEmpty(null);
      setIsDebtEmpty(null);
      setIsOtherEmpty(null);
      setIsSingleNoInstrumentVisible(null);
      setTimeout(() => {
        setIssuer(null);
      }, 0);
    }
  }, [location.pathname, loadingIssuerInfoByLei]);

  const classes = useStyles();

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
    window.scrollTo(0, 0);
    if (newValue !== tab && newValue === 0) {
      history.push(`/issuer/${lei}`, 0);
    } else if (newValue !== tab && newValue === 1) {
      history.push(`/issuer/${lei}`, 1);
    } else if (newValue !== tab && newValue === 2) {
      history.push(`/issuer/${lei}`, 2);
    }
  };

  useEffect(() => {
    if (isEquityEmpty && isDebtEmpty && isOtherEmpty) {
      setIsSingleNoInstrumentVisible("true");
    } else if (isEquityEmpty === false || isDebtEmpty === false || isOtherEmpty === false) {
      setIsSingleNoInstrumentVisible("false");
    }
  }, [isEquityEmpty, isDebtEmpty, isOtherEmpty]);

  useEffect(() => {
    if (getIssuerInfoByLeiError) {
      setErrorMessage(getIssuerInfoByLeiError.message);
    }
    if (IssuerInfoByLei && IssuerInfoByLei.getIssuerByLEI) {
      setIsIssuerDataLoaded(true);
      const { entityNew, lei, registrationNew } = IssuerInfoByLei.getIssuerByLEI;
      if (entityNew && lei && registrationNew) {
        setIssuer(mapGleifData(IssuerInfoByLei.getIssuerByLEI));
      } else {
        return;
      }
    }
  }, [IssuerInfoByLei, getIssuerInfoByLeiError, setErrorMessage]);

  useEffect(() => {
    if (!isStructureBasic) {
      setTab(0);
    }
  }, [isStructureBasic, isStructureBasicLoaded]);

  useEffect(() => {
    if (history.action === "POP") {
      if (history.location.state === 1 && history.location.state !== undefined) {
        setTab(1);
      } else if (history.location.state === 2 && history.location.state !== undefined) {
        setTab(2);
      } else if (history.location.state === undefined || history.location.state === 0) {
        setTab(0);
      }
    }
  }, [history.location.state, history.action]);

  return (
    <Page>
      <IssuerPageHeaderWrapper>
        {IssuerInfoByLei && issuer && issuer.title && issuer.title.country ? (
          <>
            <Box display="flex" alignItems="center" mb={3}>
              <CustomFlag code={issuer.title.country} alt={issuer.title.country} />
              <Box>
                <Typography variant="h4" className={classes.title}>
                  {issuer.title.legalName}
                </Typography>
                <Typography variant="h5">
                  <SubTitle>LEI</SubTitle>
                  {`${issuer.title.lei} - ${issuer.title.entityStatus}`}
                </Typography>
              </Box>
            </Box>
            <DisabledTab>
              <AntTabs
                value={tab}
                onChange={handleTabChange}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#3F18AA",
                  },
                }}
              >
                <AntTab label="Overview" />
                <AntTab
                  label="Corporate Group Issues"
                  disabled={isStructureBasicEmpty || !(isMaturingInstrumentsLoaded && isStructureBasicLoaded)}
                />
                <AntTab
                  label="Company Information"
                  disabled={!(isMaturingInstrumentsLoaded && isStructureBasicLoaded)}
                />
              </AntTabs>
            </DisabledTab>
          </>
        ) : (
          loadingIssuerInfoByLei && (
            <Typography variant="h1" className={classes.title}>
              Loading data...
            </Typography>
          )
        )}
      </IssuerPageHeaderWrapper>
      <Box hidden={tab !== 0} className={classes.root}>
        <GridContentWrapper item container spacing={2}>
          <TopTablesWrapper container item spacing={2} xs={12} xl={4}>
            {IssuerInfoByLei && issuer && issuer.mainInformation && (
              <>
                <Grid item xs={12} xl={12}>
                  <MaturingInstruments lei={lei} setMaturingInstrumentsLoaded={setMaturingInstrumentsLoaded} />
                </Grid>
                <Grid item xs={12} xl={12}>
                  <InformationCard
                    title="Company Information"
                    rows={issuer.mainInformation}
                    handleClick={handleTabChange}
                  />
                </Grid>
                <Grid item xs={12} xl={12}>
                  <StructureBasic
                    lei={lei}
                    isIssuerDataLoaded={isIssuerDataLoaded}
                    handleTabChange={handleTabChange}
                    isStructureBasic={isStructureBasic}
                    setStructureBasic={setStructureBasic}
                    setIsStructureBasicLoaded={setIsStructureBasicLoaded}
                    setIsStructureBasicEmpty={setIsStructureBasicEmpty}
                  />
                </Grid>
              </>
            )}
          </TopTablesWrapper>
          {isSingleNoInstrumentVisible === null && !loadingIssuerInfoByLei ? (
            <LoaderWrapper item xs={12} xl={7}>
              <Typography variant="h1" className={classes.title}>
                Loading data...
              </Typography>
            </LoaderWrapper>
          ) : null}
          <SingleNoInstrumentTableWrapper
            issinglenoinstrumentvisible={isSingleNoInstrumentVisible}
            isanyfilterselected={isAnyFilterSelected ? 1 : 0}
            item
            xs={12}
            xl={8}
          >
            <CardStyle>
              <EmptyCard>
                <SubjectIcon fontSize="large" color="inherit" />
                <Message>No instruments</Message>
              </EmptyCard>
            </CardStyle>
          </SingleNoInstrumentTableWrapper>
          <TablesWrapper
            issinglenoinstrumentvisible={isSingleNoInstrumentVisible}
            isanyfilterselected={isAnyFilterSelected ? 1 : 0}
            container
            item
            spacing={2}
            xs={12}
            xl={8}
          >
            <InstrumentsTables
              lei={lei}
              table={"EQUITY"}
              setEmpty={setIsEquityEmpty}
              setTab={setTab}
              setIsAnyFilterSelected={setIsAnyFilterSelected}
            />
            <InstrumentsTables
              lei={lei}
              table={"DEBT"}
              setEmpty={setIsDebtEmpty}
              setTab={setTab}
              setIsAnyFilterSelected={setIsAnyFilterSelected}
            />
            <InstrumentsTables
              lei={lei}
              table={"OTHER"}
              setEmpty={setIsOtherEmpty}
              setTab={setTab}
              setIsAnyFilterSelected={setIsAnyFilterSelected}
            />
          </TablesWrapper>
        </GridContentWrapper>
      </Box>
      <Box hidden={tab !== 1}>
        <IssuerStructure
          lei={lei}
          setTab={setTab}
          tab={tab}
          handleTabChange={handleTabChange}
          setIsScroll={setIsScroll}
        />
      </Box>
      <Box hidden={tab !== 2}>
        {!loadingIssuerInfoByLei && issuer ? (
          <IssuerPageLeiSearch
            lei={lei}
            tab={tab}
            informationData={issuer}
            isScroll={isScroll}
            setIsScroll={setIsScroll}
          />
        ) : (
          <LoaderWrapper item xs={12} xl={7}>
            <Typography variant="h1" className={classes.title}>
              Loading Issuer...
            </Typography>
          </LoaderWrapper>
        )}
      </Box>
    </Page>
  );
}

export { IssuerPage };
