import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { QueryInterface, RequestInterface } from "../components/TradingVenues/TradingVenuesInterface";

const GET_TRADING_VENUES = gql`
  query getTradingVenue($isin: String, $tradingVenueMICCode: String) {
    getTradingVenue(isin: $isin, tradingVenueMICCode: $tradingVenueMICCode) {
      approvalOfTheAdmissionToTrading
      firstTradeDate
      instrumentShortName
      micCode
      requestForAdmissionToTrading
      requestForAdmissionToTradingByIssuer
      terminationDate
      tradingVenueName
      type
    }
  }
`;

export const useLazyGetTradingVenuesQuery = () => useLazyQuery<RequestInterface, QueryInterface>(GET_TRADING_VENUES);
