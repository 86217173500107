import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { InstrumentNew, InstrumentResourceParametersInput } from "../common/types";

const queryAuth = (isPremium: any) => {
  const GET_INSTRUMENT_SUGGESTIONS_RESULT = gql`
    query getInstrumentSuggestionsResult(
      $numberOfRecords: Int
      $startingCharacters: String!
      $resourceParameters: InstrumentResourceParametersInput
      $startingRecord: Int!
    ) {
      getInstrumentSuggestionsResult(
        numberOfRecords: $numberOfRecords
        startingCharacters: $startingCharacters
        resourceParameters: $resourceParameters
        startingRecord: $startingRecord
      ) {
        instruments {
          upcomingRCA
          instrumentFullName
          firstTradeDate
          terminationDate
          maturityDate
          notionalCurrency
          instrumentIdentificationCode
          totalIssuedNominalAmount @include(if: ${isPremium})
          nominalValuePerUnit @include(if: ${isPremium})
        }
      }
    }
  `;
  return GET_INSTRUMENT_SUGGESTIONS_RESULT;
};

interface Params {
  numberOfRecords: number;
  startingCharacters: string;
  resourceParameters: InstrumentResourceParametersInput;
  startingRecord: number;
}

interface GetInstrumentSuggestionsResult {
  getInstrumentSuggestionsResult: {
    instruments: InstrumentNew[];
  };
}

export const useLazyInstrumentSuggestionResultQuery = (isPremium: any) =>
  useLazyQuery<GetInstrumentSuggestionsResult, Params>(queryAuth(isPremium));
