import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  makeStyles,
  MenuItem,
} from "@material-ui/core";
import styled from "styled-components";
import mediaQuery from "../../common/mediaQuery";

export const StyledEditButton = styled(Button)`
  border-radius: 10px;
`;

export const StyledForm = styled.form`
  min-width: 600px;
  display: flex;
  flex-wrap: wrap;
`;

export const StyledFormControl = styled(FormControl)`
  min-width: 600px;
  padding-bottom: 15px;
  width: 100%;

  ${mediaQuery.MD} {
    min-width: unset;
  }

  ${mediaQuery.SM} {
    min-width: unset;
    padding-bottom: 5px;

    label {
      font-size: 0.8rem;
    }
  }
`;
export const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    max-width: 800px;
  }
  ${mediaQuery.MD} {
    .MuiDialog-paper {
      width: 100%;
    }
  }
  ${mediaQuery.SM} {
    .MuiDialog-paper {
      width: 100%;
    }
    .MuiDialogContent-root {
      padding: 8px 12px;
    }
  }
`;
export const StyledDialogTitle = styled(DialogTitle)`
  ${mediaQuery.SM} {
    padding: 12px 0px 0 12px;
    h2 {
      font-size: 1rem;
    }
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  ${mediaQuery.SM} {
    border-bottom: 1px solid #eee;
    font-size: 12px;
    white-space: pre-wrap;
    min-height: unset;
  }
`;

export const DialogActionsWrapper = styled(DialogActions)`
  ${mediaQuery.SM} {
    button {
      font-size: 0.7rem;
    }
  }
`;

export const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down("md")]: {
    dropdownStyle: {
      maxHeight: 350,
      overflowY: "scroll",
    },
  },
}));
