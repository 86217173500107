import { Link, makeStyles, Tab, Tabs } from "@material-ui/core";
import styled from "styled-components";
import mediaQuery from "../../common/mediaQuery";
import theme from "../../common/theme";
interface LinkComponent {
  component: any;
  to: any;
}

export const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  button: {
    margin: theme.spacing(1),
  },
});

export const AntTabs = styled(Tabs)`
  border-bottom: 2px solid #dedcdc;
  margin-bottom: 25px;

  .MuiTabs-indicator {
    background-color: #3f18aa;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    margin-bottom: 0;
  }
  ${mediaQuery.SM} {
    margin-bottom: 0;
  }
`;

export const AntTab = styled(Tab)`
  text-transform: none;
  min-width: 72px;
  font-weight: 400;
  margin-right: 32px;
  font-family: Roboto;

  &:hover {
    color: #3f18aa;
    opacity: 1;
  }

  &.Mui-selected {
    color: #3f18aa;
    font-weight: 500;
  }

  &.Mui-disabled,
  &.Mui-disabled:hover {
    color: #b9b9b9;
    font-weight: 400;
    cursor: not-allowed;
    pointer-events: all;
  }
`;

export const DisabledTab = styled.div`
  ${mediaQuery.SM} {
    & div {
      overflow-x: scroll;
    }
  }
`;

export const InstrumentPageHeader = styled.div`
  img {
    height: 85px;
    margin-right: 15px;
  }

  ${mediaQuery.MD} {
    & > div:first-child {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-bottom: 8px;

      & button {
        order: 2;
        margin-top: 10px;
      }

      & h6 {
        display: inline-block;
        width: 100%;
      }
    }
  }

  ${mediaQuery.SM_LANDSCAPE} {
    img {
      height: 60px;
    }

    h4 {
      font-size: 1.3rem;
      font-weight: 600;
      display: inline-block;
      width: 100%;
    }

    h5 {
      font-size: 0.7rem;
      display: inline-block;
      width: 100%;
    }

    h6 {
      font-size: 0.7rem;
      font-weight: 600;
    }

    button {
      font-size: 0.7rem;
    }
  }

  ${mediaQuery.SM} {
    img {
      height: 50px;
    }

    td {
      white-space: normal;
    }

    .MuiGrid-spacing-xs-2 > .MuiGrid-item {
      padding: 5px;
    }

    .MuiCard-root {
      height: auto !important;
      overflow-x: scroll;
    }

    h4 {
      font-size: 1.3rem;
      font-weight: 600;
      display: inline-block;
      width: 100%;
    }

    h5 {
      font-size: 0.7rem;
      display: inline-block;
      width: 100%;
    }

    h6 {
      font-size: 0.7rem;
      font-weight: 600;
    }

    button {
      font-size: 0.7rem;
    }
  }
`;

export const InstrumentPageContent = styled.div`
  ${mediaQuery.SM_LANDSCAPE} {
    h5 {
      font-size: 1.1rem;
      white-space: nowrap;
    }

    td,
    th {
      font-size: 10px;
    }

    & td:first-child {
      width: 40%;
      white-space: inherit;
      vertical-align: top;
    }

    .MuiCardHeader-root {
      padding: 10px 0 0 16px;
    }

    .MuiCardContent-root {
      padding-top: 10px;
    }

    .MuiGrid-spacing-xs-2 {
      width: 100%;
      margin: 0;
      padding: 8px 0;
    }
  }

  ${mediaQuery.SM} {
    h5 {
      font-size: 1.1rem;
      white-space: nowrap;
    }

    td,
    th {
      font-size: 10px;
    }

    & td:first-child {
      width: 40%;
      white-space: inherit;
      vertical-align: top;
    }

    .MuiCardHeader-root {
      padding: 10px 0 0 16px;
    }

    .MuiCardContent-root {
      padding-top: 10px;
    }

    .MuiGrid-spacing-xs-2 {
      width: 100%;
      margin: 0;
      padding: 8px 0;
    }
  }
`;

export const InstrumentPageTabs = styled.div`
  ${mediaQuery.SM_LANDSCAPE} {
    .MuiTabs-root {
      margin-top: 10px;
      min-height: 0;
    }

    .MuiTabs-root button {
      font-size: 0.55rem;
      max-width: 100px;
      margin-right: 0;
      padding: 10px 0;
    }

    .MuiTab-root {
      font-size: 0.7rem;
      min-height: 0;
    }

    .MuiBox-root {
      margin-bottom: 0;
    }
  }

  ${mediaQuery.SM} {
    .MuiTabs-root {
      margin-top: 10px;
      min-height: 0;
    }

    .MuiTabs-root button {
      font-size: 0.55rem;
      max-width: 100px;
      margin-right: 0;
      padding: 10px 0;
    }

    .MuiTab-root {
      font-size: 0.7rem;
      min-height: 0;
    }

    .MuiBox-root {
      margin-bottom: 0;
    }
  }
`;

export const LinkStyle = styled(Link)<LinkComponent>`
  background-color: #efe6ff;
  border-radius: 12px;
  padding: 3px 15px 3px 15px;
  color: #000;
  margin: 0 0 0 -15px;
  height: auto;

  &:hover {
    background-color: #e5d6ff;
    text-decoration: none;
  }

  ${mediaQuery.SM} {
    display: block;
  }
`;

export const LoadingMessage = styled.span`
  font-size: 22px;
  font-weight: 700;
`;
