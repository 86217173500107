export const nameIndxData = {
  EONA: "EONIA",
  EONS: "EONIA SWAP",
  EURI: "EURIBOR",
  EUUS: "EURODOLLAR",
  EUCH: "EuroSwiss",
  GCFR: "GCF REPO",
  ISDA: "ISDAFIX",
  LIBI: "LIBID",
  LIBO: "LIBOR",
  MAAA: "Muni AAA",
  PFAN: "Pfandbriefe",
  TIBO: "TIBOR",
  STBO: "STIBORBBSW – BBSW",
  JIBA: "JIBAR",
  BUBO: "BUBOR",
  CDOR: "CDOR",
  CIBO: "CIBOR",
  MOSP: "MOSPRIM",
  NIBO: "NIBOR",
  PRBO: "PRIBOR",
  TLBO: "TELBOR",
  WIBO: "WIBOR",
  TREA: "Treasury",
  SWAP: "SWAP",
  FUSW: "Future SWAP",
  OTHR: "OTHER",
};

export const SeniorityBondData = {
  SNDB: "Senior Debt",
  MZZD: "Mezzanine",
  SBOD: "Subordinated Debt",
  JUND: "Junior Debt",
};
