import styled, { css } from "styled-components";
import mediaQuery from "../../common/mediaQuery";
import { Button, IconButton, makeStyles } from "@material-ui/core";
import theme, { MuiMainBackgroundColor } from "../../common/theme";
import SearchIcon from "@material-ui/icons/Search";

export const useStyles = makeStyles({
  root: {
    padding: "0",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  error: {
    borderColor: theme.palette.error.light,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  sugestionList: {
    padding: 0,
  },
  sugestionItem: {
    display: "block",
  },
  sugestionSecondary: {
    color: "rgba(0, 0, 0, 0.54)",
  },
});

export const SearchFieldWrapper = styled.div`
  input {
    padding: 11px 0 10px 16px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    height: 100%;
    min-width: 230px;
    border: 0;
    flex: 1;
  }

  ${mediaQuery.MD} {
    input {
      min-width: auto;
    }

    & div:first-child button:last-child {
      padding: 5px 20px;
    }

    & div:first-child button {
      padding: 0 10px 0 25px;
    }

    input {
      font-size: 15px;
    }
    svg {
      font-size: 1.9rem;
    }
  }

  ${mediaQuery.SM_LANDSCAPE} {
    input {
      min-width: auto;
    }

    & div:first-child button:last-child {
      padding: 5px 10px;
    }

    & div:first-child button {
      padding: 0 0 0 5px;
    }

    input {
      font-size: 12px;
    }
    svg {
      font-size: 1.7rem;
    }
  }

  ${mediaQuery.SM} {
    input {
      min-width: auto;
    }

    & div:first-child button:last-child {
      padding: 5px 10px;
    }

    & div:first-child button {
      padding: 0 0 0 5px;
    }

    input {
      font-size: 12px;
    }
    svg {
      font-size: 1.7rem;
    }
  }
`;

interface StyledSelectInterface {
  filter: string;
}

export const StyledSelect = styled(Button)<StyledSelectInterface>`
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background: #dbdbdb;
  padding: 0 25px;

  span {
    ${(props) =>
      props.filter.length === 3
        ? css`
            width: 100%;
          `
        : props.filter.length === 13
        ? css`
            width: 200px;
          `
        : css`
            width: 255px;
          `}
  }
`;

export const SearchButton = styled(IconButton)`
  width: 75px;
  background-color: ${MuiMainBackgroundColor};
  border-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 100%;
  padding: 12px 20px;

  & > * {
    pointer-events: none;
  }

  &:hover {
    background-color: ${MuiMainBackgroundColor};
  }
`;

export const StyledSearchIcon = styled(SearchIcon)`
  color: white;
`;
