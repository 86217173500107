import { Table, TableBody, TableRow, ThemeProvider, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { SubscribeContext } from "../../../common/subcriptionProvider";
import Loader from "../../../components/loader/Loader";
import PaymentCardElement from "../../../components/PaymentCard/PaymentCardElement";
import { useLazyGetCustomerQuery } from "../../../graphql/GetCustomer";
import { useLazyGetPremiumPlanQuery } from "../../../graphql/GetPrepiumPlan";
import { useLazyGetSubscriptionDetailsQuery } from "../../../graphql/GetSubscriptionDetails";
import SubscriptionButtonsWrapper from "../SubscriptionButtons/SubscriptionButtonsWrapper";
import { themeTypography } from "../UserSettingsPage.css";
import { createNextBillingInformation, createPaymentInformation } from "./../UserSettingsPageFunctions";
import { StyledActivePeriodTypography, StyledTableCell, UserSubscriptionDetails } from "./SubscriptionDetailsCard.css";

const SubscriptionDetailsCard = () => {
  const { isUserPremium, isStamdataUser, userSubscription, setErrorMessage } = useContext(SubscribeContext);
  const [isSubscriptionCanceled, setIsSubscriptionCanceled] = useState<boolean>();
  const [subscriptionDetails, setSubscriptionDetails] = useState<any>({ nextBilling: "", paymentInformation: "" });
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);

  const [
    getSubscriptionDetails,
    { data: subscriptionDetailsData, error: getSubscriptionDetailsError },
  ] = useLazyGetSubscriptionDetailsQuery({
    fetchPolicy: "no-cache",
  });

  const [getPremiumPlan, { data: premiumPlanData, error: getPremiumPlanError }] = useLazyGetPremiumPlanQuery();

  const [getCustomer, { data: customerData, error: getCustomerDataError }] = useLazyGetCustomerQuery();

  useEffect(() => {
    if (
      getSubscriptionDetails &&
      getPremiumPlan &&
      getCustomer &&
      userSubscription === true &&
      isUserPremium !== null
    ) {
      getCustomer();
      getSubscriptionDetails();
      getPremiumPlan();
    }
    if (userSubscription === false) {
      setIsDataLoaded(true);
    }
  }, [getSubscriptionDetails, getPremiumPlan, getCustomer, userSubscription, isUserPremium]);

  useEffect(() => {
    if (getPremiumPlanError) {
      setErrorMessage(getPremiumPlanError.message);
    }
    if (getSubscriptionDetailsError) {
      setErrorMessage(getSubscriptionDetailsError.message);
    }
    if (getCustomerDataError) {
      setErrorMessage(getCustomerDataError.message);
    }
    if (subscriptionDetailsData && premiumPlanData && customerData) {
      setIsDataLoaded(true);
      const { status, cancelAtPeriodEnd, activeUntil } = subscriptionDetailsData.getSubscriptionDetails;
      const { brand, last4Digits, expMonth, expYear } = customerData.getCustomer.paymentMethodDetails;
      const { price, currency } = premiumPlanData.getPremiumPlan;
      setSubscriptionDetails({
        nextBilling: createNextBillingInformation({ price, currency, activeUntil }),
        paymentInformation: createPaymentInformation({ brand, last4Digits, expMonth, expYear }),
        paymentStatus: status,
      });
      setIsSubscriptionCanceled(status === "canceled" || cancelAtPeriodEnd);
    }
  }, [
    premiumPlanData,
    subscriptionDetailsData,
    customerData,
    getPremiumPlanError,
    getSubscriptionDetailsError,
    getCustomerDataError,
  ]);

  const generateDetailsTable = () => {
    return (
      <Table>
        <TableBody>
          <TableRow>
            <StyledTableCell>Next Billing</StyledTableCell>
            <StyledTableCell>
              <span>
                <strong>{isSubscriptionCanceled ? "Subscription canceled" : subscriptionDetails.nextBilling}</strong>
              </span>
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>Payment Method</StyledTableCell>
            <StyledTableCell>Card</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>Payment Information</StyledTableCell>
            <StyledTableCell>{subscriptionDetails.paymentInformation}</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>Subscription payment status</StyledTableCell>
            <StyledTableCell>{subscriptionDetails.paymentStatus}</StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  const generateSubscriptionDetailCard = () => {
    return (
      <>
        <ThemeProvider theme={themeTypography}>
          <Typography variant="h5">Subscription</Typography>
          <Typography variant="h4">{isUserPremium ? "Premium" : "Basic"}</Typography>
        </ThemeProvider>
        {generateDetailsTable()}
        <SubscriptionButtonsWrapper
          setIsSubscriptionCanceled={setIsSubscriptionCanceled}
          isSubscriptionCanceled={isSubscriptionCanceled}
        />
      </>
    );
  };

  const generatePaymentCard = () => {
    return (
      <>
        {isUserPremium ? (
          <ThemeProvider theme={themeTypography}>
            <Typography variant="h5">Subscription</Typography>
            <StyledActivePeriodTypography variant="h4">
              Premium{" "}
              <strong>{isStamdataUser ? "Trial period end date : 01/08/2021" : "Unlimited premium account"}</strong>
            </StyledActivePeriodTypography>
          </ThemeProvider>
        ) : (
          <PaymentCardElement />
        )}
      </>
    );
  };

  return (
    <>
      {isDataLoaded ? (
        <UserSubscriptionDetails>
          {userSubscription ? generateSubscriptionDetailCard() : generatePaymentCard()}
        </UserSubscriptionDetails>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default SubscriptionDetailsCard;
