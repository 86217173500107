import { CircularProgress, MenuItem, Select } from "@material-ui/core";
import React from "react";
import {
  ArrowBackIosIconStyle,
  ArrowForwardIosIconStyle,
  FormControlStyle,
  NavigationArrows,
  PaginationContainer,
  PaginationLoader,
  Range,
  Title,
} from "./TablePagination.css";
import { TablePaginationInterface } from "./TablePaginationInterface";

const TablePagination: React.FC<TablePaginationInterface> = ({
  isNewInstrumentMonitor,
  rowsPerPage,
  handleRowsChange,
  page,
  setPage,
  totalInstrumentElements,
  loading,
}) => {
  const limitPage = Math.floor(totalInstrumentElements / rowsPerPage);

  const minItemsNumber = isNewInstrumentMonitor ? 20 : 10;
  const isShown = totalInstrumentElements > minItemsNumber;

  const handleNextPage = () => {
    if (page < limitPage) {
      setPage(page + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const fromInstrument = page === 0 ? 1 : page * rowsPerPage + 1;
  const toInstrument = page === 0 ? rowsPerPage : page * rowsPerPage + rowsPerPage;

  return (
    <PaginationContainer>
      {!loading
        ? isShown && (
            <>
              <Title>Rows per page:</Title>
              <FormControlStyle>
                <Select id="SelectRowsPerPage" value={rowsPerPage} onChange={handleRowsChange}>
                  {isNewInstrumentMonitor
                    ? [20, 35, 50].map((item: number) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))
                    : [10, 25, 50].map((item: number) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                </Select>
              </FormControlStyle>
              <Range>
                {`${fromInstrument} - ${
                  toInstrument < totalInstrumentElements ? toInstrument : totalInstrumentElements
                } of ${totalInstrumentElements}`}
              </Range>
              <NavigationArrows>
                <ArrowBackIosIconStyle onClick={handlePrevPage} disabled={page > 0} />
                <ArrowForwardIosIconStyle onClick={handleNextPage} disabled={page < limitPage} />
              </NavigationArrows>
            </>
          )
        : isShown && (
            <PaginationLoader>
              <CircularProgress size={25} />
            </PaginationLoader>
          )}
    </PaginationContainer>
  );
};

export default TablePagination;
