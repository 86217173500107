import { Dialog, TableCell, TableRow } from "@material-ui/core";
import styled from "styled-components";
import mediaQuery from "../../common/mediaQuery";

export const DialogStyle = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    max-width: 900px;
  }
`;
export const DialogContent = styled.div`
  padding: 32px;

  ${mediaQuery.MD} {
    padding: 16px;
  }
`;

export const DialogHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 20px;

  div:nth-child(1) {
    text-decoration: underline;
  }

  ${mediaQuery.MD} {
    font-size: 0.9rem;
  }
`;

export const MainTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  margin-top: 15px;

  ${mediaQuery.MD} {
    font-size: 0.9rem;
  }
`;

export const SubTitle = styled.div`
  font-size: 17px;
  font-weight: 700;

  ${mediaQuery.MD} {
    font-size: 0.9rem;
  }
`;

export const Text = styled.div`
  margin: 5px 0;
  font-size: 15px;

  a {
    word-break: break-all;
    color: #3f18aa;
    text-decoration: none;
  }

  ${mediaQuery.MD} {
    font-size: 0.7rem;
  }
`;

export const TableRowStyle = styled(TableRow)`
  ${mediaQuery.MD} {
    td {
      font-size: 0.7rem;
    }
  }
`;

export const TableCellKey = styled(TableCell)`
  font-weight: 700;

  ${mediaQuery.MD} {
    padding: 6px 3px;
  }
`;

export const TableCellVal = styled(TableCell)`
  ${mediaQuery.MD} {
    padding: 6px 3px;

    &.MuiTableCell-sizeSmall:last-child {
      padding: 6px 3px;
    }
  }
`;
