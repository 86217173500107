import { CardHeader, TableCell, TableRow, Tooltip } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SubjectIcon from "@material-ui/icons/Subject";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import Flag from "react-world-flags";
import { SubscribeContext } from "../../common/subcriptionProvider";
import {
  CATEGORY_CODE,
  CATEGORY_COL,
  getInstrCode,
  instrCategoryFields,
  instrColumnMap,
  instrTypeFields,
  TYPE_CODE,
  TYPE_COL,
} from "../../components/InstrumentsTypeAndCategory/InstrumentSelectFunctions";
import LinearProgressBar from "../../components/LinearProgressBar/LinearProgressBar";
import Loader from "../../components/loader/Loader";
import { Page } from "../../components/Page/Page";
import TablePagination from "../../components/TablePagination/TablePagination";
import { useLazyGetAdditionalDataForRecentInstrumentsQuery } from "../../graphql/GetAdditionalDataForRecentInstruments";
import { useLazyGetRecentInstrumentsQuery } from "../../graphql/GetRecentInstruments";
import { TradingVenue, useLazyGetTradingVenuesForCodesQuery } from "../../graphql/GetTradingVenuesForCodes";
import { calcDaysProgress } from "../../utils/calcDates";
import { formatAmount } from "../../utils/formatAmount";
import NewInstrumentFilter from "./NewInstrumentFilter";
import {
  ActiveFiltersContainer,
  CardContentStyle,
  ClearFilters,
  EmptyCard,
  Filter,
  LoaderOverlay,
  Message,
  RecentInstrumentsCardWrapper,
  StyledBox,
  StyledChip,
  StyledTable,
  StyledTableBody,
  StyledTableHead,
  StyledTradingVenueCodeWithIcon,
  StyledTradingVenueName,
  TablePeriodCell,
  TableRowStyle,
  TooltipTitle,
  TypographyStyle,
} from "./NewInstrumentMonitorPage.css";
import { FiltersInterface, IRecentInstrumentsRows } from "./NewInstrumentMonitorPageInterface";

const NewInstrumentMonitorPage: React.FC = () => {
  const history = useHistory();

  const { isUserPremium, setErrorMessage } = useContext(SubscribeContext);

  const tableHeaders = [
    "Country",
    "Currency",
    "Trading Venue",
    "Instrument Type",
    "Instrument Category",
    "Name",
    "Period",
    "Value Per Unit",
    "Issued Amount",
  ];

  const [instrumentsData, setInstrumentsData] = useState<any>();
  const [additionalRecentInstrumentData, setAdditionalRecentInstrumentData] = useState<any>();
  const [selectedTradingVenueFullName, setSelectedTradingVenueFullName] = useState<any>("");
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(35);
  const [country, setCountry] = useState<string>("");
  const [currency, setCurrency] = useState<string>("");
  const [tradingVenue, setTradingVenue] = useState<string>("");
  const [instrumentType, setInstrumentType] = useState<any>("");
  const [instrumentCategory, setInstrumentCategory] = useState<string>("");
  const [isFilter, setIsFilter] = useState<number>(0);
  const [filtersInitData, setFiltersInitData] = useState<any>(null);
  const sessionInitialFilters: any = JSON.parse(sessionStorage.getItem("NIM_initial_filters") as string);

  const [
    getRecentInstruments,
    { data: recentInstruments, loading: loadingRecentInstruments, error: getRecentInstrumentsError },
  ] = useLazyGetRecentInstrumentsQuery(true);

  const [
    getAdditionalDataForRecentInstruments,
    {
      data: additionalData,
      loading: loadingAdditionDataForRecentInstruments,
      error: getAdditionalDataForRecentInstrumentsError,
    },
  ] = useLazyGetAdditionalDataForRecentInstrumentsQuery(isUserPremium !== null ? isUserPremium : false);

  const [
    getTradingVenuesForCodes,
    { data: tradingVenuesForCodes, loading: loadingTradingVenuesForCodes, error: getTradingVenuesForCodeError },
  ] = useLazyGetTradingVenuesForCodesQuery();

  const handleRedirectInstrument = (isin: string) => {
    history.push(`/instrument/${isin}`);
  };

  const handleDeleteFilterCountry = () => {
    setCountry("");
    setPage(0);
  };

  const handleDeleteFilterCurrency = () => {
    setCurrency("");
    setPage(0);
  };

  const handleDeleteFilterTradingVenue = () => {
    setTradingVenue("");
    setSelectedTradingVenueFullName("");
    setPage(0);
  };

  const handleDeleteFilterType = () => {
    setInstrumentType("");
    setInstrumentCategory("");
    setPage(0);
  };

  const handleDeleteFilterCategory = () => {
    setInstrumentCategory("");
    setPage(0);
  };

  const handleClearFilters = () => {
    setCountry("");
    setCurrency("");
    setTradingVenue("");
    setInstrumentType("");
    setInstrumentCategory("");
    setSelectedTradingVenueFullName("");
    setPage(0);
  };

  const handleRowsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPage(0);
    setRowsPerPage(event.target.value as number);
  };

  const filters = [
    {
      name: "Country",
      value: country,
      chipsValue: country,
      handler: handleDeleteFilterCountry,
    },
    {
      name: "Currency",
      value: currency,
      chipsValue: currency,
      handler: handleDeleteFilterCurrency,
    },
    {
      name: "Trading Venue",
      value: tradingVenue,
      chipsValue: selectedTradingVenueFullName,
      handler: handleDeleteFilterTradingVenue,
    },
    {
      name: "Instrument Type",
      value: instrumentType,
      chipsValue: instrumentType,
      handler: handleDeleteFilterType,
    },
    {
      name: "Instrument Category",
      value: instrumentCategory,
      chipsValue: instrumentCategory,
      handler: handleDeleteFilterCategory,
    },
  ];

  const instrCodeParam = () => {
    if (instrumentType && instrumentCategory) {
      return getInstrCode(instrCategoryFields, instrumentCategory, CATEGORY_COL, CATEGORY_CODE);
    }
    return getInstrCode(instrTypeFields, instrumentType, TYPE_COL, TYPE_CODE);
  };

  useEffect(() => {
    const sessionFiltersArr: any = JSON.parse(sessionStorage.getItem("NIM_filters") as string);
    const isSessionFilter = sessionFiltersArr && sessionFiltersArr.filter((item: any) => item.value).length;
    setIsFilter(isSessionFilter);
    if (isSessionFilter) {
      setCountry(sessionFiltersArr[0].value);
      setCurrency(sessionFiltersArr[1].value);
      setTradingVenue(sessionFiltersArr[2].value);
      setSelectedTradingVenueFullName(sessionFiltersArr[2].chipsValue);
      setInstrumentType(sessionFiltersArr[3].value);
      setInstrumentCategory(sessionFiltersArr[4].value);
    }
  }, []);

  useEffect(() => {
    const isFilter = filters.filter((item) => item.value).length;
    setIsFilter(isFilter);
    sessionStorage.setItem("NIM_filters", JSON.stringify(filters));
  }, [instrumentCategory, instrumentType, tradingVenue, currency, country]);

  useEffect(() => {
    getRecentInstruments({
      variables: {
        numberOfRecords: rowsPerPage,
        randomize: isFilter ? false : true,
        resourceParameters: {
          instrumentClassificationPrefix: instrCodeParam(),
          instrumentStatus: "LISTED",
          currency,
          authority: country,
          tradingVenue,
        },
        startingRecord: page === 0 ? 1 : page * rowsPerPage + 1,
      },
    });
  }, [
    getRecentInstruments,
    page,
    instrumentType,
    instrumentCategory,
    currency,
    country,
    tradingVenue,
    rowsPerPage,
    isFilter,
  ]);

  useEffect(() => {
    getAdditionalDataForRecentInstruments({
      variables: {
        resourceParameters: {
          instrumentClassificationPrefix: instrCodeParam(),
          instrumentStatus: "LISTED",
          currency,
          authority: country,
          tradingVenue,
        },
      },
    });
  }, [instrumentType, instrumentCategory, currency, country, tradingVenue, getAdditionalDataForRecentInstruments]);

  useEffect(() => {
    if (getRecentInstrumentsError) {
      setErrorMessage(getRecentInstrumentsError.message);
    }
    if (recentInstruments && recentInstruments?.getRecentInstruments) {
      setInstrumentsData(recentInstruments.getRecentInstruments.instruments);
    }
  }, [recentInstruments, getRecentInstrumentsError, setErrorMessage]);

  const micCodesFullNames = useMemo(() => {
    if (getTradingVenuesForCodeError) {
      setErrorMessage(getTradingVenuesForCodeError.message);
    }
    if (tradingVenuesForCodes && tradingVenuesForCodes.getTradingVenuesForCodes) {
      const micCodesFullNameList: any = {};
      tradingVenuesForCodes.getTradingVenuesForCodes.map(({ name, micCode }: TradingVenue) => {
        micCodesFullNameList[micCode] = name;
      });
      return micCodesFullNameList;
    }
  }, [tradingVenuesForCodes, getTradingVenuesForCodeError, setErrorMessage]);

  useEffect(() => {
    if (getAdditionalDataForRecentInstrumentsError) {
      setErrorMessage(getAdditionalDataForRecentInstrumentsError.message);
    }
    if (additionalData && additionalData.getAdditionalDataForRecentInstruments) {
      const { tradingVenues, currencies, countries } = additionalData.getAdditionalDataForRecentInstruments;
      setAdditionalRecentInstrumentData(additionalData.getAdditionalDataForRecentInstruments);

      if (!sessionInitialFilters && !micCodesFullNames && tradingVenues) {
        getTradingVenuesForCodes({ variables: { micCodes: tradingVenues } });
      } else if (sessionInitialFilters && sessionInitialFilters.tradingVenues) {
        getTradingVenuesForCodes({ variables: { micCodes: sessionInitialFilters.tradingVenues } });
      }

      if (sessionInitialFilters) {
        setFiltersInitData(sessionInitialFilters);
      }

      if (!sessionInitialFilters && currencies && countries && tradingVenues) {
        sessionStorage.setItem("NIM_initial_filters", JSON.stringify({ currencies, countries, tradingVenues }));
        setFiltersInitData({ tradingVenues, currencies, countries });
      }
    }
  }, [additionalData, getAdditionalDataForRecentInstrumentsError, setErrorMessage]);

  return (
    <Page>
      <RecentInstrumentsCardWrapper>
        <CardHeader
          title={
            <StyledBox>
              <TypographyStyle variant="h5">New Listings and Instrument Monitor - last 30 days</TypographyStyle>
            </StyledBox>
          }
        />
        <CardContentStyle>
          <>
            <ActiveFiltersContainer>
              {isFilter > 0 &&
                filters.map((filter: FiltersInterface) => {
                  return filter.chipsValue ? (
                    <Filter key={filter.name}>
                      <StyledChip label={`${filter.name}: ${filter.chipsValue}`} onDelete={filter.handler} />
                    </Filter>
                  ) : null;
                })}
              {isFilter > 1 && <ClearFilters onClick={handleClearFilters}>Clear filters</ClearFilters>}
            </ActiveFiltersContainer>
            <StyledTable isfilter={isFilter ? 1 : 0} size="small">
              <StyledTableHead>
                <TableRow>
                  {tableHeaders.map((header: string) => (
                    <TableCell key={header}>{header}</TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <NewInstrumentFilter
                    country={country}
                    currency={currency}
                    tradingVenue={tradingVenue}
                    micCodesFullNames={micCodesFullNames}
                    instrumentType={instrumentType}
                    instrumentCategory={instrumentCategory}
                    setCountry={setCountry}
                    setCurrency={setCurrency}
                    setTradingVenue={setTradingVenue}
                    setInstrumentType={setInstrumentType}
                    setInstrumentCategory={setInstrumentCategory}
                    selectedTradingVenueFullName={selectedTradingVenueFullName}
                    setSelectedTradingVenueFullName={setSelectedTradingVenueFullName}
                    filtersInitData={filtersInitData}
                    setPage={setPage}
                  />
                </TableRow>
              </StyledTableHead>
              <StyledTableBody loading={loadingRecentInstruments ? 1 : 0}>
                {micCodesFullNames &&
                  instrumentsData &&
                  instrumentsData.length > 0 &&
                  instrumentsData.map((row: IRecentInstrumentsRows) => {
                    const daysProgress = calcDaysProgress(row.firstTradeDate, row.maturityDate, row.terminationDate);
                    const tradingVenueArray = row.tradingVenues.split(",");
                    const instrType = instrColumnMap(row.instrumentClassification, TYPE_COL);
                    const instrCategory = instrColumnMap(row.instrumentClassification, CATEGORY_COL);
                    return (
                      <TableRowStyle
                        key={row.instrumentIdentificationCode}
                        hover
                        onClick={() => handleRedirectInstrument(row.instrumentIdentificationCode)}
                      >
                        <TableCell id="new_instrument_monitor__instrument" align="center">
                          {row.upcomingRCA ? (
                            <Flag code={row.upcomingRCA} alt={row.upcomingRCA} style={{ width: "38px" }} />
                          ) : (
                            <Flag code={"EU"} alt={"EU"} style={{ width: "38px" }} />
                          )}
                        </TableCell>
                        <TableCell id="new_instrument_monitor__instrument">{row.notionalCurrency}</TableCell>
                        <Tooltip
                          title={
                            tradingVenueArray.length > 1 ? (
                              <TooltipTitle>{`${row.tradingVenues.slice(5)}`}</TooltipTitle>
                            ) : (
                              ""
                            )
                          }
                        >
                          <TableCell id="new_instrument_monitor__instrument">
                            <StyledTradingVenueName>
                              {tradingVenue
                                ? micCodesFullNames[tradingVenue]
                                : micCodesFullNames[row.tradingVenues.split(",")[0]]}
                            </StyledTradingVenueName>
                            {tradingVenueArray.length > 1 ? (
                              <StyledTradingVenueCodeWithIcon>
                                {tradingVenue ? tradingVenue : row.tradingVenues.split(",")[0]}
                                <ExpandMoreIcon />
                              </StyledTradingVenueCodeWithIcon>
                            ) : (
                              <span>{tradingVenue ? tradingVenue : row.tradingVenues.split(",")[0]}</span>
                            )}
                          </TableCell>
                        </Tooltip>
                        <TableCell id="new_instrument_monitor__instrument">{instrType}</TableCell>
                        <TableCell id="new_instrument_monitor__instrument">{instrCategory}</TableCell>
                        <TableCell id="new_instrument_monitor__instrument">
                          <p>{row.instrumentIdentificationCode}</p>
                          <p>{row.instrumentFullName}</p>
                        </TableCell>
                        {daysProgress !== 0 ? (
                          <TablePeriodCell>
                            <LinearProgressBar
                              progress={daysProgress}
                              firstTradeDate={row.firstTradeDate}
                              maturityDate={row.maturityDate}
                              terminationDate={row.terminationDate}
                            />
                          </TablePeriodCell>
                        ) : (
                          <TableCell id="new_instrument_monitor__instrument"></TableCell>
                        )}
                        <TableCell id="new_instrument_monitor__instrument" align="right">
                          {row.nominalValuePerUnit ? formatAmount(parseFloat(row.nominalValuePerUnit)) : "N/A"}
                        </TableCell>
                        <TableCell id="new_instrument_monitor__instrument" align="right">
                          {row.totalIssuedNominalAmount
                            ? formatAmount(parseFloat(row.totalIssuedNominalAmount))
                            : "N/A"}
                        </TableCell>
                      </TableRowStyle>
                    );
                  })}
              </StyledTableBody>
            </StyledTable>
          </>
          {(loadingRecentInstruments || loadingTradingVenuesForCodes || loadingAdditionDataForRecentInstruments) && (
            <LoaderOverlay>
              <Loader />
            </LoaderOverlay>
          )}
          {!loadingRecentInstruments && instrumentsData && instrumentsData.length === 0 && (
            <EmptyCard>
              <SubjectIcon fontSize="large" color="inherit" />
              <Message>No results</Message>
            </EmptyCard>
          )}
          <TablePagination
            isNewInstrumentMonitor
            rowsPerPage={rowsPerPage}
            handleRowsChange={handleRowsChange}
            page={page}
            setPage={setPage}
            totalInstrumentElements={additionalRecentInstrumentData?.totalIssuedAmount}
            loading={loadingAdditionDataForRecentInstruments}
          />
        </CardContentStyle>
      </RecentInstrumentsCardWrapper>
    </Page>
  );
};

export { NewInstrumentMonitorPage };
