import { Dialog, DialogContent, DialogTitle, Snackbar } from "@material-ui/core";
import styled from "styled-components";
import mediaQuery from "../../common/mediaQuery";

export const Footer = styled.div`
  margin-top: auto;
  background-color: #dcdddb;
  padding: 20px 0 5px;
  width: 100%;
`;

export const FooterContent = styled.div`
  display: flex;

  ${mediaQuery.LG} {
    flex-direction: column;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    padding: 0 10px;

    svg {
      height: 50px;
    }
  }

  ${mediaQuery.SM} {
    padding: 0 10px;

    svg {
      height: 50px;
    }
  }
`;

export const Text = styled.div`
  margin-bottom: 5px;

  a {
    color: #3f18aa;
    text-decoration: none;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    font-size: 0.55rem;
  }

  ${mediaQuery.SM} {
    font-size: 0.55rem;
  }
`;

export const TextContainer = styled.div`
  width: 65%;

  ${mediaQuery.LG} {
    width: 100%;
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  text-align: end;
  width: 35%;

  a {
    color: #3f18aa;
    text-decoration: none;
  }
  div {
    color: #3f18aa;
    cursor: pointer;
  }
  ${mediaQuery.LG} {
    flex-direction: row;
    width: 100%;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    div:first-child {
      text-align: left;
    }
    div:last-child {
      text-align: right;
    }
  }

  ${mediaQuery.SM} {
    div:first-child {
      text-align: left;
    }
    div:last-child {
      text-align: right;
    }
  }
`;

export const LinksSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;

  ${mediaQuery.LG} {
    flex-direction: column;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    font-size: 10px;
  }

  ${mediaQuery.SM} {
    font-size: 10px;
  }
`;

export const StyledCookieBar = styled(Snackbar)`
  left: 0;
  right: auto;
  bottom: 0;
  border-radius: 0;

  & > div:first-child {
    width: 100vw;
    height: 85px;
    padding: 10px 70px;
    font-size: 17px;
    display: flex;
    flex-wrap: nowrap;
  }

  .MuiButton-root {
    font-size: 17px;
    height: 41px;
    min-height: 50px;
    padding: 0 18px;
  }

  .MuiButton-root:first-child {
    color: black;
    background: #f5f5f5;
  }

  .MuiButton-root:nth-child(2) {
    color: white;
    background: #3f18aa;
  }

  .MuiSnackbarContent-root {
    border-radius: 0;
  }

  .MuiSnackbarContent-action {
    min-width: 260px;
    max-width: 260px;
    justify-content: space-between;
  }

  p > span {
    text-decoration: underline;
    cursor: pointer;
  }

  ${mediaQuery.MD} {
    & > div:first-child {
      padding: 5px;
      height: auto;
      padding-bottom: 5px;
      flex-wrap: wrap;
    }

    p {
      margin: 0;
      font-size: 11px;
    }

    .MuiButton-root {
      font-size: 10px;
      min-height: 29px;
      height: 29px;
    }

    .MuiSnackbarContent-action {
      min-width: 200px;
      max-width: 200px;
      margin: 0 auto;
    }
  }

  ${mediaQuery.SM_LANDSCAPE} {
    & > div:first-child {
      height: auto;
      padding-bottom: 5px;
      flex-wrap: wrap;
    }

    p {
      margin: 0;
      padding: 0 5px;
      font-size: 9px;
    }

    .MuiButton-root {
      font-size: 10px;
      min-height: 29px;
      height: 29px;
      padding: 0 12px;
    }

    .MuiSnackbarContent-action {
      min-width: 200px;
      max-width: 200px;
      margin: 0 auto;
    }
  }

  ${mediaQuery.SM} {
    & > div:first-child {
      height: auto;
      padding-bottom: 5px;
      flex-wrap: wrap;
    }

    p {
      margin: 0;
      padding: 0 5px;
      font-size: 9px;
    }

    .MuiButton-root {
      font-size: 10px;
      min-height: 29px;
      height: 29px;
      padding: 0 12px;
    }

    .MuiSnackbarContent-action {
      min-width: 200px;
      max-width: 200px;
      margin: 0 auto;
    }
  }
`;

export const StyledDialogTitle = styled(DialogTitle)`
  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    padding: 0px 10px;

    h2 {
      font-size: 13px;
    }

    svg {
      font-size: 1.2rem;
    }
  }

  ${mediaQuery.SM} {
    padding: 0px 10px;

    h2 {
      font-size: 13px;
    }

    svg {
      font-size: 1.2rem;
    }
  }
`;

export const StyledDialog = styled(Dialog)`
  .MuiTypography-gutterBottom {
    margin-bottom: 1.35em;
  }
`;

export const StyledDialogContent = styled(DialogContent)`
  ${mediaQuery.SM_LANDSCAPE} {
    max-height: 55vh;

    p {
      font-size: 0.65rem;
    }
  }

  ${mediaQuery.SM} {
    max-height: 55vh;

    p {
      font-size: 0.65rem;
    }
  }
`;
