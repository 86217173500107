import React from "react";
import { Page } from "../../components";
import FavouriteInstrumentsTable from "./FavouriteInstrumentsTable";

const FavouriteInstrumentsPage: React.FC = () => {
  return (
    <Page>
      <FavouriteInstrumentsTable />
    </Page>
  );
};

export { FavouriteInstrumentsPage };
