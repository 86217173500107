import { Grid } from "@material-ui/core";
import React from "react";
import { DataCard } from "../../../components";
import InstrumentDetails from "../../../components/InstrumentDetails/InstrumentDetails";
import LinearProgressBar from "../../../components/LinearProgressBar/LinearProgressBar";
import TradingVenues from "../../../components/TradingVenues/TradingVenues";
import UnderlyingInstruments from "../../../components/UnderlyingInstruments/UnderlyingInstruments";
import { GridRight, ProgressBarContainer } from "./InstrumentMainDetails.css";
import { InstrumentMainDetailsProps } from "./InstrumentMainDetailsInterface";

const InstrumentMainDetails: React.FC<InstrumentMainDetailsProps> = ({
  instrumentData,
  loadingGleifData,
  loadingInstrumentData,
  issuerRows,
  calcDaysProgress,
  underlyingInstruments,
  setTab,
  tab,
  isin,
  setIsTradingVenueDisabled,
  setIsQueriesDataLoaded,
}) => {
  const daysProgress = calcDaysProgress(
    instrumentData?.firstTradeDate,
    instrumentData?.maturityDate,
    instrumentData?.terminationDate
  );
  return (
    <Grid container spacing={2}>
      <Grid container item spacing={2} md={6}>
        <Grid item xs={12}>
          <DataCard title="Issuer" loading={loadingGleifData} rows={issuerRows} />
        </Grid>
        <Grid item xs={12}>
          <InstrumentDetails instrumentData={instrumentData} loadingInstrumentData={loadingInstrumentData} />
        </Grid>
      </Grid>
      <GridRight container item spacing={2} md={6}>
        {daysProgress !== 0 && instrumentData && (
          <Grid item xs={12}>
            <ProgressBarContainer>
              <LinearProgressBar
                isMainDetails
                progress={daysProgress}
                firstTradeDate={instrumentData.firstTradeDate}
                maturityDate={instrumentData.maturityDate}
                terminationDate={instrumentData.terminationDate}
              />
            </ProgressBarContainer>
          </Grid>
        )}
        <Grid item xs={12}>
          <TradingVenues
            tab={tab}
            setTab={setTab}
            isin={isin}
            setIsTradingVenueDisabled={setIsTradingVenueDisabled}
            setIsQueriesDataLoaded={setIsQueriesDataLoaded}
          />
        </Grid>
        <Grid item xs={12}>
          <UnderlyingInstruments underlyingIsins={underlyingInstruments} />
        </Grid>
      </GridRight>
    </Grid>
  );
};

export default InstrumentMainDetails;
