import { Lock } from "@material-ui/icons";
import React from "react";
import { LockWrapper } from "./LockCell.css";

const LockCell: React.FC = () => {
  return (
    <LockWrapper>
      <Lock />
    </LockWrapper>
  );
};

export default LockCell;
