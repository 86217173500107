import { CardContent, CardHeader, FormControl, Table, TableRow } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useMedia from "use-media";
import { SubscribeContext } from "../../common/subcriptionProvider";
import { useLazyGetSelectableTradingVenues } from "../../graphql/getSelectableTradingVenues";
import { useLazyGetTradingVenuesQuery } from "../../graphql/GetTradingVenue";
import { mapTradingVenuesFunc } from "../InstrumentDetails/InstrumentFunctions";
import FilterLoader from "../loader/FilterLoader";
import Loader from "../loader/Loader";
import {
  CardStyle,
  Container,
  LinkStyle,
  SelectItem,
  SelectStyle,
  StyledMenuItem,
  StyledTableBody,
  TableCellStyle,
  Title,
  TitleContainer,
  TitleDropdownWrapper,
  TypographyStyle,
} from "./TradingVenues.css";
import { GetTradingVenuesInterface, PropsInterface } from "./TradingVenuesInterface";
const TradingVenues: React.FC<PropsInterface> = ({
  tab,
  setTab,
  isin,
  setIsTradingVenueDisabled,
  setIsQueriesDataLoaded,
}) => {
  const [tradingData, setTradingData] = useState<any>();
  const [tradingSelect, setTradingSelect] = useState<any>();
  const [tradingVenue, setTradingVenue] = useState<any>();

  const history = useHistory();
  const { setErrorMessage } = useContext(SubscribeContext);

  const tradingVenuesFields: any = tradingData && mapTradingVenuesFunc(tradingData).tradingVenues;
  const dataFields = tradingVenuesFields;

  const mediaXL = useMedia({ maxWidth: 1219 });
  const alphabeticOrder = function (a: any, b: any) {
    if (a.micCode < b.micCode) {
      return -1;
    }
    if (a.micCode > b.micCode) {
      return 1;
    }
    return 0;
  };
  const [
    getTradingVenue,
    { data: tradingVenueData, loading: loadingTradingVenue, error: getTradingVenueError },
  ] = useLazyGetTradingVenuesQuery();
  const [
    getSelectableTradingVenues,
    { data: tradingVenueSelectData, loading: loadingTradingVenueSelect, error: getSelectableTradingVenuesError },
  ] = useLazyGetSelectableTradingVenues();

  useEffect(() => {
    if (isin) {
      setTradingVenue("");
      setTradingSelect([]);
    }
  }, [isin]);

  useEffect(() => {
    if (tradingVenue) {
      getTradingVenue({ variables: { isin, tradingVenueMICCode: tradingVenue } });
    }
  }, [isin, tradingVenue, getTradingVenue]);

  useEffect(() => {
    getSelectableTradingVenues({ variables: { isin } });
  }, [isin, getSelectableTradingVenues]);

  useEffect(() => {
    if (getTradingVenueError) {
      setErrorMessage(getTradingVenueError.message);
    }
    if (tradingVenueData) {
      setTradingData(tradingVenueData.getTradingVenue);
      setIsQueriesDataLoaded((prevState: any) => ({
        ...prevState,
        getTradingVenue: true,
      }));
    }
  }, [tradingVenueData, getTradingVenueError]);

  useEffect(() => {
    if (getSelectableTradingVenuesError) {
      setErrorMessage(getSelectableTradingVenuesError.message);
    }
    if (
      tradingVenueSelectData &&
      tradingVenueSelectData.getSelectableTradingVenues &&
      tradingVenueSelectData.getSelectableTradingVenues.length
    ) {
      const tradingVenueSelect: any = tradingVenueSelectData.getSelectableTradingVenues;
      const initTradingVenueVal = tradingVenueSelect[0].micCode;
      const isSingleFutureTradingVenue = tradingVenueSelectData.getSelectableTradingVenues.filter(
        (el: any) => el.name !== null
      ).length;

      setIsQueriesDataLoaded((prevState: any) => ({
        ...prevState,
        getSelectableTradingVenues: true,
      }));

      if (!isSingleFutureTradingVenue) {
        setIsTradingVenueDisabled(true);
      }
      setTradingVenue(initTradingVenueVal);
      const sortedActive = tradingVenueSelect.filter((e: any) => e.isActive).sort(alphabeticOrder);
      const sortedInactive = tradingVenueSelect.filter((e: any) => !e.isActive).sort(alphabeticOrder);
      setTradingSelect([...sortedActive, ...sortedInactive]);
    } else if (
      tradingVenueSelectData &&
      tradingVenueSelectData.getSelectableTradingVenues &&
      !tradingVenueSelectData.getSelectableTradingVenues.length
    ) {
      setIsTradingVenueDisabled(true);
    }
  }, [tradingVenueSelectData, getSelectableTradingVenuesError]);

  const handleVenueChange = (e: any) => {
    setTradingVenue(e.target.value as string);
  };

  const handleTabChange = (newValue: number) => {
    setTab(newValue);
    window.scrollTo(0, 0);
    if (newValue !== tab) {
      history.push(`/instrument/${isin}`, 2);
    }
  };

  return (
    <CardStyle>
      <CardHeader
        title={
          <TitleContainer>
            <Container>
              <TitleDropdownWrapper media={mediaXL ? 1 : 0}>
                <TypographyStyle variant="h5">Trading venue(s)</TypographyStyle>
                <FormControl variant="outlined">
                  <SelectStyle
                    id="TradingVenueSelect"
                    value={tradingSelect ? tradingVenue : ""}
                    onChange={handleVenueChange}
                    disabled={false}
                  >
                    {tradingSelect && !loadingTradingVenueSelect ? (
                      tradingSelect.map((item: GetTradingVenuesInterface) => (
                        <StyledMenuItem key={item.micCode} value={item.micCode} data-active={item.isActive}>
                          <SelectItem>
                            <span>{item.name}</span>
                            <span>{item.micCode}</span>
                          </SelectItem>
                        </StyledMenuItem>
                      ))
                    ) : (
                      <FilterLoader />
                    )}
                  </SelectStyle>
                </FormControl>
              </TitleDropdownWrapper>
              <TypographyStyle>
                <LinkStyle onClick={() => handleTabChange(2)}>See all Trading venues</LinkStyle>
              </TypographyStyle>
            </Container>
          </TitleContainer>
        }
      />
      <CardContent>
        {!loadingTradingVenue && dataFields ? (
          <Table>
            <StyledTableBody>
              {Object.keys(dataFields).map((key: any) => (
                <TableRow key={key}>
                  <TableCellStyle>
                    <Title>{key}</Title>
                  </TableCellStyle>
                  <TableCellStyle>{dataFields[key]}</TableCellStyle>
                </TableRow>
              ))}
            </StyledTableBody>
          </Table>
        ) : (
          <Loader />
        )}
      </CardContent>
    </CardStyle>
  );
};

export default TradingVenues;
