import { Grid, TableBody, TableCell, Typography } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import React, { useContext } from "react";
import { SubscribeContext } from "../../common/subcriptionProvider";
import { Page } from "../../components";
import EditDialog from "../../components/EditDialog/EditDialog";
import SubscriptionDetailsCard from "./SubscriptionDetailsCard/SubscriptionDetailsCard";
import {
  Avatar,
  CellContent,
  CellTitle,
  GridStyle,
  TableRowStyle,
  TableWrapper,
  themeTypography,
  UserDetailStyle,
  UserPersonalInformationDetails,
  UserPersonalInformationTable,
  UserSettingsPageWrapper,
} from "./UserSettingsPage.css";

export function UserSettingsPage() {
  const { currentUserData } = useContext(SubscribeContext);

  return (
    <Page>
      <UserSettingsPageWrapper>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12}>
            <ThemeProvider theme={themeTypography}>
              <Typography variant="h3">{`${currentUserData.givenName} ${currentUserData.surname}`}</Typography>
              <Typography variant="subtitle1">{currentUserData.profession}</Typography>
            </ThemeProvider>
          </Grid>
          <Grid container spacing={2}>
            <GridStyle item xs={12} lg={8}>
              <UserDetailStyle>
                <UserPersonalInformationDetails>
                  <Avatar></Avatar>
                  <TableWrapper>
                    <UserPersonalInformationTable size="small">
                      <TableBody>
                        <TableRowStyle>
                          <TableCell>Email</TableCell>
                          <TableCell>
                            <CellContent>{currentUserData.email ? currentUserData.email : "N/A"}</CellContent>
                          </TableCell>
                        </TableRowStyle>
                        <TableRowStyle>
                          <TableCell>
                            <CellTitle>Industry</CellTitle>
                          </TableCell>
                          <TableCell>
                            <CellContent>{currentUserData.profession || "N/A"}</CellContent>
                          </TableCell>
                        </TableRowStyle>
                        <TableRowStyle>
                          <TableCell>
                            <CellTitle>Role</CellTitle>
                          </TableCell>
                          <TableCell>
                            <CellContent>{currentUserData.role || "N/A"}</CellContent>
                          </TableCell>
                        </TableRowStyle>
                      </TableBody>
                    </UserPersonalInformationTable>
                    <EditDialog />
                  </TableWrapper>
                </UserPersonalInformationDetails>
              </UserDetailStyle>
            </GridStyle>
            <Grid item xs={12} lg={4}>
              <SubscriptionDetailsCard />
            </Grid>
          </Grid>
        </Grid>
      </UserSettingsPageWrapper>
    </Page>
  );
}
