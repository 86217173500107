import { Grid } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import React from "react";
import { Page } from "../../components";
import { GridStyle, PaperStyle, Title, TypographyText, TypographyTitle } from "./DisclaimerPage.css";

const DisclaimerPage = () => {
  return (
    <Page>
      <GridStyle container spacing={0} alignItems="center" justify="center">
        <Grid item xs={12} sm={10} md={8} lg={6}>
          <PaperStyle>
            <TypographyTitle variant="h1">
              <Info></Info>
              <Title>Legal Disclaimer</Title>
            </TypographyTitle>
          </PaperStyle>
          <TypographyText>
            Stamdata AS (Stamdata), a subsidiary of Nordic Trustee Holding AS, provides this information as a service.
            Stamdata endeavors to ensure the accuracy and reliability of the information provided, but does not
            guarantee its accuracy or reliability and accepts no liability for any direct or indirect loss or damage
            arising from inaccuracies or omissions. Stamdata accepts no liability for any decision made or any action
            taken in reliance of information provided on this web-site.
          </TypographyText>
          <TypographyText>
            <br></br>
            Internet email delivery is not 100% reliable and Stamdata cannot be held responsible for undelivered email
            or for missed information as a result of undelivered email.
            <br></br>
          </TypographyText>
        </Grid>
      </GridStyle>
    </Page>
  );
};

export { DisclaimerPage };
