import { Button } from "@material-ui/core";
import styled from "styled-components";
import mediaQuery from "../../common/mediaQuery";

export const UpgradeButtonStyles = styled(Button)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 5px;
  transform: translate(-50%, -50%);
  float: right;
  width: 239px;
  height: 48px;
  color: #fff;
  background-color: #e87f38;
  font-size: 16px;
  font-weight: 300;
  z-index: 2;
  border-radius: 10px;

  &:hover {
    background-color: #e87f38;
  }

  ${mediaQuery.SM} {
    height: 35px;
    width: 190px;
    font-size: 14px;
    border-radius: 8px;
  }
`;
