import styled from "styled-components";
import { Typography } from "@material-ui/core";
import mediaQuery from "../../common/mediaQuery";

export const StyledTypography = styled(Typography)`
  font-weight: bold;
  display: inline-block;
`;

export const SearchPageWrapper = styled.div`
  ${mediaQuery.LG} {
    & .MuiBox-root > div > div:last-child {
      overflow-x: auto;
    }
    & .MuiContainer-root {
      padding: 5px;
    }
    thead > tr:first-child > th {
      white-space: nowrap;
      padding: 0 15px;
    }
    tbody > tr > th,
    tbody > tr > td {
      padding: 15px;
    }
  }
`;
