import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

const ADD_FAVORITES = gql`
  mutation addFavorite($isin: String) {
    addFavorite(isin: $isin) {
      instrument {
        upcomingRCA
        instrumentFullName
        instrumentType
        notionalCurrency
        instrumentClassification
      }
    }
  }
`;

interface Params {
  isin: string;
}

export const useMutationAddFavorite = () => useMutation<Params>(ADD_FAVORITES);
