import React, { forwardRef } from "react";
import Grid from "@material-ui/core/Grid";
import { CircularProgressContainer } from "./FilterLoader.css";
import { CircularProgress } from "@material-ui/core";

const FilterLoader = () => {
  return (
    <Grid xs={12} item>
      <CircularProgressContainer>
        <CircularProgress size={25} />
      </CircularProgressContainer>
    </Grid>
  );
};

export default forwardRef(FilterLoader);
