import { Box, Card, CardContent, Table, TableBody, TableCell, TableRow, Typography } from "@material-ui/core";
import styled from "styled-components";
import mediaQuery from "../../common/mediaQuery";
interface LoaderOverlayInterface {
  loaderPosition: number;
}

interface TableCellInterface {
  show: number;
}

export const CardContentStyle = styled(CardContent)`
  position: relative;
  min-height: 165px;

  ${mediaQuery.SM_LANDSCAPE} {
    min-height: 105px;
  }

  ${mediaQuery.SM} {
    min-height: 105px;
  }
`;

export const LoaderOverlay = styled.div<LoaderOverlayInterface>`
  position: absolute;
  top: ${(props) => (props.loaderPosition ? "50%" : "60%")};
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

export const TableCellName = styled(TableCell)<TableCellInterface>`
  p:first-child {
    font-weight: 700;
  }
  & > p {
    margin: 5px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: ${(props) => (props.show ? "400px" : "180px")};
  }
`;

export const EmptyCard = styled.div`
  text-align: center;
  color: #cbcbcb;
  margin: 10px 0;
`;

export const Message = styled.div`
  font-size: 16px;
  font-weight: 700;
`;

export const TypographyStyle = styled(Typography)`
  font-size: 24px;
  font-weight: 700;
`;

export const FavoriteInstrumentsCardWrapper = styled(Card)`
  & > div:last-child {
    overflow-x: scroll;
  }

  & > div:last-child::-webkit-scrollbar {
    display: none;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    .MuiCardContent-root {
      padding: 0;
    }
  }

  ${mediaQuery.SM} {
    .MuiCardContent-root {
      padding: 0;
    }
  }
`;

export const TablePeriodCell = styled(TableCell)`
  width: 220px;
  min-width: 220px;

  & > div {
    margin-top: 0;
    font-size: 13px;
  }

  & div:first-child {
    height: 13px;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    min-width: 170px;

    & div:first-child {
      color: red;
      height: 11px !important;
    }
  }

  ${mediaQuery.SM} {
    min-width: 170px;

    & div:first-child {
      color: red;
      height: 11px !important;
    }
  }
`;

export const TableRowStyle = styled(TableRow)`
  cursor: pointer;
  & > th > *,
  & > td > * {
    pointer-events: none;
  }

  & > th,
  & > td {
    pointer-events: all;
  }
`;

export const StyledTable = styled(Table)`
  th {
    font-size: 17px;
    font-weight: bold;
    white-space: nowrap;
  }

  tbody tr:last-child td {
    border-bottom: none;
  }

  td:first-child {
    width: 110px;
  }

  tr > td:nth-child(3) {
    width: 220px;
  }

  tr > td:nth-child(3) > div {
    margin-top: 0;
    font-size: 13px;
  }

  tr > td:nth-child(3) div:first-child {
    height: 13px;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    th {
      font-size: 0.8rem;
    }

    td {
      font-size: 0.7rem;
    }
  }

  ${mediaQuery.SM} {
    th {
      font-size: 0.8rem;
    }

    td {
      font-size: 0.7rem;
    }
  }
`;

export const StyledBox = styled(Box)`
  display: flex;
  justify-content: space-between;

  a {
    font-weight: 600;
  }

  a:hover {
    cursor: pointer;
    color: #40a9ff;
    text-decoration: none;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    h5 {
      font-size: 1.1rem;
    }
  }

  ${mediaQuery.SM} {
    h5 {
      font-size: 1.1rem;
    }
  }
`;

interface ILoadingInstruments {
  loading: any;
}

export const StyledTableBody = styled(TableBody)<ILoadingInstruments>`
  filter: ${({ loading }) => (loading ? "blur(1px)" : "blur(0)")};
  transition: ${({ loading }) => (loading ? "none" : "all .6s")};
`;
