export enum Constants {
  debounceRate = 500,
  debounceSearchRate = 700,
  searchMinLength = 3,
  maxNumberOfSearchSuggestions = 5,
  errorMessage = "Oops. Something went wrong. Please try again later or contact the site administrator.",
}

export const guidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export const userIndustry = [
  "Buyside (insurance firms, mutual funds, pension funds etc.)",
  "Sellside (broking/dealing, investment banking, advisory, research etc.)",
  "Issuer (of financial instruments)",
  "Financial market intermediary (custodian, paying agent agent etc.)",
  "Financial market infrastructure (Exchange, CCP, CSD etc.)",
  "Governmental body (National bank, Competent authorities, etc.)",
  "Professional services (law firm, accountant, research etc.)",
  "Market data or SaaS providers",
  "Non- profit organization (“European Issuers”, “AFME” etc.)",
  "Other",
];

export const userRoles = [
  "DCM / ECM (primary markets)",
  "Secondary trading",
  "Market making",
  "Asset management",
  "Securities lending",
  "Collateral management",
  "Custody",
  "Settlement",
  "Corporate actions",
  "Compliance",
  "Risk",
  "Legal",
  "Issuer /paying agent",
  "Treasury",
  "Data management",
  "AML/ KYC",
  "Analytics /statistics",
  "Other",
];
export const hotJarConnectionString =
  "(function (h, o, t, j, a, r) {h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments)}; h._hjSettings = { hjid: 1477370, hjsv: 6 }; a = o.getElementsByTagName('head')[0]; r = o.createElement('script'); r.async = 1;r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv; a.appendChild(r);})(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')";

export const googleAnalyticsConnectionString =
  "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-ZTFNX064XN');";

export const googleTagManagerHeader =
  "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-599ZMRZ')";

export const googleTagManagerBody =
  '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-599ZMRZ"height="0" width="0" style="display:none;visibility:hidden"></iframe>';
