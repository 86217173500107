import { Checkbox, CircularProgress, Typography } from "@material-ui/core";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { SubscribeContext } from "../../common/subcriptionProvider";
import { useMutationCreateSubscription } from "../../graphql/CreateSubscription";
import { useMutationRetryInvoice } from "../../graphql/RetryInvoice";
import PaymentAgreementDialog from "../PaymentAgreementDialog/PaymentAgreementDialog";
import { AgreementCheckboxWrapper, ButtonsWrapper, FormWrapper } from "./PaymentCard.css";
import { createSubscription, retryInvoiceWithNewPaymentMethod } from "./PaymentCardFunctions";

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          "fontSize": "12px",
          "color": "#424770",
          "letterSpacing": "0.025em",
          "fontFamily": "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
      hidePostalCode: true,
    }),
    []
  );
  return options;
};

interface IMessage {
  success?: string;
  error?: string;
}

const PaymentCard = ({ premiumPlan }: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const history = useHistory();

  const [isPaymentLoading, changeIsPaymentLoading] = useState<boolean>(false);
  const { isFirstLogin, setIsFirstLogin, userSubscription, refreshContext, isUserPremium } = React.useContext(
    SubscribeContext
  );

  const [createSubscriptionRequest] = useMutationCreateSubscription();
  const [retrieveInvoice] = useMutationRetryInvoice();

  const [infoMessage, setInfoMessage] = useState<IMessage>({ error: "", success: "" });
  const [isSubscriptionTrialPeriod, setIsSubscriptionTrialPeriod] = useState<boolean>(false);
  const [isAgreementChecked, setIsAgreementChecked] = useState<boolean>(false);
  const [isAgreementDialogOpen, setIsAgreementDialogOpen] = useState<boolean>(false);

  const isUserSettingPath = history.location.pathname === "/user-settings";

  useEffect(() => {
    if (isUserPremium && !isSubscriptionTrialPeriod) {
      setInfoMessage({
        success: "Payment has successfully passed, you will be redirected to the dashboard page soon.",
      });
    } else if (isUserPremium && isSubscriptionTrialPeriod) {
      setInfoMessage({
        success: "Trial period successfully activated,  you will be redirected to the dashboard page soon.",
      });
    }
  }, [isUserPremium, isSubscriptionTrialPeriod]);

  const handleSubmit = async (event: any) => {
    if (!isAgreementChecked) return;

    changeIsPaymentLoading(true);
    setInfoMessage({ error: "", success: "" });
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      changeIsPaymentLoading(false);
      throw new Error();
    }

    if (!userSubscription) {
      localStorage.removeItem("latestInvoiceId");
      localStorage.removeItem("latestInvoicePaymentIntentStatus");
    }

    const latestInvoicePaymentIntentStatus = localStorage.getItem("latestInvoicePaymentIntentStatus");
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      changeIsPaymentLoading(false);
      setInfoMessage({ error: error.message });
    } else {
      if (paymentMethod) {
        const paymentMethodId = paymentMethod.id;
        if (latestInvoicePaymentIntentStatus === "requires_payment_method") {
          // Update the payment method and retry invoice payment
          const invoiceId = localStorage.getItem("latestInvoiceId");
          if (invoiceId) {
            retryInvoiceWithNewPaymentMethod({
              paymentMethodId,
              invoiceId,
              stripe,
              refreshContext,
              setInfoMessage,
              changeIsPaymentLoading,
              retrieveInvoice,
            });
          } else {
            createSubscription({
              paymentMethodId,
              refreshContext,
              setInfoMessage,
              changeIsPaymentLoading,
              stripe,
              createSubscriptionRequest,
              setIsSubscriptionTrialPeriod,
            });
          }
        } else {
          createSubscription({
            paymentMethodId,
            refreshContext,
            setInfoMessage,
            changeIsPaymentLoading,
            stripe,
            createSubscriptionRequest,
            setIsSubscriptionTrialPeriod,
          });
        }
      }
    }
  };

  const handleContinueAsBasicUser = () => {
    if (isFirstLogin) {
      setIsFirstLogin(false);
    }
    history.push("/dashboard");
  };

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAgreementChecked(event.target.checked);
  };

  const handleCloseDialog = () => {
    setIsAgreementDialogOpen(false);
  };

  const handleOpenAgreementDialog = () => {
    setIsAgreementDialogOpen(true);
  };

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <Typography variant="h5">Get Premium Access </Typography>
      <Typography variant="h6"> (EUR 50 mth, 1st mth free of charge, cancel anytime)</Typography>
      <label>
        Card details
        <CardElement options={options} />
      </label>
      {!(isPaymentLoading || infoMessage.error || infoMessage.success) ? (
        <AgreementCheckboxWrapper>
          <Checkbox checked={isAgreementChecked} onChange={handleChangeCheckbox} name="checkedB" color="primary" />
          <p>
            I accept the <span onClick={handleOpenAgreementDialog}>trial services agreement</span>
          </p>
          <PaymentAgreementDialog isAgreementDialogOpen={isAgreementDialogOpen} handleCloseDialog={handleCloseDialog} />
        </AgreementCheckboxWrapper>
      ) : null}
      {infoMessage.error && !isPaymentLoading ? (
        <p className="error">{infoMessage.error}</p>
      ) : infoMessage.success ? (
        <>
          <p className={isUserPremium ? "payment-success" : "query-success"}>{infoMessage.success}</p>
          {isUserPremium ? null : <CircularProgress className="payment-animation" size={15} />}
        </>
      ) : null}
      <ButtonsWrapper>
        <span className={isPaymentLoading ? "hidden" : "visible"} onClick={handleContinueAsBasicUser}>
          {!isUserSettingPath ? "Continue with limited access." : null}
        </span>
        {isPaymentLoading ? (
          <CircularProgress color="primary" size={30} />
        ) : (
          <button type="submit" disabled={!!infoMessage.success || !isAgreementChecked}>
            Start trial period
          </button>
        )}
      </ButtonsWrapper>
    </FormWrapper>
  );
};

export default PaymentCard;
