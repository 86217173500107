import { Box, CardContent, CardHeader, Typography } from "@material-ui/core";
import React from "react";
import UpgradeButton from "../UpgradeButton/UpgradeButton";
import { BlurredImage, CardHeaderContainer, CardStyle, CardTitle } from "./BlurredCard.css";
import { IProps } from "./BlurredCardInterface";

const BlurredCard: React.FC<IProps> = ({ image, title, component }) => {
  return (
    <CardStyle>
      <CardHeader
        title={
          <Box>
            <CardHeaderContainer>
              <CardTitle>
                <Typography>{title}</Typography>
              </CardTitle>
            </CardHeaderContainer>
          </Box>
        }
      />
      <CardContent>
        <UpgradeButton />
        <BlurredImage src={image} component={component} />
      </CardContent>
    </CardStyle>
  );
};

export default BlurredCard;
