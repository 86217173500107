import styled from "styled-components";
import { Snackbar } from "@material-ui/core";
import mediaQuery from "../../common/mediaQuery";

export const SnackbarStyle = styled(Snackbar)`
  top: -80%;

  ${mediaQuery.LG} {
    min-width: 650px;
  }
  ${mediaQuery.MD} {
    min-width: 450px;
  }
  ${mediaQuery.SM} {
    min-width: unset;
  }
  .MuiAlert-root {
    background-color: #e87f38;
  }
`;

export const AlertTitle = styled.div`
  font-weight: 700;
  margin-bottom: 5px;
  ${mediaQuery.SM} {
    font-size: 13px;
  }
`;

export const AlertMessage = styled.div`
  ${mediaQuery.SM} {
    font-size: 12px;
  }
`;

export const MainWrapper = styled.main<{ isHomepage: any; hideSearch: any }>`
  margin: ${({ isHomepage }) => (isHomepage ? "0" : "30px 0")};

  ${mediaQuery.LG} {
    padding-top: ${({ isHomepage }) => (isHomepage ? "0" : "50px")};
  }

  ${mediaQuery.SM} {
    margin: ${({ isHomepage }) => (isHomepage ? "0" : "10px")};
    padding-top: ${({ isHomepage }) => (isHomepage ? "40px" : "90px")};
  }
`;
