import {createMuiTheme} from "@material-ui/core/styles";

export const MuiPaperBackgroundColor = "#f7f8f6";
export const MuiMainBackgroundColor = "#3f18aa";

export const featureColorMatches = {
  statistics: ["#7964C1", "#644AB8"],
  documents: ["#EDA67A", "#EA9761"],
  events: ["#6FBC75", "#5AAF5D"],
  logs: ["#406DCC", "#1C48CC"],
};

export const iconsColors = {
  pdf: "#C11E07",
  word: "#2B579A",
  excel: "#227447",
};

export default createMuiTheme({
  spacing: 8,
  typography: {
    h1: {
      fontSize: 20,
    },
  },
  palette: {
    primary: {
      main: MuiMainBackgroundColor,
      light: "rgb(193, 181, 227)",
    },
    secondary: {
      main: "#e87f38",
    },
    background: {
      default: "#ececec",
    },
    divider: "rgba(0,0,0,0.08)",
  },
  shadows: [
    "none",
    "0px 1px 5px 0px rgba(0,0,0,0.0375),0px 1px 1px 0px rgba(0,0,0,0.02625),0px 2px 1px -1px rgba(0,0,0,0.0225)",
    "0px 1px 7px 0px rgba(0,0,0,0.0375),0px 2px 2px 0px rgba(0,0,0,0.02625),0px 3px 1px -2px rgba(0,0,0,0.0225)",
    "0px 1px 7px 0px rgba(0,0,0,0.0375),0px 3px 4px 0px rgba(0,0,0,0.02625),0px 3px 3px -2px rgba(0,0,0,0.0225)",
    "0px 2px 6px -1px rgba(0,0,0,0.0375),0px 4px 5px 0px rgba(0,0,0,0.02625),0px 1px 10px 0px rgba(0,0,0,0.0225)",
    "0px 3px 7px -1px rgba(0,0,0,0.0375),0px 5px 8px 0px rgba(0,0,0,0.02625),0px 1px 14px 0px rgba(0,0,0,0.0225)",
    "0px 3px 7px -1px rgba(0,0,0,0.0375),0px 6px 10px 0px rgba(0,0,0,0.02625),0px 1px 18px 0px rgba(0,0,0,0.0225)",
    "0px 4px 7px -2px rgba(0,0,0,0.0375),0px 7px 10px 1px rgba(0,0,0,0.02625),0px 2px 16px 1px rgba(0,0,0,0.0225)",
    "0px 5px 7px -3px rgba(0,0,0,0.0375),0px 8px 10px 1px rgba(0,0,0,0.02625),0px 3px 14px 2px rgba(0,0,0,0.0225)",
    "0px 5px 8px -3px rgba(0,0,0,0.0375),0px 9px 12px 1px rgba(0,0,0,0.02625),0px 3px 16px 2px rgba(0,0,0,0.0225)",
    "0px 6px 8px -3px rgba(0,0,0,0.0375),0px 10px 14px 1px rgba(0,0,0,0.02625),0px 4px 18px 3px rgba(0,0,0,0.0225)",
    "0px 6px 9px -4px rgba(0,0,0,0.0375),0px 11px 15px 1px rgba(0,0,0,0.02625),0px 4px 20px 3px rgba(0,0,0,0.0225)",
    "0px 7px 10px -4px rgba(0,0,0,0.0375),0px 12px 17px 2px rgba(0,0,0,0.02625),0px 5px 22px 4px rgba(0,0,0,0.0225)",
    "0px 7px 10px -4px rgba(0,0,0,0.0375),0px 13px 19px 2px rgba(0,0,0,0.02625),0px 5px 24px 4px rgba(0,0,0,0.0225)",
    "0px 7px 11px -4px rgba(0,0,0,0.0375),0px 14px 21px 2px rgba(0,0,0,0.02625),0px 5px 26px 4px rgba(0,0,0,0.0225)",
    "0px 8px 11px -5px rgba(0,0,0,0.0375),0px 15px 22px 2px rgba(0,0,0,0.02625),0px 6px 28px 5px rgba(0,0,0,0.0225)",
    "0px 8px 12px -5px rgba(0,0,0,0.0375),0px 16px 24px 2px rgba(0,0,0,0.02625),0px 6px 30px 5px rgba(0,0,0,0.0225)",
    "0px 8px 13px -5px rgba(0,0,0,0.0375),0px 17px 26px 2px rgba(0,0,0,0.02625),0px 6px 32px 5px rgba(0,0,0,0.0225)",
    "0px 9px 13px -5px rgba(0,0,0,0.0375),0px 18px 28px 2px rgba(0,0,0,0.02625),0px 7px 34px 6px rgba(0,0,0,0.0225)",
    "0px 9px 14px -6px rgba(0,0,0,0.0375),0px 19px 29px 2px rgba(0,0,0,0.02625),0px 7px 36px 6px rgba(0,0,0,0.0225)",
    "0px 10px 15px -6px rgba(0,0,0,0.0375),0px 20px 31px 3px rgba(0,0,0,0.02625),0px 8px 38px 7px rgba(0,0,0,0.0225)",
    "0px 10px 15px -6px rgba(0,0,0,0.0375),0px 21px 33px 3px rgba(0,0,0,0.02625),0px 8px 40px 7px rgba(0,0,0,0.0225)",
    "0px 10px 16px -6px rgba(0,0,0,0.0375),0px 22px 35px 3px rgba(0,0,0,0.02625),0px 8px 42px 7px rgba(0,0,0,0.0225)",
    "0px 11px 16px -7px rgba(0,0,0,0.0375),0px 23px 36px 3px rgba(0,0,0,0.02625),0px 9px 44px 8px rgba(0,0,0,0.0225)",
    "0px 11px 17px -7px rgba(0,0,0,0.0375),0px 24px 38px 3px rgba(0,0,0,0.02625),0px 9px 46px 8px rgba(0,0,0,0.0225)",
  ],
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: MuiPaperBackgroundColor,
        border: "1px solid rgba(0,0,0, 0.08)",
      },
    },
    MuiButton: {
      containedSecondary: {
        color: "white",
      },
    },
  },
});

// Generated using http://mcg.mbitson.com/#!?mcgpalette0=%233f18aa#%2F
export const primaryColorShades = [
  "#e8e3f5",
  "#c5bae6",
  "#9f8cd5",
  "#795dc4",
  "#5c3bb7",
  "#3f18aa",
  "#3915a3",
  "#311199",
  "#290e90",
  "#1b087f",
  "#b8aeff",
  "#8b7bff",
  "#5e48ff",
  "#472fff",
];
