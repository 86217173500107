import { LoginType, MsalAuthProvider } from "react-aad-msal";

declare const window: any;
// Msal Configurations
const config = {
  auth: {
    authority: window._env_.AD_B2C_AUTHORITY_URL,
    clientId: window._env_.AD_B2C_CLIENT_ID,
    validateAuthority: false,
    postLogoutRedirectUri: window._env_.POST_LOGOUT_REDIRECT_URL,
    redirectUri: window._env_.POST_LOGIN_REDIRECT_URL,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage" as "localStorage",
    storeAuthStateInCookie: false,
  },
};

const forgotPasswordUrl =
  window._env_.AD_B2C_PWD_RESET_AUTHORITY_URL ||
  "https://msdnntb2c.b2clogin.com/msdnntb2c.onmicrosoft.com/B2C_1_password_reset";

const forgotPasswordConfig = {
  ...config,
  auth: {
    ...config.auth,
    authority: forgotPasswordUrl,
  },
};

// Authentication Parameters
const authenticationParameters = {
  scopes: [window._env_.AD_B2C_SCOPE_URL],
};

// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin + "/auth.html",
};

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options);

export const authProviderForgotPassword = new MsalAuthProvider(forgotPasswordConfig, authenticationParameters, options);

const forgotPasswordCode = "AADB2C90118";

authProvider.handleRedirectCallback((error) => {
  if (error && error.errorMessage.search(forgotPasswordCode) !== -1) {
    authProvider.authority = forgotPasswordUrl;
    authProvider.login();
  }
});
