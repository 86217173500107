import { CardContent, CardHeader, Typography } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import React from "react";
import Loader from "../loader/Loader";
import { DoubleCardWrapper, themeTypography, TittleWrapper } from "./DoubleDataCard.css";
import { DoubleDataCardProps } from "./DoubleDataCardInterface";
import StateTable from "./StateTable";

const DoubleDataCard = ({
  title,
  firstTableRows,
  secondTableRows,
  loading,
  style,
  updateDate,
}: DoubleDataCardProps) => {
  const tables = [firstTableRows, secondTableRows];
  return (
    <DoubleCardWrapper style={style}>
      <CardHeader
        title={
          <ThemeProvider theme={themeTypography}>
            <TittleWrapper>
              <Typography variant="h5">{title || ""}</Typography>
              {updateDate && <Typography variant="caption">&#8226; Updated {updateDate}</Typography>}
            </TittleWrapper>
          </ThemeProvider>
        }
      />
      <CardContent>
        {loading ? (
          <Loader />
        ) : (
          <>
            {tables.map((table: any) => (
              <StateTable key={Math.random().toString(32).substring(7)} tableRows={table} />
            ))}
          </>
        )}
      </CardContent>
    </DoubleCardWrapper>
  );
};

export default DoubleDataCard;
