import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

const GET_TRADING_VENUES_FOR_INSTRUMENT = gql`
  query getTradingVenuesForInstrument($isin: String) {
    getTradingVenuesForInstrument(isin: $isin) {
      authorisationDate
      authorisationWithdrawalEndDate
      creationDate
      tradingVenueType
      lei
      micCode
      name
      jurisdiction
      competentAuthority
      id
    }
  }
`;

interface Params {
  isin: string;
}

interface GetTradingVenuesForInstrument {
  getTradingVenuesForInstrument: TradingVenuesData[];
}

export interface TradingVenuesData {
  authorisationDate: string;
  authorisationWithdrawalEndDate: string;
  creationDate: string;
  tradingVenueType: string;
  lei: string;
  micCode: string;
  name: string;
  jurisdiction: string;
  competentAuthority: string;
  id: string;
}

export const useLazyGetTradingVenuesForInstrumentQuery = () =>
  useLazyQuery<GetTradingVenuesForInstrument, Params>(GET_TRADING_VENUES_FOR_INSTRUMENT);
