import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DebtInstrumentTable } from "..";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { formatMoney } from "accounting";
import { DebtInstrument } from "../../common/types";
import lodash from "lodash";

const useStyles = makeStyles({
  tabs: {
    float: "right",
  },
});

interface DebtInstrumentListProps {
  instruments?: DebtInstrument[];
}

type InstrumentType = "Listed" | "Delisted";

export function DebtCard({ instruments = [] }: DebtInstrumentListProps) {
  const classes = useStyles();
  const [instrumentType, setInstrumentType] = useState<InstrumentType>(
    "Listed"
  );

  const dateFilter = (instrument: DebtInstrument) =>
    instrument.debtInstrmAttrbts.mtrtyDt &&
    new Date(instrument.debtInstrmAttrbts.mtrtyDt) > new Date();

  const filteredInstruments = instruments.filter((l) =>
    instrumentType === "Listed" ? dateFilter(l) : !dateFilter(l)
  );
  const totalIssuedAmounts = Object.entries(
    lodash.groupBy(
      filteredInstruments,
      (instrument) => instrument.finInstrmGnlAttrbts.ntnlCcy
    )
  ).map(([currency, instruments]) => ({
    currency: currency,
    sum: instruments
      .map((instrument) =>
        Number(instrument.debtInstrmAttrbts.ttlIssdNmnlAmt?.text)
      )
      .reduce((a, b) => a + b, 0),
  }));

  // If there are matured, but not active debt instruments, switch tab
  useEffect(() => {
    if (filteredInstruments.length === 0) {
      setInstrumentType("Delisted");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Card>
      <CardHeader
        title={
          <>
            <Tabs
              value={instrumentType}
              onChange={(e, value: InstrumentType) => setInstrumentType(value)}
              indicatorColor="primary"
              className={classes.tabs}
            >
              <Tab value="Listed" label="Listed" />
              <Tab value="Delisted" label="Delisted" />
            </Tabs>
            <Typography variant="h6">
              {filteredInstruments.length > 0
                ? "Debt Instruments"
                : "No Debt Instruments"}
            </Typography>
          </>
        }
      />
      <CardContent>
        <DebtInstrumentTable instruments={filteredInstruments} />
        {filteredInstruments.length > 0 && (
          <Box mt={2}>
            <Typography variant="h6" align="right">
              Total Issued Amount
            </Typography>
            {totalIssuedAmounts.map(({ currency, sum }) => (
              <Typography align="right" key={currency}>
                {formatMoney(sum, {
                  symbol: currency,
                  format: "%s %v",
                })}
              </Typography>
            ))}
          </Box>
        )}
      </CardContent>
    </Card>
  );
}
