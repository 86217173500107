import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import theme from "../../common/theme";

const useStyles = makeStyles({
  loader: {
    textAlign: "center",
    margin: theme.spacing(5, 0),
  },
});

function Loader() {
  const classes = useStyles();
  return (
    <Grid xs={12} item className={classes.loader}>
      <CircularProgress />
    </Grid>
  );
}

export default Loader;
