import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { SubscribeContext } from "../../common/subcriptionProvider";

interface IProps {
  component: any;
  path: string;
}

const PrivateRoute = ({ component: Component, ...rest }: IProps) => {
  const { azureProps } = useContext(SubscribeContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        azureProps.authenticationState === "Authenticated" ? <Component {...props} /> : <Redirect to="/dashboard" />
      }
    />
  );
};

export default PrivateRoute;
