import { Card, CardContent, CardHeader, Table, TableRow } from "@material-ui/core";
import React from "react";
import Loader from "../loader/Loader";
import {
  StyledTableBody,
  TableCellStyle,
  TableContainer,
  TableTitle,
  Title,
  TypographyStyle,
} from "./InstrumentDetails.css";
import { PropsInterface } from "./InstrumentDetailsInterface";
import { mapInstrumentDetailsFunc } from "./InstrumentFunctions";

const InstrumentDetails: React.FC<PropsInterface> = ({ instrumentData, loadingInstrumentData }) => {
  const issueDataFields: any = mapInstrumentDetailsFunc(instrumentData)?.issueData;
  const regulatoryDataFields: any = mapInstrumentDetailsFunc(instrumentData)?.regulatoryData;

  return (
    <Card>
      <CardHeader title={<TypographyStyle variant="h5">Instrument details</TypographyStyle>} />
      <CardContent>
        {!loadingInstrumentData && issueDataFields && regulatoryDataFields ? (
          <>
            <TableContainer>
              <TableTitle>Issue data</TableTitle>
              <Table>
                <StyledTableBody>
                  {Object.keys(issueDataFields).map((key: string) => (
                    <TableRow key={key}>
                      <TableCellStyle>
                        <Title>{key}</Title>
                      </TableCellStyle>
                      <TableCellStyle>{issueDataFields[key]}</TableCellStyle>
                    </TableRow>
                  ))}
                </StyledTableBody>
              </Table>
            </TableContainer>
            <TableContainer>
              <TableTitle>Regulatory data</TableTitle>
              <Table>
                <StyledTableBody>
                  {Object.keys(regulatoryDataFields).map((key: any) => (
                    <TableRow key={key}>
                      <TableCellStyle>
                        <Title>{key}</Title>
                      </TableCellStyle>
                      <TableCellStyle>{regulatoryDataFields[key]}</TableCellStyle>
                    </TableRow>
                  ))}
                </StyledTableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <Loader />
        )}
      </CardContent>
    </Card>
  );
};

export default InstrumentDetails;
