import { Box, Card, CardContent, Table, TableBody, TableCell, TableRow, Typography } from "@material-ui/core";
import styled from "styled-components";
import mediaQuery from "../../../common/mediaQuery";

export const ProgressOverlay = styled.div`
  width: 100%;
  top: 50%;
  left: 0;
  position: absolute;
  &.empty {
    bottom: 50px;
  }
  .MuiCircularProgress-root {
    margin: 0 auto;
    display: block;
    position: relative;
    top: 50%;
  }
`;

export const TableHeaderCell = styled(TableCell)`
  font-weight: 700;
  font-size: 15px;
  p {
    font-weight: bold;
  }

  ${mediaQuery.SM} {
    p {
      font-size: 0.8rem;
    }
  }
`;

export const StyledTable = styled(Table)`
  th,
  td {
    &:first-child {
      width: 10%;
    }

    &:nth-child(2) {
      width: 30%;
    }
    &:nth-child(3) {
      width: 30%;
    }
    &:nth-child(4) {
      width: 10%;
    }
  }
`;

interface ILoadingInstruments {
  loading: any;
}

export const StyledTableBody = styled(TableBody)<ILoadingInstruments>`
  filter: ${({ loading }) => (loading ? "blur(1px)" : "blur(0)")};
  transition: ${({ loading }) => (loading ? "none" : "all .6s")};
`;

export const TableMainCell = styled(TableCell)`
  img {
    width: 38px;
  }

  ${mediaQuery.SM} {
    p {
      font-size: 0.7rem;
    }
  }
`;

export const Message = styled.div`
  font-size: 16px;
  font-weight: 700;
`;

export const EmptyCard = styled.div`
  text-align: center;
  color: #cbcbcb;
  margin: 10px 0;
`;

export const TableRowStyle = styled(TableRow)`
  cursor: pointer;
  & > th > *,
  & > td > * {
    pointer-events: none;
  }

  & > th,
  & > td {
    pointer-events: all;
  }
`;

export const TableScrollContainer = styled.div`
  ${mediaQuery.MD} {
    overflow-x: scroll;
  }
`;

export const StyledBox = styled(Box)`
  display: flex;
  justify-content: space-between;

  ${mediaQuery.SM} {
    h5 {
      font-size: 1.2rem;
    }
  }
`;

export const TypographyStyle = styled(Typography)`
  font-size: 24px;
  font-weight: 700;
`;

export const CardContentStyle = styled(CardContent)`
  min-height: 165px;
`;

export const CardStyle = styled(Card)`
  position: relative;

  ${mediaQuery.SM} {
    padding: 0;
  }
`;
