import { IconButton, Typography } from "@material-ui/core";
import React from "react";
import { StyledDialog, StyledDialogContent, StyledDialogTitle } from "./PaymentAgreementDialog.css";
import CloseIcon from "@material-ui/icons/Close";
import { PaymentTermsInterface } from "./PaymentAgreementDialogInterface";

const Promo: React.FC<PaymentTermsInterface> = ({ isAgreementDialogOpen, handleCloseDialog }) => {
  return (
    <StyledDialog onClose={handleCloseDialog} aria-labelledby="customized-dialog-title" open={isAgreementDialogOpen}>
      <StyledDialogTitle id="customized-dialog-title">
        TRIAL TERMS
        <IconButton aria-label="close" onClick={handleCloseDialog}>
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <StyledDialogContent dividers>
        <Typography gutterBottom>
          When registered for a trial use of eFIRDS, you will have access to a Premium Account free of charge for 30
          days (“Trial Period”). You initiate your Trial Period by clicking “start subscription” and you may at any time
          cancel the subscription by clicking “cancel subscription” in the user settings. You will receive a
          notification 7 days prior to the end of your Trial Period. If not terminated before the Trial Period ends,
          your eFIRDS Premium Account subscription will continue in force until terminated at cost of € 50 per month.
          Payments will be invoiced to your chosen Payment Method on a monthly (30-day) billing cycle. The first payment
          date is 30 days after the date of the Trial Period start. If you terminate your Premium account access during
          or after the Trial Period, you will not be able to access or retrieve any of the data or features only made
          available to Premium Account users.
        </Typography>
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default Promo;
