import styled from "styled-components";
import mediaQuery from "../../common/mediaQuery";

export const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;

  ${mediaQuery.XL} {
    display: none;
  }
`;

export const NavigationLink = styled.h4`
  padding: 6px 8px;
  margin: 0 2px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  white-space: nowrap;

  & > * {
    pointer-events: none;
  }

  span {
    margin: 0 5px;
    display: block;
  }

  &:hover {
    background-color: #2b2e33;
  }
`;

export const NavigationContainerRWD = styled.div`
  display: none;
  white-space: nowrap;

  ${mediaQuery.XL} {
    display: block;
  }
`;
