import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { InstrumentResourceParametersInput } from "../common/types";

const queryAuth = (isPremium: boolean) => {
  const GET_ADDITIONAL_DATA_FOR_RECENT_INSTRUMENTS = gql`
  query getAdditionalDataForRecentInstruments(
    $resourceParameters: InstrumentResourceParametersInput!
  ) {
    getAdditionalDataForRecentInstruments(
      resourceParameters: $resourceParameters
    ) {
      countries @include(if: ${isPremium} )
      currencies @include(if: ${isPremium})
      tradingVenues 
      totalIssuedAmount
      __typename @include(if: ${isPremium})
    }
  }
`;
  return GET_ADDITIONAL_DATA_FOR_RECENT_INSTRUMENTS;
};

interface Params {
  resourceParameters: InstrumentResourceParametersInput;
}

interface GetAdditionalDataForRecentInstruments {
  getAdditionalDataForRecentInstruments: {
    countries: Array<string>;
    currencies: Array<string>;
    tradingVenues: Array<string>;
    totalIssuedAmount: number;
    __typename: string;
  };
}

export const useLazyGetAdditionalDataForRecentInstrumentsQuery = (isPremium: any) =>
  useLazyQuery<GetAdditionalDataForRecentInstruments, Params>(queryAuth(isPremium));
