import {
  Box,
  CardHeader,
  CircularProgress,
  InputLabel,
  MenuItem,
  MuiThemeProvider,
  Select,
  TableCell,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import SubjectIcon from "@material-ui/icons/Subject";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Constants } from "../../../common/constants";
import { SubscribeContext } from "../../../common/subcriptionProvider";
import { InstrumentNew } from "../../../common/types";
import { useLazyGetAdditionalDataForInstrumentSuggestionsQuery } from "../../../graphql/GetAdditionalDataForInstrumentSuggestions";
import { useLazyGetAdditionalDataForMultipleISINsQuery } from "../../../graphql/GetAdditionalDataForMultipleISINs";
import { useLazyGetInstrumentsByMultipleISINsQuery } from "../../../graphql/getInstrumentsByMultipleISINs";
import { useLazyInstrumentSuggestionResultQuery } from "../../../graphql/GetInstrumentSuggestionsResult";
import useDebounce from "../../../hooks/useDebounce";
import formatSearchValue from "../../../utils/formatSearchValue";
import CustomFlag from "../../CustomFlag";
import FilterLoader from "../../loader/FilterLoader";
import TablePagination from "../../TablePagination/TablePagination";
import PeriodProgress from "../PeriodProgress";
import { SearchTableInterface } from "../SearchTablesInterface";
import TableFilters from "../TableFilters";
import {
  CardContentStyle,
  CardStyle,
  DropdownStyle,
  EmptyCard,
  formControlTheme,
  Message,
  ProgressOverlay,
  SelectLabel,
  StyledBox,
  StyledDatePicker,
  StyledFiltersRow,
  StyledFormControl,
  StyledTableBody,
  StyledTablePagination,
  StyledTextField,
  StyledUpgradeToUnlockRow,
  TableHeaderCell,
  TableMainCell,
  TableNameCell,
  TableRowStyle,
  TableScrollContainer,
  TypographyStyle,
  UpgradeMessage,
} from "./OtherInstruments.css";

const OtherInstruments: React.FC<SearchTableInterface> = ({ search, searchByIsin, isSearchByLei }) => {
  const history = useHistory();

  const { isUserPremium, setErrorMessage } = useContext(SubscribeContext);

  const [
    getInstrumentResults,
    { data: instrumentResults, loading: instrumentLoading, error: getInstrumentResultsError },
  ] = useLazyInstrumentSuggestionResultQuery(isUserPremium !== null ? isUserPremium : false);

  const [
    getAdditionalDataForMultipleISINs,
    { data: additionalISINsData, loading: loadingAdditionalDataISINs, error: getAdditionalDataForMultipleISINsError },
  ] = useLazyGetAdditionalDataForMultipleISINsQuery(isUserPremium && isUserPremium ? isUserPremium : false);

  const [
    getInstrumentsByMultipleISINs,
    { data: multipleIsinsResults, loading: multipleIsinsLoading, error: getInstrumentsByMultipleISINsError },
  ] = useLazyGetInstrumentsByMultipleISINsQuery(isUserPremium !== null ? isUserPremium : false);

  const [
    getAdditionalDataForInstrumentSuggestions,
    {
      data: additionalData,
      loading: loadingAdditionalDataInstr,
      error: getAdditionalDataForInstrumentSuggestionsError,
    },
  ] = useLazyGetAdditionalDataForInstrumentSuggestionsQuery(isUserPremium && isUserPremium ? isUserPremium : false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [authority, setAuthority] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [currency, setCurrency] = useState<string>("");
  const [instruments, setInstruments] = useState<any[]>([]);
  const [allCurrencies, setAllCurrencies] = useState<any>([]);
  const [allCountries, setAllCountries] = useState<any>([]);
  const [isFiltering, setIsFiltering] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [instrumentAdditionalData, setInstrumentAdditionalData] = useState<any>();
  const [instrumentAdditionalISINsData, setInstrumentAdditionalISINsData] = useState<any>();

  const debouncedAuthority = useDebounce(authority, Constants.debounceRate);
  const debounceName = useDebounce(name, Constants.debounceRate);
  const debounceCurrency = useDebounce(currency, Constants.debounceRate);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (!isSearchByLei) {
      getAdditionalDataForMultipleISINs({
        variables: {
          isins: formatSearchValue(search),
          resourceParameters: {
            instrumentType: "OTHER",
            instrumentStatus: "LISTED",
          },
        },
      });
    }
  }, [getAdditionalDataForMultipleISINs, search, isSearchByLei]);

  useEffect(() => {
    if (!isSearchByLei) {
      searchByIsin && search.length > 12
        ? getInstrumentsByMultipleISINs({
            variables: {
              numberOfRecords: rowsPerPage,
              isins: formatSearchValue(search),
              resourceParameters: {
                instrumentType: "OTHER",
                instrumentStatus: "LISTED",
                authority: debouncedAuthority,
                nameAndISIN: debounceName,
                startDate: startDate ? startDate : undefined,
                endDate: endDate ? endDate : undefined,
                currency: debounceCurrency,
              },
              startingRecord: page === 0 ? 1 : page * rowsPerPage + 1,
            },
          })
        : isMounted &&
          getInstrumentResults({
            variables: {
              numberOfRecords: rowsPerPage,
              startingCharacters: search,
              resourceParameters: {
                instrumentType: "OTHER",
                instrumentStatus: "LISTED",
                authority: debouncedAuthority,
                nameAndISIN: debounceName,
                startDate: startDate ? startDate : undefined,
                endDate: endDate ? endDate : undefined,
                currency: debounceCurrency,
              },
              startingRecord: page === 0 ? 1 : page * rowsPerPage + 1,
            },
          });
    }
  }, [
    getInstrumentResults,
    getInstrumentsByMultipleISINs,
    rowsPerPage,
    search,
    isSearchByLei,
    searchByIsin,
    page,
    debouncedAuthority,
    debounceName,
    startDate,
    endDate,
    debounceCurrency,
    isMounted,
  ]);

  useEffect(() => {
    if (!isSearchByLei) {
      getAdditionalDataForInstrumentSuggestions({
        variables: {
          startingCharacters: search,
          resourceParameters: {
            instrumentType: "OTHER",
            instrumentStatus: "LISTED",
            authority: debouncedAuthority,
            nameAndISIN: debounceName,
            startDate: startDate ? startDate : undefined,
            endDate: endDate ? endDate : undefined,
            currency: debounceCurrency,
          },
        },
      });
    }
  }, [
    getAdditionalDataForInstrumentSuggestions,
    search,
    isSearchByLei,
    debouncedAuthority,
    debounceName,
    startDate,
    endDate,
    debounceCurrency,
  ]);

  useEffect(() => {
    if (getInstrumentResultsError) {
      setErrorMessage(getInstrumentResultsError.message);
    }
    if (getAdditionalDataForInstrumentSuggestionsError) {
      setErrorMessage(getAdditionalDataForInstrumentSuggestionsError.message);
    }
    if (instrumentResults && instrumentResults.getInstrumentSuggestionsResult) {
      setInstruments(instrumentResults.getInstrumentSuggestionsResult.instruments);
      if (additionalData && additionalData?.getAdditionalDataForInstrumentSuggestions) {
        setInstrumentAdditionalData(additionalData.getAdditionalDataForInstrumentSuggestions);
      }
    }
  }, [
    instrumentResults,
    additionalData,
    getInstrumentResultsError,
    getAdditionalDataForInstrumentSuggestionsError,
    setErrorMessage,
  ]);

  useEffect(() => {
    if (getAdditionalDataForMultipleISINsError) {
      setErrorMessage(getAdditionalDataForMultipleISINsError.message);
    }
    if (additionalISINsData && additionalISINsData.getAdditionalDataForMultipleISINs) {
      setInstrumentAdditionalISINsData(additionalISINsData.getAdditionalDataForMultipleISINs);
    }
  }, [additionalISINsData, getAdditionalDataForMultipleISINsError, setErrorMessage]);

  useEffect(() => {
    if (instrumentAdditionalISINsData && searchByIsin) {
      setAllCurrencies(instrumentAdditionalISINsData.currencies);
      setAllCountries(instrumentAdditionalISINsData.countries);
    }
  }, [instrumentAdditionalISINsData, searchByIsin]);

  useEffect(() => {
    if (getInstrumentsByMultipleISINsError) {
      setErrorMessage(getInstrumentsByMultipleISINsError.message);
    }
    if (multipleIsinsResults && multipleIsinsResults.getInstrumentsByMultipleISINs) {
      setInstruments(multipleIsinsResults.getInstrumentsByMultipleISINs.instruments);
    }
  }, [multipleIsinsResults, getInstrumentsByMultipleISINsError, setErrorMessage]);

  useEffect(() => {
    if (additionalData && !searchByIsin) {
      setAllCurrencies(additionalData.getAdditionalDataForInstrumentSuggestions.currencies);
      setAllCountries(additionalData.getAdditionalDataForInstrumentSuggestions.countries);
    }
  }, [additionalData, searchByIsin]);

  const handleRowsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPage(0);
    setRowsPerPage(event.target.value as number);
  };

  const handleCurrencyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCurrency(event.target.value as string);
    setPage(0);
  };

  const handleCountryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAuthority(event.target.value as string);
    setPage(0);
  };

  return (
    <CardStyle>
      <CardHeader
        title={
          <StyledBox>
            <TypographyStyle variant="h5">Other Instruments</TypographyStyle>
          </StyledBox>
        }
      />
      <CardContentStyle>
        {instrumentLoading || multipleIsinsLoading ? (
          <ProgressOverlay className={!instruments.length ? "empty" : ""}>
            <CircularProgress />
          </ProgressOverlay>
        ) : null}
        {!instruments.length && (!instrumentLoading || !multipleIsinsLoading) && !isFiltering ? (
          !instrumentLoading && !instruments.length ? (
            <EmptyCard>
              <SubjectIcon fontSize="large" color="inherit" />
              <Message>No results</Message>
            </EmptyCard>
          ) : null
        ) : (
          <>
            <TableFilters
              isFiltering={setIsFiltering}
              filters={[
                {
                  name: "Country",
                  value: authority,
                  handler: setAuthority,
                },
                {
                  name: "Name or ISIN",
                  value: name,
                  handler: setName,
                },
                {
                  name: "Start date",
                  value: startDate,
                  handler: setStartDate,
                },
                {
                  name: "End date",
                  value: endDate,
                  handler: setEndDate,
                },
                {
                  name: "Currency",
                  value: currency,
                  handler: setCurrency,
                },
              ]}
            />
            <TableScrollContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeaderCell>Country</TableHeaderCell>
                    <TableHeaderCell>Name</TableHeaderCell>
                    <TableHeaderCell>Period</TableHeaderCell>
                    <TableHeaderCell>Currency</TableHeaderCell>
                    <TableHeaderCell></TableHeaderCell>
                    <TableHeaderCell></TableHeaderCell>
                  </TableRow>
                  {!isUserPremium && (
                    <StyledUpgradeToUnlockRow>
                      <TableCell colSpan={8} align="center">
                        <UpgradeMessage>Upgrade to premium to unlock</UpgradeMessage>
                      </TableCell>
                    </StyledUpgradeToUnlockRow>
                  )}
                  <StyledFiltersRow>
                    <TableHeaderCell align="left">
                      <DropdownStyle isUserPremium={isUserPremium}>
                        <MuiThemeProvider theme={formControlTheme}>
                          <StyledFormControl variant="outlined">
                            <SelectLabel>
                              <InputLabel>Country</InputLabel>
                            </SelectLabel>
                            <Select
                              id="SelectCountry"
                              disabled={!isUserPremium}
                              value={authority}
                              onChange={handleCountryChange}
                              label="Country"
                            >
                              {allCountries && allCountries.length > 0 ? (
                                allCountries.map((item: string) => (
                                  <MenuItem key={item} value={item}>
                                    {item}
                                  </MenuItem>
                                ))
                              ) : (
                                <FilterLoader />
                              )}
                            </Select>
                          </StyledFormControl>
                        </MuiThemeProvider>
                      </DropdownStyle>
                    </TableHeaderCell>
                    <TableHeaderCell align="left">
                      <StyledTextField
                        disabled={!isUserPremium}
                        value={name}
                        fullWidth
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Filter by name or ISIN"
                      />
                    </TableHeaderCell>
                    <TableHeaderCell align="left">
                      <Box style={{ display: "flex" }}>
                        <StyledDatePicker
                          disabled={!isUserPremium}
                          autoOk
                          clearable
                          placeholder="Start date"
                          value={startDate}
                          format="DD/MM/yyyy"
                          onChange={(date: any) => setStartDate(date ? date.toDate() : null)}
                        />

                        <StyledDatePicker
                          disabled={!isUserPremium}
                          autoOk
                          clearable
                          placeholder="End date"
                          value={endDate}
                          format="DD/MM/yyyy"
                          onChange={(date: any) => setEndDate(date ? date.toDate() : null)}
                        />
                      </Box>
                    </TableHeaderCell>
                    <TableHeaderCell align="left">
                      <DropdownStyle isUserPremium={isUserPremium}>
                        <MuiThemeProvider theme={formControlTheme}>
                          <StyledFormControl variant="outlined">
                            <SelectLabel>
                              <InputLabel>Currency</InputLabel>
                            </SelectLabel>
                            <Select
                              id="SelectCurrency"
                              disabled={!isUserPremium}
                              value={currency}
                              onChange={handleCurrencyChange}
                              label="Currency"
                            >
                              {allCurrencies && allCurrencies.length > 0 ? (
                                allCurrencies.map((item: string) => (
                                  <MenuItem key={item} value={item}>
                                    {item}
                                  </MenuItem>
                                ))
                              ) : (
                                <FilterLoader />
                              )}
                            </Select>
                          </StyledFormControl>
                        </MuiThemeProvider>
                      </DropdownStyle>
                    </TableHeaderCell>
                    <TableHeaderCell> </TableHeaderCell>
                    <TableHeaderCell></TableHeaderCell>
                  </StyledFiltersRow>
                </TableHead>
                <StyledTableBody loading={instrumentLoading ? 1 : 0}>
                  {instruments.map((instrument: InstrumentNew, i: number) => (
                    <TableRowStyle
                      key={i}
                      onClick={() => history.push(`/instrument/${instrument.instrumentIdentificationCode}`)}
                      hover
                    >
                      <TableMainCell id="search_page__instrument" component="th" scope="row" align="center">
                        {instrument.upcomingRCA ? (
                          <CustomFlag code={instrument.upcomingRCA} alt={instrument.upcomingRCA} />
                        ) : (
                          <CustomFlag code={"EU"} alt={"EU"} />
                        )}
                      </TableMainCell>
                      <TableNameCell id="search_page__instrument" component="th" scope="row">
                        <Typography variant="body1">
                          {instrument.instrumentIdentificationCode}
                          <br />
                          <Typography component="span">{instrument.instrumentFullName}</Typography>
                        </Typography>
                      </TableNameCell>
                      <TableCell id="search_page__instrument" align="center">
                        <PeriodProgress
                          maturityDate={instrument.maturityDate}
                          terminationDate={instrument.terminationDate}
                          issueDate={instrument.firstTradeDate}
                        />
                      </TableCell>
                      <TableCell id="search_page__instrument">{instrument.notionalCurrency}</TableCell>
                      <TableCell id="search_page__instrument"></TableCell>
                      <TableCell id="search_page__instrument"></TableCell>
                    </TableRowStyle>
                  ))}
                </StyledTableBody>
              </Table>
            </TableScrollContainer>
            {!instruments.length && !instrumentLoading && (
              <EmptyCard>
                <SubjectIcon fontSize="large" color="inherit" />
                <Message>No results</Message>
              </EmptyCard>
            )}
            <StyledTablePagination>
              <TablePagination
                rowsPerPage={rowsPerPage}
                handleRowsChange={handleRowsChange}
                page={page}
                setPage={setPage}
                totalInstrumentElements={instrumentAdditionalData?.totalIssuedAmount}
                loading={loadingAdditionalDataISINs || loadingAdditionalDataInstr}
              />
            </StyledTablePagination>
          </>
        )}
      </CardContentStyle>
    </CardStyle>
  );
};

export { OtherInstruments };
