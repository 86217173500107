import { Box, Card, Table, TableCell, TableRow } from "@material-ui/core";
import {createMuiTheme} from "@material-ui/core/styles";
import styled from "styled-components";
import mediaQuery from "../../common/mediaQuery";

export const StyledTable = styled(Table)`
  th {
    font-size: 17px;
    font-weight: bold;
    white-space: nowrap;
  }

  tbody tr:last-child td {
    border-bottom: none;
  }

  td:first-child {
    width: 110px;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    th {
      font-size: 0.8rem;
    }

    td {
      font-size: 0.7rem;
    }
  }

  ${mediaQuery.SM} {
    th {
      font-size: 0.8rem;
    }

    td {
      font-size: 0.7rem;
    }
  }
`;

export const TableNameCell = styled(TableCell)`
  p {
    margin: 5px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 180px;
  }

  p:first-child {
    font-weight: bold;
  }
`;

export const TablePeriodCell = styled(TableCell)`
  width: 220px;
  min-width: 220px;

  & > div {
    margin-top: 0;
    font-size: 13px;
  }

  & div:first-child {
    height: 13px;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    min-width: 170px;

    & div:first-child {
      height: 11px;
    }
  }

  ${mediaQuery.SM} {
    min-width: 170px;

    & div:first-child {
      height: 11px;
    }
  }
`;

export const StyledBox = styled(Box)`
  display: flex;
  justify-content: space-between;

  a {
    font-weight: 600;
  }

  a:hover {
    cursor: pointer;
    color: #40a9ff;
    text-decoration: none;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    h5 {
      font-size: 1.1rem;
    }
  }

  ${mediaQuery.SM} {
    h5 {
      font-size: 1.1rem;
    }
  }
`;

export const themeTypography = createMuiTheme({
  typography: {
    h5: {
      fontWeight: 700,
    },
  },
});

export const RecentInstrumentsCardWrapper = styled(Card)`
  ${mediaQuery.LG} {
    & > div:last-child {
      overflow-x: scroll;
    }
  }

  ${mediaQuery.MD} {
    & > div:last-child {
      overflow-x: scroll;
    }
  }

  ${mediaQuery.SM_LANDSCAPE} {
    .MuiCardContent-root {
      padding: 0;
    }
  }

  ${mediaQuery.SM} {
    .MuiCardContent-root {
      padding: 0;
    }
  }
`;

export const TableRowStyle = styled(TableRow)`
  cursor: pointer;

  & > th > *,
  & > td > * {
    pointer-events: none;
  }

  & > th,
  & > td {
    pointer-events: all;
  }
`;
