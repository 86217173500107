import styled from "styled-components";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Grid, CardContent, Button } from "@material-ui/core";
import mediaQuery from "../../common/mediaQuery";

interface CircleColorInterface {
  circleColor: string;
}

export const GridStyle = styled(Grid)`
  width: inherit;
`;

export const CardContentStyle = styled(CardContent)`
  margin: 0 10px;
`;

export const ImageContainer = styled.div`
  min-height: 145px;
`;

export const InfoSection = styled.div`
  min-height: 80px;
  margin: 0 0 20px 0;

  p {
    margin-top: 5px;
  }

  ${mediaQuery.EXTRA_LG} {
    min-height: 10px;
  }
`;

export const FeatureSection = styled.div`
  min-height: 110px;
`;

export const CircleIcon = styled(CheckCircleIcon)`
  font-size: 16px;
  margin: 0 5px 0 0;
`;

export const CircleColor = styled.div<CircleColorInterface>`
  color: ${(props) => props.circleColor};
`;

export const Image = styled.img`
  display: block;
  margin: 0 auto 20px;
`;

export const Feature = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
`;

export const SubscribeButtonStyles = styled(Button)`
  display: block;
  margin: 25px auto 0;
  width: 239px;
  height: 48px;
  color: #fff;
  background-color: #3f18aa;
  font-size: 16px;
  font-weight: 300;
  z-index: 2;
  border-radius: 10px;

  button > * {
    pointer-events: none;
  }

  &:hover {
    background-color: #3f18aa;
  }

  ${mediaQuery.LG} {
    width: 155px;
  }
`;

export const ContactInfo = styled.div`
  font-weight: 700;
  margin: 37px 0 15px 0;
  text-align: center;

  a {
    color: #3f18aa;
  }
`;
