import { CardContent, CardHeader, Table, TableCell, TableHead, TableRow } from "@material-ui/core";
import SubjectIcon from "@material-ui/icons/Subject";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SubscribeContext } from "../../common/subcriptionProvider";
import { useLazyGetUnderlyingInstruments } from "../../graphql/GetUnderlyingInstruments";
import Loader from "../loader/Loader";
import {
  CardStyle,
  ChipStyle,
  EmptyCard,
  LoaderOverlay,
  StyTableBody,
  TypographyStyle,
} from "./UnderlyingInstruments.css";
import { Props, UnderlyingInstrumentsInterface } from "./UnderlyingInstrumentsInterface";

const UnderlyingInstruments: React.FC<Props> = ({ underlyingIsins }) => {
  const [underlyingInstruments, setUnderlyingInstruments] = useState<any>([]);
  const [underlyingResultNew, setUnderlyingResultNew] = useState<any>([]);
  const [isins, setIsins] = useState<Array<string>>([]);
  const { setErrorMessage } = useContext(SubscribeContext);

  const history = useHistory();

  useEffect(() => {
    setIsins(underlyingIsins);
    return () => {
      setIsins([]);
    };
  }, [underlyingIsins]);

  useEffect(() => {
    const underlyingInstrIsins = underlyingInstruments.map((item: any) => item.isin);
    const uniqueIsins = underlyingIsins?.filter((item: any) => underlyingInstrIsins?.indexOf(item) === -1);

    if (uniqueIsins?.length) {
      const result: any = [];
      uniqueIsins.map((uniqueIsin: string) => result.push({ isin: uniqueIsin, isClickable: true }));
      setUnderlyingResultNew([...underlyingInstruments, ...result]);
    }
  }, [underlyingInstruments, underlyingIsins]);

  const underlyingResults =
    underlyingIsins?.length === underlyingInstruments?.length ? underlyingInstruments : underlyingResultNew;

  const [
    getUnderlyingInstruments,
    { data: instrumentsData, loading: loadingUnderlyingInstruments, error: getUnderlyingInstrumentsError },
  ] = useLazyGetUnderlyingInstruments();

  useEffect(() => {
    if (isins?.length) {
      getUnderlyingInstruments({ variables: { isins } });
    }
  }, [isins]);

  useEffect(() => {
    if (getUnderlyingInstrumentsError) {
      setErrorMessage(getUnderlyingInstrumentsError.message);
    }
    if (instrumentsData) {
      setUnderlyingInstruments(instrumentsData.getUnderlyingInstruments);
    }
  }, [instrumentsData, getUnderlyingInstrumentsError, setErrorMessage]);

  return (
    <CardStyle>
      <CardHeader title={<TypographyStyle variant="h5">Underlying instrument(s)</TypographyStyle>} />
      {loadingUnderlyingInstruments && (
        <LoaderOverlay loaderPosition={underlyingInstruments && underlyingInstruments.length}>
          <Loader />
        </LoaderOverlay>
      )}

      <CardContent>
        {underlyingResults?.length > 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ISIN</TableCell>
                <TableCell>Instrument name</TableCell>
                <TableCell>Issuer LeI</TableCell>
              </TableRow>
            </TableHead>
            <StyTableBody>
              {!loadingUnderlyingInstruments &&
                underlyingResults.map((instrument: UnderlyingInstrumentsInterface) => (
                  <TableRow key={instrument.isin}>
                    <TableCell>
                      <ChipStyle
                        id="instrument_page__undelying_instruments__ISIN"
                        label={instrument.isin}
                        clickable={!instrument.isClickable}
                        onClick={() => !instrument.isClickable && history.push(`/instrument/${instrument.isin}`)}
                      />
                    </TableCell>
                    <TableCell>{instrument.instrumentFullName}</TableCell>
                    <TableCell>
                      <ChipStyle
                        id="instrument_page__LEI"
                        label={instrument.issuer}
                        clickable
                        onClick={() => history.push(`/issuer/${instrument.issuer}`)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </StyTableBody>
          </Table>
        ) : (
          <EmptyCard>
            <SubjectIcon fontSize="large" color="inherit" />
            <div>No underlying instruments.</div>
          </EmptyCard>
        )}
      </CardContent>
    </CardStyle>
  );
};

export default UnderlyingInstruments;
