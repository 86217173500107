import { Card } from "@material-ui/core";
import styled from "styled-components";
import mediaQuery from "../../common/mediaQuery";

export const CardStyle = styled(Card)`
  min-height: 194px;

  ${mediaQuery.XXL} {
    min-height: auto;
  }
`;

export const WelcomeText = styled.div`
  font-size: 32px;
  font-weight: 700;

  ${mediaQuery.SM_LANDSCAPE} {
    font-size: 25px;
  }

  ${mediaQuery.SM} {
    font-size: 25px;
  }
`;

export const WelcomeSubText = styled.div`
  font-size: 14px;
  font-weight: 500;

  ${mediaQuery.SM_LANDSCAPE} {
    font-size: 12px;
  }

  ${mediaQuery.SM} {
    font-size: 12px;
  }
`;

export const WelcomeMessage = styled.div`
  height: 80px;

  ${mediaQuery.SM_LANDSCAPE} {
    font-size: 12px;
  }

  ${mediaQuery.SM} {
    font-size: 12px;
  }
`;

export const Link = styled.span`
  color: #3f18aa;
  font-weight: 700;
  cursor: pointer;
`;

export const SignUpText = styled.div`
  margin: 20px 0 0 0;
`;

export const UpdateMessage = styled.div`
  font-weight: 700;

  span:nth-child(2) {
    margin-left: 5px;
  }

  span:nth-child(3) {
    display: block;
    font-size: 11px;
    font-weight: normal;
    color: #000;
    margin-top: 3px;
  }

  div {
    margin-left: 10px;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    font-size: 11px;
  }

  ${mediaQuery.SM} {
    font-size: 12px;
  }
`;
