import moment from "moment";

export const tooltips: any = {
  "Mifir Identifier": "The MiFIR Identifier of the instrument",
  "Liquidity Flag": "A flag indicating whether the instrument is liquid",
  "The pre-trade Large-In-Scale threshold (LIS)": null,
  "The post-trade Large-In-Scale threshold (LIS)": null,
  "Pre-trade Size-Specific-to-the-Instrument threshold (SSTI)": null,
  "Post-trade Size-Specific-to-the-Instrument threshold (SSTI)": null,
  "Calculation methodology": "Type of calculation (Methodology)",
  "Average Daily Turnover (ADT)": null,
  "Average Value of Transactions (ATV)": null,
  "Standard Market Size (SMS)": null,
  "Average Daily Number of Transactions (ADNTE)": null,
  "Total Number of Transactions": "The total number of transactions executed",
  "Total Volume of Transactions": "The total volume of transactions executed",
  "Most Relevant Market in Terms of Liquidity (MRMTL)":
    "The most relevant market in terms of liquidity for the purpose of MiFIR Art.4(1)(a)",
  "Average Daily Number of Transactions on MRMTL":
    "The average daily number of transactions on the most relevant market in terms of liquidity",
};

const generateTransparencyCalculationDetailsRows = (calculation: any) => {
  const {
    mifidIdentifier,
    liquidity,
    preTradeLargeInScaleThresholdCurrency,
    preTradeLargeInScaleThresholdAmount,
    postTradeLargeInScaleThresholdAmount,
    postTradeLargeInScaleThresholdCurrency,
    preTradeSizeSpecificToTheInstrumentThresholdAmount,
    preTradeSizeSpecificToTheInstrumentThresholdCurrency,
    postTradeSizeSpecificToTheInstrumentThresholdAmount,
    postTradeSizeSpecificToTheInstrumentThresholdCurrency,
    calculationMethodology,
    averageDailyTurnoverAmount,
    averageDailyTurnoverCurrency,
    averageValueOfTransactionsAmount,
    averageValueOfTransactionsCurrency,
    standardMarketSize,
    averageDailyNumberOfTransactions,
    totalNumberOfTransactions,
    totalVolumeOfTransactions,
    mostRelevantMarketInTermsOfLiquidity,
    averageDailyNumberOfTransactionsOnMRMTL,
    tradingActivityPeriodStart,
    tradingActivityPeriodEnd,
  } = calculation;
  return {
    ...(liquidity !== null && { "Liquidity Flag": mapLiquidityFlag(liquidity) }),
    ...(countMethodology(calculationMethodology, tradingActivityPeriodStart, tradingActivityPeriodEnd) && {
      "Calculation methodology": countMethodology(
        calculationMethodology,
        tradingActivityPeriodStart,
        tradingActivityPeriodEnd
      ),
    }),
    ...(averageDailyTurnoverAmount &&
      averageDailyTurnoverCurrency && {
        "Average Daily Turnover (ADT)": separateNumber(averageDailyTurnoverAmount) + averageDailyTurnoverCurrency,
      }),
    ...(averageValueOfTransactionsAmount &&
      averageValueOfTransactionsCurrency && {
        "Average Value of Transactions (ATV)":
          separateNumber(averageValueOfTransactionsAmount) + averageValueOfTransactionsCurrency,
      }),
    ...(standardMarketSize && { "Standard Market Size (SMS)": separateNumber(standardMarketSize) }),
    ...(averageDailyNumberOfTransactions && {
      "Average Daily Number of Transactions (ADNTE)": separateNumber(averageDailyNumberOfTransactions),
    }),
    ...(preTradeLargeInScaleThresholdAmount &&
      preTradeLargeInScaleThresholdCurrency && {
        "The pre-trade Large-In-Scale threshold (LIS)":
          separateNumber(preTradeLargeInScaleThresholdAmount) + preTradeLargeInScaleThresholdCurrency,
      }),
    ...(postTradeLargeInScaleThresholdAmount &&
      postTradeLargeInScaleThresholdCurrency && {
        "The post-trade Large-In-Scale threshold (LIS)":
          separateNumber(postTradeLargeInScaleThresholdAmount) + postTradeLargeInScaleThresholdCurrency,
      }),
    ...(preTradeSizeSpecificToTheInstrumentThresholdAmount &&
      preTradeSizeSpecificToTheInstrumentThresholdCurrency && {
        "Pre-trade Size-Specific-to-the-Instrument threshold (SSTI)":
          separateNumber(preTradeSizeSpecificToTheInstrumentThresholdAmount) +
          preTradeSizeSpecificToTheInstrumentThresholdCurrency,
      }),
    ...(postTradeSizeSpecificToTheInstrumentThresholdAmount &&
      postTradeSizeSpecificToTheInstrumentThresholdCurrency && {
        "Post-trade Size-Specific-to-the-Instrument threshold (SSTI)":
          separateNumber(postTradeSizeSpecificToTheInstrumentThresholdAmount) +
          postTradeSizeSpecificToTheInstrumentThresholdCurrency,
      }),
    ...(totalNumberOfTransactions && { "Total Number of Transactions": separateNumber(totalNumberOfTransactions) }),
    ...(totalVolumeOfTransactions && { "Total Volume of Transactions": separateNumber(totalVolumeOfTransactions) }),
    ...(mostRelevantMarketInTermsOfLiquidity && {
      "Most Relevant Market in Terms of Liquidity (MRMTL)": mostRelevantMarketInTermsOfLiquidity,
    }),
    ...(averageDailyNumberOfTransactionsOnMRMTL && {
      "Average Daily Number of Transactions on MRMTL": separateNumber(averageDailyNumberOfTransactionsOnMRMTL),
    }),
    ...(mifidIdentifier && { "Mifir Identifier": mifidIdentifier }),
  };
};

export const mapTransparencyCalculationRows = (selectedFileCalculation: any): any => {
  return {
    transparencyData: generateTransparencyCalculationDetailsRows(selectedFileCalculation),
    labelTooltips: tooltips,
  };
};

export const calcDaysProgress = (transparencyData: any) => {
  const currentDate = moment();

  const startDate = transparencyData.tradingActivityPeriodStart
    ? moment(transparencyData.tradingActivityPeriodStart)
    : null;
  const calculationTime = transparencyData.calculationTime ? moment(transparencyData.calculationTime) : null;
  const endDate = transparencyData.tradingActivityPeriodEnd ? moment(transparencyData.tradingActivityPeriodEnd) : null;
  let progressResult;

  if (!startDate || !endDate || !calculationTime) {
    return {
      progress: 0,
      startDate: startDate ? startDate.format("DD/MM/YYYY") : null,
      publicationDate: calculationTime ? calculationTime.format("DD/MM/YYYY") : null,
      endDate: endDate ? endDate.format("DD/MM/YYYY") : null,
    };
  } else {
    if (endDate.unix() < currentDate.unix() && startDate.unix() < currentDate.unix()) {
      progressResult = 100;
    } else if (endDate.unix() > currentDate.unix() && startDate.unix() > currentDate.unix()) {
      progressResult = 0;
    } else if (endDate.unix() > currentDate.unix() && startDate.unix() < currentDate.unix()) {
      const durationDays = (endDate.unix() - startDate.unix()) / (3600 * 24);
      const leftDays = (endDate.unix() - currentDate.unix()) / (3600 * 24);
      progressResult = (leftDays / durationDays) * 100;
    }
    return {
      progress: progressResult,
      startDate: startDate.format("DD/MM/YYYY"),
      publicationDate: calculationTime.format("DD/MM/YYYY"),
      endDate: endDate.format("DD/MM/YYYY"),
    };
  }
};

const mapLiquidityFlag = (liquidity: boolean) => {
  return liquidity ? "Liquid" : "Not Liquid";
};

export const separateNumber = (number: string) => {
  if (number.includes(".")) {
    return number.split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "." + number.split(".")[1] + " ";
  } else {
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " ";
  }
};

export const countMethodology = (methodology: string, periodStart: string, periodEnd: string) => {
  if (methodology) {
    return methodology;
  }

  if (!(periodStart && periodEnd)) {
    return "ESTM";
  } else {
    const startDay = periodStart.substr(8, 2);
    const startMonth = periodStart.substr(5, 2);
    const endDay = periodEnd.substr(8, 2);
    const endMonth = periodEnd.substr(5, 2);

    const startDate = new Date(periodStart.slice(0, 10));
    const endDate = new Date(periodEnd.slice(0, 10));

    const diffInDays = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);

    if (startDay === "01" && startMonth === "01" && endDay === "31" && endMonth === "12") {
      return "YEAR";
    } else if (
      (startDay === "01" && startMonth === "01" && endDay === "31" && endMonth === "03") ||
      (startDay === "01" && startMonth === "04" && endDay === "30" && endMonth === "06") ||
      (startDay === "01" && startMonth === "07" && endDay === "30" && endMonth === "09") ||
      (startDay === "01" && startMonth === "10" && endDay === "31" && endMonth === "12")
    ) {
      return "QUARTER";
    } else if (
      (startDay === "01" && startMonth === "01" && endDay === "30" && endMonth === "06") ||
      (startDay === "01" && startMonth === "04" && endDay === "30" && endMonth === "09") ||
      (startDay === "01" && startMonth === "07" && endDay === "31" && endMonth === "12") ||
      (startDay === "01" && startMonth === "10" && endDay === "31" && endMonth === "03")
    ) {
      return "SINT";
    } else if (diffInDays === 27) {
      return "FFWK";
    } else {
      return null;
    }
  }
};
