import { FormControl, MenuItem, Select, TableCell } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {createMuiTheme} from "@material-ui/core/styles";
import styled from "styled-components";
import mediaQuery from "../../common/mediaQuery";

interface SelectStyleInterface {
  disabled: boolean;
}

export const SelectStyle = styled(Select)<SelectStyleInterface>`
  background-color: ${(props) => (props.disabled ? "#e3e3e3" : "")};
  font-size: 0.8rem;
`;

export const UpgradeMessage = styled.div`
  width: max-content;
  color: #959595;
  font-size: 12px;
`;

export const TableMainCell = styled(TableCell)`
  position: relative;
  padding: 10px 5px 10px 0;
`;

export const TableCountryCell = styled(TableCell)`
  position: relative;
  padding: 10px 5px 10px 0;
  max-width: 110px;
  & > div:first-child > div:nth-child(2) {
    max-width: 110px;
  }

  ${mediaQuery.SM} {
    svg {
      right: 0;
    }
  }
`;

export const TableCurrencyCell = styled(TableCell)`
  position: relative;
  padding: 10px 5px 10px 0;
  max-width: 110px;
  & > div:first-child > div:nth-child(2) {
    max-width: 110px;
  }

  ${mediaQuery.SM} {
    svg {
      right: 0;
    }
  }
`;

export const TableTradingVenueCell = styled(TableCell)`
  position: relative;
  padding: 10px 5px 10px 0;

  ${mediaQuery.SM} {
    svg {
      right: 0;
    }
  }

  ${mediaQuery.XXL} {
    max-width: 170px;
  }
`;

export const TableInstrumentTypCeell = styled(TableCell)`
  position: relative;
  padding: 10px 5px 10px 0;
  max-width: 175px;
  & > div:first-child > div:nth-child(2) {
    max-width: 175px;
  }

  ${mediaQuery.SM} {
    svg {
      right: 0;
    }
  }
`;

export const TableInstrumentCategoryCell = styled(TableCell)`
  position: relative;
  padding: 10px 5px 10px 0;
  max-width: 185px;
  & > div:first-child > div:nth-child(2) {
    max-width: 185px;
  }
`;

export const SelectLabel = styled.div`
  label {
    top: -10px;
    font-size: 0.8rem;
    color: #b7b7b6;
  }
`;

export const formControlTheme = createMuiTheme({
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "#F7F8F6",
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        "&$shrink": {
          transform: "translate(15px, 6px) scale(0.75)",
        },
      },
    },
  },
});

export const useStyles = makeStyles((theme) => ({
  menuPaper: {
    maxHeight: 500,
  },
  [theme.breakpoints.down("md")]: {
    menuPaper: {
      maxHeight: 250,
    },
  },
}));

export const StyledFormControl = styled(FormControl)`
  display: flex;
  font-size: 0.8rem;
  max-height: 500px;

  & > div:nth-child(2) {
    max-height: 33px;
  }
`;

interface IMenuItem {
  name?: string;
  miccode?: string;
}

export const StyledMenuItem = styled(MenuItem)<IMenuItem>`
  font-size: 0.8rem;

  ${mediaQuery.SM} {
    min-height: unset;
    white-space: unset;
  }
`;

export const SelectItem = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;

  span {
    &:nth-child(1) {
      font-weight: 700;
    }
  }

  ${mediaQuery.SM} {
    font-size: 10px;
  }
`;
