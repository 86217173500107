import { Card, Grid, makeStyles } from "@material-ui/core";
import styled from "styled-components";
import mediaQuery from "../../../common/mediaQuery";

interface DisableInterface {
  isUserPremium: boolean | null;
}

export const ProgressBarContainer = styled.div`
  margin: 14px 0;

  ${mediaQuery.SM_LANDSCAPE} {
    margin: 0;
  }
  ${mediaQuery.SM} {
    margin: 0;
  }
`;

export const Legend = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const Title = styled.div`
  span {
    font-weight: 700;
  }
`;

export const CardStyle = styled(Card)`
  position: relative;
`;

export const EmptyCard = styled.div`
  text-align: center;
  color: #cbcbcb;
`;

export const CardContentStyle = styled.div<DisableInterface>`
  .MuiCardContent-root {
    filter: ${(props) => (props.isUserPremium ? "" : "blur(10px)")};
  }
`;

export const Link = styled.div`
  cursor: pointer;
`;

export const GridRight = styled(Grid)`
  align-content: flex-start;
`;

export const useStyles = makeStyles({ 
  
  root: { height: "100%", position: "relative" },
  title: {
    fontSize: "24px",
    fontWeight: 'bold'
  },
});
