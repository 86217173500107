import { FormControl, InputBase, TableCell } from "@material-ui/core";
import {createMuiTheme} from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { DatePicker } from "@material-ui/pickers";
import styled from "styled-components";
import mediaQuery from "../../common/mediaQuery";

interface DisableInterface {
  isUserPremium?: boolean | null;
  disabled?: boolean;
  filter?: string | boolean;
}

export const SelectLabel = styled.div`
  label {
    font-size: 14px;
    top: -11px;
    color: #b7b7b6;
  }
`;

export const TableMainCell = styled(TableCell)`
  position: relative;
  padding: 3px 5px 3px 0;
`;

export const DateCell = styled.div<DisableInterface>`
  display: ${(props) => (props.filter ? "flex" : "none")};

  div:nth-child(2) {
    margin-left: 5px;
  }
`;

export const SearchStyles = styled.div<DisableInterface>`
  position: relative;
  border: 1px solid #70707080;
  border-radius: 4px;
  margin-left: 0;
  min-width: 200px;
  width: 100%;
  display: ${(props) => (props.filter ? "block" : "none")};
`;

export const DropdownStyle = styled.div<DisableInterface>`
  display: ${(props) => (props.filter ? "block" : "none")};
  width: fit-content;
`;

export const formControlTheme = createMuiTheme({
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "none",
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        "&$shrink": {
          transform: "translate(15px, 6px) scale(0.75)",
        },
      },
    },
  },
});

export const StyledDatePicker = styled(DatePicker)<DisableInterface>`
  & ::after,
  & ::before {
    display: none;
  }

  border-radius: 4px;
  border: 1px solid #70707080;
  padding: 0 5px;
  height: 31px;
  width: 100%;

  & input {
    font-size: 14px;
  }

  background-color: ${(props) => (props.disabled ? "#e3e3e3" : "")};

  ${mediaQuery.SM_LANDSCAPE} {
    height: 28px;
    width: 80px;

    & input {
      font-size: 0.65rem;
    }
  }

  ${mediaQuery.SM} {
    height: 28px;
    width: 80px;

    & input {
      font-size: 0.65rem;
    }
  }
`;

export const DatePickerTheme = createMuiTheme({
  overrides: {
    MuiInput: {
      root: {
        top: "1px",
        left: "10px",
      },
    },
  },
});

export const StyledSearchIcon = styled(SearchIcon)`
  margin-left: 16px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #70707080;

  ${mediaQuery.SM_LANDSCAPE} {
    margin-left: 2px;
  }

  ${mediaQuery.SM} {
    margin-left: 2px;
  }
`;

export const StyledInput = styled(InputBase)`
  color: inherit;
  height: 29px;
  padding: 6px 0 5px 45px;
  font-size: 14px;


  ${mediaQuery.SM_LANDSCAPE} {
    height: 25px;
    width: 160px;
    font-size: 0.65rem;
  }

  ${mediaQuery.SM} {
    height: 25px;
    width: 160px;
    font-size: 0.65rem;
  }
`;

export const StyledSelect = styled(FormControl)`
  min-width: 110px;

  div:nth-child(2n) {
    max-height: 31px;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    div:last-child {
      font-size: 0.65rem;
    }

    & label {
      font-size: 0.65rem;
      top: -11px;
    }

    div:nth-child(2n) {
      max-height: 28px;
    }
  }

  ${mediaQuery.SM} {
    & label {
      font-size: 0.65rem;
      top: -11px;
    }

    div:nth-child(2n) {
      max-height: 28px;
    }
  }
`;
