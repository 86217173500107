import React, { useEffect } from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { StylesProvider } from "@material-ui/core/styles";
import { authProvider } from "./common/authProvider";
import AzureAD, { IAzureADFunctionProps } from "react-aad-msal";
import AppProvider from "./common/store";
import AppRouterWithProviders from "./components/AppRouterWithProviders/AppRouterWithProviders";
import { hotJarConnectionString, googleAnalyticsConnectionString, googleTagManagerHeader } from "./common/constants";

declare const window: any;

const hotJarID = window._env_.REACT_APP_HOTJAR_ID;
const googleAnalyticsID = window._env_.GOOGLE_ANALYTICS_ID;

const App = () => {
  useEffect(() => {
    if (googleAnalyticsID && googleAnalyticsID === "G-ZTFNX064XN") {
      const scriptAsync = document.createElement("script");
      scriptAsync.type = "text/javascript";
      scriptAsync.async = true;
      scriptAsync.src = "https://www.googletagmanager.com/gtag/js?id=G-ZTFNX064XN";

      const script = document.createElement("script");
      scriptAsync.type = "text/javascript";
      script.text = googleAnalyticsConnectionString;
      document.head.appendChild(scriptAsync);
      document.head.appendChild(script);
    }

    if (hotJarID && hotJarID === "1477370") {
      const script = document.createElement("script");
      script.text = hotJarConnectionString;
      document.head.appendChild(script);
    }

    const googleTagManagerScript = document.createElement("script");
    googleTagManagerScript.text = googleTagManagerHeader;
    document.head.prepend(googleTagManagerScript);

    const noScriptTag = document.createElement("noscript");
    const IFrameTag = document.createElement("iframe");
    IFrameTag.src = "https://www.googletagmanager.com/ns.html?id=GTM-599ZMRZ";
    IFrameTag.height = "0";
    IFrameTag.width = "0";
    IFrameTag.style.display = "none";
    IFrameTag.style.visibility = "hidden";
    noScriptTag.appendChild(IFrameTag);
    document.body.prepend(noScriptTag);
  }, []);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <StylesProvider injectFirst>
        <AppProvider>
          <AzureAD provider={authProvider}>
            {(props: IAzureADFunctionProps): JSX.Element => <AppRouterWithProviders azureProps={props} />}
          </AzureAD>
        </AppProvider>
      </StylesProvider>
    </MuiPickersUtilsProvider>
  );
};

export default App;

