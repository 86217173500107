import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import InputAdornment from "@material-ui/core/InputAdornment";
import Link from "@material-ui/core/Link";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Search from "@material-ui/icons/Search";
import { formatMoney } from "accounting";
import React, { useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import theme from "../../common/theme";
import { DebtInstrument } from "../../common/types";

const useStyles = makeStyles({
  table: {
    "@media print": {
      "& p": {
        fontSize: 8,
      },
      "& .hidePrintCell": {
        display: "none",
      },
    },
  },
  label: {
    color: theme.palette.text.secondary,
  },
  tableCardFooter: {
    "margin-left": "15px",
    "width": "100%",
    "max-width": "400px",
  },
});

interface OwnProps {
  instruments: DebtInstrument[];
}

type ComponentProps = OwnProps;

function InstrumentsWithinTable({ instruments }: ComponentProps) {
  const classes = useStyles();
  const history = useHistory();

  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const displayedInstruments =
    searchText.length > 0
      ? instruments.filter(
          (instrument) =>
            instrument.finInstrmGnlAttrbts.fullNm.match(new RegExp(searchText, "i")) ||
            instrument.finInstrmGnlAttrbts.isin.match(new RegExp(searchText, "i"))
        )
      : instruments;

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Table size="small" className={classes.table}>
      <TableHead>
        <TableRow>
          <td>
            <TextField
              placeholder="Filter by name or ISIN"
              value={searchText}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPage(0);
                setSearchText(event.target.value);
              }}
              className={classes.tableCardFooter}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </td>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography variant="body1">Name</Typography>
          </TableCell>
          <TableCell className="hidePrintCell">
            <Typography variant="body1">Progress</Typography>
          </TableCell>
          <TableCell align="right">
            <Typography variant="body1">Issued Amount</Typography>
          </TableCell>
          <TableCell align="right">
            <Typography variant="body1">Value Per Unit</Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="body1">Classification</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {displayedInstruments
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((instrument: DebtInstrument, i: number) => (
            <TableRow key={i} onClick={() => history.push(`/instrument/${instrument.finInstrmGnlAttrbts.isin}`)} hover>
              <TableCell component="th" scope="row">
                <Typography variant="body1">
                  <Link component={RouterLink} to={`/instrument/${instrument.finInstrmGnlAttrbts.isin}`}>
                    {instrument.finInstrmGnlAttrbts.fullNm}
                  </Link>
                  <br />
                  <Typography component="span" color="textSecondary">
                    {`ISIN: ${instrument.finInstrmGnlAttrbts.isin}`}
                  </Typography>
                </Typography>
              </TableCell>
              <TableCell style={{ width: "30%" }} className="hidePrintCell">
                {/* period here */}
              </TableCell>
              <TableCell align="right" style={{ whiteSpace: "nowrap" }}>
                <Typography variant="body1">
                  {formatMoney(instrument.debtInstrmAttrbts.ttlIssdNmnlAmt?.text || 0, {
                    symbol: instrument.debtInstrmAttrbts.ttlIssdNmnlAmt?.ccy,
                    format: "%s",
                  })}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body1">
                  {formatMoney(instrument.debtInstrmAttrbts.nmnlValPerUnit?.text || 0, {
                    symbol: instrument.debtInstrmAttrbts.nmnlValPerUnit?.ccy,
                    format: "%s %v",
                  })}
                </Typography>
              </TableCell>
              <TableCell align="center" style={{ whiteSpace: "nowrap" }}>
                <Typography variant="body1">{instrument.finInstrmGnlAttrbts.clssfctnTp}</Typography>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
      {instruments.length > 0 && (
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              count={displayedInstruments.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              style={{ borderBottom: "0" }}
            />
          </TableRow>
        </TableFooter>
      )}
    </Table>
  );
}

function InstrumentsWithinExpandablePanels({ instruments }: ComponentProps) {
  const classes = useStyles();

  return (
    <>
      {instruments.map((instrument: DebtInstrument) => (
        <ExpansionPanel key={instrument.finInstrmGnlAttrbts.isin}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{instrument.finInstrmGnlAttrbts.fullNm}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography variant="body2">
              <Typography component="span" variant="body2" className={classes.label}>
                ISIN
              </Typography>
              <br />
              {instrument.finInstrmGnlAttrbts.isin}
              <br />
              <Typography component="span" variant="body2" className={classes.label}>
                Issued Amount
              </Typography>
              <br />
              {formatMoney(instrument.debtInstrmAttrbts.ttlIssdNmnlAmt?.text || 0, {
                symbol: instrument.debtInstrmAttrbts.ttlIssdNmnlAmt?.ccy,
                format: "%s %v",
              })}
              <br />
              <Typography component="span" variant="body2" className={classes.label}>
                Value Per Unit
              </Typography>
              <br />
              {formatMoney(instrument.debtInstrmAttrbts.nmnlValPerUnit?.text || 0, {
                symbol: instrument.debtInstrmAttrbts.nmnlValPerUnit?.ccy,
                format: "%s %v",
              })}
              <br />
              <Typography component="span" variant="body2" className={classes.label}>
                Classification
              </Typography>
              <br />
              {instrument.finInstrmGnlAttrbts.clssfctnTp}
            </Typography>
          </ExpansionPanelDetails>
          <Divider />
          <ExpansionPanelActions>
            <Button
              size="small"
              color="primary"
              component={RouterLink}
              to={`/instrument/${instrument.finInstrmGnlAttrbts.isin}`}
            >
              See instrument details
            </Button>
          </ExpansionPanelActions>
        </ExpansionPanel>
      ))}
    </>
  );
}

function DebtInstrumentTable({ instruments }: OwnProps) {
  return (
    <>
      <Box display={{ xs: "none", sm: "block" }}>
        <InstrumentsWithinTable instruments={instruments} />
      </Box>
      <Box display={{ xs: "block", sm: "none" }}>
        <InstrumentsWithinExpandablePanels instruments={instruments} />
      </Box>
    </>
  );
}

export { DebtInstrumentTable };
