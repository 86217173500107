import {createMuiTheme} from "@material-ui/core/styles";
import styled from "styled-components";

export const themeTypography = createMuiTheme({
  typography: {
    h5: {
      fontWeight: 700,
      marginBottom: 40,
    },
  },
});

export const UpdateCardWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;

  label {
    color: #6b7c93;
    font-weight: 500;
    letter-spacing: 0.025em;
  }

  button {
    white-space: nowrap;
    border: 0;
    outline: 0;
    height: 40px;
    line-height: 40px;
    padding: 0 60px;
    color: #fff;
    float: right;
    margin-right: 20px;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    background-color: #3922a3;
    text-decoration: none;
    transition: all 150ms ease;
  }

  button:hover {
    color: #fff;
    cursor: pointer;
    background-color: #3922a3;
    transform: translateY(-1px);
  }

  button:disabled {
    background-color: #80808073;
    cursor: not-allowed;
    transform: none;
  }

  div .MuiCircularProgress-root {
    float: right;
    margin-right: 30px;
  }

  .StripeElement {
    display: block;
    margin: 10px 20px 20px 20px;
    font-size: 1em;
    font-family: "Source Code Pro", monospace;
    border: 0;
    outline: 0;
    border-bottom: 1px solid;
  }

  input::placeholder {
    color: #aab7c4;
  }

  .MuiPaper-root {
    padding: 25px 10px;
    width: 100%;
  }

  p[data-message="error"] {
    padding-left: 20px;
    color: #9e2146;
    letter-spacing: 1.4px;
  }

  p[data-message="success"] {
    padding-left: 20px;
    color: green;
    letter-spacing: 1.4px;
  }
`;
