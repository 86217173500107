import { CardHeader, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import SubjectIcon from "@material-ui/icons/Subject";
import React, { useContext } from "react";
import { SubscribeContext } from "../../common/subcriptionProvider";
import Loader from "../loader/Loader";
import UpgradeButton from "../UpgradeButton/UpgradeButton";
import {
  CardContentStyle,
  EmptyCard,
  Message,
  TableScrollWrapper,
  themeTypography,
  UpdateTableWrapper,
} from "./InstrumentUpdatesTable.css";
import { IInstrumentUpdatesTable } from "./InstrumentUpdatesTableInterface";

const InstrumentUpdatesTable: React.FC<IInstrumentUpdatesTable> = ({ instrumentHistoricalData, title, loading }) => {
  const { isUserPremium } = useContext(SubscribeContext);

  return (
    <UpdateTableWrapper>
      <CardHeader
        title={
          <ThemeProvider theme={themeTypography}>
            <Typography variant="h5">{title ? title : ""}</Typography>
          </ThemeProvider>
        }
      />
      {isUserPremium ? (
        <CardContentStyle>
          {loading ? (
            <Loader />
          ) : (
            <TableScrollWrapper>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell scope="row">Date of Change</TableCell>
                    <TableCell scope="row">Field Name</TableCell>
                    <TableCell scope="row">Old Value</TableCell>
                    <TableCell scope="row">New Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {instrumentHistoricalData &&
                    instrumentHistoricalData.map((row: any, index: number) => (
                      <TableRow key={index} data-emptycell={row[0] === ""}>
                        <TableCell scope="row">{row[0]}</TableCell>
                        <TableCell scope="row">{row[1]}</TableCell>
                        <TableCell scope="row">{row[2]}</TableCell>
                        <TableCell scope="row">{row[3]}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              {!instrumentHistoricalData?.length ? (
                <EmptyCard>
                  <SubjectIcon fontSize="large" color="inherit" />
                  <Message>No updates.</Message>
                </EmptyCard>
              ) : null}
            </TableScrollWrapper>
          )}
        </CardContentStyle>
      ) : (
        <CardContentStyle>
          <UpgradeButton />
        </CardContentStyle>
      )}
    </UpdateTableWrapper>
  );
};

export { InstrumentUpdatesTable };
