import { Grid, Paper, Typography } from "@material-ui/core";
import styled from "styled-components";
import mediaQuery from "../../common/mediaQuery";

export const Title = styled.span`
  margin-left: 5px;

  ${mediaQuery.MD} {
    font-size: 1.2rem;
  }
`;

export const GridStyle = styled(Grid)`
  min-height: auto;
`;

export const TypographyTitle = styled(Typography)`
  display: flex;
  align-items: center;
`;

export const TypographyText = styled(Typography)`
  ${mediaQuery.MD} {
    font-size: 0.8rem;
  }
`;

export const PaperStyle = styled(Paper)`
  margin: 24px 0 16px 0;
  padding: 24px;

  ${mediaQuery.MD} {
    margin: 5px 0 12px 0;
    padding: 10px;
  }
`;
