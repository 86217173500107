import { Card } from "@material-ui/core";
import styled from "styled-components";
import mediaQuery from "../../common/mediaQuery";

export const InformationCardWrapper = styled(Card)`
  height: 100%;

  .MuiBox-root {
    display: flex;
    justify-content: space-between;
  }

  h5 {
    font-size: 1.6rem;
    font-weight: 600;
  }

  p {
    align-self: center;
  }

  a {
    font-weight: 600;
  }

  a:hover {
    cursor: pointer;
    color: #40a9ff;
    text-decoration: none;
  }

  tr {
    height: 32px;
    border-bottom: 1px solid;
    border-bottom-color: rgba(0, 0, 0, 0.08);
  }

  tr:last-child {
    border-bottom: none;
  }

  td {
    border-bottom: 0;
    padding-left: 0;
    font-size: 1rem;
  }

  td:first-child {
    font-weight: 600;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    a {
      font-size: 0.8rem;
    }
  }

  ${mediaQuery.SM} {
    a {
      font-size: 0.8rem;
    }
  }
`;
