import { gql } from "apollo-boost";
import { Invoice } from "../common/types";
import { useMutation } from "@apollo/react-hooks";

const RETRY_INVOICE = gql`
 mutation retryInvoice($retryInvoice: RetryInvoiceInput ) {
  retryInvoice(retryInvoice: $retryInvoice) {
    amountDue
    amountPaid
    amountRemaining
    applicationFeeAmount
    attemptCount
    attempted
    autoAdvance
    collectionMethod
    created
    currency
    dueDate
    id
    invoicePdf
    number
    paid
    paymentIntent {
      clientSecret
      error
      status
    }
    receiptNumber
    status
    tax
    taxPercent
    subscriptionDetails {
      status
    }
  }
}
`;

interface RetryInvoiceInput {
  invoiceID: string;
  paymentMethodToken: string;
}

interface Params {
  retryInvoice: RetryInvoiceInput;
}

interface RetryInvoice {
  retryInvoice: Invoice;
}

export const useMutationRetryInvoice = () =>
  useMutation<RetryInvoice, Params>(RETRY_INVOICE);
