import { gql } from "apollo-boost";
import { Subscription } from "../common/types";
import { useMutation } from "@apollo/react-hooks";

const CREATE_SUBSCRIPTION = gql`
 mutation createSubscription($paymentMethodToken: String!) {
    createSubscription(paymentMethodToken: $paymentMethodToken) {
    error
    activeUntil
    cancelAtPeriodEnd
    canceledAt
    createdAt
    currentPeriodStart
    endedAt
    invoiceID
    paymentIntent {
      clientSecret
      error
      status
    }
    paymentMethodID
    priceID
    status
    stripeCustomerID
    subscriptionID
    userID
  }
}
`;

interface Params {
  paymentMethodToken: string;
}

interface CreateSubscription {
  createSubscription: Subscription;
}

export const useMutationCreateSubscription = () =>
  useMutation<CreateSubscription, Params>(CREATE_SUBSCRIPTION);
