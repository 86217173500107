import moment from "moment";
import React from "react";
import { calcEndDate, isDateValid } from "../../utils/calcDates";
import { BorderLinearProgress, Legend, SubTitle, Title } from "./LinearProgressBar.css";
import { PropsInterface } from "./LinearProgressBarInterface";

const LinearProgressBar: React.FC<PropsInterface> = ({
  isMainDetails,
  progress,
  firstTradeDate,
  maturityDate,
  terminationDate,
}) => {
  const isMaturityDateValid = isDateValid(maturityDate);

  const startDate = moment(firstTradeDate).format("DD/MM/YYYY");
  const endDate = calcEndDate(maturityDate, terminationDate);

  const startTitle = "First Trade Date:";
  const endTitle = isMaturityDateValid ? "Maturity Date:" : "Termination Date:";

  return (
    <>
      <BorderLinearProgress variant="determinate" value={progress} details={isMainDetails ? 1 : 0} />
      <Legend isMainDetails={isMainDetails}>
        <Title>
          {isMainDetails ? (
            <>
              <SubTitle>{startTitle}</SubTitle>
              {startDate}
            </>
          ) : (
            startDate
          )}
        </Title>
        <Title>
          {isMainDetails ? (
            <>
              <SubTitle>{endTitle}</SubTitle>
              {endDate}
            </>
          ) : (
            endDate
          )}
        </Title>
      </Legend>
    </>
  );
};

export default LinearProgressBar;
