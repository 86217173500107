import { Container } from "@material-ui/core";
import styled from "styled-components";
import mediaQuery from "../common/mediaQuery";

const PageWidthWrapper = styled(Container)`
  width: 100%;
  max-width: 1900px;

  ${mediaQuery.EXTRA_LG} {
    max-width: 1085px;
  }
  ${mediaQuery.XXL} {
    max-width: 1485px;
  }
  ${mediaQuery.SM} {
    padding: 0px;
    font-size: 0.7rem;
  }
`;

export default PageWidthWrapper;
