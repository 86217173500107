import React, { useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import { Input, InputLabel, Select } from "@material-ui/core";
import {
  DialogActionsWrapper,
  StyledDialog,
  StyledDialogTitle,
  StyledEditButton,
  StyledFormControl,
  StyledMenuItem,
  useStyles,
} from "./EditDialog.css";
import { userIndustry, userRoles } from "../../common/constants";
import { useMutationEditUser } from "../../graphql/EditUser";
import { SubscribeContext } from "../../common/subcriptionProvider";

const EditDialog = () => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const { setErrorMessage, refreshContext } = useContext(SubscribeContext);
  const [dialogRole, setDialogRole] = useState<string>("");
  const [dialogIndustry, setDialogIndustry] = useState<string>("");

  const [editUser, { data: updatedUser, error: editUserError }] = useMutationEditUser();

  useEffect(() => {
    if (editUserError) {
      setErrorMessage(editUserError.message);
      setOpen(false);
      return;
    }

    if (updatedUser && updatedUser.editUser) {
      refreshContext(false);
      setDialogRole("");
      setDialogIndustry("");
      setOpen(false);
    }
  }, [updatedUser, editUserError]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setDialogRole("");
    setDialogIndustry("");
    setOpen(false);
  };

  const handleSave = () => {
    editUser({ variables: { role: dialogRole, profession: dialogIndustry } });
  };

  const handleChangeRole = (event: any) => {
    setDialogRole(event.target.value);
  };

  const handleChangeIndustry = (event: any) => {
    setDialogIndustry(event.target.value);
  };

  return (
    <>
      <StyledEditButton onClick={handleOpen} variant="outlined" color="primary">
        Edit
      </StyledEditButton>
      <StyledDialog disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose}>
        <StyledDialogTitle>Edit profile</StyledDialogTitle>
        <DialogContent>
          <StyledFormControl>
            <InputLabel id="demo-dialog-select-label">Industry</InputLabel>
            <Select
              value={dialogIndustry}
              onChange={handleChangeIndustry}
              input={<Input />}
              MenuProps={{ classes: { paper: classes.dropdownStyle } }}
            >
              {userIndustry.map((industry) => (
                <StyledMenuItem key={industry} value={industry}>
                  {industry}
                </StyledMenuItem>
              ))}
            </Select>
          </StyledFormControl>
          <StyledFormControl>
            <InputLabel htmlFor="demo-dialog-native">Role</InputLabel>
            <Select
              value={dialogRole}
              onChange={handleChangeRole}
              input={<Input />}
              MenuProps={{ classes: { paper: classes.dropdownStyle } }}
            >
              {userRoles.map((role) => (
                <StyledMenuItem key={role} value={role}>
                  {role}
                </StyledMenuItem>
              ))}
            </Select>
          </StyledFormControl>
        </DialogContent>
        <DialogActionsWrapper>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActionsWrapper>
      </StyledDialog>
    </>
  );
};

export default EditDialog;
