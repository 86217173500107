import { Button, Menu, MenuItem } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { redirectPath } from "../../utils/redirect";
import { NavigationContainer, NavigationContainerRWD, NavigationLink } from "./NavigationMenu.css";

const NavigationMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const history = useHistory();

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <NavigationContainer>
        <NavigationLink
          id="header__new_instrument_monitor"
          onClick={() => redirectPath("/new-instrument-monitor", history)}
        >
          <span>New Instrument Monitor</span>
        </NavigationLink>
        <NavigationLink
          id="header__favourite_instruments"
          onClick={() => redirectPath("/favourite-instruments", history)}
        >
          <span>Favourite Instruments</span>
        </NavigationLink>
        <NavigationLink id="header__products" onClick={() => redirectPath("/products", history)}>
          <span>Products</span>
        </NavigationLink>
        <NavigationLink id="header__about" onClick={() => redirectPath("/", history)}>
          <span>About eFIRDS</span>
        </NavigationLink>
      </NavigationContainer>
      <NavigationContainerRWD>
        <Button
          color="inherit"
          aria-label="Menu"
          aria-controls="navigation-hamburger"
          aria-haspopup="true"
          onClick={handleMenuOpen}
        >
          <MenuIcon />
        </Button>
        <Menu
          id="navigation-hamburger"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MenuItem
            id="header__new_instrument_monitor"
            onClick={() => redirectPath("/new-instrument-monitor", history)}
          >
            New Instrument Monitor
          </MenuItem>
          <MenuItem id="header__favourite_instruments" onClick={() => redirectPath("/favourite-instruments", history)}>
            Favourite Instruments
          </MenuItem>
          <MenuItem id="header__products" onClick={() => redirectPath("/products", history)}>
            Products
          </MenuItem>
          <MenuItem id="header__about" onClick={() => redirectPath("/", history)}>
            About eFIRDS
          </MenuItem>
        </Menu>
      </NavigationContainerRWD>
    </>
  );
};

export default NavigationMenu;
