import Chip from "@material-ui/core/Chip";
import moment from "moment";
import React, { useEffect } from "react";
import styled from "styled-components";
import { MuiMainBackgroundColor } from "../../common/theme";

interface FilterInterface {
  name: string;
  value: any;
  handler: Function;
}

interface TableFitlersProps {
  isFiltering: Function;
  filters: FilterInterface[];
}

const TableFilters: React.FC<TableFitlersProps> = ({ isFiltering, filters }) => {
  const handleDelete = (singlerFilter: FilterInterface) => {
    if (typeof singlerFilter.value === "string") {
      singlerFilter.handler("");
    } else {
      singlerFilter.handler(null);
    }
  };

  useEffect(() => {
    filterWithValues.length ? isFiltering(true) : isFiltering(false);
  });

  const filterWithValues = filters.filter((singleFilter: FilterInterface) => singleFilter.value);

  const clearAllFilters = () => {
    filterWithValues.forEach((filter: FilterInterface) => handleDelete(filter));
  };

  return (
    <FiltersContainer>
      {filterWithValues.map((singleFilter: FilterInterface) => {
        return (
          <StyledChip
            label={`${singleFilter.name}: ${
              typeof singleFilter.value === "object"
                ? moment(singleFilter.value).format("DD/MM/yyyy")
                : singleFilter.value
            }`}
            key={singleFilter.name}
            onDelete={() => handleDelete(singleFilter)}
          />
        );
      })}
      {filterWithValues.length >= 2 ? <ClearChip label="Clear filters" onClick={clearAllFilters} /> : null}
    </FiltersContainer>
  );
};

const FiltersContainer = styled.div`
  padding: 0 0 5px;
`;

const StyledChip = styled(Chip)`
  background: #b498ff29;
  margin-right: 5px;
  height: 25px;
  & svg {
    color: ${MuiMainBackgroundColor};
    opacity: 0.3;
  }
`;

const ClearChip = styled(Chip)`
  background: none;
  font-weight: bold;
  color: ${MuiMainBackgroundColor};
`;

export default TableFilters;
