import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights, DistributedTracingModes } from "@microsoft/applicationinsights-web";
import { createBrowserHistory } from "history";
import { guidPattern } from "../common/constants";

const { APPINSIGHTS_INSTRUMENTATIONKEY } = window._env_;

const browserHistory = createBrowserHistory({ basename: "" });
const reactPlugin = new ReactPlugin();

let appInsights = {} as ApplicationInsights;

const handleLocalInsights = (key?: string) => {
  if (key && guidPattern.test(key)) {
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: APPINSIGHTS_INSTRUMENTATIONKEY,
        disableFetchTracking: false,
        enableAutoRouteTracking: true,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory },
        },
      },
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
  }
};

handleLocalInsights(APPINSIGHTS_INSTRUMENTATIONKEY);

export { reactPlugin, appInsights };
