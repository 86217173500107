import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { IssuerRelationsFullInterface } from "../pages/IssuerPage/CompanyStructureTab/IssuerStructureInterface";

const queryAuth = (isPremium: any) => {
  const GET_ISSUER_RELATIONS_FULL = gql`
    query getIssuerRelationsFull($lei: String!) {
      getIssuerRelationsFull(lei: $lei) {
        lei @include(if: ${isPremium})
        name @include(if: ${isPremium})
        __typename @include(if: ${isPremium})
        instrumentsAmount @include(if: ${isPremium}) {
          debt
          equities
        }
        ultimateParent @include(if: ${isPremium}) {
          name
          lei
          instrumentsAmount {
            debt
            equities
          }
        }
        directParent @include(if: ${isPremium}) {
          name
          lei
          instrumentsAmount {
            debt
            equities
          }
          directChildren {
            name
            lei
            instrumentsAmount {
              debt
              equities
            }
            directChildren {
              name
              lei
              instrumentsAmount {
                debt
                equities
              }
            }
          }
        }
        directChildren @include(if: ${isPremium}) {
          name
          lei
          instrumentsAmount {
            debt
            equities
          }
          directChildren {
            name
            lei
          }
        }
        ultimateChildren @include(if: ${isPremium}) {
          name
          lei
          instrumentsAmount {
            debt
            equities
          }
        }
      }
    }
  `;
  return GET_ISSUER_RELATIONS_FULL;
};

interface Param {
  lei: string;
}

interface GetIssuerRelationsFull {
  getIssuerRelationsFull: IssuerRelationsFullInterface;
}

export const useLazyGetIssuerRelationsFullQuery = (isPremium: any) =>
  useLazyQuery<GetIssuerRelationsFull, Param>(queryAuth(isPremium));
