import styled from "styled-components";
import mediaQuery from "../../common/mediaQuery";
import crossIcon from "../../assets/images/cross-icon.png";
import tickIcon from "../../assets/images/tick-icon.png";

export const PaymentCardWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;

  label {
    color: #6b7c93;
    font-weight: 500;
    letter-spacing: 0.025em;
  }

  .StripeElement {
    display: block;
    margin: 10px 20px 20px 20px;
    font-size: 1em;
    font-family: "Source Code Pro", monospace;
    border: 0;
    outline: 0;
    border-bottom: 1px solid;
  }

  input::placeholder {
    color: #aab7c4;
  }

  .MuiPaper-root {
    padding: 25px 10px;
    width: 100%;
  }

  p.error {
    padding-left: 20px;
    color: #9e2146;
    letter-spacing: 1.4px;
    line-height: 20px;
  }

  p.query-success {
    display: inline-block;
    padding: 0 20px;
    letter-spacing: 1.4px;
  }

  p.payment-success {
    display: inline-block;
    padding: 0 20px;
    color: green;
    letter-spacing: 1.4px;
    line-height: 20px;
  }

  p.payment-success:before {
    content: "";
    background: url(${tickIcon}) no-repeat;
    background-size: contain;
    width: 20px;
    height: 20px;
    float: left;
    margin: 0 10px 10px 0px;
  }

  p.error:before {
    content: "";
    background: url(${crossIcon}) no-repeat;
    background-size: contain;
    width: 20px;
    height: 20px;
    float: left;
    margin: 0 10px 10px 0px;
  }

  .payment-animation {
    color: green;
  }
`;

export const ButtonsWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  button {
    white-space: nowrap;
    border: 0;
    outline: 0;
    height: 40px;
    padding: 0 60px;
    color: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    background-color: #3922a3;
    text-decoration: none;
    transition: all 150ms ease;
  }

  button:hover {
    color: #fff;
    cursor: pointer;
    background-color: #3922a3;
    transform: translateY(-1px);
  }

  button:disabled {
    background-color: #80808073;
    cursor: not-allowed;
    transform: none;
  }

  span {
    min-width: 105px;
    cursor: pointer;
    margin: 0 10px 0 0;
  }

  span.hidden {
    visibility: hidden;
  }

  span.visible {
    visibility: visible;
  }

  span:hover {
    text-decoration: underline;
  }

  ${mediaQuery.EXTRA_LG} {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    button {
      margin-bottom: 10px;
    }

    ${mediaQuery.SM} {
      button {
        font-size: 10px;
        height: 30px;
      }
    }
  }
`;

export const FormWrapper = styled.form`
  ${mediaQuery.SM} {
    h5 {
      font-size: 1.1rem;
      margin-bottom: 25px;
    }
  }
`;

export const AgreementCheckboxWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;

  span {
    cursor: pointer;
    font-weight: 500;
    text-decoration: underline;
  }

  ${mediaQuery.SM} {
    justify-content: center;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    justify-content: center;
  }
`;
