import React from "react";

interface StoreProps {
  children: React.ReactNode;
}

const appCtxDefaultValue = {
  isTopSearchBarOpen: true,
  toggleTopSearchBar: (isTopSearchBarOpen: boolean) => {},
};

export const AppContext = React.createContext(appCtxDefaultValue);

export const AppProvider = ({ children }: StoreProps) => {
  const [isTopSearchBarOpen, toggleTopSearchBar] = React.useState(
    appCtxDefaultValue.isTopSearchBarOpen
  );

  return (
    <AppContext.Provider value={{ isTopSearchBarOpen, toggleTopSearchBar }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
