import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { InstrumentResourceParametersInput } from "../common/types";

const queryAuth = (isPremium: any) => {
  const GET_ADDITIONAL_DATA_FOR_MULTIPLE_ISINS = gql`
      query getAdditionalDataForMultipleISINs(
        $isins: [String]
        $resourceParameters: InstrumentResourceParametersInput!
      ) {
        getAdditionalDataForMultipleISINs(
          isins: $isins
          resourceParameters: $resourceParameters
        ) {
          countries @include(if: ${isPremium})
          currencies @include(if: ${isPremium})
          totalIssuedAmount
          __typename @include(if: ${isPremium})
        }
      }
    `;
  return GET_ADDITIONAL_DATA_FOR_MULTIPLE_ISINS;
};

interface Params {
  isins: Array<string>;
  resourceParameters: InstrumentResourceParametersInput;
}

interface GetAdditionalDataForMultipleISINs {
  getAdditionalDataForMultipleISINs: {
    countries: Array<string>;
    currencies: Array<string>;
    totalIssuedAmount: number;
    __typename: string;
  };
}

export const useLazyGetAdditionalDataForMultipleISINsQuery = (isPremium: any) =>
  useLazyQuery<GetAdditionalDataForMultipleISINs, Params>(queryAuth(isPremium));
