import { CardHeader, CircularProgress } from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import SubjectIcon from "@material-ui/icons/Subject";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SubscribeContext } from "../../../common/subcriptionProvider";
import { InstrumentNew } from "../../../common/types";
import { useLazyGetAdditionalDataForInstrumentSuggestionsQuery } from "../../../graphql/GetAdditionalDataForInstrumentSuggestions";
import { useLazyGetInstrumentsByMultipleISINsQuery } from "../../../graphql/getInstrumentsByMultipleISINs";
import { useLazyInstrumentSuggestionResultQuery } from "../../../graphql/GetInstrumentSuggestionsResult";
import formatSearchValue from "../../../utils/formatSearchValue";
import CustomFlag from "../../CustomFlag";
import TablePagination from "../../TablePagination/TablePagination";
import PeriodProgress from "../PeriodProgress";
import {
  CardContentStyle,
  CardStyle,
  EmptyCard,
  Message,
  ProgressOverlay,
  StyledBox,
  StyledTable,
  StyledTableBody,
  StyledTablePagination,
  TableHeaderCell,
  TableMainCell,
  TableNameCell,
  TableRowStyle,
  TableScrollContainer,
  TypographyStyle,
} from "./EquitiesTable.css";
import { SearchTableInterface } from "../SearchTablesInterface";

const EquitiesTable: React.FC<SearchTableInterface> = ({ search, searchByIsin, isSearchByLei }) => {
  const history = useHistory();

  const { isUserPremium, setErrorMessage } = useContext(SubscribeContext);

  const [
    getInstrumentResults,
    { data: instrumentResults, loading: instrumentLoading, error: getInstrumentResultsError },
  ] = useLazyInstrumentSuggestionResultQuery(isUserPremium !== null ? isUserPremium : false);

  const [
    getInstrumentsByMultipleISINs,
    { data: multipleIsinsResults, loading: multipleIsinsLoading, error: getInstrumentsByMultipleISINsError },
  ] = useLazyGetInstrumentsByMultipleISINsQuery(isUserPremium !== null ? isUserPremium : false);

  const [
    getAdditionalDataForInstrumentSuggestions,
    {
      data: additionalData,
      loading: loadingAdditionalDataInstr,
      error: getAdditionalDataForInstrumentSuggestionsError,
    },
  ] = useLazyGetAdditionalDataForInstrumentSuggestionsQuery(isUserPremium && isUserPremium ? isUserPremium : false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [instruments, setInstruments] = useState<any[]>([]);
  const [isMounted, setIsMounted] = useState(false);
  const [instrumentAdditionalData, setInstrumentAdditionalData] = useState<any>();

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (!isSearchByLei) {
      getAdditionalDataForInstrumentSuggestions({
        variables: {
          startingCharacters: search,
          resourceParameters: {
            instrumentType: "EQUITY",
            instrumentStatus: "LISTED",
          },
        },
      });
    }
  }, [getAdditionalDataForInstrumentSuggestions, search, isSearchByLei]);

  useEffect(() => {
    if (!isSearchByLei) {
      searchByIsin && search.length > 12
        ? getInstrumentsByMultipleISINs({
            variables: {
              numberOfRecords: rowsPerPage,
              isins: formatSearchValue(search),
              resourceParameters: {
                instrumentType: "EQUITY",
                instrumentStatus: "LISTED",
              },
              startingRecord: page === 0 ? 1 : page * rowsPerPage + 1,
            },
          })
        : isMounted &&
          getInstrumentResults({
            variables: {
              numberOfRecords: rowsPerPage,
              startingCharacters: search,
              resourceParameters: {
                instrumentType: "EQUITY",
                instrumentStatus: "LISTED",
              },
              startingRecord: page === 0 ? 1 : page * rowsPerPage + 1,
            },
          });
    }
  }, [
    rowsPerPage,
    search,
    searchByIsin,
    isSearchByLei,
    page,
    isMounted,
    getInstrumentResults,
    getInstrumentsByMultipleISINs,
  ]);

  useEffect(() => {
    if (getInstrumentResultsError) {
      setErrorMessage(getInstrumentResultsError.message);
    }
    if (getAdditionalDataForInstrumentSuggestionsError) {
      setErrorMessage(getAdditionalDataForInstrumentSuggestionsError.message);
    }
    if (instrumentResults && instrumentResults.getInstrumentSuggestionsResult) {
      setInstruments(instrumentResults.getInstrumentSuggestionsResult.instruments);
      if (additionalData && additionalData?.getAdditionalDataForInstrumentSuggestions) {
        setInstrumentAdditionalData(additionalData.getAdditionalDataForInstrumentSuggestions);
      }
    }
  }, [
    instrumentResults,
    additionalData,
    getInstrumentResultsError,
    getAdditionalDataForInstrumentSuggestionsError,
    setErrorMessage,
  ]);

  useEffect(() => {
    if (getInstrumentsByMultipleISINsError) {
      setErrorMessage(getInstrumentsByMultipleISINsError.message);
    }
    if (multipleIsinsResults && multipleIsinsResults.getInstrumentsByMultipleISINs) {
      setInstruments(multipleIsinsResults.getInstrumentsByMultipleISINs.instruments);
    }
  }, [multipleIsinsResults, getInstrumentsByMultipleISINsError, setErrorMessage]);

  const handleRowsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPage(0);
    setRowsPerPage(event.target.value as number);
  };

  return (
    <CardStyle>
      <CardHeader
        title={
          <StyledBox>
            <TypographyStyle variant="h5">Equities</TypographyStyle>
          </StyledBox>
        }
      />
      <CardContentStyle>
        {instrumentLoading || multipleIsinsLoading ? (
          <ProgressOverlay className={!instruments.length ? "empty" : ""}>
            <CircularProgress />
          </ProgressOverlay>
        ) : null}
        {!instruments.length && (!instrumentLoading || !multipleIsinsLoading) ? (
          !instrumentLoading && !instruments.length ? (
            <EmptyCard>
              <SubjectIcon fontSize="large" color="inherit" />
              <Message>No results</Message>
            </EmptyCard>
          ) : null
        ) : (
          <TableScrollContainer>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <TableHeaderCell>Country</TableHeaderCell>
                  <TableHeaderCell>Name</TableHeaderCell>
                  <TableHeaderCell>Period</TableHeaderCell>
                  <TableHeaderCell>Currency</TableHeaderCell>
                  <TableHeaderCell></TableHeaderCell>
                  <TableHeaderCell></TableHeaderCell>
                </TableRow>
              </TableHead>
              <StyledTableBody loading={instrumentLoading ? 1 : 0}>
                {instruments.map((instrument: InstrumentNew, i: number) => (
                  <TableRowStyle
                    key={i}
                    onClick={() => history.push(`/instrument/${instrument.instrumentIdentificationCode}`)}
                    hover
                  >
                    <TableMainCell id="search_page__instrument" component="th" scope="row" align="center">
                      {instrument.upcomingRCA ? (
                        <CustomFlag code={instrument.upcomingRCA} alt={instrument.upcomingRCA} />
                      ) : (
                        <CustomFlag code={"EU"} alt={"EU"} />
                      )}
                    </TableMainCell>
                    <TableNameCell id="search_page__instrument" component="th" scope="row">
                      <Typography variant="body1">
                        {instrument.instrumentIdentificationCode}
                        <br />
                        <Typography component="span">{instrument.instrumentFullName}</Typography>
                      </Typography>
                    </TableNameCell>
                    <TableMainCell id="search_page__instrument" align="center">
                      <PeriodProgress
                        maturityDate={instrument.maturityDate}
                        terminationDate={instrument.terminationDate}
                        issueDate={instrument.firstTradeDate}
                      />
                    </TableMainCell>
                    <TableMainCell id="search_page__instrument">{instrument.notionalCurrency}</TableMainCell>
                    <TableMainCell id="search_page__instrument"></TableMainCell>
                    <TableMainCell id="search_page__instrument"></TableMainCell>
                  </TableRowStyle>
                ))}
              </StyledTableBody>
            </StyledTable>
            <StyledTablePagination>
              <TablePagination
                rowsPerPage={rowsPerPage}
                handleRowsChange={handleRowsChange}
                page={page}
                setPage={setPage}
                totalInstrumentElements={instrumentAdditionalData?.totalIssuedAmount}
                loading={loadingAdditionalDataInstr}
              />
            </StyledTablePagination>
          </TableScrollContainer>
        )}
      </CardContentStyle>
    </CardStyle>
  );
};

export { EquitiesTable };
