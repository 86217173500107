import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { MaturingInstrumentsInterface } from "../components/MaturingInstruments/MaturingInstrumentsInterface";

const queryAuth = (isPremium: any) => {
  const GET_MATURING_INSTRUMENTS = gql`
    query getMaturingInstruments($lei: String!, $years: Int) {
      getMaturingInstruments(lei: $lei, years: $years) {
        amount @include(if: ${isPremium})
        year @include(if: ${isPremium})
        __typename @include(if: ${isPremium})
      }
    }
  `;
  return GET_MATURING_INSTRUMENTS;
};

interface Param {
  lei: string;
  years: number;
}

interface GetMaturingInstruments {
  getMaturingInstruments: MaturingInstrumentsInterface;
}

export const useLazyGetMaturingInstrumentsQuery = (isPremium: any) =>
  useLazyQuery<GetMaturingInstruments, Param>(queryAuth(isPremium));
