import { Grid, Table, TableHead, TableRow, Tooltip } from "@material-ui/core";
import SubjectIcon from "@material-ui/icons/Subject";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Flag from "react-world-flags";
import { SubscribeContext } from "../../common/subcriptionProvider";
import { InstrumentInterface, InstrumentType, TotalIssuedAmount } from "../../common/types";
import { useLazyGetAdditionalDataForInstrumentsQuery } from "../../graphql/GetAdditionalDataForInstruments";
import { useLazyGetInstrumentsQuery } from "../../graphql/GetInstruments";
import { useLazyGetTotalIssuedAmountQuery } from "../../graphql/getTotalIssuedAmountByLEI";
import { calcDaysProgress } from "../../utils/calcDates";
import { formatAmount } from "../../utils/formatAmount";
import {
  CATEGORY_CODE,
  CATEGORY_COL,
  getInstrCode,
  instrCategoryFields,
  instrColumnMap,
  instrTypeFields,
  TYPE_CODE,
  TYPE_COL,
} from "../InstrumentsTypeAndCategory/InstrumentSelectFunctions";
import LinearProgressBar from "../LinearProgressBar/LinearProgressBar";
import Loader from "../loader/Loader";
import TablePagination from "../TablePagination/TablePagination";
import InstrumentFilter from "./InstrumentFilter";
import {
  ActiveFiltersContainer,
  CardContentStyle,
  CardHeaderContent,
  CardHeaderInfo,
  CardHeaderStyle,
  CardHeaderTitle,
  CardStyle,
  CellText,
  ClearFilters,
  Dots,
  EmptyCard,
  Filter,
  InstrumentIsinText,
  InstrumentNameText,
  LoaderOverlay,
  Message,
  StyledChip,
  StyledTableBody,
  TableFiltersRowStyle,
  TableHeaderCell,
  TableMainCell,
  TableRowStyle,
  TooltipTitle,
  TotalAmount,
  TotalIssued,
  TotalIssuedWrapper,
  useTableStyles,
} from "./InstrumentsTables.css";
import { tableHeader } from "./InstrumentsTablesUtils";
interface Props {
  lei: string;
  table: InstrumentType;
  setEmpty: Function;
  setTab: Function;
  setIsAnyFilterSelected: Function;
}

interface FiltersInterface {
  name: string;
  value: any;
  handler: any;
}

const InstrumentsTables: React.FC<Props> = ({ lei, table, setEmpty, setTab, setIsAnyFilterSelected }) => {
  const history = useHistory();
  const [instrumentsData, setInstrumentsData] = useState<any>();
  const [totalAmountData, setTotalAmountData] = useState<any>();
  const [instrumentAdditionalData, setInstrumentAdditionalData] = useState<any>();
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [currency, setCurrency] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [instrumentType, setInstrumentType] = useState<string>("");
  const [instrumentCategory, setInstrumentCategory] = useState<string>("");
  const classes = useTableStyles();
  const { setErrorMessage } = useContext(SubscribeContext);

  const isAnyFilterSelected = [name, currency, name, endDate, startDate, instrumentType, instrumentCategory].filter(
    (el) => !!el
  ).length;

  useEffect(() => {
    if (isAnyFilterSelected) {
      setIsAnyFilterSelected(true);
    }
  }, [isAnyFilterSelected]);

  const [
    getInstruments,
    { data: instruments, loading: loadingInstrumentsData, error: getInstrumentsError },
  ] = useLazyGetInstrumentsQuery(true);

  const [getTotalIssuedAmount, { data: amount, error: getTotalIssuedAmountError }] = useLazyGetTotalIssuedAmountQuery();

  const [
    getAdditionalDataForInstruments,
    { data: additionalData, loading: loadingAdditionalData, error: getAdditionalDataForInstrumentsError },
  ] = useLazyGetAdditionalDataForInstrumentsQuery(true);

  const tableHeaders = tableHeader(table);

  const handleDeleteFilterName = () => {
    setName("");
    setPage(0);
  };

  const handleDeleteFilterCurrency = () => {
    setCurrency("");
    setPage(0);
  };

  const handleDeleteFilterStartDate = () => {
    setStartDate(null);
    setPage(0);
  };

  const handleDeleteFilterEndDate = () => {
    setEndDate(null);
    setPage(0);
  };

  const handleDeleteFilterType = () => {
    setInstrumentType("");
    setInstrumentCategory("");
    setPage(0);
  };

  const handleDeleteFilterCategory = () => {
    setInstrumentCategory("");
    setPage(0);
  };

  const instrCodeParam = () => {
    if (instrumentType && instrumentCategory) {
      return getInstrCode(instrCategoryFields, instrumentCategory, CATEGORY_COL, CATEGORY_CODE);
    } else if (instrumentCategory) {
      return getInstrCode(instrCategoryFields, instrumentCategory, CATEGORY_COL, CATEGORY_CODE);
    }
    return getInstrCode(instrTypeFields, instrumentType, TYPE_COL, TYPE_CODE);
  };

  const filters = [
    {
      name: "Instrument Type",
      value: instrumentType,
      handler: handleDeleteFilterType,
    },
    {
      name: "Instrument Category",
      value: instrumentCategory,
      handler: handleDeleteFilterCategory,
    },
    {
      name: "Name or ISIN",
      value: name,
      handler: handleDeleteFilterName,
    },
    {
      name: "Start Date",
      value: startDate ? moment.utc(startDate).format("DD/MM/YYYY") : null,
      rawDate: startDate,
      handler: handleDeleteFilterStartDate,
    },
    {
      name: "End Date",
      value: endDate ? moment.utc(endDate).format("DD/MM/YYYY") : null,
      rawDate: endDate,
      handler: handleDeleteFilterEndDate,
    },
    { name: "Currency", value: currency, handler: handleDeleteFilterCurrency },
  ];

  const isFilter = filters.filter((item) => item.value).length;

  useEffect(() => {
    setTab(0);
  }, [instrumentsData, table, isFilter, setTab]);

  useEffect(() => {
    if (lei) {
      getInstruments({
        variables: {
          lei,
          numberOfRecords: rowsPerPage,
          resourceParameters: {
            instrumentType: table,
            instrumentStatus: "LISTED",
            nameAndISIN: name,
            startDate: startDate ? startDate : undefined,
            endDate: endDate ? endDate : undefined,
            currency,
            instrumentClassificationPrefix: instrCodeParam(),
          },
          startingRecord: page === 0 ? 1 : page * rowsPerPage + 1,
        },
      });
    }
  }, [
    getInstruments,
    lei,
    table,
    rowsPerPage,
    page,
    name,
    startDate,
    endDate,
    currency,
    instrumentType,
    instrumentCategory,
  ]);

  useEffect(() => {
    setName("");
    setCurrency("");
    setStartDate(null);
    setEndDate(null);
    setPage(0);
  }, [lei]);

  useEffect(() => {
    getTotalIssuedAmount({ variables: { lei } });
  }, [getTotalIssuedAmount, lei]);

  useEffect(() => {
    getAdditionalDataForInstruments({
      variables: {
        lei,
        resourceParameters: {
          instrumentType: table,
          instrumentStatus: "LISTED",
          nameAndISIN: name,
          startDate: startDate ? startDate : undefined,
          endDate: endDate ? endDate : undefined,
          currency,
          instrumentClassificationPrefix: instrCodeParam(),
        },
      },
    });
  }, [
    getAdditionalDataForInstruments,
    lei,
    table,
    name,
    startDate,
    endDate,
    currency,
    instrumentType,
    instrumentCategory,
  ]);

  useEffect(() => {
    if (getInstrumentsError) {
      setErrorMessage(getInstrumentsError.message);
    }
    if (getTotalIssuedAmountError) {
      setErrorMessage(getTotalIssuedAmountError.message);
    }
    if (instruments && instruments?.getInstruments.instruments) {
      setInstrumentsData(instruments.getInstruments.instruments);

      if (amount && amount?.getTotalIssuedAmount) {
        setTotalAmountData(amount.getTotalIssuedAmount);
      }
    }
  }, [instruments, amount, setEmpty, getInstrumentsError, getTotalIssuedAmountError, setErrorMessage]);

  useEffect(() => {
    if (getAdditionalDataForInstrumentsError) {
      setErrorMessage(getAdditionalDataForInstrumentsError.message);
    }
    if (instruments && instruments?.getInstruments.instruments) {
      if (additionalData && additionalData?.getAdditionalDataForInstruments) {
        setInstrumentAdditionalData(additionalData.getAdditionalDataForInstruments);

        additionalData.getAdditionalDataForInstruments.totalIssuedAmount > 0 ? setEmpty(false) : setEmpty(true);
      }
    }
  }, [instruments, additionalData, setEmpty, getAdditionalDataForInstrumentsError, setErrorMessage]);

  useEffect(() => {
    const issuerLeiForFilter = JSON.parse(sessionStorage.getItem("Issuer_LEI_for_filters") as string);
    if (issuerLeiForFilter && issuerLeiForFilter.lei !== lei) {
      sessionStorage.removeItem(`${table}_filters`);
      handleClearFilters();
    }
    const sessionFiltersArr: any = JSON.parse(sessionStorage.getItem(`${table}_filters`) as string);
    if (sessionFiltersArr) {
      setInstrumentType(sessionFiltersArr.instrumentType ? sessionFiltersArr.instrumentType : "");
      setInstrumentCategory(sessionFiltersArr.instrumentCategory ? sessionFiltersArr.instrumentCategory : "");
      setName(sessionFiltersArr.name ? sessionFiltersArr.name : "");
      setStartDate(sessionFiltersArr.startDate ? sessionFiltersArr.startDate : null);
      setEndDate(sessionFiltersArr.endDate ? sessionFiltersArr.endDate : null);
      setCurrency(sessionFiltersArr.currency ? sessionFiltersArr.currency : "");
    }
  }, [lei, table]);

  const handleRowsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPage(0);
    setRowsPerPage(event.target.value as number);
  };

  const handleClearFilters = () => {
    setName("");
    setStartDate(null);
    setEndDate(null);
    setCurrency("");
    setInstrumentType("");
    setInstrumentCategory("");
    setPage(0);
  };

  return (
    <>
      <Grid item xs={12}>
        <CardStyle>
          <CardHeaderStyle
            title={
              <CardHeaderContent>
                <CardHeaderTitle>
                  {table === "EQUITY" ? "Equities" : table === "DEBT" ? "Debt Instruments" : "Other Instruments"}
                </CardHeaderTitle>
                <CardHeaderInfo>{`- Showing ${instrumentsData && instrumentsData.length} of ${
                  instrumentAdditionalData && instrumentAdditionalData.totalIssuedAmount
                } instruments`}</CardHeaderInfo>
              </CardHeaderContent>
            }
          />
          <CardContentStyle>
            <ActiveFiltersContainer>
              {isFilter > 0 &&
                filters.map((filter: FiltersInterface) => {
                  return filter.value ? (
                    <Filter key={filter.name}>
                      <StyledChip label={`${filter.name}: ${filter.value}`} onDelete={filter.handler} />
                    </Filter>
                  ) : null;
                })}
              {isFilter > 1 && <ClearFilters onClick={handleClearFilters}>Clear filters</ClearFilters>}
            </ActiveFiltersContainer>
            {loadingInstrumentsData && (
              <LoaderOverlay loaderPosition={instrumentsData && instrumentsData.length}>
                <Loader />
              </LoaderOverlay>
            )}
            {instrumentsData && !instrumentsData.length && !loadingInstrumentsData && !isFilter ? null : (
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {tableHeaders.map((header: string) => {
                      return <TableHeaderCell key={header}>{header}</TableHeaderCell>;
                    })}
                  </TableRow>
                  <TableFiltersRowStyle table={table}>
                    <InstrumentFilter
                      totalAmountData={instrumentAdditionalData && instrumentAdditionalData.currencies}
                      currency={currency}
                      name={name}
                      setCurrency={setCurrency}
                      setName={setName}
                      startDate={startDate}
                      setStartDate={setStartDate}
                      endDate={endDate}
                      setEndDate={setEndDate}
                      setPage={setPage}
                      instrumentType={instrumentType}
                      setInstrumentType={setInstrumentType}
                      instrumentCategory={instrumentCategory}
                      setInstrumentCategory={setInstrumentCategory}
                      table={table}
                    />
                  </TableFiltersRowStyle>
                </TableHead>
                {instrumentsData && instrumentsData.length > 0 && (
                  <StyledTableBody className={loadingInstrumentsData ? classes.loading : classes.notLoading}>
                    {instrumentsData &&
                      instrumentsData.map((instrument: InstrumentInterface) => {
                        const daysProgress = calcDaysProgress(
                          instrument.firstTradeDate,
                          instrument.maturityDate,
                          instrument.terminationDate
                        );
                        const instrTypeField = instrColumnMap(instrument.instrumentClassification, TYPE_COL);
                        const instrCategoryField = instrColumnMap(instrument.instrumentClassification, CATEGORY_COL);
                        return (
                          <TableRowStyle
                            hover
                            key={instrument.instrumentIdentificationCode}
                            onClick={() => history.push(`/instrument/${instrument.instrumentIdentificationCode}`)}
                            table={table}
                          >
                            <TableMainCell id="issuer_page__instrument" component="td" scope="row" align="center">
                              {instrument.upcomingRCA ? (
                                <Flag
                                  code={instrument.upcomingRCA}
                                  alt={instrument.upcomingRCA}
                                  style={{ width: "38px" }}
                                />
                              ) : (
                                <Flag code={"EU"} alt={"EU"} style={{ width: "38px" }} />
                              )}
                            </TableMainCell>
                            {table === "OTHER" && (
                              <TableMainCell id="issuer_page__instrument">{instrTypeField}</TableMainCell>
                            )}
                            <TableMainCell id="issuer_page__instrument">{instrCategoryField}</TableMainCell>
                            <TableMainCell id="issuer_page__instrument">
                              <InstrumentIsinText>{instrument.instrumentIdentificationCode}</InstrumentIsinText>
                              <InstrumentNameText>{instrument.instrumentFullName}</InstrumentNameText>
                            </TableMainCell>
                            {daysProgress !== 0 ? (
                              <TableMainCell id="issuer_page__instrument" align="center">
                                <LinearProgressBar
                                  progress={daysProgress}
                                  firstTradeDate={instrument.firstTradeDate}
                                  maturityDate={instrument.maturityDate}
                                  terminationDate={instrument.terminationDate}
                                />
                              </TableMainCell>
                            ) : (
                              <TableMainCell id="issuer_page__instrument" data-emptycell={true}>
                                <div>N/A</div>
                              </TableMainCell>
                            )}
                            <TableMainCell id="issuer_page__instrument">
                              <CellText>{instrument.notionalCurrency}</CellText>
                            </TableMainCell>
                            {table === "DEBT" ? (
                              <>
                                <TableMainCell id="issuer_page__instrument" align="right">
                                  <CellText>{formatAmount(parseFloat(instrument.nominalValuePerUnit))}</CellText>
                                </TableMainCell>
                                <TableMainCell id="issuer_page__instrument" align="right">
                                  <CellText>{formatAmount(parseFloat(instrument.totalIssuedNominalAmount))}</CellText>
                                </TableMainCell>
                              </>
                            ) : (
                              <>
                                <TableMainCell id="issuer_page__instrument">
                                  <CellText>{instrument.instrumentClassification}</CellText>
                                </TableMainCell>
                              </>
                            )}
                          </TableRowStyle>
                        );
                      })}
                  </StyledTableBody>
                )}
              </Table>
            )}
            {instrumentsData && !instrumentsData.length && !loadingInstrumentsData && (
              <EmptyCard>
                <SubjectIcon fontSize="large" color="inherit" />
                <Message>No results</Message>
              </EmptyCard>
            )}
          </CardContentStyle>
          <TotalIssuedWrapper>
            {table === "DEBT" && instrumentsData && instrumentsData.length > 0 && (
              <Tooltip
                title={
                  <TooltipTitle>
                    {totalAmountData &&
                      totalAmountData.map((item: TotalIssuedAmount) => {
                        return item.amount && (currency === item.currency || !currency) ? (
                          <span key={item.currency}>{`${formatAmount(item.amount)} ${item.currency}`}</span>
                        ) : null;
                      })}
                  </TooltipTitle>
                }
                placement="right"
              >
                <TotalIssued>
                  <span>Total Issued Amount:</span>
                  {totalAmountData &&
                    totalAmountData.map((item: TotalIssuedAmount, index: number) => {
                      return index < 5 && item.amount && (currency === item.currency || !currency) ? (
                        <TotalAmount key={item.amount}>{`${formatAmount(item.amount)} ${item.currency}`}</TotalAmount>
                      ) : null;
                    })}
                  {totalAmountData && totalAmountData.length > 6 ? <Dots>...</Dots> : null}
                </TotalIssued>
              </Tooltip>
            )}
          </TotalIssuedWrapper>
          <TablePagination
            rowsPerPage={rowsPerPage}
            handleRowsChange={handleRowsChange}
            page={page}
            setPage={setPage}
            totalInstrumentElements={instrumentAdditionalData?.totalIssuedAmount}
            loading={loadingAdditionalData}
          />
        </CardStyle>
      </Grid>
    </>
  );
};

export default InstrumentsTables;
