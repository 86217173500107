import { Paper, TableCell, Typography } from "@material-ui/core";
import styled from "styled-components";
import mediaQuery from "../../../common/mediaQuery";

export const UserSubscriptionDetails = styled(Paper)`
  position: relative;
  height: 100%;

  a {
    margin: 25px 15px;
    display: block;
  }

  td {
    padding: 15px;
  }

  tr:last-child > td {
    border-bottom: none;
  }

  button {
    margin: 10px 5px 10px 14px;
    bottom: 0;
    font-weight: bold;
  }
  div.progress {
    position: absolute;
    bottom: 25px;
    left: 95px;
  }

  ${mediaQuery.SM} {
    button {
      position: relative;
      float: none;
      font-size: 11px;
      margin: 10px 5px 10px 6px;
      padding: 5px 25px;
      min-width: unset;
    }

    td {
      padding: 8px 5px;
    }
    a {
      margin: 20px 10px;
      font-size: 12px;
    }

    h5 {
      font-size: 15px;
      padding-left: 5px;
    }

    h4 {
      font-size: 30px;
      padding: 10px 0 8px 5px;
    }
  }
`;
export const StyledActivePeriodTypography = styled(Typography)`
  strong {
    color: black;
    font-weight: 400;
    font-size: 1.9rem;
    display: block;
    margin-top: 15px;
    white-space: nowrap;
  }
`;
export const StyledTableCell = styled(TableCell)`
  ${mediaQuery.SM} {
    font-size: 10px;
  }
`;
