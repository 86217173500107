import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { UpgradeButtonStyles } from "./UpgradeButton.css";
import { SubscribeContext } from "../../common/subcriptionProvider";

const UpgradeButton: React.FC = () => {
  const history = useHistory();
  const { azureProps } = useContext(SubscribeContext);

  if (azureProps.authenticationState === "Authenticated") {
    return <UpgradeButtonStyles onClick={() => history.push("/products")}>Upgrade To Unlock</UpgradeButtonStyles>;
  } else if (azureProps.authenticationState === "Unauthenticated" || azureProps.authenticationState === "InProgress") {
    return <UpgradeButtonStyles onClick={azureProps.login}>Upgrade To Unlock</UpgradeButtonStyles>;
  } else {
    return null;
  }
};

export default UpgradeButton;
