import { gql } from "apollo-boost";
import { SubscriptionDetails } from "../common/types";
import { useLazyQuery } from "@apollo/react-hooks";

const GET_SUBSCRIPTION_DETAILS = gql`
 query getSubscriptionDetails {
  getSubscriptionDetails {
    activeUntil
    cancelAtPeriodEnd
    canceledAt
    createdAt
    currentPeriodStart
    endedAt
    invoiceID
    paymentIntent {
      clientSecret
      error
      status
    }
    paymentMethodID
    priceID
    status
    stripeCustomerID
    subscriptionID
    userID
  }
}
`;

interface GetSubscriptionDetails {
  getSubscriptionDetails: SubscriptionDetails;
}

export const useLazyGetSubscriptionDetailsQuery = ( params? : any ) =>
  useLazyQuery<GetSubscriptionDetails>(GET_SUBSCRIPTION_DETAILS, {fetchPolicy: params ? params.fetchPolicy : "cache-first"});


