import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Paper, CircularProgress, Box, MenuList, MenuItem, Typography, Menu } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { MuiPaperBackgroundColor } from "../../common/theme";
import { IssuerSugestion, InstrumentSugestion } from "../../common/types";
import Downshift from "downshift";
import { Constants } from "../../common/constants";
import { SearchFieldWrapper, SearchButton, StyledSearchIcon, StyledSelect, useStyles } from "./SearchField.css";
import { SearchFieldProps } from "./SearchField.interface";

function SearchField(props: SearchFieldProps) {
  const classes = useStyles(props);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const history = useHistory();

  const handleOpenSelect = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const generateMenuItemID = (e: string) => {
    switch (e) {
      case "All":
        return "searchbar__search_all_dropdown";
      case "Instrument (ISIN)":
        return "searchbar__search_instrument_dropdown";
      case "Issuer (Name, LEI)":
        return "searchbar__search_issuer_dropdown";
      default:
        return;
    }
  };

  const redirectExactSearch = () => {
    if (props.value.length === 20 && props.activeFilter === "Instrument (ISIN)") {
      history.push(`/issuer/${props.value}`);
    } else if (props.sugestions.length === 1) {
      props.sugestions[0].__typename === "InstrumentSuggestion"
        ? history.push(`/instrument/${props.sugestions[0].isin}`)
        : history.push(`/issuer/${props.sugestions[0].lei}`);
    } else {
      props.onSearch();
    }
  };

  return (
    <Downshift
      itemToString={(sugestion: InstrumentSugestion | IssuerSugestion) => (sugestion ? sugestion.name : "")}
      onSelect={(selectedItem: InstrumentSugestion | IssuerSugestion | undefined) => {
        if (selectedItem) {
          props.onSubmit(selectedItem);
        }
      }}
      isOpen={props.openSugestionList}
      onOuterClick={() => props.closeSugestions()}
    >
      {({ getInputProps, getRootProps, getMenuProps, getItemProps, highlightedIndex, isOpen }) => (
        <SearchFieldWrapper {...getRootProps()}>
          <Paper
            style={{
              position: "relative",
              display: "flex",
              border: 0,
              height: "57px",
              ...(props.toggleTopSearchBar && {
                height: "40px",
              }),
              ...(isOpen && {
                borderRadius: "4px 4px 4px 0px",
              }),
              backgroundColor: props.toggleTopSearchBar ? "#FFFFFFBA" : MuiPaperBackgroundColor,
            }}
          >
            <StyledSelect
              filter={props.activeFilter}
              color="inherit"
              aria-label="Menu"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleOpenSelect}
            >
              {props.activeFilter}
              <ArrowDropDownIcon />
            </StyledSelect>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
              {props.filters.map((el: string) => (
                <MenuItem
                  id={generateMenuItemID(el)}
                  onClick={() => {
                    props.setActiveFilter(el);
                    handleClose();
                  }}
                  key={el}
                >
                  {el}
                </MenuItem>
              ))}
            </Menu>
            <Box className={`${classes.root} ${props.error ? classes.error : ""}`}>
              <input
                {...getInputProps({
                  onKeyDown: (event) => {
                    if (event.key === "Enter" && props.value.trim().length < 3) return;
                    if (event.key === "Enter") {
                      redirectExactSearch();
                    } else if (event.key === "Escape" || event.key === "Esc") {
                      props.closeSugestions();
                    }
                  },
                  onChange: (event) => props.onChange(event.target.value),
                })}
                style={{
                  outline: "none",
                }}
                placeholder={props.placeholder}
                value={props.value}
                disabled={props.disabled}
                autoFocus={true}
                id={"searchField"}
              />
              <SearchButton
                id="searchbar__magnifier_icon"
                aria-label="search"
                onClick={() => {
                  redirectExactSearch();
                }}
                disabled={props.disabled}
              >
                {props.loading ? (
                  <CircularProgress
                    size={20}
                    style={{
                      color: "white",
                      margin: "2px",
                    }}
                  />
                ) : (
                  <StyledSearchIcon fontSize="large" />
                )}
              </SearchButton>
            </Box>
            <Paper
              style={
                !isOpen || props.loading
                  ? { display: "none" }
                  : {
                      position: "absolute",
                      minHeight: "0",
                      width: "100%",
                      zIndex: 2,
                      top: "40px",
                      borderRadius: 0,
                      borderBottomRightRadius: "4px",
                      borderBottomLeftRadius: "4px",
                    }
              }
              className={"suggestionList"}
            >
              <MenuList {...getMenuProps()} className={classes.sugestionList}>
                {props.sugestions.length > 0 ? (
                  props.sugestions
                    .slice(0, Constants.maxNumberOfSearchSuggestions)
                    .map((sugestion: IssuerSugestion | InstrumentSugestion, index: number) =>
                      "isin" in sugestion || "isin" in sugestion ? (
                        <MenuItem
                          {...getItemProps({
                            item: sugestion,
                            index: index,
                            key: index,
                          })}
                          key={sugestion.isin}
                          selected={highlightedIndex === index}
                          className={classes.sugestionItem}
                        >
                          <Typography variant="body1" component="p">
                            {sugestion.name}
                          </Typography>
                          <Typography variant="body2" component="p" className={classes.sugestionSecondary}>
                            {sugestion.isin || sugestion.isin}
                          </Typography>
                        </MenuItem>
                      ) : (
                        <MenuItem
                          {...getItemProps({
                            item: sugestion,
                            index: index,
                            key: index,
                          })}
                          key={sugestion.lei}
                          selected={highlightedIndex === index}
                          className={classes.sugestionItem}
                        >
                          <Typography variant="body1" component="p">
                            {sugestion.name}
                          </Typography>
                          <Typography variant="body2" component="p" className={classes.sugestionSecondary}>
                            {sugestion.lei}
                          </Typography>
                        </MenuItem>
                      )
                    )
                ) : props.loading ? null : props.value.trim().length < Constants.searchMinLength ? (
                  <MenuItem disabled={true}>
                    <Typography variant="body1" component="p">
                      {`Type ${Constants.searchMinLength} or more characters for suggestions`}
                    </Typography>
                  </MenuItem>
                ) : (
                  <MenuItem disabled={true}>
                    <Typography variant="body1" component="p">
                      No Results
                    </Typography>
                  </MenuItem>
                )}
              </MenuList>
            </Paper>
          </Paper>
        </SearchFieldWrapper>
      )}
    </Downshift>
  );
}

export { SearchField };
