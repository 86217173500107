import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

const GET_UNDERLYING_INSTRUMENTS = gql`
  query getUnderlyingInstruments($isins: [String]) {
    getUnderlyingInstruments(isins: $isins) {
      instrumentFullName
      isin
      issuer
    }
  }
`;

interface Params {
  isins: Array<string>;
}

interface GetUnderlyingInstrumentsInterface {
  getUnderlyingInstruments: {
    instrumentFullName: string;
    isin: string;
    issuer: string;
  };
}

export const useLazyGetUnderlyingInstruments = () =>
  useLazyQuery<GetUnderlyingInstrumentsInterface, Params>(GET_UNDERLYING_INSTRUMENTS);
