import styled from "styled-components";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

export const InformationText = styled.div`
  span {
    font-weight: 700;
  }
`;

export const CircleIcon = styled(CheckCircleIcon)`
  font-size: 16px;
  color: #3f18aa;
  margin: 0 5px 0 0;
`;

export const FeatureContainer = styled.div`
  margin: 20px 0 0 0;
`;

export const FeatureTitle = styled.div`
  font-weight: 700;
  margin: 0 0 5px 0;
`;

export const Feature = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 5px 5px;
`;
