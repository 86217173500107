import { LinearProgress } from "@material-ui/core";
import styled from "styled-components";
import mediaQuery from "../../common/mediaQuery";

interface BorderLinearProgressInterface {
  value: number;
  details: number;
}

interface LegendInterface {
  isMainDetails: boolean | undefined;
}

export const BorderLinearProgress = styled(LinearProgress)<BorderLinearProgressInterface>`
  height: ${(props) => (props.details ? "25px" : "15px")};
  border-radius: 10px;
  background-color: ${(props) => (props.value === -1 ? "#E3E3E3" : "#b498ff")};
  min-width: 200px;

  .MuiLinearProgress-bar {
    border-radius: 5px;
    background-color: #3f18aa;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    height: 11px;
    border-radius: 5px;
  }

  ${mediaQuery.SM} {
    height: 11px;
    border-radius: 5px;
  }
`;

export const Legend = styled.div<LegendInterface>`
  display: flex;
  justify-content: space-between;
  margin-top: ${(props) => (props.isMainDetails ? "20px" : "3px")};

  ${mediaQuery.SM_LANDSCAPE} {
    margin-top: 10px;
  }

  ${mediaQuery.SM} {
    margin-top: 10px;
  }
`;

export const Title = styled.span`
  font-weight: 400;
  display: flex;

  ${mediaQuery.SM_LANDSCAPE} {
    font-size: 0.6rem;
  }

  ${mediaQuery.SM} {
    font-size: 0.6rem;
  }
`;

export const SubTitle = styled.div`
  font-weight: 700;
  margin-right: 3px;
`;
