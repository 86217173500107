import styled from "styled-components";
import mediaQuery from "../../../common/mediaQuery";

interface ISelectTradingVenuesWrapper {
  disabled: null | boolean;
}

export const SelectTradingVenuesWrapper = styled.div<ISelectTradingVenuesWrapper>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${(props) => (props.disabled ? "475px" : "315px")};
  margin-bottom: 10px;

  & p {
    font-weight: bold;
  }

  & .MuiInput-underline:before,
  .MuiInput-underline:after,
  .MuiInput-underline:hover:before {
    border-bottom: none;
  }
  & select {
    width: 145px;
    padding: 6px 105px 7px 15px;
    background: ${(props) => (props.disabled ? "#e3e3e3" : "#f7f8f6")};
    border: 1px solid rgb(0 0 0 / 0.16);
    border-radius: 6px;
  }

  & select:focus {
    background: #f7f8f6;
    border-radius: 6px;
  }

  span {
    color: #959595;
    font-size: 12px;
    font-weight: 500;
    margin-left: 5px;
  }

  ${mediaQuery.SM} {
    width: 300px;

    & select {
      width: 130px;
    }
  }
`;

export const TablesWrapper = styled.div`
  ${mediaQuery.SM} {
    & > div:nth-child(2n-1) > div:last-child {
      flex-wrap: wrap;
      & table {
        width: 100%;
      }
    }
  }
`;
