import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { TotalIssuedAmount } from "../common/types";

const GET_TOTAL_ISSUED_AMOUNT = gql`
  query getTotalIssuedAmount($lei: String!) {
    getTotalIssuedAmount(lei: $lei) {
      amount
      currency
    }
  }
`;

interface QueryText {
  lei: string;
}

interface GetTotalIssuedAmount {
  getTotalIssuedAmount: TotalIssuedAmount;
}

export const useLazyGetTotalIssuedAmountQuery = () =>
  useLazyQuery<GetTotalIssuedAmount, QueryText>(GET_TOTAL_ISSUED_AMOUNT);
