import { CircularProgress, Typography } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useEffect, useMemo, useState } from "react";
import { useMutationUpdateCustomer } from "../../graphql/UpdateCustomer";
import { themeTypography } from "./UpdatePaymentCard.css";

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          "fontSize": "12px",
          "color": "#424770",
          "letterSpacing": "0.025em",
          "fontFamily": "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
      hidePostalCode: true,
    }),
    []
  );
  return options;
};

interface IMessage {
  success?: string;
  error?: string;
}

const UpdatePaymentCard = (props?: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [infoMessage, setInfoMessage] = useState<IMessage>({ error: "", success: "" });
  const [isUpdateInProgress, setIsUpdateInProgress] = useState<boolean>(false);

  const [
    updateCustomer,
    { data: updateCustomerData, loading: loadingUpdateCustomer, error: updateCustomerError },
  ] = useMutationUpdateCustomer();

  useEffect(() => {
    if (updateCustomerError) {
      setInfoMessage({ error: updateCustomerError.message });
    }
    if (updateCustomerData && updateCustomerData.updateCustomer && !updateCustomerData.updateCustomer.error) {
      setIsUpdateInProgress(false);
      setInfoMessage({ success: "Updated your card details." });
      props.refreshCustomer();
    } else if (updateCustomerData && updateCustomerData.updateCustomer.error) {
      setIsUpdateInProgress(false);
      setInfoMessage({ error: updateCustomerData.updateCustomer.error });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCustomerData, updateCustomerError]);

  const handleSubmit = async (event: any) => {
    setIsUpdateInProgress(true);

    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      throw new Error();
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setIsUpdateInProgress(false);
      setInfoMessage({ error: error.message });
    } else {
      if (paymentMethod) {
        updateCustomer({ variables: { paymentMethodToken: paymentMethod.id } });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <ThemeProvider theme={themeTypography}>
        <Typography variant="h5">Update card details</Typography>
      </ThemeProvider>
      <label>
        Card details
        <CardElement options={options} />
      </label>
      {infoMessage.error && !loadingUpdateCustomer && !isUpdateInProgress ? (
        <p data-message="error">{infoMessage?.error}</p>
      ) : (
        <p data-message="success">{infoMessage?.success}</p>
      )}
      {isUpdateInProgress ? (
        <CircularProgress color="secondary" size={30} />
      ) : (
        <button type="submit">UPDATE CARD</button>
      )}
    </form>
  );
};

export default UpdatePaymentCard;
