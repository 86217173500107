import React, { useContext, useState } from "react";
import { PageHeader, PageFooter } from "../index";
import PageWidthWrapper from "../PageWidthWrapper";
import MuiAlert from "@material-ui/lab/Alert";
import { SubscribeContext } from "../../common/subcriptionProvider";
import { PageProps } from "./Page.interface";
import { AlertMessage, AlertTitle, MainWrapper, SnackbarStyle } from "./Page.css";
import { Constants } from "../../common/constants";

const Alert = (props: any) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

function Page({ children, hideBanner, hideSearch, isHomepage }: PageProps) {
  const [open, setOpen] = useState(true);
  const { errorMessage, setErrorMessage } = useContext(SubscribeContext);
  const isGlobalErrorMessage = true;

  const handleCloseAlert = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorMessage("");
    setOpen(false);
  };

  return (
    <>
      <PageHeader hideBanner={!hideBanner} hideSearch={hideSearch} />
      {errorMessage ? (
        <SnackbarStyle open={open} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity="warning">
            <AlertTitle>Error</AlertTitle>
            <AlertMessage>{isGlobalErrorMessage ? Constants.errorMessage : errorMessage}</AlertMessage>
          </Alert>
        </SnackbarStyle>
      ) : null}
      {isHomepage ? (
        <MainWrapper isHomepage={isHomepage} hideSearch={hideSearch} role="main">
          {children}
        </MainWrapper>
      ) : (
        <PageWidthWrapper>
          <MainWrapper isHomepage={isHomepage} hideSearch={hideSearch} role="main">
            {children}
          </MainWrapper>
        </PageWidthWrapper>
      )}

      <PageFooter />
    </>
  );
}

export { Page };
