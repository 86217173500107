import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import styled from "styled-components";
import mediaQuery from "../../common/mediaQuery";

export const StyledDialogTitle = styled(DialogTitle)`
  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    padding: 0px 10px;

    h2 {
      font-size: 13px;
    }

    svg {
      font-size: 1.2rem;
    }
  }

  ${mediaQuery.SM} {
    padding: 0px 10px;

    h2 {
      font-size: 13px;
    }

    svg {
      font-size: 1.2rem;
    }
  }
`;

export const StyledDialog = styled(Dialog)`
  .MuiTypography-gutterBottom {
    margin-bottom: 1.35em;
  }
`;

export const StyledDialogContent = styled(DialogContent)`
  ${mediaQuery.SM_LANDSCAPE} {
    max-height: 55vh;

    p {
      font-size: 0.65rem;
    }
  }

  ${mediaQuery.SM} {
    max-height: 55vh;

    p {
      font-size: 0.65rem;
    }
  }
`;
