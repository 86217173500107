import React from "react";
import { calcDaysProgress } from "../../utils/calcDates";
import LinearProgressBar from "../LinearProgressBar/LinearProgressBar";
import { NotAvailable, PeriodsProgressContainer, ProgressContainer } from "./PeriodProgress.css";

interface PeriodProgress {
  maturityDate?: Date;
  terminationDate: Date;
  issueDate?: Date;
}

const PeriodProgress: React.FC<PeriodProgress> = ({ maturityDate, terminationDate, issueDate }) => {
  const daysProgress = calcDaysProgress(issueDate, maturityDate, terminationDate);

  return (
    <>
      {daysProgress !== 0 ? (
        <PeriodsProgressContainer>
          <ProgressContainer>
            <LinearProgressBar
              progress={daysProgress}
              firstTradeDate={issueDate}
              maturityDate={maturityDate}
              terminationDate={terminationDate}
            />
          </ProgressContainer>
        </PeriodsProgressContainer>
      ) : (
        <NotAvailable>N/A</NotAvailable>
      )}
    </>
  );
};

export default PeriodProgress;
