import { Card } from "@material-ui/core";
import styled, { css } from "styled-components";
import mediaQuery from "../../common/mediaQuery";

interface BlurredImageInterface {
  component: string | undefined;
}

export const CardStyle = styled(Card)`
  position: relative;
  height: 100%;
`;

export const CardHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;

  ${mediaQuery.SM} {
    a {
      font-size: 0.8rem;
    }
  }
`;

export const CardTitle = styled.div`
  .MuiTypography-body1 {
    font-size: 25px;
    font-weight: 700;

    ${mediaQuery.SM} {
      font-size: 1.2rem;
    }
  }
`;

export const BlurredImage = styled.img<BlurredImageInterface>`
  filter: blur(20px);
  ${(props) =>
    (props.component === "Structure" || props.component === "IssuerRelations") &&
    css`
      height: 30%;
      width: 30%;
    `}
  ${(props) =>
    props.component === "FavoriteInstrumentsTable" &&
    css`
      height: 300px;
      width: 100%;
    `}
    ${(props) =>
    props.component !== "Structure" &&
    props.component !== "FavoriteInstrumentsTable" &&
    props.component !== "IssuerRelations" &&
    css`
      height: 100%;
      width: 100%;
    `}
`;
