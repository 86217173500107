import styled from "styled-components";
import mediaQuery from "../../common/mediaQuery";
import { makeStyles, fade, Snackbar, Button } from "@material-ui/core";

export const UserName = styled.div`
  max-width: 100px;
  overflow: hidden;
  overflow-wrap: break-word;
  max-height: 40px;
  text-overflow: ellipsis;

  ${mediaQuery.EXTRA_LG} {
    display: none;
  }
`;

export const SnackbarStyle = styled(Snackbar)`
  top: -85%;

  .MuiAlert-root {
    background-color: #e87f38;
  }
`;

export const StyledButton = styled(Button)`
  & > * {
    pointer-events: none;
  }
`;

export const useStyles = makeStyles((theme) => ({
  button: {
    "marginTop": "-3px",
    "&:hover": {
      backgroundColor: fade(theme.palette.primary.contrastText, 0.1),
    },
  },
}));
