import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { EditUser } from "../common/types";

const EDIT_USER = gql`
  mutation editUser($role: String!, $profession: String!) {
    editUser(role: $role, profession: $profession) {
      profession
      role
    }
  }
`;

interface Params {
  role: string;
  profession: string;
}

interface IEditUser {
  editUser: EditUser;
}

export const useMutationEditUser = () => useMutation<IEditUser, Params>(EDIT_USER);
