import { Box, Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { BreadcrumbsLinks, Page } from "../../components";
import { NotFoundPageStyles } from "./NotFoundPage.css";

function NotFound(props: RouteComponentProps<{}>) {
  const classes = NotFoundPageStyles();
  const { location } = props;

  return (
    <Page>
      <Box mb={3}>
        <BreadcrumbsLinks
          links={[
            { to: "/", label: "Home" },
            { to: "/", label: "Not found" },
          ]}
        />
      </Box>
      <Grid container spacing={0} alignItems="center" justify="center" className={classes.grid}>
        <Grid item xs={12} sm={10} md={8} lg={6}>
          <Paper className={classes.paper}>
            <Typography>404 Page {location.pathname} not found</Typography>
          </Paper>
        </Grid>
      </Grid>
    </Page>
  );
}

export { NotFound };
