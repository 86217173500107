import { CircularProgress, Menu, MenuItem } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import PersonIcon from "@material-ui/icons/PersonOutlined";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { SubscribeContext } from "../../common/subcriptionProvider";
import { redirectPath } from "../../utils/redirect";
import { StyledButton, UserName, useStyles } from "./LoginButton.css";

export function LoginButton() {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const {
    azureProps: { authenticationState, login, logout, accountInfo },
  } = useContext(SubscribeContext);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignIn = () => {
    sessionStorage.setItem("directLinkPathName", history.location.pathname + history.location.search);
    login();
  };

  switch (authenticationState) {
    case "Authenticated":
      return (
        <>
          <StyledButton
            id="header__user_icon"
            className={classes.button}
            color="inherit"
            aria-label="Menu"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <PersonIcon />
            <UserName>{accountInfo?.account.name}</UserName>
            <ArrowDropDownIcon />
          </StyledButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <MenuItem onClick={() => redirectPath("/user-settings", history)}>User settings</MenuItem>
            <MenuItem onClick={logout}>Logout</MenuItem>
          </Menu>
        </>
      );
    case "Unauthenticated":
      return (
        <>
          <StyledButton
            id="header__user_icon"
            className={classes.button}
            color="inherit"
            aria-label="Menu"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <PersonIcon />
            <ArrowDropDownIcon />
          </StyledButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <MenuItem id="header__log_in_sign_up" onClick={handleSignIn}>
              Log in / Sign up
            </MenuItem>
          </Menu>
        </>
      );
    case "InProgress":
      return (
        <>
          <PersonIcon />
          <CircularProgress size={25} />
          <ArrowDropDownIcon />
        </>
      );
    default:
      return null;
  }
}
