import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import SubscriptionToggleButtons from "./SubscriptionToggleButtons";

declare const window: any;

const stripePromise = loadStripe(window._env_.STRIPE_ID);

const SubscriptionButtonsWrapper = ({ isSubscriptionCanceled, setIsSubscriptionCanceled }: any) => {
  return (
    <Elements stripe={stripePromise} options={{ locale: "en" }}>
      <SubscriptionToggleButtons
        setIsSubscriptionCanceled={setIsSubscriptionCanceled}
        isSubscriptionCanceled={isSubscriptionCanceled}
      />
    </Elements>
  );
};

export default SubscriptionButtonsWrapper;
