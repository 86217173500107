import { Box, Grid, makeStyles } from "@material-ui/core";
import { fade } from "@material-ui/core/styles";
import styled from "styled-components";
import mediaQuery from "../../common/mediaQuery";

export const useStyles = makeStyles((theme) => ({
  toolbar: {
    maxHeight: "64px",
    paddingLeft: 0,
    paddingRight: 0,
  },
  banner: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    justifyContent: "center",
    minHeight: "32px !important",
    paddingLeft: 0,
    paddingRight: 0,
  },
  button: {
    "marginTop": "-3px",
    "&:hover": {
      backgroundColor: fade(theme.palette.primary.contrastText, 0.1),
    },
  },
  disabled: {
    color: fade(theme.palette.primary.contrastText, 0.3) + " !important",
  },
}));

interface HeaderWrapperBoxInterface {
  media: number;
}

export const HeaderWrapperBox = styled(Box)<HeaderWrapperBoxInterface>`
  .MuiAppBar-colorPrimary {
    background: #13161c;
  }
  .MuiToolbar-root {
    max-height: 64px;
    padding-left: 0;
    padding-right: 0;
  }
  & .MuiGrid-item {
    width: ${(props) => (props.media ? "34%" : "70%")};
    flex: ${(props) => (props.media ? "" : 2)};
  }

  a {
    text-decoration: none;
    color: white;
    margin-right: 20px;
    & h1 {
      font-size: 35px;
    }
  }

  ul {
    box-shadow: 0px 5px 4px 3px rgba(219, 219, 219, 1);
  }

  ul > li {
    border-bottom: 1px solid #dbdbdb;
  }

  ${mediaQuery.LG} {
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    .scroll-down {
      transform: translate3d(0, -100%, 0);
      transition: transform 0.3s;
    }
    .scroll-up {
      transform: none;
      transition: transform 0.3s;
    }
  }

  ${mediaQuery.MD} {
    .MuiToolbar-root {
      display: flex;
      flex-wrap: nowrap;
      max-height: none;
      & .MuiGrid-item {
        order: 3;
        margin: 0 auto;
        padding-bottom: 10px;
        padding-top: 5px;
      }
    }
  }

  ${mediaQuery.SM_LANDSCAPE} {
    .MuiContainer-root {
      padding: 0;
    }

    .MuiToolbar-root {
      display: flex;
      justify-content: space-between;
      min-height: 55px;
      & .MuiGrid-item {
        order: 0;
        margin: 0 auto;
        padding-bottom: 3px;
      }
    }
    & a h1 {
      font-size: 20px;
    }
    & button:first-child span {
      width: 100%;
      font-size: 9px;
    }

    ul > li {
      padding: 0 16px !important;
      min-height: 35px;
    }

    ul > li > p {
      font-size: 0.8rem;
    }

    ul > li > p:first-child {
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  ${mediaQuery.SM} {
    .MuiContainer-root {
      padding: 0;
    }

    .MuiToolbar-root {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      max-height: none;
      & .MuiGrid-item {
        order: 3;
        margin: 0 auto;
        padding-bottom: 3px;
      }
    }
    & a h1 {
      font-size: 20px;
    }
    & button:first-child span {
      width: 100%;
      font-size: 9px;
    }

    ul > li {
      padding: 0 16px !important;
      min-height: 35px;
    }

    ul > li > p {
      font-size: 0.8rem;
    }

    ul > li > p:first-child {
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const LogoStyles = styled.div`
  margin-top: 8px;
  pointer-events: none;

  & > * {
    pointer-events: none;
  }

  & img {
    width: auto;
    height: 45px;
    padding-right: 27px;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    margin-top: 0px;
    & img {
      width: auto;
      height: 35px;
      padding-top: 5px;
      padding-right: 10px;
    }
  }
  ${mediaQuery.SM} {
    margin-top: 0px;
    & img {
      width: auto;
      height: 35px;
      padding-top: 5px;
      padding-right: 10px;
    }
  }
`;

export const StyledContainer = styled(Grid)`
  display: flex;
  flex: 2;
  justify-content: flex-end;
  align-items: center;

  ${mediaQuery.SM_LANDSCAPE} {
    flex: 0;
  }
`;
