import { CardHeader, Link, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import SubjectIcon from "@material-ui/icons/Subject";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Flag from "react-world-flags";
import useMedia from "use-media";
import favorite_instruments from "../../assets/images/favorite_instruments.png";
import { SubscribeContext } from "../../common/subcriptionProvider";
import BlurredCard from "../../components/BlurredCard/BlurredCard";
import LinearProgressBar from "../../components/LinearProgressBar/LinearProgressBar";
import Loader from "../../components/loader/Loader";
import TablePagination from "../../components/TablePagination/TablePagination";
import { useLazyGetFavoritesQuery } from "../../graphql/GetFavorites";
import { calcDaysProgress } from "../../utils/calcDates";
import { formatAmount } from "../../utils/formatAmount";
import { IFavoriteInstruments } from "./FavouriteInstrumentsInterface";
import {
  CardContentStyle,
  EmptyCard,
  FavoriteInstrumentsCardWrapper,
  LoaderOverlay,
  Message,
  StyledBox,
  StyledTable,
  StyledTableBody,
  TableCellName,
  TablePeriodCell,
  TableRowStyle,
  TypographyStyle,
} from "./FavouriteInstrumentsTable.css";

const FavouriteInstrumentsTable: React.FC = () => {
  const [favoriteInstrumentsRows, setFavoriteInstrumentsRows] = useState<any>([]);
  const [totalNumberOfRec, setTotalNumberOfRec] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const { isUserPremium, setErrorMessage } = useContext(SubscribeContext);

  const history = useHistory();
  const mediaXXL = useMedia({ maxWidth: 1919 });

  const favInstrumentsPath = history.location.pathname === "/favourite-instruments";
  const dashboardPath = history.location.pathname === "/dashboard";

  const isShown = favInstrumentsPath || (dashboardPath && mediaXXL);

  const rows = favInstrumentsPath ? rowsPerPage : 5;

  const cardTitle = "Favourite Instruments";

  const [
    getFavorites,
    { data: favoritesInstruments, loading: loadingFavoritesInstruments, error: getFavouritesError },
  ] = useLazyGetFavoritesQuery({ fetchPolicy: "no-cache" });

  useEffect(() => {
    if (isUserPremium !== null) {
      getFavorites({
        variables: {
          startingRecord: page === 0 ? 1 : page * rowsPerPage + 1,
          numberOfRecords: rows,
        },
      });
    }
  }, [getFavorites, page, rows, rowsPerPage, isUserPremium]);

  useEffect(() => {
    if (getFavouritesError) {
      setErrorMessage(getFavouritesError.message);
    }
    if (favoritesInstruments && favoritesInstruments?.getFavorites) {
      setFavoriteInstrumentsRows(favoritesInstruments.getFavorites.instruments);
      setTotalNumberOfRec(favoritesInstruments.getFavorites.metadata.totalNumberOfRecords);
    }
  }, [favoritesInstruments, getFavouritesError]);

  const handleRedirectInstrument = (isin: string) => {
    history.push(`/instrument/${isin}`);
  };

  const handleRowsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPage(0);
    setRowsPerPage(event.target.value as number);
  };

  const handleSeeAll = () => {
    history.push("/favourite-instruments");
  };

  return (
    <>
      {isUserPremium !== null ? (
        <FavoriteInstrumentsCardWrapper>
          <CardHeader
            title={
              <StyledBox>
                <TypographyStyle variant="h5">{cardTitle}</TypographyStyle>
                <Typography>
                  {!favInstrumentsPath ? (
                    <Link id="dashboard__see_all_favourite_instruments" onClick={handleSeeAll}>
                      See all
                    </Link>
                  ) : null}
                </Typography>
              </StyledBox>
            }
          />
          <CardContentStyle>
            <>
              {loadingFavoritesInstruments && (
                <LoaderOverlay loaderPosition={favoriteInstrumentsRows && favoriteInstrumentsRows.length}>
                  <Loader />
                </LoaderOverlay>
              )}
              {favoriteInstrumentsRows.length > 0 && (
                <StyledTable size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Country</TableCell>
                      <TableCell>Name</TableCell>
                      {isShown && <TableCell>Period</TableCell>}
                      <TableCell>Currency</TableCell>
                      {isShown && (
                        <>
                          <TableCell>Value Per Unit</TableCell>
                          <TableCell>Issued Amount</TableCell>
                        </>
                      )}
                    </TableRow>
                  </TableHead>
                  <StyledTableBody loading={loadingFavoritesInstruments ? 1 : 0}>
                    {favoriteInstrumentsRows.map((row: IFavoriteInstruments) => {
                      const daysProgress = calcDaysProgress(row.firstTradeDate, row.maturityDate, row.terminationDate);
                      return (
                        <TableRowStyle
                          key={row.instrumentIdentificationCode}
                          hover
                          onClick={() => handleRedirectInstrument(row.instrumentIdentificationCode)}
                        >
                          <TableCell id="favourite_instruments__instrument" align="center">
                            {row.upcomingRCA ? (
                              <Flag code={row.upcomingRCA} alt={row.upcomingRCA} style={{ width: "38px" }} />
                            ) : (
                              <Flag code={"EU"} alt={"EU"} style={{ width: "38px" }} />
                            )}
                          </TableCell>
                          <TableCellName id="favourite_instruments__instrument" show={isShown ? 1 : 0}>
                            <p>{row.instrumentIdentificationCode}</p>
                            <p>{row.instrumentFullName}</p>
                          </TableCellName>
                          {daysProgress !== 0 && isShown ? (
                            <TablePeriodCell id="favourite_instruments__instrument">
                              <LinearProgressBar
                                progress={daysProgress}
                                firstTradeDate={row.firstTradeDate}
                                maturityDate={row.maturityDate}
                                terminationDate={row.terminationDate}
                              />
                            </TablePeriodCell>
                          ) : (
                            isShown && <TableCell id="favourite_instruments__instrument">N/A</TableCell>
                          )}
                          <TableCell id="favourite_instruments__instrument">{row.notionalCurrency}</TableCell>
                          {isShown && (
                            <>
                              <TableCell id="favourite_instruments__instrument" align="left">
                                {row.nominalValuePerUnit ? formatAmount(parseFloat(row.nominalValuePerUnit)) : "N/A"}
                              </TableCell>
                              <TableCell id="favourite_instruments__instrument" align="left">
                                {row.totalIssuedNominalAmount
                                  ? formatAmount(parseFloat(row.totalIssuedNominalAmount))
                                  : "N/A"}
                              </TableCell>
                            </>
                          )}
                        </TableRowStyle>
                      );
                    })}
                  </StyledTableBody>
                </StyledTable>
              )}
              {!favoriteInstrumentsRows.length && !loadingFavoritesInstruments && (
                <EmptyCard>
                  <SubjectIcon fontSize="large" color="inherit" />
                  <Message>No Favourite instruments</Message>
                </EmptyCard>
              )}
              {favInstrumentsPath && (
                <TablePagination
                  rowsPerPage={rowsPerPage}
                  handleRowsChange={handleRowsChange}
                  page={page}
                  setPage={setPage}
                  totalInstrumentElements={totalNumberOfRec}
                  loading={loadingFavoritesInstruments}
                />
              )}
            </>
          </CardContentStyle>
        </FavoriteInstrumentsCardWrapper>
      ) : (
        <BlurredCard image={favorite_instruments} title={cardTitle} component={"FavoriteInstrumentsTable"} />
      )}
    </>
  );
};

export default FavouriteInstrumentsTable;
