import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

const REMOVE_FAVORITE = gql`
  mutation removeFavorite($isin: String) {
    removeFavorite(isin: $isin) {
      instrument {
        upcomingRCA
        instrumentFullName
        instrumentType
        notionalCurrency
        instrumentClassification
      }
    }
  }
`;

interface Params {
  isin: string;
}

export const useMutationRemoveFavorite = () => useMutation<Params>(REMOVE_FAVORITE);
