import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

const GET_NEWEST_PUBLICATION_DATE = gql`
  query getNewestPublicationDate {
    getNewestPublicationDate {
      newestPublicationDate
    }
  }
`;

interface IPublicationDate {
  getNewestPublicationDate: {
    newestPublicationDate: Date;
  };
}

export const useLazyGetNewestPublicationDate = () => useLazyQuery<IPublicationDate>(GET_NEWEST_PUBLICATION_DATE);
