import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import SplitForm from "./PaymentCard";
import { Card } from "@material-ui/core";
import { PaymentCardWrapper } from "./PaymentCard.css";
import { useLazyGetPremiumPlanQuery } from "../../graphql/GetPrepiumPlan";
import { PremiumPlan } from "../../common/types";
import { SubscribeContext } from "../../common/subcriptionProvider";
import Loader from "../loader/Loader";

declare const window: any;

const stripePromise = loadStripe(window._env_.STRIPE_ID);

const PaymentCardElement = () => {
  const [premiumPlan, setPremiumPlan] = useState<PremiumPlan>();
  const { setErrorMessage } = React.useContext(SubscribeContext);

  const [
    getPremiumPlan,
    { data: premiumPlanData, error: premiumPlanError },
  ] = useLazyGetPremiumPlanQuery();

  useEffect(() => {
    if (premiumPlanError) {
      setErrorMessage(premiumPlanError.message);
    }

    if (getPremiumPlan) {
      getPremiumPlan();
    }
  }, [getPremiumPlan, premiumPlanError, setErrorMessage]);

  useEffect(() => {
    if (premiumPlanData && premiumPlanData?.getPremiumPlan) {
      setPremiumPlan(premiumPlanData?.getPremiumPlan);
    }
  }, [premiumPlanData]);

  return (
    <PaymentCardWrapper>
      <Card>
        <Elements stripe={stripePromise} options={{ locale: "en" }}>
          {premiumPlan ? <SplitForm premiumPlan={premiumPlan} /> : <Loader />}
        </Elements>
      </Card>
    </PaymentCardWrapper>
  );
};

export default PaymentCardElement;
