import { Card, CardContent, Typography } from "@material-ui/core";
import styled from "styled-components";
import mediaQuery from "../../common/mediaQuery";

export const Message = styled.div`
  font-size: 16px;
  font-weight: 700;
`;

export const BlurredImage = styled.img`
  filter: blur(20px);
  height: 100%;
  width: 100%;
`;

export const CardContentStyle = styled(CardContent)`
  position: relative;
`;

export const EmptyCard = styled.div`
  text-align: center;
  color: #cbcbcb;
`;

export const TypographyStyle = styled(Typography)`
  font-size: 25px;
  font-weight: 700;

  ${mediaQuery.SM_LANDSCAPE} {
    font-size: 15px;
  }
  ${mediaQuery.SM} {
    font-size: 15px;
  }
`;

export const CardStyle = styled(Card)`
  height: 100%;
  position: relative;
`;
