import { makeStyles } from "@material-ui/core";
import theme from "../../common/theme";

export const NotFoundPageStyles = makeStyles({
  grid: {
    minHeight: "auto",
  },
  paper: {
    margin: theme.spacing(3, 0, 2, 0),
    padding: theme.spacing(3),
  },
});
