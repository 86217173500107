import { Card, Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {createMuiTheme} from "@material-ui/core/styles";
import styled from "styled-components";
import StatusBlue from "../../../assets/images/status_icon_blue.png";
import StatusGreen from "../../../assets/images/status_icon_green.png";
import StatusOrange from "../../../assets/images/status_icon_orange.png";
import StatusRed from "../../../assets/images/status_icon_red.png";
import mediaQuery from "../../../common/mediaQuery";

interface ButtonWrapperInterface {
  disabled?: boolean | null;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: theme.spacing(3),
    },
    title: {
      fontWeight: 'bold',
    },
    rightColumn: {
      [theme.breakpoints.down("lg")]: {
        order: 2,
      },
    },
    leftColumn: {
      [theme.breakpoints.down("lg")]: {
        order: 1,
      },
    },
    alert: {
      fontSize: "35px",
      textAlign: "center",
      margin: "9px 0 0 7px",
      padding: "20px",
    },
  })
);

export const themeTypography = createMuiTheme({
  typography: {
    h5: {
      fontWeight: 700,
    },
  },
});

export const InformationCardWrapper = styled(Grid)`
  & tr td:first-child {
    width: 30%;
    vertical-align: top;
  }

  td > p {
    margin: 0;
  }

  span[data-status="ACTIVE"]:after,
  span[data-status="ISSUED"]:after {
    background-image: url(${StatusGreen});
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    content: "";
  }

  span[data-status="LAPSED"]:after,
  span[data-status="INACTIVE"]:after {
    background-image: url(${StatusOrange});
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    content: "";
  }

  span[data-status="MERGED"]:after,
  span[data-status="RETIRED"]:after,
  span[data-status="DUPLICATE"]:after {
    background-image: url(${StatusBlue});
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    content: "";
  }

  span[data-status="ANNULLED"]:after {
    background-image: url(${StatusRed});
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    content: "";
  }

  ${mediaQuery.SM_LANDSCAPE} {
    margin: 0;

    span[data-status="ACTIVE"]:after,
    span[data-status="ISSUED"]:after,
    span[data-status="INACTIVE"]:after,
    span[data-status="MERGED"]:after,
    span[data-status="RETIRED"]:after,
    span[data-status="ANNULLED"]:after,
    span[data-status="DUPLICATE"]:after,
    span[data-status="LAPSED"]:after {
      width: 15px;
      height: 15px;
    }

    .MuiCardHeader-root {
      padding-bottom: 0;
    }

    td {
      font-size: 10px;
    }

    h5 {
      font-size: 1.1rem;
    }
  }

  ${mediaQuery.SM} {
    margin: 0;

    span[data-status="ACTIVE"]:after,
    span[data-status="ISSUED"]:after,
    span[data-status="INACTIVE"]:after,
    span[data-status="MERGED"]:after,
    span[data-status="RETIRED"]:after,
    span[data-status="ANNULLED"]:after,
    span[data-status="DUPLICATE"]:after,
    span[data-status="LAPSED"]:after {
      width: 15px;
      height: 15px;
    }

    .MuiCardHeader-root {
      padding-bottom: 0;
    }

    td {
      font-size: 10px;
    }

    h5 {
      font-size: 1.1rem;
    }
  }
`;

export const IssuerPageHeaderWrapper = styled.div`
  img {
    height: 85px;
    margin-right: 15px;
  }

  .MuiTabs-root {
    border-bottom: 2px solid #dedcdc;
    margin-bottom: 25px;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    .MuiTabs-root {
      margin-top: 10px;
      min-height: 0;
    }

    .MuiTabs-root button {
      font-size: 0.6rem;
      max-width: unset;
      margin-right: 21px;
      padding: 10px 0;
    }

    .MuiTab-root {
      font-size: 0.7rem;
      min-height: 0;
    }

    .MuiBox-root {
      margin-bottom: 0;
    }

    .MuiTypography-h4 {
      font-size: 1.3rem;
    }

    .MuiTypography-h5 {
      font-size: 0.7rem;
    }

    img {
      height: 50px;
    }
  }

  ${mediaQuery.SM} {
    .MuiTabs-root {
      margin-top: 10px;
      min-height: 0;
    }

    .MuiTabs-root button {
      font-size: 0.6rem;
      max-width: 100px;
      margin-right: 21px;
      padding: 10px 0;
    }

    .MuiTab-root {
      font-size: 0.7rem;
      min-height: 0;
    }

    .MuiBox-root {
      margin-bottom: 0;
    }

    .MuiTypography-h4 {
      font-size: 1.3rem;
    }

    .MuiTypography-h5 {
      font-size: 0.7rem;
    }

    img {
      height: 50px;
    }
  }
`;

export const CardStyle = styled(Card)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 128px;
`;

export const EmptyCard = styled.div`
  text-align: center;
  color: #cbcbcb;
`;

export const Message = styled.div`
  font-size: 16px;
  font-weight: 700;
`;

interface INoInstruments {
  issinglenoinstrumentvisible: string | null;
  isanyfilterselected: number;
}
export const LoaderWrapper = styled(Grid)`
  order: 0;
  margin: 0;

  ${mediaQuery.SM} {
    & h1 {
      font-size: 0.5rem;
    }
  }
`;

export const TablesWrapper = styled(Grid)<INoInstruments>`
  display: ${({ issinglenoinstrumentvisible, isanyfilterselected }) =>
    issinglenoinstrumentvisible === "true" && !isanyfilterselected ? "none" : "block"};
  visibility: ${({ issinglenoinstrumentvisible, isanyfilterselected }) =>
    (issinglenoinstrumentvisible === "true" && !isanyfilterselected) || issinglenoinstrumentvisible === null
      ? "hidden"
      : "visible"};
`;

export const SingleNoInstrumentTableWrapper = styled(Grid)<INoInstruments>`
  display: ${({ issinglenoinstrumentvisible, isanyfilterselected }) =>
    issinglenoinstrumentvisible === "true" && !isanyfilterselected ? "grid" : "none"};

  ${mediaQuery.XXL} {
    padding-right: 23px !important;
  }

  ${mediaQuery.SM} {
    padding-right: 8px !important;
  }
`;

export const TopTablesWrapper = styled(Grid)`
  order: 2;
  height: max-content;
  ${mediaQuery.XXL} {
    order: 0;
  }
`;

export const GridContentWrapper = styled(Grid)`
  ${mediaQuery.SM_LANDSCAPE} {
    h5 {
      font-size: 1.1rem;
    }

    td,
    span {
      font-size: 10px;
    }

    .MuiGrid-spacing-xs-2 {
      width: 100%;
      margin: 0;
      padding: 8px 0;
    }
  }

  ${mediaQuery.SM} {
    h5 {
      font-size: 1.1rem;
    }

    td,
    span {
      font-size: 10px;
    }

    .MuiGrid-spacing-xs-2 {
      width: 100%;
      margin: 0;
      padding: 8px 0;
    }
  }
`;

export const ButtonWrapper = styled.div<ButtonWrapperInterface>`
  position: absolute;
  top: 23px;
  right: 23px;

  button {
    padding: 0 30px;
    font-size: 20px;
    font-weight: 200;
    background: #3f18aa;
    border-radius: 10px;
    color: white;
  }

  button:hover {
    background: #5f3cc2;
  }

  & > button > * {
    pointer-events: none;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    button {
      padding: 5px 14px;
      font-size: 10px;
    }
  }

  ${mediaQuery.SM} {
    button {
      padding: 5px 14px;
      font-size: 10px;
    }
  }
`;

export const BtnTooltipTitle = styled.div`
  font-size: 14px;
  font-weight: 300;
`;

export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .MuiGrid-grid-xs-12,
  .MuiGrid-grid-sm-6 {
    padding: 8px;
  }
`;

export const GridItemWithButton = styled(Grid)`
  position: relative;

  ${mediaQuery.SM} {
    h5 {
      width: 170px;
    }
  }
`;

export const ISINList = styled.div`
  width: max-content;

  span {
    display: inherit;
  }
`;

export const TooltipTitle = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100px;
  font-size: 12px;
  justify-content: flex-start;

  span {
    padding: 2px 0;
    display: block;
  }
`;

export const Dots = styled.div`
  font-size: 30px;
  padding-right: 8px;
  float: right;
  margin-top: -20px;
`;

export const StyledSpan = styled.span`
  margin-right: 10px;
  width: 50%;
  white-space: nowrap;
`;

export const StyledRecordContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #e0e0e0;
`;

export const SubTitle = styled.span`
  font-weight: 700;
  margin-right: 8px;
`;
