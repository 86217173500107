import { CardHeader, Table, TableRow, Tooltip } from "@material-ui/core";
import { Lock } from "@material-ui/icons";
import React, { useContext } from "react";
import { SubscribeContext } from "../../../../common/subcriptionProvider";
import { EmptyCard, SubjectIconStyle } from "../TransparencyCalculations.css";
import {
  StyledTableBody,
  TableCellStyle,
  TableContainer,
  TypographyStyle,
  TooltipTitle,
  IconTitle,
  LockedFileDetails,
} from "./TransparencyCalculationsDetails.css";
import { mapTransparencyCalculationRows, calcDaysProgress } from "./TransparencyCalculationsFunctions";
import TransparencyLinearProgressBar from "./TransparencyLinearProgressBar";
import { ITransparencyCalculationsDetails } from "./TransparencyLinearProgressInterface";

const TransparencyCalculationsDetails: React.FC<ITransparencyCalculationsDetails> = ({ selectedFile }) => {
  const { isUserPremium } = useContext(SubscribeContext);

  const generateTransparencyTableEmpty = () => {
    if (!isUserPremium) {
      return (
        <LockedFileDetails>
          <Lock />
        </LockedFileDetails>
      );
    } else {
      return (
        <EmptyCard>
          <SubjectIconStyle fontSize="large" color="inherit" />
          <IconTitle>Choose one of the entries to display the details.</IconTitle>
        </EmptyCard>
      );
    }
  };

  const generateTransparencyWithData = () => {
    const { transparencyData, labelTooltips } = mapTransparencyCalculationRows(selectedFile);
    return (
      <TableContainer>
        <Table>
          <StyledTableBody>
            {Object.keys(transparencyData).map((el: string) => (
              <TableRow key={el}>
                <>
                  <Tooltip
                    title={
                      labelTooltips[el] ? (
                        <TooltipTitle key={el}>
                          <span key={el}>{labelTooltips[el]}</span>
                        </TooltipTitle>
                      ) : (
                        ""
                      )
                    }
                    key={el}
                    placement="top"
                    enterTouchDelay={0}
                  >
                    <TableCellStyle>{el}</TableCellStyle>
                  </Tooltip>
                  <TableCellStyle>{transparencyData[el]}</TableCellStyle>
                </>
              </TableRow>
            ))}
          </StyledTableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <>
      <CardHeader title={<TypographyStyle variant="h5">Transparency Calculations - results</TypographyStyle>} />
      {selectedFile && <TransparencyLinearProgressBar progressData={calcDaysProgress(selectedFile)} />}
      {selectedFile ? generateTransparencyWithData() : generateTransparencyTableEmpty()}
    </>
  );
};

export default TransparencyCalculationsDetails;
