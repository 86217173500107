import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

const GET_TRADING_VENUES_FOR_CODE = gql`
  query getTradingVenuesForCodes($micCodes: [String]) {
    getTradingVenuesForCodes(micCodes: $micCodes) {
      micCode
      name
    }
  }
`;

interface Params {
  micCodes: string[];
}

interface GetTradingVenuesForCodes {
  getTradingVenuesForCodes: TradingVenue[];
}

export interface TradingVenue {
  name: string;
  micCode: string;
}
export const useLazyGetTradingVenuesForCodesQuery = () =>
  useLazyQuery<GetTradingVenuesForCodes, Params>(GET_TRADING_VENUES_FOR_CODE);
