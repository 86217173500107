import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import React from "react";

const StateTable: React.FC<any> = ({ tableRows }) => {
  return (
    <Table size="small">
      <TableBody>
        {tableRows &&
          Object.keys(tableRows).map((key: string) => (
            <TableRow key={key}>
              <TableCell scope="row">{key}</TableCell>
              <TableCell scope="row">{tableRows[key]}</TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

export default StateTable;
