import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { InstrumentResourceParametersInput } from "../common/types";

const queryAuth = (isPremium: any) => {
  const GET_ADDITIONAL_DATA_FOR_INSTRUMENT_SUGGESTIONS = gql`
  query getAdditionalDataForInstrumentSuggestions(
    $startingCharacters: String
    $resourceParameters: InstrumentResourceParametersInput!
  ) {
    getAdditionalDataForInstrumentSuggestions(
      startingCharacters: $startingCharacters
      resourceParameters: $resourceParameters
    ) {
      countries @include(if: ${isPremium})
      currencies @include(if: ${isPremium})
      totalIssuedAmount
    }
  }
`;
  return GET_ADDITIONAL_DATA_FOR_INSTRUMENT_SUGGESTIONS;
};

interface Params {
  startingCharacters: string;
  resourceParameters: InstrumentResourceParametersInput;
}

interface GetAdditionalDataForInstrumentSuggestions {
  getAdditionalDataForInstrumentSuggestions: {
    countries: Array<string>;
    currencies: Array<string>;
    totalIssuedAmount: number;
  };
}

export const useLazyGetAdditionalDataForInstrumentSuggestionsQuery = (
  isPremium: any
) =>
  useLazyQuery<GetAdditionalDataForInstrumentSuggestions, Params>(
    queryAuth(isPremium)
  );
