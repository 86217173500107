import { gql } from "apollo-boost";
import { Customer } from "../common/types";
import { useMutation } from "@apollo/react-hooks";

const UPDATE_CUSTOMER = gql`
  mutation updateCustomer($paymentMethodToken: String!) {
    updateCustomer(paymentMethodToken: $paymentMethodToken) {
      created
      email
      id
      name
      error
      paymentMethodDetails {
        brand
        country
        cvcCheck
        expMonth
        expYear
        id
        last4Digits
      }
    }
  }
`;

interface Params {
  paymentMethodToken: string;
}

interface UpdateCustomer {
  updateCustomer: Customer;
}

export const useMutationUpdateCustomer = () => useMutation<UpdateCustomer, Params>(UPDATE_CUSTOMER);
