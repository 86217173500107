import { InputLabel, MuiThemeProvider } from "@material-ui/core";
import React, { useContext } from "react";
import { SubscribeContext } from "../../common/subcriptionProvider";
import {
  getInstrCode,
  instrSubcategoryFields,
  instrTypeFields,
  TYPE_CODE,
  TYPE_COL,
} from "../../components/InstrumentsTypeAndCategory/InstrumentSelectFunctions";
import FilterLoader from "../../components/loader/FilterLoader";
import {
  formControlTheme,
  SelectItem,
  SelectLabel,
  SelectStyle,
  StyledFormControl,
  StyledMenuItem,
  TableCountryCell,
  TableCurrencyCell,
  TableInstrumentCategoryCell,
  TableInstrumentTypCeell,
  TableMainCell,
  TableTradingVenueCell,
  UpgradeMessage,
  useStyles,
} from "./NewInstrumentFilter.css";
import { INewInstrumentFilter } from "./NewInstrumentMonitorPageInterface";

const NewInstrumentFilter: React.FC<INewInstrumentFilter> = ({
  country,
  currency,
  tradingVenue,
  micCodesFullNames,
  instrumentType,
  instrumentCategory,
  setCountry,
  setCurrency,
  setTradingVenue,
  setInstrumentType,
  setInstrumentCategory,
  selectedTradingVenueFullName,
  setSelectedTradingVenueFullName,
  filtersInitData,
  setPage,
}) => {
  const classes = useStyles();
  const { isUserPremium } = useContext(SubscribeContext);
  const code = getInstrCode(instrTypeFields, instrumentType, TYPE_COL, TYPE_CODE);
  const handleCountryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCountry(event.target.value as string);
    setPage(0);
  };

  const handleCurrencyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCurrency(event.target.value as string);
    setPage(0);
  };

  const handleTradingVenueChange = (event: React.ChangeEvent<{ value: unknown }>, child: any) => {
    const tradingVenueName = child.props.name;
    const tradingVenueMicCode = child.props.miccode;
    setSelectedTradingVenueFullName(`${tradingVenueName} (${tradingVenueMicCode})`);
    setTradingVenue(tradingVenueMicCode);
    setPage(0);
  };

  const handleInstrumentTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setInstrumentType(event.target.value as string);
    setPage(0);
  };

  const handleInstrumentCategoryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setInstrumentCategory(event.target.value as string);
    setPage(0);
  };

  const isDisabled = () => {
    if (!isUserPremium) {
      return true;
    }
    if (isUserPremium && instrumentType.length !== 0) {
      return false;
    }
    return true;
  };

  return (
    <>
      <TableCountryCell>
        <MuiThemeProvider theme={formControlTheme}>
          <StyledFormControl variant="outlined">
            <SelectLabel>
              <InputLabel>Country</InputLabel>
            </SelectLabel>
            <SelectStyle
              id="NewInstrCountry"
              value={filtersInitData && filtersInitData.countries ? country : ""}
              onChange={handleCountryChange}
              label="Currency"
              disabled={!isUserPremium}
              MenuProps={{ classes: { paper: classes.menuPaper } }}
            >
              {filtersInitData && filtersInitData.countries ? (
                filtersInitData.countries.map((countryItem: string) => (
                  <StyledMenuItem key={countryItem} value={countryItem}>
                    {countryItem}
                  </StyledMenuItem>
                ))
              ) : (
                <FilterLoader />
              )}
            </SelectStyle>
          </StyledFormControl>
        </MuiThemeProvider>
      </TableCountryCell>
      <TableCurrencyCell>
        <MuiThemeProvider theme={formControlTheme}>
          <StyledFormControl variant="outlined">
            <SelectLabel>
              <InputLabel>Currency</InputLabel>
            </SelectLabel>
            <SelectStyle
              id="NewInstrCurrency"
              value={filtersInitData && filtersInitData.currencies ? currency : ""}
              onChange={handleCurrencyChange}
              label="Currency"
              disabled={!isUserPremium}
              MenuProps={{ classes: { paper: classes.menuPaper } }}
            >
              {filtersInitData && filtersInitData.currencies ? (
                filtersInitData.currencies.map((currencyItem: string) => (
                  <StyledMenuItem key={currencyItem} value={currencyItem}>
                    {currencyItem}
                  </StyledMenuItem>
                ))
              ) : (
                <FilterLoader />
              )}
            </SelectStyle>
          </StyledFormControl>
        </MuiThemeProvider>
      </TableCurrencyCell>
      <TableTradingVenueCell>
        <MuiThemeProvider theme={formControlTheme}>
          <StyledFormControl variant="outlined">
            <SelectLabel>
              <InputLabel>Trading Venue</InputLabel>
            </SelectLabel>
            <SelectStyle
              id="NewInstrTrading"
              value={filtersInitData && filtersInitData.tradingVenues && micCodesFullNames ? tradingVenue : ""}
              renderValue={() => (selectedTradingVenueFullName ? selectedTradingVenueFullName : "")}
              onChange={handleTradingVenueChange}
              label="Trading Venue"
              disabled={!isUserPremium}
              MenuProps={{ classes: { paper: classes.menuPaper } }}
            >
              {filtersInitData && filtersInitData.tradingVenues && micCodesFullNames ? (
                filtersInitData.tradingVenues.map((micCode: string) => (
                  <StyledMenuItem key={micCode} value={micCode} name={micCodesFullNames[micCode]} miccode={micCode}>
                    <SelectItem>
                      <span>{micCodesFullNames[micCode]}</span>
                      <span>{micCode}</span>
                    </SelectItem>
                  </StyledMenuItem>
                ))
              ) : (
                <FilterLoader />
              )}
            </SelectStyle>
          </StyledFormControl>
        </MuiThemeProvider>
      </TableTradingVenueCell>
      <TableInstrumentTypCeell>
        <MuiThemeProvider theme={formControlTheme}>
          <StyledFormControl variant="outlined">
            <SelectLabel>
              <InputLabel>Instr. Type</InputLabel>
            </SelectLabel>
            <SelectStyle
              id="NewInstrType"
              value={instrumentType}
              onChange={handleInstrumentTypeChange}
              label="Instr. Type"
              disabled={!isUserPremium}
              MenuProps={{ classes: { paper: classes.menuPaper } }}
            >
              {instrTypeFields ? (
                instrTypeFields.map((item: any) => (
                  <StyledMenuItem key={item.typeCode} value={item.type}>
                    {item.type}
                  </StyledMenuItem>
                ))
              ) : (
                <FilterLoader />
              )}
            </SelectStyle>
          </StyledFormControl>
        </MuiThemeProvider>
      </TableInstrumentTypCeell>
      <TableInstrumentCategoryCell>
        <MuiThemeProvider theme={formControlTheme}>
          <StyledFormControl variant="outlined">
            <SelectLabel>
              <InputLabel>{instrumentType.length !== 0 ? "Instr. Category" : "Choose Instr. type"}</InputLabel>
            </SelectLabel>
            <SelectStyle
              id="NewInstrCategory"
              value={instrumentCategory}
              onChange={handleInstrumentCategoryChange}
              label="Instr. Category"
              disabled={isDisabled()}
              MenuProps={{ classes: { paper: classes.menuPaper } }}
            >
              {instrSubcategoryFields(code) ? (
                instrSubcategoryFields(code).map((item: any) => (
                  <StyledMenuItem key={item.catCode} value={item.category}>
                    {item.category}
                  </StyledMenuItem>
                ))
              ) : (
                <FilterLoader />
              )}
            </SelectStyle>
          </StyledFormControl>
        </MuiThemeProvider>
      </TableInstrumentCategoryCell>
      <TableMainCell>{!isUserPremium && <UpgradeMessage>Upgrade to premium to unlock</UpgradeMessage>}</TableMainCell>
      <TableMainCell></TableMainCell>
      <TableMainCell></TableMainCell>
      <TableMainCell></TableMainCell>
    </>
  );
};

export default NewInstrumentFilter;
