import { gql } from "apollo-boost";
import { PremiumPlan } from "../common/types";
import { useLazyQuery } from "@apollo/react-hooks";

const GET_PREMIUM_PLAN = gql`
 query getPremiumPlan{
    getPremiumPlan {
      currency
      id
      interval
      name
      price
    }
  }
`;

interface IPremiumPlan {
  getPremiumPlan: PremiumPlan;
}

export const useLazyGetPremiumPlanQuery = () =>
  useLazyQuery<IPremiumPlan>(GET_PREMIUM_PLAN);
