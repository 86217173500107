import React from "react";
import { Page } from "../../components";
import { Grid, Card, CardHeader, Typography, CardContent } from "@material-ui/core";
import { InformationText, CircleIcon, FeatureContainer, FeatureTitle, Feature } from "./ProductsPage.css";
import ProductsTiles from "./ProductsTiles";

const ProductsPage: React.FC = () => {
  return (
    <Page>
      <Grid container spacing={2} justify="center">
        <Grid item md={12}>
          <Card>
            <CardHeader
              title={
                <Typography variant="h6">
                  Welcome to eFIRDS – a European database on financial instruments reference data.
                </Typography>
              }
            />
            <CardContent>
              <InformationText>
                We source data directly from <span>ESMA FIRDS, FCA FIRDS, GLEIF</span> and other relevant “golden
                source” databases to provide you updated and reliable European reference data and the benefits of the
                datasets combined.
              </InformationText>
              <FeatureContainer>
                <FeatureTitle>Key features:</FeatureTitle>
                <Feature>
                  <CircleIcon />
                  <div>Search Issuers, LEI codes and ISINs listed on any recognized trading venue in EU and UK</div>
                </Feature>
                <Feature>
                  <CircleIcon />
                  <div>Verify regulatory compliance (MIFID II, EMIR, SFTR and more)</div>
                </Feature>
                <Feature>
                  <CircleIcon />
                  <div>View all instruments by issuers or company hierarchy structures</div>
                </Feature>
                <Feature>
                  <CircleIcon />
                  <div>Access data mapped from multiple sources</div>
                </Feature>
              </FeatureContainer>
            </CardContent>
          </Card>
        </Grid>
        <ProductsTiles />
      </Grid>
    </Page>
  );
};

export { ProductsPage };
