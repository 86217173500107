import { FormControl, MenuItem, Select } from "@material-ui/core";
import styled from "styled-components";
import mediaQuery from "../../common/mediaQuery";

interface FormControlInterface {
  cell: string;
  table: string;
}

interface SelectStyleInterface {
  disabled: boolean;
}

export const StyledFormControl = styled(FormControl)<FormControlInterface>`
  display: flex;
  font-size: 0.8rem;

  div {
    max-height: 31px;
    max-width: ${(props) => (props.cell === "category" ? "220px" : "170px")};
    min-width: ${(props) => props.table === "OTHER" && props.cell === "category" && "130px"};
  }

  ${mediaQuery.SM_LANDSCAPE} {
    div {
      max-height: 28px;
    }
  }

  ${mediaQuery.SM} {
    div {
      max-height: 28px;
    }
  }
`;

export const SelectLabel = styled.div`
  label {
    font-size: 14px;
    top: -11px;
    color: #b7b7b6;

    ${mediaQuery.SM_LANDSCAPE} {
      font-size: 0.65rem;
    }

    ${mediaQuery.SM} {
      font-size: 0.65rem;
    }
  }
`;

export const SelectStyle = styled(Select)<SelectStyleInterface>`
  background-color: ${(props) => (props.disabled ? "#e3e3e3" : "")};

  ${mediaQuery.SM_LANDSCAPE} {
    font-size: 0.65rem;
  }

  ${mediaQuery.SM} {
    font-size: 0.65rem;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  font-size: 0.8rem;
`;
