export const TYPE_COL = "type";
export const CATEGORY_COL = "category";
export const TYPE_CODE = "typeCode";
export const CATEGORY_CODE = "catCode";

export const instrTypeFields = [
  { typeCode: "E", type: "Equities" },
  { typeCode: "C", type: "Collective investment vehicles" },
  { typeCode: "D", type: "Debt instruments" },
  { typeCode: "R", type: "Entitlements (rights)" },
  { typeCode: "O", type: "Listed options" },
  { typeCode: "F", type: "Futures" },
  { typeCode: "S", type: "Swaps" },
  { typeCode: "H", type: "Non-listed and complex listed options" },
  { typeCode: "I", type: "Spot" },
  { typeCode: "J", type: "Forwards" },
  { typeCode: "K", type: "Strategies" },
  { typeCode: "L", type: "Financing" },
  { typeCode: "T", type: "Referential instruments" },
  { typeCode: "M", type: "Others" },
];

export const instrTypeOtherFields = [
  { typeCode: "C", type: "Collective investment vehicles" },
  { typeCode: "R", type: "Entitlements (rights)" },
  { typeCode: "O", type: "Listed options" },
  { typeCode: "F", type: "Futures" },
  { typeCode: "S", type: "Swaps" },
  { typeCode: "H", type: "Non-listed and complex listed options" },
  { typeCode: "I", type: "Spot" },
  { typeCode: "J", type: "Forwards" },
  { typeCode: "K", type: "Strategies" },
  { typeCode: "L", type: "Financing" },
  { typeCode: "T", type: "Referential instruments" },
  { typeCode: "M", type: "Others" },
];

export const subgroupE = [
  { catCode: "ES", category: "Common/Ordinary shares" },
  { catCode: "EP", category: "Preferred/Preference shares" },
  { catCode: "EC", category: "Common/Ordinary convertible shares" },
  { catCode: "EF", category: "Preferred / Preference convertible shares" },
  { catCode: "EL", category: "Limited partnership units" },
  { catCode: "ED", category: "Depositary receipts on equities" },
  { catCode: "EY", category: "Structured instruments (participation)" },
  { catCode: "EM", category: "Others (miscellaneous)" },
];

const subgroupC = [
  { catCode: "CI", category: "Standard (vanilla) investment funds/ mutual funds" },
  { catCode: "CH", category: "Hedge funds" },
  { catCode: "CB", category: "Real estate investment trusts (REITs)" },
  { catCode: "CE", category: "Exchange-traded funds (ETFs)" },
  { catCode: "CS", category: "Pension funds" },
  { catCode: "CF", category: "Funds of funds" },
  { catCode: "CP", category: "Private equity funds" },
  { catCode: "CM", category: "Others (miscellaneous)" },
];

export const subgroupD = [
  { catCode: "DB", category: "Bonds" },
  { catCode: "DC", category: "Convertible bonds" },
  { catCode: "DW", category: "Bonds with warrants attached" },
  { catCode: "DT", category: "Medium term notes" },
  { catCode: "DS", category: "Structured products (capital protection)" },
  { catCode: "DE", category: "Structured products (without capital protection)" },
  { catCode: "DG", category: "Mortgage-backed securities" },
  { catCode: "DA", category: "Asset-backed securities" },
  { catCode: "DN", category: "Municipal bonds" },
  { catCode: "DD", category: "Depositary receipts on debt instruments" },
  { catCode: "DM", category: "Other (miscellaneous)" },
  { catCode: "DY", category: "Money Market Instruments" },
];

const subgroupR = [
  { catCode: "RA", category: "Allotments (Bonus Rights)" },
  { catCode: "RS", category: "Subscription Rights" },
  { catCode: "RP", category: "Purchase Rights" },
  { catCode: "RW", category: "Warrants" },
  { catCode: "RF", category: "Mini-future certificates/constant leverage certificates" },
  { catCode: "RD", category: "Depositary receipts on entitlements" },
  { catCode: "RM", category: "Other (miscellaneous)" },
];

const subgroupO = [
  { catCode: "OC", category: "Call options" },
  { catCode: "OP", category: "Put options" },
  { catCode: "OM", category: "Other (miscellaneous)" },
];

const subgroupF = [
  { catCode: "FF", category: "Financial futures" },
  { catCode: "FC", category: "Commodities futures" },
];

const subgroupS = [
  { catCode: "SR", category: "Rates" },
  { catCode: "ST", category: "Commodities" },
  { catCode: "SE", category: "Equity" },
  { catCode: "SC", category: "Credit" },
  { catCode: "SF", category: "Foreign exchange" },
  { catCode: "SM", category: "Other (miscellaneous)" },
];

const subgroupH = [
  { catCode: "HR", category: "Rates" },
  { catCode: "HT", category: "Commodities" },
  { catCode: "HE", category: "Equity" },
  { catCode: "HC", category: "Credit" },
  { catCode: "HF", category: "Foreign exchange" },
  { catCode: "HM", category: "Other (miscellaneous)" },
];

const subgroupI = [
  { catCode: "IF", category: "Foreign exchange" },
  { catCode: "IT", category: "Commodities" },
];

const subgroupJ = [
  { catCode: "JE", category: "Equity" },
  { catCode: "JF", category: "Foreign exchange" },
  { catCode: "JC", category: "Credit" },
  { catCode: "JR", category: "Rates" },
  { catCode: "JT", category: "Commodities" },
];

const subgroupK = [
  { catCode: "KR", category: "Rates" },
  { catCode: "KT", category: "Commodities" },
  { catCode: "KE", category: "Equity" },
  { catCode: "KC", category: "Credit" },
  { catCode: "KF", category: "Foreign exchange" },
  { catCode: "KY", category: "Mixed assets" },
  { catCode: "KM", category: "Other (miscellaneous)" },
];

const subgroupL = [
  { catCode: "LL", category: "Loan-lease" },
  { catCode: "LR", category: "Repurchase agreements" },
  { catCode: "LS", category: "Securities lending" },
];

const subgroupT = [
  { catCode: "TC", category: "Currencies" },
  { catCode: "TT", category: "Commodities" },
  { catCode: "TR", category: "Interest rates" },
  { catCode: "TI", category: "Indices" },
  { catCode: "TB", category: "Baskets" },
  { catCode: "TD", category: "Stock dividends" },
  { catCode: "TM", category: "Others (miscellaneous)" },
];

const subgroupM = [
  { catCode: "MC", category: "Combined instruments" },
  { catCode: "MM", category: "Other assets" },
];

export const instrCategoryFields = [
  ...subgroupE,
  ...subgroupC,
  ...subgroupD,
  ...subgroupR,
  ...subgroupO,
  ...subgroupF,
  ...subgroupS,
  ...subgroupH,
  ...subgroupI,
  ...subgroupJ,
  ...subgroupK,
  ...subgroupL,
  ...subgroupT,
  ...subgroupM,
];

export const instrSubcategoryFields = (subgroupCode: string) => {
  switch (subgroupCode) {
    case "E":
      return subgroupE;
    case "C":
      return subgroupC;
    case "D":
      return subgroupD;
    case "R":
      return subgroupR;
    case "O":
      return subgroupO;
    case "F":
      return subgroupF;
    case "S":
      return subgroupS;
    case "H":
      return subgroupH;
    case "I":
      return subgroupI;
    case "J":
      return subgroupJ;
    case "K":
      return subgroupK;
    case "L":
      return subgroupL;
    case "T":
      return subgroupT;
    case "M":
      return subgroupM;
    default:
      return [];
  }
};

export const instrColumnMap = (instrClassification: string, column: string) => {
  const classFirstChar = instrClassification.charAt(0);
  const classTwoChars = instrClassification.substring(0, 2);
  let result = "";

  column === TYPE_COL &&
    instrTypeFields.map((item: any) => {
      if (item.typeCode === classFirstChar) {
        result = item.type;
      }
    });

  column === CATEGORY_COL &&
    instrCategoryFields.map((item: any) => {
      if (item.catCode === classTwoChars) {
        result = item.category;
      }
    });

  return result;
};

export const getInstrCode = (instrArr: any, value: string, column: string, code: string) => {
  const result = value ? instrArr.filter((item: any) => item[column] === value)[0][code] : "";
  return result;
};
