import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { IssuerRelationsBasicInterface } from "../components/StructureBasic/StructureBasicInterface";

const queryAuth = (isPremium: any) => {
  const GET_ISSUER_RELATIONS_BASIC = gql`
  query getIssuerRelationsBasic($lei: String!) {
    getIssuerRelationsBasic(lei: $lei) {
      lei @include(if: ${isPremium})
      name @include(if: ${isPremium})
      hasMoreDirectChildren @include(if: ${isPremium})
      __typename @include(if: ${isPremium})
      instrumentsAmount @include(if: ${isPremium}) {
        debt
        equities
      }
      ultimateParent @include(if: ${isPremium}) {
        name
        lei
        instrumentsAmount  {
          debt
          equities
        }
      }
      directParent @include(if: ${isPremium}) {
        name
        lei
        hasMoreDirectChildren
        instrumentsAmount {
          debt
          equities
        }
        directChildren {
          name
          lei
          instrumentsAmount {
            debt
            equities
          }
        }
      }
      directChildren @include(if: ${isPremium}) {
        name
        lei
        instrumentsAmount {
          debt
          equities
        }
      }
      ultimateChildren @include(if: ${isPremium}) {
        name
        lei
        instrumentsAmount {
          debt
          equities
        }
      }
    }
  }
`;
  return GET_ISSUER_RELATIONS_BASIC;
};

interface Param {
  lei: string;
}

interface GetIssuerRelationsBasic {
  getIssuerRelationsBasic: IssuerRelationsBasicInterface;
}

export const useLazyGetIssuerRelationsBasicQuery = (isPremium: any) =>
  useLazyQuery<GetIssuerRelationsBasic, Param>(queryAuth(isPremium));
