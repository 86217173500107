import { LinearProgress } from "@material-ui/core";
import styled from "styled-components";
import mediaQuery from "../../../../common/mediaQuery";
import { BorderLinearProgressInterface } from "./TransparencyLinearProgressInterface";

export const BorderLinearContainer = styled.div`
  margin-bottom: 30px;
  ${mediaQuery.SM_LANDSCAPE} {
    margin-bottom: 20px;
  }

  ${mediaQuery.SM} {
    margin-bottom: 20px;
  }
`;

export const BorderLinearProgress = styled(LinearProgress)<BorderLinearProgressInterface>`
  height: 25px;
  border-radius: 10px;
  background-color: ${(props) => (props.value === 0 ? "#E3E3E3" : "#b498ff")};

  .MuiLinearProgress-bar {
    border-radius: 5px;
    background-color: #3f18aa;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    height: 11px;
    border-radius: 5px;
  }

  ${mediaQuery.SM} {
    height: 11px;
    border-radius: 5px;
  }
`;

export const Legend = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3px;
`;

export const Title = styled.span`
  font-weight: 400;
  display: flex;
  margin: 5px 0;

  ${mediaQuery.SM_LANDSCAPE} {
    font-size: 10px;
  }

  ${mediaQuery.SM} {
    font-size: 10px;
  }
`;

export const SubTitle = styled.strong`
  font-weight: 700;
  margin-right: 3px;

  ${mediaQuery.SM_LANDSCAPE} {
    font-size: 10px;
  }

  ${mediaQuery.SM} {
    font-size: 10px;
  }
`;
