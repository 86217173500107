import { Card, Table, TableHead, TableBody, TableRow, CardHeader } from "@material-ui/core";
import SubjectIcon from "@material-ui/icons/Subject";
import styled from "styled-components";
import mediaQuery from "../../../common/mediaQuery";

export const CardStyle = styled(Card)`
  width: 100%;
  height: 640px;
  padding: 15px;
  position: relative;

  ${mediaQuery.MUI_LG} {
    height: auto;
  }

  ${mediaQuery.SM} {
    padding: 5px;
  }
`;

export const TableWrapper = styled.div`
  overflow-y: auto;
  height: 550px;

  &::-webkit-scrollbar {
    width: 0.7em;
    height: 0.7em;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    border-radius: 30px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: none;
    border-radius: 30px;
  }

  ${mediaQuery.MUI_LG} {
    height: auto;
  }

  ${mediaQuery.MD} {
    overflow-x: scroll;
  }
`;

export const StyledTable = styled(Table)`
  ${mediaQuery.SM} {
    width: 100%;

    & th,
    td {
      width: 200px;
    }
  }
`;

export const StyledTableHead = styled(TableHead)`
  th {
    position: sticky;
    top: 0;
    font-size: 12px;
    padding: 5px;
    background: #f7f8f6;
  }

  ${mediaQuery.MD} {
    th {
      font-size: 11px;
    }
  }

  ${mediaQuery.SM} {
    th {
      font-size: 10px;
    }
  }
`;

export const StyledCardHeader = styled(CardHeader)`
  ${mediaQuery.SM} {
    h5 {
      font-size: 15px;
    }
  }
`;

export const StyledTableBody = styled(TableBody)`
  tr:last-child > td {
    border-bottom: none;
  }

  td {
    font-size: 12px;
    padding: 5px;
    white-space: nowrap;
  }

  td > p {
    white-space: nowrap;
    margin: 3px;
  }

  ${mediaQuery.MD} {
    td {
      font-size: 11px;
    }
  }

  ${mediaQuery.SM} {
    td {
      font-size: 10px;
    }
  }
`;

export const EmptyCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 75%;
  color: #cbcbcb;
`;

export const SubjectIconStyle = styled(SubjectIcon)`
  color: inherit;
  font-size: 54px;
`;
export const TransparencyCalculationWrapper = styled.div`
  & > div:first-child {
    padding-bottom: 10px;
  }

  ${mediaQuery.SM} {
    .MuiGrid-spacing-xs-2 > .MuiGrid-item {
      padding: 4px;
    }
  }
`;

export const IconTitle = styled.div`
  font-size: 18px;

  ${mediaQuery.SM_LANDSCAPE} {
    font-size: 12px;
  }

  ${mediaQuery.SM} {
    font-size: 12px;
  }
`;

export const StyledTableRow = styled(TableRow)`
  cursor: pointer;

  &.selected {
    background-color: rgba(57, 34, 164, 0.1);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  ${mediaQuery.MUI_LG} {
    td > .MuiSvgIcon-root {
      transform: rotate(90deg);
    }
  }
`;

export const LockedFileList = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 75%;

  & > svg {
    height: 100px;
    width: 100px;
  }
`;
