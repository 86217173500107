import { Card, CardContent, CardHeader, Chip, makeStyles, TableBody, TableCell, TableRow } from "@material-ui/core";
import styled, { css } from "styled-components";
import mediaQuery from "../../common/mediaQuery";
interface LoaderOverlayInterface {
  loaderPosition: number;
}

interface TableRowInterface {
  table: string;
}

export const CardStyle = styled(Card)`
  padding: 10px 10px 0 10px;
  min-height: 230px;
`;

export const CardHeaderStyle = styled(CardHeader)`
  padding: 0;
  margin-bottom: 20px;

  ${mediaQuery.MD} {
    margin-bottom: 5px;
  }
`;

export const CardContentStyle = styled(CardContent)`
  position: relative;
  min-height: 165px;

  ${mediaQuery.EXTRA_LG} {
    overflow-x: scroll;
    padding: 0;
  }
`;

export const CardHeaderContent = styled.div`
  display: flex;
  align-items: baseline;
  padding: 16px;

  ${mediaQuery.SM_LANDSCAPE} {
    padding: 0;
  }

  ${mediaQuery.SM} {
    padding: 0;
  }
`;

export const CardHeaderTitle = styled.h5`
  font-size: 1.6rem;
  font-weight: 700;
  margin: 0;
  white-space: nowrap;

  ${mediaQuery.SM} {
    font-size: 1.1rem;
  }
`;

export const CardHeaderInfo = styled.span`
  font-size: 0.9rem;
  margin-left: 5px;

  ${mediaQuery.SM} {
    font-size: 0.5rem;
  }
`;

export const TableRowStyle = styled(TableRow)<TableRowInterface>`
  cursor: pointer;

  & > th > *,
  & > td > * {
    pointer-events: none;
  }

  & > th,
  & > td {
    pointer-events: all;
  }

  td {
    &:nth-child(1) {
      width: 50px;
    }
    &:nth-child(2) {
      min-width: 150px;
    }
  }

  ${(props) =>
    props.table === "OTHER"
      ? css`
          td {
            &:nth-child(3) {
              max-width: 220px;
            }
          }
        `
      : css`
          td {
            &:nth-child(2) {
              width: 170px;
            }
          }
        `}
`;

export const TableFiltersRowStyle = styled(TableRow)<TableRowInterface>`
  cursor: pointer;

  td {
    &:nth-child(1) {
      width: 50px;
    }
    &:nth-child(2) {
      min-width: 150px;
    }
  }

  ${(props) =>
    props.table === "OTHER"
      ? css`
          td {
            &:nth-child(3) {
              max-width: 220px;
            }
          }
        `
      : css`
          td {
            &:nth-child(2) {
              width: 170px;
            }
          }
        `}
`;

export const InstrumentIsinText = styled.span`
  font-size: 14px;
  font-weight: 700;
  display: block;

  ${mediaQuery.MD} {
    font-size: 0.8rem;
  }

  ${mediaQuery.SM} {
    font-size: 0.7rem;
  }
`;

export const InstrumentNameText = styled.span`
  font-size: 14px;

  ${mediaQuery.MD} {
    font-size: 0.7rem;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    font-size: 0.6rem;
  }

  ${mediaQuery.SM} {
    font-size: 0.6rem;
  }
`;

export const CellText = styled.div`
  font-size: 14px;
  white-space: nowrap;

  ${mediaQuery.SM_LANDSCAPE} {
    font-size: 0.6rem;
  }

  ${mediaQuery.SM} {
    font-size: 0.6rem;
  }
`;

export const TotalIssuedWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const TotalIssued = styled.div`
  margin: 20px 0 10px 0;
  text-align: right;
  font-size: 16px;
  max-height: 160px;
  overflow: hidden;
  width: 300px;
  span {
    font-weight: 700;
    margin-right: 5px;
  }

  ${mediaQuery.MD} {
    font-size: 0.9rem;
  }

  ${mediaQuery.SM} {
    font-size: 0.7rem;
  }
`;

export const Dots = styled.div`
  font-size: 30px;
  padding-right: 8px;
  margin-top: -20px;
`;

export const TotalAmount = styled.div`
  font-size: 15px;
  margin: 3px 10px 0 0;

  ${mediaQuery.MD} {
    font-size: 0.8rem;
  }

  ${mediaQuery.SM_LANDSCAPE} {
    font-size: 0.6rem;
  }

  ${mediaQuery.SM} {
    font-size: 0.6rem;
  }
`;

export const ActiveFiltersContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const Filter = styled.div`
  margin-right: 10px;
`;

export const ClearFilters = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: #3f18aa;
  cursor: pointer;
`;

export const StyledChip = styled(Chip)`
  background: #b498ff29;
  margin: 0 5px 5px 0;
  height: 25px;

  & svg {
    color: #b8aae0;
  }
`;

export const TableHeaderCell = styled(TableCell)`
  font-size: 15px;
  font-weight: 700;
  white-space: nowrap;

  ${mediaQuery.SM_LANDSCAPE} {
    font-size: 0.6rem;

    &.MuiTableCell-sizeSmall {
      padding: 6px;
      line-height: 1.3;
      white-space: nowrap;
    }
  }

  ${mediaQuery.SM} {
    font-size: 0.6rem;

    &.MuiTableCell-sizeSmall {
      padding: 6px;
      line-height: 1.3;
      white-space: nowrap;
    }
  }
`;

export const TableMainCell = styled(TableCell)`
  ${mediaQuery.MD} {
    &[data-emptycell="true"] {
      min-width: 120px;
    }
  }
`;

export const LoaderOverlay = styled.div<LoaderOverlayInterface>`
  position: absolute;
  top: ${(props) => (props.loaderPosition ? "50%" : "145%")};
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

export const EmptyCard = styled.div`
  text-align: center;
  color: #cbcbcb;
  margin: 10px 0;
`;

export const Message = styled.div`
  font-size: 16px;
  font-weight: 700;
`;

export const useTableStyles = makeStyles({
  loading: {
    filter: "blur(1px)",
    background: "rgba(0,0,0, 0.05)",
  },
  notLoading: {
    filter: "blur(0)",
    transition: "all .6s",
  },
});

export const TooltipTitle = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 130px;
  font-size: 12px;
  justify-content: flex-end;

  span {
    padding: 2px 0;
    display: block;
  }
`;

export const StyledTableBody = styled(TableBody)`
  tr:last-child td {
    border-bottom: none;
  }
`;

export const UpgradeMessage = styled.p`
  color: #959595;
  white-space: nowrap;
  font-size: 13px;
  letter-spacing: 2px;
  line-height: normal;
  margin: 0;

  ${mediaQuery.SM_LANDSCAPE} {
    font-size: 11px;
  }

  ${mediaQuery.SM} {
    font-size: 11px;
  }
`;

export const StyledUpgradeToUnlockRow = styled(TableRow)`
  th {
    border-bottom: none;
    padding: 3px 0;
  }
`;
