import { AppBar, Grid, Toolbar } from "@material-ui/core";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import useMedia from "use-media";

import { LoginButton } from "../LoginButton/LoginButton";
import NavigationMenu from "../NavigationMenu/NavigationMenu";
import PageWidthWrapper from "../PageWidthWrapper";
import UserSettingReminderDialog from "../UserSettingReminderDialog/UserSettingReminderDialog";
import { HeaderWrapperBox, LogoStyles, StyledContainer, useStyles } from "./PageHeader.css";
import logo from "../../assets/images/logo.png";
import SearchBar from "../SearchBar/SearchBar";

interface PageHeaderProps {
  hideBanner?: boolean;
  hideSearch?: boolean;
}

function PageHeader({ hideBanner, hideSearch }: PageHeaderProps) {
  const classes = useStyles();

  const mediaXL = useMedia({ minWidth: 1441, maxWidth: 1919 });
  let header: any;
  let lastScroll = 0;

  useEffect(() => {
    header = document.getElementById("page-header");
  }, []);

  window.addEventListener("scroll", () => {
    const currentScroll = window.pageYOffset;
    if (currentScroll <= 0) {
      return;
    }
    if (header && currentScroll > lastScroll && !header.classList.contains("scroll-down")) {
      // down
      header.classList.remove("scroll-up");
      header.classList.add("scroll-down");
    } else if (header && currentScroll < lastScroll && header.classList.contains("scroll-down")) {
      // up
      header.classList.remove("scroll-down");
      header.classList.add("scroll-up");
    }
    lastScroll = currentScroll;
  });

  return (
    <HeaderWrapperBox component="header" display="block" displayPrint="none" media={mediaXL ? 1 : 0}>
      <AppBar id="page-header" position="static">
        <PageWidthWrapper>
          <Toolbar>
            <Link id="header__logo" to="/dashboard">
              <LogoStyles>
                <img src={logo} alt="efirds-logo" />
              </LogoStyles>
            </Link>
            {!hideSearch ? (
              <Grid item>
                <SearchBar />
              </Grid>
            ) : null}
            <StyledContainer>
              <NavigationMenu />
              <LoginButton />
              <div style={{ position: "relative" }}>
                <UserSettingReminderDialog />
              </div>
            </StyledContainer>
          </Toolbar>
        </PageWidthWrapper>
        {!hideBanner ? (
          <Toolbar className={classes.banner}>
            This is an open beta version of Nordic Trustee Firds App. After product launch access may be restricted.
          </Toolbar>
        ) : null}
      </AppBar>
    </HeaderWrapperBox>
  );
}

export { PageHeader };
